import axios from 'axios'
import AuthService from '@/services/AuthService';
const instance = axios.create({
    baseURL: process.env.VUE_APP_ASSESSMENT_API_URL
});


export default {
    createAssessment: async function (formData) {
        try {
            //console.log("pushing data",formData)
            const response = await instance.post('assessment', formData, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
   
    

    createScreening: async function (screeningConfiguration,assessmentId) {
        try {

            const response = await instance.put('assessment-screening?id='+assessmentId, {screeningConfiguration}, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    screeningQuestions: async function (id) {
        try {  
           
            const response = await instance.get('screening-question?id='+id, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    mainsQuestions: async function (id) {
        try {  
           
            const response = await instance.get('mains-question?id='+id, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },



    createMains: async function (mainsConfiguration,assessmentId) {
        try {
            console.log("mains data","id=",)
            const response = await instance.put('assessment-mains?id='+assessmentId, {mainsConfiguration}, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    getAssessments: async function () {

        try {
            const response = await instance.get('assessments',{
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            alert("Something went wrong")
            return error.response;
        }
    },
    deleteAssessment: async function (id) {

        try {
            const response = await instance.delete('assessment?id=' + id, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    
}