<template>
  <div class="qp-container">
    <v-card height="64px" width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Question Preview</p>
        
      </v-card-title>
      <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center" style="position: fixed; right: 0">
        <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
          <v-hover>
            <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg" />
          </v-hover>
        </v-badge>

        <div>
          <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>

    <!-- <v-container class="pt-6 pb-0"> -->
    <div fluid class="pad px-8 d-flex">

    	<v-card elevation="2"
		  outlined
		  shaped
		  tile 
		  class="rounded-xl mr-4 pa-12"
		  width=400px

		  >	
		 <span >Campaign Name</span>
		  <v-divider class="mt-2"></v-divider>
		 <div class="card-container">
		 
		 <div class="card mt-6">
		 	<v-card>
		 		Answered, Skipped and Bookmarked Questions count will appear here
		 	</v-card>
		 </div>
		 <div class="card">
		 	<v-card>
		 		List of Questions will appear here
		 	</v-card>
		 </div>
		 </div>
    	</v-card>

    	<v-card elevation="2"
		  outlined
		  shaped
		  tile 
		  class="rounded-xl pa-12"
		  width=100%
		  >
		   <!-- <v-icon>mdi-close</v-icon> -->
		  <!-- <span>Cross Icon</span> -->
		  <v-row class="align-center text-align-center px-7">
              <v-card class="pa-0" width="70" elevation="0">
                    <v-text-field hide-details="" label="HH" readonly :value=hours outlined rounded
                      class="rounded-xl centered-input timmer "
                      :style="seconds <= 60 ? 'background: linear-gradient(180deg, rgba(255, 59, 48, 0.076) 0%, rgba(255, 59, 48, 0.12) 100%);' : 'background: linear-gradient(180deg, rgba(255, 255, 255, 0.38) 0%, rgba(130, 210, 218, 0.6) 100%)'">

                    </v-text-field>
                  </v-card>
                  <span class="pa-2">:</span>
	              <v-card class="pa-0" width="70" elevation="0">
	                <v-text-field hide-details="" label="MM" readonly :value=30 outlined rounded
	                  class="rounded-xl centered-input timmer timmer-color"
	                  >

	                </v-text-field>
	              </v-card>
              <span class="pa-2">:</span>
              <v-card class="pa-0" width="70" elevation="0">
                <v-text-field hide-details="" label="SS" readonly :value=20 outlined rounded
                  class="rounded-xl centered-input timmer timmer-color"
                  >

                </v-text-field>
              </v-card>
              <v-col cols="2" class="pr-0">
                <p role-type="body-4" class="ma-0 black--text">Time Left</p>
              </v-col>
              <v-spacer></v-spacer>
              <!-- Submit Button -->
              <v-col cols="4" class="text-end pr-0">
                <v-btn width="150px" height="48px" rounded color="#6c367e" class="white--text"
                  >SUBMIT</v-btn>
              </v-col>
            </v-row>
            <div class="ma-4">
            <v-progress-linear  class="rounded-xl " rounded value=0
              color="secondary" background-color="grey lighten-2" height="18"></v-progress-linear>
              </div>
		  <div  class="d-flex  justify-end mr-4">
		  	<v-chip active text-color="black" class="m-q-chip">{{
                  question.skill == 'Core Skills' ? question.subject : question.skill }}
          </v-chip>
		  </div>
		  
        <div>
            <!-- Question Statement Card -->
            <v-card   :class="psyStarted || optionMediaCheck ? 'my-card-psy': 'my-card'" class="pa-0 ma-4 rounded-xl" elevation="0" color="grey lighten-4" id="myScroll">
             
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                    class="report-icon"
                  
                    v-bind="attrs"
                    v-on="on" icon><v-icon class="red--text">mdi-information-outline</v-icon>
                  </v-btn>
                </template>
              <span>Report this question</span>
            </v-tooltip>

              <v-card height="auto" elevation="0" color="grey lighten-4" class="question-text-container">
                <v-card-title v-if="question != null">
                  <!-- <h6 v-html="question.questionStatement"></h6> -->
                  <h6 v-if="question.questionType == 'MULTIPLE_CHOICE'" v-html="question.questionStatement + ' <em>(Select all that apply)</em>'"></h6>
                  <h6 v-else v-html="question.questionStatement">
                  </h6>
                </v-card-title>
                <v-card-subtitle v-if="question.questionAssetUrl != 'NA'">  
                  <v-img v-if=" showMedia && (mediaType === 'IMG')" @load="handleMediaLoad" :src="question.questionAssetUrl" @loadstart="resolveQuestionAssetUrl" @error="imgError" width="400px" />
                  <object v-else-if="showMedia && (mediaType ==='OBJ')" @load="handleMediaLoad" @error="objError" :data="question.questionAssetUrl"   height="220px"  ></object>

                    <div v-if="(mediaType === 'ERR')"  outlined class=" ma-2 invalid-media d-flex justify-center flex-column align-center red--text ">                     
                        <!-- Media Not Found -->
                        <img src="../assets/caution.png" alt="media not found" width="48" height="48">
                        <div class="mt-2">Media Not Found</div>
                    </div>
                </v-card-subtitle>
              </v-card>
            </v-card>
          </div>
        <div class="mr-8" v-if="question.questionType=='MULTIPLE_CHOICE'">  
          <v-card  class="option-card mt-4 rounded-xl float-bottom w-100 " elevation="0">
              <v-card height="auto" color="sufaceAccent w-100" elevation="0">
              
                <v-card-title v-if="question != null"
                  class="d-flex w-100 pa-1 flex-row justify-space-around"> 
                
                  <v-card elevation="1" height="auto" width="auto" class="ma-2 option-width overflow-hidden white"
                    v-for="(option, index) in question.questionOptions" :key="index">
                    <v-card elevation="0" height="30%" width="100%"
                      v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')"
                      class="w-100 d-flex justify-center  sub-text-option"
                      :class="question.myAnswer?.includes(option.optionKey) ? 'secondaryAccent' : ''"
                      @click="openAnswerDialog();" 
                      >  
                      <div class="option-left" 
                        >
                        <v-img class="my-2" height="70px" width="auto" type="image" @loadstart="resolveQuestionOptionUrl" :src="option.optionValue"
                          alt="Image not found" />
                            <v-checkbox
                            class='ma-0 pa-0 ml-2'
                            hide-details
                        
                            v-model="question.myAnswer"
                            multiple
                            disabled
                            :value="question.questionOptions[index].optionKey"
                            @click="openAnswerDialog();"
                          ></v-checkbox>

                      </div>

                      <v-btn class="zoom-out" icon>
                        <v-icon size="20px"> mdi-arrow-expand </v-icon>
                      </v-btn>
                    </v-card>
                    <v-card v-else 
                    @click="openAnswerDialog();"
                        elevation="0" height="48px" width="100%"
                      class="w-100 d-flex justify-start cursor sub-text-option"
                      :class="question.myAnswer?.includes(option.optionKey) ? 'secondaryAccent' : ''">
                     
                      <v-checkbox
    v-model="question.myAnswer"
    :id="'hhhhhhhh'+index+'h'"
    multiple         
    class='ma-0 pa-0 ml-2'
    hide-details
    disabled
    :value="question.questionOptions[index].optionKey"
    @click="openAnswerDialog();"
  ></v-checkbox>
                      <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div  v-bind="attrs"
                    v-on="on" class="assessment-name text-h6 font-weight-regular">
                      <template v-if="isHTML(option.optionValue)">
                        <p role-type="subtitle-1" class="black--text assessment-name" v-html="option.optionValue"></p> 
                      </template>
                      <template v-else>
                        <p role-type="subtitle-1" class="black--text assessment-name" > {{ option.optionValue }} </p>
                      </template>
                    </div>
                  </template>
                  <template v-if="isHTML(option.optionValue)">
                    <div class="font-14" v-html="option.optionValue"> </div> 
                  </template>
                  <template v-else>
                    <div class="font-14"> {{ option.optionValue }} </div> 
                  </template> 
              </v-tooltip>
                    </v-card>
                  </v-card>                  
                </v-card-title>
              <!-- </v-radio-group> -->
              </v-card>
      </v-card>
  </div>
  <div v-else class="mr-8">
      <v-card  class="option-card mt-4 rounded-xl float-bottom w-100 " elevation="0">
              <v-card height="auto" color="sufaceAccent w-100" elevation="0">
                <v-radio-group hide-details class="ma-0 pa-0" v-model="question.myAnswer" >
                <v-card-title v-if="question != null"
                  class="d-flex w-100 pa-1 flex-row justify-space-around"> 
                
                  <v-card elevation="1" height="auto" width="auto" class="ma-2 option-width  overflow-hidden white"
                    v-for="(option, index) in question.questionOptions" :key="index">
                    <v-card elevation="2" height="30%" width="100%"
                      v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')"
                      class="w-100 d-flex justify-center cursor sub-text-option"
                      :class="question.myAnswer?.includes(option.optionKey) ? 'secondaryAccent' : ''">
                      <div class="option-left"  @click="setOption(
                      question.questionOptions[index]
                                    )
                        ">
                        <v-img class="my-2" height="70px" width="auto" type="image" @loadstart="resolveQuestionOptionUrl" :src="option.optionValue"
                          alt="Image not found" />

                            <v-radio disabled  style="align-self:center; position: absolute; left: 10px; top: 10px" :key="index+'1'" :value="[question.questionOptions[index].optionKey]"
                             ></v-radio>

                      </div>

                      <v-btn  class="zoom-out" icon>
                        <v-icon size="20px"> mdi-arrow-expand </v-icon>
                      </v-btn>
                    </v-card>
                    <v-card v-else elevation="0" height="48px" width="100%"
                      class="w-100 d-flex justify-start cursor sub-text-option"
                      @click="openAnswerDialog"
                      :class="question.myAnswer?.includes(option.optionKey) ? 'secondaryAccent' : ''">
                      
                      <v-radio disabled  class="ml-2 my-0 py-0" :key="index" :value="[question.questionOptions[index].optionKey]"></v-radio>

                      <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div  v-bind="attrs"
                    v-on="on" class="text-h6 font-weight-regular  assessment-name">
                    <template v-if="isHTML(option.optionValue)">
                      <p role-type="subtitle-1" class="black--text ma-0 assessment-name" v-html="option.optionValue"> </p> 
                    </template>
                    <template v-else>
                      <p role-type="subtitle-1" class="black--text ma-0 assessment-name"> {{ option.optionValue }} </p>
                    </template> 
                    </div>
                  </template>
                  <template v-if="isHTML(option.optionValue)">
                    <div class="font-14" v-html="option.optionValue"> </div> 
                  </template>
                  <template v-else>
                    <div class="font-14"> {{ option.optionValue }} </div> 
                  </template>  
              </v-tooltip>
                    </v-card>
                  </v-card>                  
                </v-card-title>
              </v-radio-group>
              </v-card>
      </v-card>
  </div>
      <v-card elevation="0"  class=" overflow-hidden mt-4" >
                <v-divider></v-divider>
            <v-container class="px-16">
              <v-card-title><v-row class="d-flex flex-row justify-space-between align-center">
                    <div>
                      <v-btn rounded color="qpColor"  width="120px" height="36px" outlined
                        >
                        Previous
                      </v-btn>

                      
                      <v-btn rounded color="secondary" :disabled="question.myAnswer==null" width="120px"
                        height="36px" class="ml-8 mr-0 black--text">
                        NEXT
                      </v-btn>
                    </div>


                    <div>
                      <v-tooltip bottom >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn large text color="qpColor" class="ml-0" v-bind="attrs" v-on="on"
                            >
                            <v-icon class="pr-2" right> mdi-bookmark-outline </v-icon>
                            BOOKMARK
                          </v-btn>
                        </template>
                        <span>Bookmark this question to revisit it later</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text large color="qpColor" v-bind="attrs" v-on="on" 
                            >
                            SKIP >>
                          </v-btn>
                        </template>
                        <span>Skip this question if you do not know the answer</span>
                      </v-tooltip>
                    </div>
                  </v-row></v-card-title>
            </v-container>
          </v-card>

        </v-card>
    </div>
<v-dialog
      v-model="openAnsDialogue"
      width="auto"
      persistent
      >
      <v-card class="options-popup" max-width="948px">
      
      <v-card v-if="question.questionType=='MULTIPLE_CHOICE'" class="mt-4 rounded-xl float-bottom w-100 " elevation="0">
        <v-card-title class="ml-2">
          <h6>Options</h6>
          <v-spacer></v-spacer>
          <v-btn
              color="black"
              text
              @click="onCloseDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
              <v-card height="auto"  elevation="0" class="px-4 py-4">
                <v-card-title v-if="question != null"
                  class="d-flex w-100 flex-column justify-space-around"> 
                
                  <v-card elevation="1" height="auto" width="100%" class="ma-2 overflow-hidden white"
                    v-for="(option, index) in question.questionOptions" :key="index">


                    <v-card elevation="0" height="30%" width="824px"
                      v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')"
                      class="w-100 d-flex justify-center cursor sub-text-option text-start"
                      :class="dialogSelectionQsnOption?.includes(option.optionKey) ? 'secondaryAccent' : 'sufaceAccent'"
                      
                      >
                      <div class="option-left" 
                        >
                        <v-img class="my-2" height="70px" width="auto" type="image" @loadstart="resolveQuestionOptionUrl" :src="option.optionValue"
                          alt="Image not found" />
                            <v-checkbox
                            class='ma-0 pa-0 ml-2'
                            hide-details
                            v-model="dialogSelectionQsnOption"
                            multiple
                            :value="question.questionOptions[index].optionKey"
                          ></v-checkbox>

                      </div>

                      <v-btn class="zoom-out" icon>
                        <v-icon size="20px"> mdi-arrow-expand </v-icon>
                      </v-btn>
                    </v-card>
                    <v-card v-else 
                      
                      elevation="0" height="auto" width="100%"
                      class="w-100 d-flex justify-start cursor sub-text-option text-start"
                      :class="dialogSelectionQsnOption?.includes(option.optionKey) ? 'secondaryAccent' : 'sufaceAccent'"
                      
                      >
                      <v-checkbox
                      v-model="dialogSelectionQsnOption"
                      
                      multiple
                      :id="'fff'+index+'f'"       
                      class='ma-0 pa-0 ml-2'
                      hide-details
                      :value="question.questionOptions[index].optionKey"
                      ></v-checkbox>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div  v-bind="attrs"
                          v-on="on" class="text-h6 font-weight-regular ">

                          <template v-if="isHTML(option.optionValue)">
                              <p role-type="body-4" class="black--text" v-html="option.optionValue"> </p> 
                            </template>
                            <template v-else>
                              <p role-type="body-4" class="black--text"> {{ option.optionValue }} </p>
                            </template> 
                          </div>
                        </template>
                        <template v-if="isHTML(option.optionValue)">
                            <p role-type="body-4" class="black--text" v-html="option.optionValue"> </p>
                          </template>
                          <template v-else>
                            <p role-type="body-4" class="black--text"> {{ option.optionValue }} </p>
                          </template> 
                    </v-tooltip>
                    </v-card>
                  </v-card>                  
                </v-card-title>
              <!-- </v-radio-group> -->
              </v-card>
      </v-card>
      <v-card v-else class=" mt-4  rounded-xl float-bottom w-100 " elevation="0">
        <v-card-title class="ml-2">
          <h6>Options</h6>
          <v-spacer></v-spacer>
          <v-btn
              color="black"
              text
              @click="onCloseDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
          
              <v-card height="auto" elevation="0" class="px-4 py-4">
                <v-radio-group hide-details class="ma-0 pa-0" v-model="dialogSelectionQsnOption"  >
                <v-card-title v-if="question != null"
                  class="d-flex w-100  flex-column justify-space-around"> 
                
                  <v-card elevation="1" height="auto" width="100%" class="ma-2  overflow-hidden white"
                    v-for="(option, index) in question.questionOptions" :key="index">
                    <v-card elevation="0" height="30%" width="824px"
                      v-if="option.optionValue.startsWith('https://') || option.optionValue.startsWith('http://') || option.optionValue.startsWith('blob:')"
                      class="w-100 d-flex justify-center cursor sub-text-option text-start"
                      :class="question.myAnswer?.includes(option.optionKey) ? 'secondaryAccent' : ''">
                      <div class="option-left"  @click="setOption(
                                      question.questionOptions[index]
                                    )
                        ">
                        <v-img class="my-2" height="70px" width="auto" type="image" @loadstart="resolveQuestionOptionUrl" :src="option.optionValue"
                          alt="Image not found" />

                            <v-radio disabled  style="align-self:center; position: absolute; left: 10px; top: 10px" :key="index+'1'" :value="[question.questionOptions[index].optionKey]" ></v-radio>

                      </div>
                      <v-btn @click="zoomOutFun(option.optionValue)" class="zoom-out" icon>
                        <v-icon size="20px"> mdi-arrow-expand </v-icon>
                      </v-btn>
                    </v-card>
                    <v-card v-else @click="
                      setDialogOption(
                        question.questionOptions[index]
                      );
                      " elevation="0"
                      class="w-100 d-flex justify-start cursor sub-text-option pa-4 text-start"
                      :class="dialogSelectionQsnOption?.includes(option.optionKey) ? 'secondaryAccent' : 'sufaceAccent'" >
                      
                      <v-radio disabled  class="ml-2 my-0 py-0" :key="index" :value="[question.questionOptions[index].optionKey]"></v-radio>

                      <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div  v-bind="attrs"
                            v-on="on">
                            <template v-if="isHTML(option.optionValue)">
                              <p role-type="body-4" class="black--text" v-html="option.optionValue"> </p> 
                            </template>
                            <template v-else>
                              <p role-type="body-4" class="black--text"> {{ option.optionValue }} </p>
                            </template> 
                            </div>
                          </template>
                          <template v-if="isHTML(option.optionValue)">
                            <p role-type="body-4" class="black--text" v-html="option.optionValue"> </p>
                          </template>
                          <template v-else>
                            <p role-type="body-4" class="black--text"> {{ option.optionValue }} </p>
                          </template>
                      </v-tooltip>
                    </v-card>
                  </v-card>                  
                </v-card-title>
              </v-radio-group>
              </v-card>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
      <v-btn rounded color="secondaryColor"  width="120px"
        height="36px" class="ml-8 mr-0 mb-2 black--text" @click="onDoneDialog">
        DONE
      </v-btn>
    </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<script>
import AssessmentController from "@/controllers/AssessmentController";
import QuestionsController from "@/controllers/QuestionsController";
import AuthService from "../services/AuthService";

export default {
	props: {
    showNavbar: Boolean // Define showNavbar as a prop
  	},
data(){
	return {
		question: {},
		psyStarted: false,
	    showMedia: true,
	    mediaType: "IMG",
	    mediaLoaded:false,
	    seconds:61,
	    localShowNavbar: this.showNavbar,
	    hours:'00',
      showAnsDialog:false,
      openAnsDialogue:false,
      dialogSelectionQsnOption:''
	    
	};
},
watch: {
    showNavbar(newVal) {
      this.localShowNavbar = newVal; // Update localShowNavbar when showNavbar prop changes
    }
  },
methods:{
  resolveQuestionOptionUrl: async function (url) {
      const optionIndex = this.options.findIndex(({ optionValue }) => optionValue === url)
      if (url.startsWith('https://drive.google.com/')) {
        let fileId = null
        if(url.indexOf('&id=') > -1) {
          fileId = url.split('&id=').pop()
        } else {
          // Regular expression pattern to extract file ID
          const pattern = /\/file\/d\/([^\\/]+)\//;
          fileId = url.match(pattern)[1];
        }
        const response = await QuestionsController.doFetchFileContentFromDriveUrl(fileId)
        this.options[optionIndex].optionValue = window.URL.createObjectURL(new Blob([response.data]));
      }
    },
	logout() {
		AuthService.logout();
		this.$router.push("/login");
	},
	handleMediaLoad() {
	    this.mediaLoaded = true;
	},
	handleMediaError() {
	    this.mediaType = "ERROR"
	    this.mediaLoaded = false;
	},
	imgError(){
	    this.mediaType = 'OBJ' 
	},
	objError(){
	    this.mediaType = 'ERR'; 
	},
	resolveQuestionAssetUrl: async function (url) {
      if(url.startsWith('https://drive.google.com/')) {
        let fileId = null
        if(url.indexOf('&id=') > -1) {
          fileId = url.split('&id=').pop()
        } else {
          // Regular expression pattern to extract file ID
          const pattern = /\/file\/d\/([^\\/]+)\//;
          fileId = url.match(pattern)[1];
        }
        const response = await QuestionsController.doFetchFileContentFromDriveUrl(fileId)
        if(response.status === 200) {
          this.question.questionAssetUrl = window.URL.createObjectURL(new Blob([response.data]));
          this.mediaType = 'IMG'
        } else {
          this.mediaType = 'ERR'
        }
      } 
    },
    removeHTMLTags(text) {
      return text.replace(/<[^>]+>/g, '');
    },
    openAnswerDialog() {
      for (let availableOption of this.question.questionOptions) {
        const optionValue = availableOption.optionValue;

        // Check if the optionValue contains a <math> tag
        const containsMathTag = /<math[\s\S]*?>[\s\S]*?<\/math>/i.test(optionValue);
        let formattedOption = '';

        if (containsMathTag) {
          console.log('Math tag found, processing math content');
          // Create a temporary div to hold the optionValue HTML
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = optionValue;

          // Extract the content of the <math> tag
          const mathElement = tempDiv.querySelector('math');
          if (mathElement) {
            formattedOption = this.renderMathContent(mathElement.innerHTML);
          }
        } else {
          // Remove HTML tags from the optionValue
          formattedOption = this.removeHTMLTags(optionValue);
        }
        // Check if the formattedOption length is greater than 55
        if (formattedOption.length > 55) {
          this.showAnsDialog = true;
          break;
        }
      }

      // Open dialog if showAnsDialog is true
      if (this.showAnsDialog) {
        this.openAnsDialogue = true; 
        this.showAnsDialog = false;
      }
    },
    renderMathContent(mathContent) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = mathContent;
      return tempDiv.textContent || tempDiv.innerText || '';
    },
    closeAnsDialog()
    {
      this.openAnsDialogue = false;
    },
    onCloseDialog() {
      this.closeAnsDialog();
    },
    onDoneDialog(){
      this.closeAnsDialog(); 
    },
    isHTML: function(content) {
      return /<[a-z][\s\S]*>/i.test(content)
    }    
},
computed:{
	optionMediaCheck() {
	 	let hasMedia = false;
	    return hasMedia;
	},	
},
mounted(){
	
	this.localShowNavbar = false;
	const query = this.$route.query;
    // Decode the ID to get the original ID
    const decodedId = atob(query.id);
    // Parse the question object from the query parameters
    this.question = JSON.parse(query.question);
    console.log('mounted question',this.question)
	if (this.$store.state.role.createdOn == undefined) {
      this.logout();
      if (this.$store.state.role.createdOn == undefined) {
        this.logout();
      }
    }
},

}
	
</script>
<style scoped>

.option-card {
  border: 0.5px solid rgba(0, 0, 0, 0.26);
  margin-left: 18px;
  /*margin-right: 20px;*/
 
}
.option-width {
  width: calc(50% - 32px) !important;
  height: 50px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.sub-text-option {
  word-break: break-word;
  font-size: 18px !important;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  line-height: 20px;
  position: relative;
  column-gap: 10px;
}
.option-left {
  width: calc(100% - 20px);
}
#myScroll {
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}
.my-card {
  border: 1px solid rgba(175, 175, 175, 0.342);
  height: calc(100vh - 608px) !important;
  
}
.card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 5px;
    box-sizing: border-box;
    height:100%;

    /*background-color: #f0f0f0;*/
  }
.card {
    flex: 1;
    max-width: 500px;
    /*min-height: 330px; /* Fixed height */
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex; 
    align-items: center; 
    justify-content: center;
    border: 0.5px solid rgba(0, 0, 0, 0.26);
}
.qp-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 38px)
} 
h6 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.40px;
  line-height: 24px;
  color: black;
}
.question-text-container h6 {
  word-break: break-word;
}

</style>