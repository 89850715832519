<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>
  <div v-else>
    <div>
      <v-dialog
        v-model="recommandationFeedbackForm"
        persistent
        width="1000px"
        class="elevation-0"
      >
        <v-card class="pa-8">
          <div>Overall Recommendation* </div>
          <div class="d-flex">
            <v-chip
              class="ml-2 mt-2 text-caption cursor"
              medium
              >{{ finalRecommendation }}</v-chip
            >
          </div>
          <div class="pt-6">Remarks* </div>
          <v-textarea
          counter
          solo
          v-model="remarkValues"
          :rules="[rules.required, rules.counter]"
          maxlength="1000"
          class="rounded-xl mt-2 border-button"
        ></v-textarea>
          <div class="d-flex pt-2">
            <v-checkbox v-model="isAgree"></v-checkbox>
            <div class="text-caption mt-2">
              By submitting this interview rating, I confirm that I have
              thoroughly evaluated the candidate's performance and provided a
              fair and accurate representation of their qualifications and
              suitability for the position. This rating will be considered as a
              part of the assessment process to inform the final decision on
              candidate selection.*
            </div>
          </div>
          <v-card-actions class="px-6 pt-6 pb-3">
            <v-spacer></v-spacer>
            <v-btn
              width="102px"
              height="48px"
              rounded
              outlined
              class="accent--text pa-4"
              @click="recommandationFeedbackForm = false"
              >Cancel</v-btn
            >
            <v-btn
              width="102px"
              height="48px"
              rounded
              @click="submitInterviewFeedback()"
              :disabled="
                finalRecommendation == '' ||
                remarkValues == '' ||
                remarkValues.length > 1000 ||
                isAgree == false
              "
              class="accent pa-4"
              :loading="isbtnLoading"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
      v-model="videoDialog"
      persistent
      width="70%"
      class="elevation-0"
      

    >
      <v-card color="background" class="pa-8">
        <div>
          <v-icon @click="dialogClosed" class="pa-0 ma-0">mdi-close</v-icon>
        </div>
        <div  class="d-flex justify-center text-h6 pb-2">
          Recorded Video
        </div>
        <div class="d-flex recordViedoCard justify-space-between w:100 align-center pa-2">
          <div>
              <div class="text-caption">RECOMMEMDED TOPIC</div>
              <div class="text-subtitle-1">{{this.currentVideo.topicName}}</div>
          </div>
          <div>
              <div class="text-caption">RECOMMEMDED DURATION</div>
              <div  class="text-subtitle-1">12 -17 Mins</div>
          </div>
      </div>
      <div class="userVidDivForDemo bg-white d-flex justify-center">
        <video :key="this.currentVideo.videoUrl" ref="video" controls width="75%" class="pt-2">
          <source  :src="this.currentVideo.videoUrl" type="video/mp4">
      </video>
      </div>
      
      </v-card>
    </v-dialog>
      <div
        class="qpad ml-4 mr-6 my-0 px-4 w-100 d-flex align-center justify-space-between"
      >
        <div class="font-weight-bold">Interview Details</div>
        <v-btn
          v-if="showInterviewDetails && !showDemoDetails"
          depressed
          :disabled="!isSumitButtonActive"
          @click="openRecomendationFeedbackForm()"
          rounded
          color="white--text"
          class="accent"
        >
          Submit
        </v-btn>
        <v-btn
          v-if="!showInterviewDetails && userData.interViewFeedback == undefined"
          depressed
          @click="startInterview"
          rounded
          color="white--text"
          class="accent"
        >
          Start Interview
        </v-btn>

      </div>
      <div class="d-flex">
        <v-card
          width="28%"
          height="100%"
          elevation="0"
          color="#F7F7F3"
          class="pa-5 ml-6 mt-2 rounded-xl elevation-0 cardBorder"
        >
          <div>
            <div class="d-flex pb-7 pt-3">
              <div>
                <img v-if="userData.userinfo?.proctoringPic"
                  :src="userData.userinfo?.proctoringPic"
                  class="rounded-xl mr-2"
                  width="32"
                  height="32"
                />
                <img v-else
                  src="../assets/avtar.png"
                  class="rounded-xl mr-2"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <div class="">
                  {{ this.userData.userinfo?.personalInfo.firstName }}
                  {{ this.userData.userinfo?.personalInfo.lastName }}
                </div>
                <div class="text-caption textGreyColor--text">
                  {{ this.userData.userinfo?.personalInfo?.contactInfo?.email }}
                </div>
                <div class="text-caption textGreyColor--text">
                  {{
                    this.userData.userinfo?.personalInfo?.contactInfo
                      ?.phoneNumber
                  }}
                </div>
              </div>
            </div>
            <v-divider class="pb-2"></v-divider>
            <v-card
              elevation="0"
              color="#F7F7F3"
              class="pa-4 mt-5 mb-7 rounded-xl elevation-0 cardBorder"
            >
              <div v-if="isFeedbackSubmit">
                <div class="w-100 d-flex flex-row justify-space-between">
                  <div class="text-subtitle-2 textGreyColor--text">
                    My Net Rankings
                  </div>
                  <div
                    class="text-subtitle-2 font-weight-medium green--text font-weight-bold pl-1 mr-1"
                  >
                    {{ this.userData?.interViewFeedback?.avgScore }}/10
                  </div>
                </div>
                <div class="w-100 pt-3 d-flex flex-row justify-space-between">
                  <div class="text-subtitle-2 textGreyColor--text pt-1">
                    Status
                  </div>
                  <v-chip
                    medium
                    color="#06C2700F"
                    class="green--text py-0 my-0"
                  >
                    Interviewed
                  </v-chip>
                </div>
                <div class="w-100 pt-2 d-flex flex-row justify-space-between">
                  <div class="text-subtitle-2 textGreyColor--text pt-1">
                    My Recommendation
                  </div>
                  <v-chip small color="#06C2700F" class="w-100 green--text py-0 my-0">
                    {{ this.userData?.interViewFeedback?.recommendation }}
                  </v-chip>
                </div>
              </div>
              <div v-else>
                <div class="w-100 d-flex flex-row justify-space-between">
                  <div class="text-subtitle-2 textGreyColor--text">
                    Average Score
                  </div>
                  <div class="text-subtitle-2 font-weight-medium pl-1">
                    {{ this.avgScore }}/10
                  </div>
                </div>
                <div class="w-100 pt-1 d-flex flex-row justify-space-between">
                  <div class="text-subtitle-2 textGreyColor--text">Status</div>
                  <div class="text-caption pl-1 bronzeIcon--text">
                    IN Progress
                  </div>
                </div>
              </div>
            </v-card>
            <v-divider></v-divider>
            <div class="pt-6 pb-2">
              <div class="d-flex align-baseline flex-wrap mb-3">
                <div class="text-subtitle-2 textGreyColor--text">Level -</div>
                <v-chip
                  v-for="data in this.userData.userinfo?.levels"
                  :key="data"
                  small
                  :class="['mx-1', {'mb-2' : userData?.userinfo?.levels?.length >=2 }]"
                  >{{ data }}</v-chip
                >
              </div>
              <div class="d-flex align-baseline flex-wrap">
                <div class="text-subtitle-2 textGreyColor--text">Subject - </div>
                <v-chip
                  v-for="data in this.userData?.userinfo?.subjects"
                  :key="data"
                  small
                  :class="['mx-1', {'mb-2' : userData?.userinfo?.subjects?.length >=2 }]"
                  >{{ data }}</v-chip>
              </div>
            </div>
            <v-divider class="my-7"></v-divider>
            <div>
              <div class="w-100 d-flex flex-row justify-space-between pt-1">
                <div class="text-subtitle-2 textGreyColor--text">
                  Total Experience -
                </div>
                <div class="text-subtitle-2 font-weight-medium pl-1">
                  {{ this.userData.candidateDetails.totalExperience.years }}.{{ this.userData.candidateDetails.totalExperience.months }} years
                </div>
              </div>
              <div class="w-100 d-flex flex-row justify-space-between pt-1">
                <div class="text-subtitle-2 textGreyColor--text">
                  Relevant Experience -
                </div>
                <div class="text-subtitle-2 font-weight-medium pl-1">
                  {{ this.userData.candidateDetails.relevantExperience.years }}.{{ this.userData.candidateDetails.relevantExperience.months }} years
                </div>
              </div>
              <div class="w-100 d-flex flex-row justify-space-between pt-1">
                <div class="text-subtitle-2 textGreyColor--text">
                  Notice Period -
                </div>
                <div class="text-subtitle-2 font-weight-medium pl-1">
                  {{ this.userData.candidateDetails.noticePeriod }} days
                </div>
              </div>
            </div>
            <v-divider class="my-7"></v-divider>
            <div class="pt-2 pb-8">
              <div class="w-100 d-flex flex-row justify-space-between">
                <div class="text-subtitle-2 textGreyColor--text">
                  Current CTC -
                </div>
                <div class="text-subtitle-2 font-weight-medium pl-1">
                  {{ this.userData.candidateDetails.currentCTC }} LPA
                </div>
              </div>
              <div class="w-100 d-flex flex-row pt-1 justify-space-between">
                <div class="text-subtitle-2 textGreyColor--text">
                  Expected CTC -
                </div>
                <div class="text-subtitle-2 font-weight-medium pl-1">
                  {{ this.userData.candidateDetails.expectedCTC }} LPA
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <v-card
          v-if="!showInterviewDetails && userData.interViewFeedback == undefined"
          color="#F7F7F3"
          width="100%"
          class="mx-6 mt-2 rounded-xl elevation-0 cardBorder"
        >
          <div class="py-2 pl-6 text-subtitle-1 font-weight-bold">Demo Video</div>
          <div class="d-flex">
            <v-card
              v-for="data in this.userData.demoVideoData"
              :key="data"
              color="#F7F7F3"
              width="240px"
              height="280px"
              class="mx-5 rounded-xl elevation-0 cardBorder"
            >
              <v-container class="">
                <v-row justify="center">
                  <v-col>
                    <video :key="data.videoUrl" ref="video"  height="140px"
                      width="215px"
                      class="rounded-xl d-flex justify-align-center">
                    <source  :src="data.videoUrl" type="video/mp4">
                    </video>
                  </v-col>
                </v-row>
                <div class="text-caption pt-2 justify-align-center">
                  {{ data.subject }}
                </div>
                <div class="text-body-2 justify-align-center">
                  {{ data.topicName }}
                </div>
                <v-card-actions class="">
                  <v-spacer></v-spacer>
                  <v-btn
                    width="70px"
                    height="30px"
                    rounded
                    elevation="0"
                    @click="selectVideo(data)"
                    class="btnLiteColor accent--text elevation-0 text-body-2"
                  >
                    View
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card>
          </div>
        </v-card>




        <v-card
          v-if="showInterviewDetails && !showDemoDetails"
          color="#F7F7F3"
          width="100%"
          class="mx-6 mt-2 rounded-xl elevation-0 cardBorder"
        >
        <div class="d-flex overflow-hidden h-100" v-if="showInterviewDetails">
            <div class="d-flex flex-column">
              <div
                v-for="(data, index) in skills"
                :key="index"
                @click="selectSkill(data.skillName, index)"
                :class="['commonColoredContainer', selectedSkill != data.skillName
                    ? findColor(data.skillName)
                    : findColorForSelected(data.skillName)
                ]"
              >
                <span class="spanContainer text-subtitle-2">{{
                  data.skillName
                }}</span>
              </div>
            </div>
            <v-col class="pt-6 pl-4">
              <div v-if="selectedSkill == 'Core Skills'">
                <v-col class="py-0">
                  <v-btn-toggle
                    rounded
                    mandatory
                    dense
                    class="subjectToggel mb-4"
                  >
                    <v-btn
                      v-for="(data, index) in this.subject"
                      :key="index"
                      width="125px"
                      @change="selectSubject(index)"
                      :class="
                        selectedSubjectIndex == index
                          ? 'btnLiteColor btn-color--text'
                          : 'btn-color--text'
                      "
                    >
                      {{ data.subjectName }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-card
                  width="100%"
                  height="20%"
                  elevation="0"
                  color="#F7F7F3"
                  v-for="(data, index) in this.subject[
                    this.selectedSubjectIndex
                  ]?.feedback"
                  :key="index"
                  class="rounded-xl elevation-0 cardBorder px-3 py-3 mb-4"
                >
                  <div class="text-body-1">{{ data.title }}</div>
                  <div
                    class="text-subtitle-2 textGreyColor--text mb-5"
                  >
                    {{ data.description }}
                  </div>
                  <div class="d-flex align-center">
                    <div
                      v-for="rate in 10"
                      :key="rate"
                      @click="selectRatingValue(rate, index)"
                      :class="selectRating(rate, data.rating)"
                    >
                      {{ rate }}
                    </div>
                  </div>
                </v-card>
              </div>
              <div v-else id="myScroll" class="h-100">
                <v-card
                  width="100%"
                  elevation="0"
                  color="#F7F7F3"
                  v-for="(data, index) in this.skills[this.selectedSkillIndex]
                    ?.feedback"
                  :key="index"
                  class="rounded-xl elevation-0 cardBorder px-3 py-3 mb-4"
                >
                  <div class="text-body-1">{{ data.title }}</div>
                  <div
                    class="text-subtitle-2 textGreyColor--text mb-5"
                  >
                    {{ data.description }}
                  </div>
                  <div class="d-flex">
                    <div
                      v-for="rate in 10"
                      :key="rate"
                      @click="selectRatingValueForSkills(rate, index)"
                      :class="selectRating(rate, data.rating)"
                    >
                      {{ rate }}
                    </div>
                  </div>
                </v-card>
              </div>
            </v-col>
          </div>
        </v-card>
        <v-card
          v-if="userData.interViewFeedback != undefined"
          color="#F7F7F3"
          width="100%"
          class="mt-2 mx-5 rounded-xl elevation-0 cardBorder"
        >
          <v-container class="mt-4">
            <v-row justify="center">
              <v-col cols="12" sm="5">
                <img
                  src="../assets/interviewFeedbackSuccess.png"
                  class="rounded-xl d-flex justify-align-center"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" sm="4">
                <div
                  class="text-subtitle-1 d-flex justify-align-center font-weight-bold"
                >
                  Interview feedback Submitted
                </div>
              </v-col>
            </v-row>
          </v-container>
          <div class="pl-4 text-subtitle-1 font-weight-bold">Demo Video</div>
          <div class="d-flex">
            <v-card
              v-for="data in this.userData.demoVideoData"
              :key="data"
              color="#F7F7F3"
              width="240px"
              height="280px"
              class="mx-5 rounded-xl elevation-0 cardBorder"
            >
              <v-container class="">
                <v-row justify="center">
                  <v-col>
                    <video :key="data.videoUrl" ref="video"  height="140px"
                      width="215px"
                      class="rounded-xl d-flex justify-align-center">
                    <source  :src="data.videoUrl" type="video/mp4">
                    </video>
                  </v-col>
                </v-row>
                <div class="text-caption pt-2 justify-align-center">
                  {{ data.subject }}
                </div>
                <div class="text-body-2 justify-align-center">
                  {{ data.topicName }}
                </div>
                <v-card-actions class="">
                  <v-spacer></v-spacer>
                  <v-btn
                    width="70px"
                    height="30px"
                    rounded
                    elevation="0"
                    @click="selectVideo(data)"
                    class="btnLiteColor accent--text elevation-0 text-body-2"
                  >
                    View
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card>
          </div>
        </v-card>
        <!-- </v-card> -->

      </div>
    </div>
    
  </div>
</template>
<script>
import CampaignController from "@/controllers/CampaignController";
import AuthService from "../services/AuthService";
import "../styles.css";

export default {
  components: {},
  name: "InterviewFeedbackForm",
  data() {
    return {
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 1000 || 'Maximum 1000 characters',
      },
      showInterviewDetails:false,
      preloader: true,
      selectedSkill: "Core Skills",
      isSumitButtonActive: false,
      isSubmitSubject: false,
      isSubmitSkills: false,
      selectedSkillIndex: 0,
      selectedSubjectIndex: 0,
      remarkValues: "",
      isAgree: false,
      finalRecommendation: "",
      avgScore: 0,
      skillAvg: 0,
      currentVideo:{},
      recommandationFeedbackForm: false,
      userData: {},
      isbtnLoading: false,
      recommendation: ["NOT RECOMMNDED", "HOLD", "STRONGLY RECOMMNDED"],
      isFeedbackSubmit: false,
      subAvg: 0,
      ratingValue: 0,
      pedagogyFeedback: [
        {
          title: "Creativity and Innovation*",
          description:
            "creativity and ability to generate innovative ideas or approaches within the subject domain",
          rating: 0,
        },
        {
          title: "Classroom Management*",
          description:
            "Inquire about the candidate's strategies for creating a positive and inclusive learning environment",
          rating: 0,
        },
        {
          title: "Student Engagement*",
          description:
            "Candidate's methods for promoting active student engagement and participation",
          rating: 0,
        },
        {
          title: "Differentiation*",
          description:
            "Addressing diverse needs and learning styles of the students",
          rating: 0,
        },
        {
          title: "Lesson Planning*",
          description:
            "Review the candidate's proficiency in developing comprehensive lesson plans that align with curriculum standards and learning objectives for the subject",
          rating: 0,
        },
      ],
      englishFeedback: [
        {
          title: "Verbal Communication*",
          description:
            "Evaluate the candidate's ability to articulate ideas clearly and effectively during verbal communication",
          rating: 0,
        },
        {
          title: "Clarity and Conciseness* ",
          description:
            "Assess the candidate's capacity to communicate information concisely and avoid ambiguity",
          rating: 0,
        },
        {
          title: "Active Listening*",
          description:
            "Inquire about the candidate's ability to actively listen to students, colleagues, and parents to understand their needs and concerns",
          rating: 0,
        },
        {
          title: "Nonverbal Communication*",
          description:
            "Discuss the candidate's awareness of and proficiency in using nonverbal cues to enhance communication i.e. Body Language, Expression, Facial Expression etc",
          rating: 0,
        },
        {
          title: "Empathy and Empathetic Communication*",
          description:
            "Assess the candidate's capacity to empathize with students, colleagues, and parents and communicate in a supportive and compassionate manner",
          rating: 0,
        },
        {
          title: "Collaborative Communication*",
          description:
            "Inquire about the candidate's ability to collaborate effectively with colleagues, administrators, and other stakeholders",
          rating: 0,
        },
      ],
      computerFeedback: [
        {
          title: "Basic Computer Proficiency*",
          description:
            "Assess the candidate's familiarity with basic computer operations and software applications",
          rating: 0,
        },
        {
          title: "Educational Technology Tools",
          description:
            "Inquire about the candidate's knowledge and experience with educational technology tools and platforms",
          rating: 0,
        },
        {
          title: "Digital Content Creation*",
          description:
            "Evaluate the candidate's ability to create digital content for instructional purposes, such as presentations, videos, and interactive multimedia resources",
          rating: 0,
        },
        {
          title: "Internet Research Skills* ",
          description:
            "Discuss the candidate's proficiency in conducting internet research and evaluating online resources",
          rating: 0,
        },
        {
          title: "Learning Management Systems (LMS)*",
          description:
            "Inquire about the candidate's experience with learning management systems for organizing course materials, assignments, and communication with students",
          rating: 0,
        },
        {
          title: "Data Management and Analysis*",
          description:
            "Assess the candidate's ability to collect, manage, and analyze data using digital tools and software",
          rating: 0,
        },
      ],
      psychometryFeedback: [
        {
          title: "Professional Ethics and Integrity*",
          description:
            "Assess the candidate's commitment to ethical standards and integrity in their interactions with students, colleagues, and parents",
          rating: 0,
        },
        {
          title: "Self-awareness*",
          description:
            "Ask the candidate about their process of self-reflection and how they assess their teaching practices",
          rating: 0,
        },
        {
          title: "Empathy and Understanding*",
          description:
            "Inquire about the candidate's ability to empathize with students perspectives and understand their individual needs and challenges",
          rating: 0,
        },
        {
          title: "Motivation and Enthusiasm*",
          description:
            "Assess the candidate's level of motivation and enthusiasm for teaching and their ability to inspire and engage students",
          rating: 0,
        },
        {
          title: "Interpersonal Skills*",
          description:
            "Assess the candidate's capacity to build positive relationships with students, colleagues, parents, and other stakeholders",
          rating: 0,
        },
        {
          title: "Emotional Intelligence*",
          description:
            "Inquire about the candidate's ability to recognize, understand, and manage their own emotions, as well as those of their students",
          rating: 0,
        },
      ],
      subject: [],
      skills: [],
      videoDialog:false,
      userId: "",
      slotId: "",
      timeSlotId: "",
      intervieweremail: "",
      campaignId: "",
      showDemoDetails:false,
      recomendationScoreMap: {
        40 : "Not Recommended",
        60  : "Below Standard",
        75  : "Moderately Recommended",
        90  : "Highly Recommended",
        101  : "Strongly Recommended"
      }
    };
  },
  watch: {},
  mounted() {
    this.userId = this.$route.query.userId;
    this.slotId = this.$route.query.slotId;
    this.timeSlotId = this.$route.query.timeSlotId;
    this.intervieweremail = this.$route.query.intervieweremail;
    this.campaignId = this.$route.query.campaignId;
    console.log("userId", this.userId);
    console.log("slotId", this.slotId);
    console.log("timeSlotId", this.timeSlotId);
    console.log("intervieweremail", this.intervieweremail);
    console.log("campaignId", this.campaignId);
    this.getInterInfo(
      this.intervieweremail,
      this.userId,
      this.slotId,
      this.timeSlotId,
      this.campaignId
    );
  },
  methods: {
    startInterview() {
      this.showInterviewDetails = true;
    },
    dialogClosed() {
      this.pauseVideo();
      this.videoDialog = false;
    },
    pauseVideo() {
      if (this.$refs.video) {
        this.$refs.video.pause();
      }
    },
    async getSubject() {
      console.log("getSubject");
      this.userData.userinfo?.subjects?.forEach((sub) => {
        this.subject.push({
          subjectName: sub,
          feedback: [
            {
              title: "Fundamental Knowledge*",
              description:
                "Understanding of basic principles and concepts related to the subject matter",
              rating: 0,
            },
            {
              title: "Depth of Knowledge*",
              description:
                "Probe the depth of the candidate's understanding by asking questions that delve into advanced or specialized topics within the subject area ",
              rating: 0,
            },
            {
              title: "Application of knowledge* ",
              description:
                "Assess the candidate's ability to apply their theoretical understanding to practical situations or case studies",
              rating: 0,
            },
          ],
        });
      });
      console.log("this.subject", this.subject);
    },
    async getSkills() {
      console.log("getSkills");
      this.userData.userinfo?.skills?.forEach((sub) => {
        switch (sub) {
          case "Pedagogy":
            this.skills.push({
              skillName: sub,
              feedback: this.pedagogyFeedback,
            });
            break;
          case "Digital Literacy":
            this.skills.push({
              skillName: sub,
              feedback: this.computerFeedback,
            });
            break;
          case "Communication Skills":
            this.skills.push({
              skillName: sub,
              feedback: this.englishFeedback,
            });
            break;
          case "Psychometry":
            this.skills.push({
              skillName: sub,
              feedback: this.psychometryFeedback,
            });
            break;
          case "Core Skills":
            this.skills.splice(0, 0, {
              skillName: sub,
            });
            break;
        }
      });
    },
    async avgForSubject() {
      this.isSubmitSubject = true;
      console.log("avgForSubject");
      var subjectAvg = 0;
      this.subject?.forEach((sub) => {
        var currSubAvg = 0;
        sub?.feedback?.forEach((feed) => {
          currSubAvg = currSubAvg + feed.rating;
          if (feed.rating == 0) {
            this.isSubmitSubject = false;
          }
        });
        var currSubAvg = currSubAvg / sub?.feedback?.length;
        subjectAvg = subjectAvg + currSubAvg;
        if (this.isSubmitSkills && this.isSubmitSubject) {
          this.isSumitButtonActive = true;
        }
      });
      this.subAvg = subjectAvg / this.subject?.length;
      if(this.skills?.length > 1){
        this.avgScore = ((this.subAvg + this.skillAvg) / 2).toFixed(2);
        if (isNaN(this.avgScore)) {
          this.avgScore = 0.00;
        }
      }else{
        this.avgScore = this.subAvg.toFixed(2);
        if (isNaN(this.avgScore)) {
          this.avgScore = 0.00;
        }
      }
    },
    async avgForSkills() {
      this.isSubmitSkills = true;
      console.log("avgForSkillls");
      var skillAvg = 0;
      var length = 0;
      var isHaveCoreSkill = false;
      this.skills?.forEach((sub) => {
        if (sub.skillName != "Core Skills") {
          var currSkillAvg = 0;
          sub?.feedback?.forEach((feed) => {
            currSkillAvg = currSkillAvg + feed.rating;
            if (feed.rating == 0) {
              this.isSubmitSkills = false;
            }
          });
          var currSkillAvg = currSkillAvg / sub?.feedback?.length;
          skillAvg = skillAvg + currSkillAvg;
        } else {
          isHaveCoreSkill = true;
        }
      });
      if (isHaveCoreSkill) {
        if (this.isSubmitSkills && this.isSubmitSubject) {
          this.isSumitButtonActive = true;
        }
        length = this.skills?.length - 1;
        this.skillAvg = skillAvg / length;
        this.avgScore = ((this.subAvg + this.skillAvg) / 2).toFixed(2);
        if (isNaN(this.avgScore)) {
          this.avgScore = 0.00;
        }
      } else {
        this.isSumitButtonActive = this.isSubmitSkills;
        length = this.skills?.length;
        this.skillAvg = skillAvg / length;
        this.avgScore = (this.subAvg + this.skillAvg).toFixed(2);
        if (isNaN(this.avgScore)) {
          this.avgScore = 0.00;
        }
      }
    },
    selectSkill(skillName, index) {
      console.log("selectSkills");
      this.selectedSkill = skillName;
      this.selectedSkillIndex = index;
    },
    selectSubject(index) {
      console.log("selectSubject", index);
      this.selectedSubjectIndex = index;
    },
    selectRatingValue(val, index) {
      console.log("selectRatingValueSub");
      this.subject[this.selectedSubjectIndex].feedback[index].rating = val;
      this.avgForSubject();
    },
    selectRatingValueForSkills(val, index) {
      console.log("selectRatingValueForSkills");
      this.skills[this.selectedSkillIndex].feedback[index].rating = val;
      this.avgForSkills();
    },
    findColor(value) {
      var colors = "";
      switch (value) {
        case "Core Skills":
          return "containerCoreSkillLite cursor";
          break;
        case "Pedagogy":
          return "containerPedagigyLite cursor";
          break;
        case "Communication Skills":
          return "containerEnglishLite cursor";
          break;
        case "Digital Literacy":
          return "containerComputerLite cursor";
          break;
        case "Psychometry":
          return "containerPsychrometricLite cursor";
          break;
        default:
          return "containerPsychrometricLite cursor";
      }
    },
    selectRating(rating, ratingValue) {
      switch (rating) {
        case 1:
          return ratingValue >= 1
            ? "interviewRate1 white--text"
            : "interviewRate";
          break;
        case 2:
          return ratingValue >= 2
            ? "interviewRate2"
            : "interviewRate";
          break;
        case 3:
          return ratingValue >= 3
            ? "interviewRate3"
            : "interviewRate";
          break;
        case 4:
          return ratingValue >= 4
            ? "interviewRate4"
            : "interviewRate";
          break;
        case 5:
          return ratingValue >= 5
            ? "interviewRate5"
            : "interviewRate";
          break;
        case 6:
          return ratingValue >= 6
            ? "interviewRate6"
            : "interviewRate";
          break;
        case 7:
          return ratingValue >= 7
            ? "interviewRate7"
            : "interviewRate";
          break;
        case 8:
          return ratingValue >= 8
            ? "interviewRate8"
            : "interviewRate";
          break;
        case 9:
          return ratingValue >= 9
            ? "interviewRate9"
            : "interviewRate";
          break;
        case 10:
          return ratingValue >= 10
            ? "interviewRate10"
            : "interviewRate";
          break;
        default:
          return "interviewRate";
          break;
      }
    },
    findColorForSelected(value) {
      switch (value) {
        case "Core Skills":
          return "containerCoreSkillHard cursor";
          break;
        case "Pedagogy":
          return "containerPedagigyHard cursor";
          break;
        case "Communication Skills":
          return "containerEnglishHard cursor";
          break;
        case "Digital Literacy":
          return "containerComputerHard cursor";
          break;
        case "Psychometry":
          return "containerPsychrometricHard cursor";
          break;
        default:
          return "containerPsychrometricHard cursor";
      }
    },
    async submitInterviewFeedback() {
      
      this.isbtnLoading = true;
      var data = {
        subjects: this.subject,
        skills: this.skills,
        avgScore: this.avgScore,
        recommendation: this.finalRecommendation,
        remark: this.remarkValues,
        interviewerEmail : this.intervieweremail,
      };
      try {
        var res = await CampaignController.submitInterViewFeedback(
          this.intervieweremail,
          this.slotId,
          this.timeSlotId,
          data,
          this.userId,
          this.campaignId
        );
        if (res.data.flag) {
          console.log("success");
          this.getInterInfo(
            this.intervieweremail,
            this.userId,
            this.slotId,
            this.timeSlotId,
            this.campaignId
          );
          this.isbtnLoading = false;
          this.isFeedbackSubmit = true;
          this.showDemoDetails = true;
          this.recommandationFeedbackForm = false;
          
        }
      } catch (error) {
        alert(error);
        this.isbtnLoading = false;
      }
    },
    async getInterInfo(email, userId, slotId, timeSlotId, camapaignId) {
      try {
        var data = await CampaignController.getInterViewUserInfo(
          email,
          slotId,
          timeSlotId,
          userId,
          camapaignId
        );
        if (data.data.flag) {
          this.userData = data.data.data;
          await this.getSubject();
          await this.getSkills();
          await this.avgForSkills();
          await this.avgForSubject();
          this.preloader = false;
          if (this.userData.interViewFeedback == undefined) {
            this.isFeedbackSubmit = false;
          } else {
            this.isFeedbackSubmit = true;
          }
        } else {
          alert(data.data.message);
        }
      } catch (error) {
        alert(error);
      }
    },
    logout() {
      AuthService.logout();
      // this.$router.push("/login");
    },
    openRecomendationFeedbackForm() {      
      let calculatedAvgPercentage = ((this.avgScore / 10) * 100).toFixed(2);
      for (const [_score, _status] of Object.entries(this.recomendationScoreMap)) {
        if(Number(calculatedAvgPercentage) < _score ) {
          this.finalRecommendation = _status
          break
        }
      } 
      this.recommandationFeedbackForm = true
    },
    selectVideo(videoData) {
        this.currentVideo = videoData;
        this.videoDialog = true;
    }
  },
  created() {
    this.logout();
  },
};
</script>
<style scoped>
.v-list-item__content {
  min-height: 64px;
}

.qpad {
  padding-top: 80px !important;
  padding-right: 40px !important;
}
</style>
