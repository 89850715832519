<template>
    <div>
      <v-col>
        <v-card height="64px" width="100%"
          class="ml-4 pl-1 pt-5 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
          <v-card-title class="pl-0 ml-0">
            <span @click="$router.push('/')" class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</span>
  
            <span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></span><v-menu offset-y>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="breadcrumb-text">
                  Campaign Result
                  <v-icon class="breadcrumb-arrow cursor">mdi-chevron-down</v-icon>
                </span>
              </template>
  
              <v-list>
                <v-list-item @click="$router.push('/schoolteachersappraisal')">No. of teachers to be Appraised</v-list-item>
                <v-list-item @click="$router.push('/schoolteacherspip')">No. of teachers on PIP</v-list-item>
                <v-list-item @click="$router.push('/schoolteacherstbd')">No. of teachers on TBD</v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <div class="size-menu d-flex flex-row justify-start w-fit align-center fixLog">
            <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
              <!-- <v-hover v-model="hover">
                  <img width="24px" src="../../assets/bell 1.svg" />
                </v-hover> -->
            </v-badge>
            <div>
              <img src="../assets/avtar.png" class="rounded-xl mr-2" width="24" height="24" />
            </div>
            <div class="d-flex flex-column d-justify-evenly user-details">
              <v-card-title class="ma-0 pa-0 elipsis">
                {{ $store.state.userInfo.name }}
              </v-card-title>
              <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
                {{ $store.state.userInfo.email }}
              </v-card-subtitle>
            </div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                  <v-icon class="">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-btn @click="logout">logout</v-btn>
            </v-menu>
          </div>
        </v-card>
  
        <div class="pad">
  
          <v-container class="funnel-contianer pl-0 pr-3 ml-3" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 w-16">
                <v-card class="rounded-lg pr-0 pl-0" width="100%" height="30vh">
                  <v-card-title class="pb-0 pr-0 pl-0">
                   <p class="font-weight-bold pl-2 funnelText">TEACHERS</p>
                  </v-card-title>
                  <v-card-text class="pt-0 pr-0 pl-0">
                    <span class="pl-2 black--text">Teachers Invited : {{ schoolChartData.totalInvited }}
                    </span>
                    <FunnelChartSchool :school="schoolChartData" />
  
                     <div class="funnelPadding" style="text-align: center">
                      <span class="black--text font-weight-medium" style="display: inline-block; margin-bottom: 0"><b>{{
                        calculatePercentage(
                          schoolChartData.totalInvited -
                          schoolChartData.totalScreeningStarted,
                          schoolChartData.totalInvited
                        )
                      }}% dropped</b></span>
                      <br />
                      <span class="black--text" style="display: inline-block">Yet To Attempt :
                        {{
                          schoolChartData.totalInvited -
                          schoolChartData.totalScreeningStarted
                        }}</span>
                    </div>
                  </v-card-text>
                
                </v-card>
              </div>
  
              <div class="rounded-lg px-0 py-0 pl-1 w-16">
                <v-card class="rounded-lg" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                   <p class="font-weight-bold pl-2 funnelText">SCREENING</p>
                  </v-card-title>
                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-2 black--text">Attempted : {{ schoolChartData.totalScreeningStarted }}</span>
                    <FunnelChartSchool1 :school="schoolChartData" />
                     <div class="funnelPadding" style="text-align: center">
                      <span class="black--text font-weight-medium" style="display: inline-block; margin-bottom: 0"><b>{{
                        calculatePercentage(
                          schoolChartData.totalScreeningStarted -
                          schoolChartData.totalScreeningPassed,
                          schoolChartData.totalScreeningStarted
                        )
                      }}% dropped</b></span>
                      <br />
                      <span style="display: inline-block" class="red--text">Failed :  {{ schoolChartData.totalScreeningFailed
                      }}</span>
                    </div>
                    <!-- <img src="DownIcon.png" alt="Icon" style="margin-top: auto;"> -->
                  </v-card-text>
                
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 w-17">
                <v-card class="rounded-lg" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                   <p class="font-weight-bold pl-2 funnelText">SCREENING RESULT</p>
                  </v-card-title>
                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-2 green--text">Passed : {{ schoolChartData.totalScreeningPassed }}</span>
                    <FunnelChartSchool2 :school="schoolChartData" />
  
                     <div class="funnelPadding" style="text-align: center">
                      <span class="black--text font-weight-medium" style="display: inline-block; margin-bottom: 0"><b>{{
                        calculatePercentage(
                          schoolChartData.totalScreeningPassed -
                          schoolChartData.totalMainsStarted,
                          schoolChartData.totalScreeningPassed
                        )
                      }}% dropped</b></span>
                      <br />
                      <span class="black--text" style="display: inline-block">Mains Not Started : 
                        {{
                          schoolChartData.totalScreeningPassed -
                          schoolChartData.totalMainsStarted
                        }}</span>
                    </div>
                    <!-- <img src="DownIcon.png" alt="Icon" style="margin-top: auto;"> -->
                  </v-card-text>
                
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 w-16">
                <v-card class="rounded-lg" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                   <p class="font-weight-bold pl-2 funnelText">MAINS</p>
                  </v-card-title>
                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-2 black--text">Attempted : {{ schoolChartData.totalMainsStarted }}</span>
                    <FunnelChartSchool3 :school="schoolChartData" />
  
                     <div class="funnelPadding" style="text-align: center">
                      <span class="black--text font-weight-medium" style="display: inline-block; margin-bottom: 0"><b>{{
                        calculatePercentage(
                          schoolChartData.totalMainsStarted -
                          schoolChartData.totalMainsPassed,
                          schoolChartData.totalMainsStarted
                        )
                      }}% dropped</b></span>
                      <br />
                      <span style="display: inline-block" class="red--text">Failed :  {{ schoolChartData.totalMainsFailed
                      }}</span>
                    </div>
                    <!-- <img src="DownIcon.png" alt="Icon" style="margin-top: auto;"> -->
                  </v-card-text>
                
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 w-16">
                <v-card class="rounded-lg" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                   <p class="font-weight-bold pl-2 funnelText">RESULT</p>
                  </v-card-title>
                  <v-card-text class="pr-0 pl-0 pt-0">
                    <span class="pl-2 green--text">Passed : {{ schoolChartData.totalMainsPassed }}</span>
                    <FunnelChartSchool4 :school="schoolChartData" />
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 w-19">
                <v-card class="rounded-lg pr-0 pl-0" width="100%" height="30vh">
                  <v-card-title class="pr-0 pl-0 pb-0">
                   <p class="font-weight-bold pl-2 funnelText">CAMPAIGN SUMMARY</p>
                  </v-card-title>
  
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    Teachers Passed : {{ schoolChartData.totalMainsPassed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    Mains Failed : {{ schoolChartData.totalMainsFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                  <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded mx-3 my-2" width="80%"
                    height="40px">
                    Screening Failed : {{ schoolChartData.totalScreeningFailed }}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-card>
                </v-card>
              </div>
            </div>
            <!-- <v-col cols="2.5">
                  <FunnelChart/>
                 </v-col>
                 <v-col cols="2.5">
                  <FunnelChart/>
                 </v-col>
                 <v-col cols="2.5">
                  <FunnelChart/>
                 </v-col> -->
          </v-container>
  
          <v-row class="d-flex justify-center pt-0 my-0 ml-3 pr-1" fluid>
            <v-col cols="4" md="4" sm="4">
              <div class="text-h6 font-weight-medium highEmphasis">
                Campaign Result
              </div>
            </v-col>
  
            <v-col cols="8" md="8" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent">
                <v-text-field solo hide-details label="Search here..." prepend-inner-icon="mdi-magnify"
                  class="search-bar border-button rounded-pill mx-3" dense clearable></v-text-field>
              </v-card>
  
              <v-btn depressed rounded outlined class="border-button mx-3"><v-icon>mdi-tune</v-icon>FILTER</v-btn>
              <!-- <v-btn depressed rounded outlined class="border-button mx-3"
              ><img src="../../../public/svgs/SORT.svg" alt="" />SORT</v-btn
            > -->
  
              <!-- <v-btn
                class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
              <v-btn depressed outlined rounded class="border-button mx-3"><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>
  
          <v-card
          elevation="0"
          height="55px"
          class="d-flex align-center rounded-table mx-5"
          color="#B2AC88"
        >
          <v-row class="pl-4">
            <v-col cols="1" class="reportTableText"> S.No </v-col>
            <v-col cols="1" class="reportTableText mr-8"> Full Name </v-col>
            <v-col cols="1" class="reportTableText"> Campaign </v-col>
            <v-col cols="2" class="reportTableText"> SchoolName </v-col>
            <v-col cols="1" class="reportTableText "> Invited </v-col>
            <v-col cols="1" class="reportTableText"> Screening </v-col>
            <v-col cols="1" class="reportTableText"> Mains </v-col>
            <v-col cols="1" class="reportTableText"> Demo </v-col>
            <v-col cols="1" class="text-center reportTableText"> League </v-col>
            <v-col cols="1" class="text-end reportTableText"> Action </v-col>
  
          </v-row>
        </v-card>
        <v-card
          class="rounded-table mx-5 mt-2"
          id="myScroll"
          height="350px"
          elevation="0"
        >
          <v-list elevation="0" class="px-0 py-0">
            <v-list-item
              class="px-0 pl-4 py-0"
              v-for="(item, index) in displayedTeachers"
              :key="item.id"
            >
              <v-row class="px-0 py-0 mt-2">
                <v-col cols="1" class="d-flex flex-row align-center ml-2 py-0">
                  {{ index + 1 }}
                </v-col>
                <v-col
                  class="d-flex flex-row align-center pl-0 mr-8 py-0"
                  cols="1"
                >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <td class="t-data" v-bind="attrs" v-on="on">
                        {{ `${item.firstName} ${item.lastName}` }}
                      </td>
                    </template>
                    <span style="font-size: 15px">
                      {{
                        "Grade : " +
                        item.responsibleTeachingAt[0].grade +
                        ", " +
                        "Level : " +
                        item.responsibleTeachingAt[0].level +
                        ", " +
                        "Subject : " +
                        item.responsibleTeachingAt[0].subject
                      }}
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="1" class="d-flex flex-row align-center px-0 py-0">
                  <div>{{ item.campaignName }}</div>
                </v-col>
                <v-col cols="2" class="d-flex flex-row align-center px-0 py-0">
                  <div>{{ item.schoolName }}</div>
                </v-col>
                <v-col class="d-flex flex-row align-center py-0 pr-15" cols="4">
                  <v-row class="d-flex flex-row align-center pl-2">
                    <v-icon
                      medium
                      :color="item.stepper.invitation.iconColor"
                      >{{ item.stepper.invitation.name}}
                    </v-icon>
                    <v-divider
                      :thickness="10"
                      class="border-opacity-25 px-0 py-0"
                       :color="item.stepper.screening.iconColor"
                    ></v-divider>
                    <v-icon
                      medium
                       :color="item.stepper.screening.iconColor"
                    >
                    {{ item.stepper.screening.name}}
                    </v-icon>
                    <v-divider
                      :thickness="2"
                      class="border-opacity-87 px-0 py-0"
                      :color="item.stepper.mains.iconColor"
                    ></v-divider>
                    <v-icon
                      medium
                      :color="item.stepper.mains.iconColor"
                    >
                    {{ item.stepper.mains.name}}</v-icon
                    >

                    <v-divider
                      :thickness="10"
                      class="border-opacity-25 px-0 py-0"
                      :color="item.stepper.demo.iconColor"
                    ></v-divider>
                    <v-icon
                      medium
                      class="pr-8"
                      :color="item.stepper.demo.iconColor"
                      >{{ item.stepper.demo.name}}</v-icon
                    >
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <div
                    v-if="
                      item.coreSkillsSubject != undefined &&
                      item.coreSkillsSubject.length > 0
                    "
                    class="text-subtitle-1 d-flex justify-center"
                  >
                    <div
                      v-if="
                        item.coreSkillsSubject.length > 0 &&
                        item.coreSkillsSubject.length <= 3
                      "
                      class="d-flex flex-row w-100 flex-wrap d-flex justify-center"
                    >
                      <div
                        v-for="(coreSkill, index) in item.coreSkillsSubject"
                        :key="index"
                        class="w-fit"
                      >
                        <v-tooltip v-if="coreSkill.leagueValueIcon != 'NA'" top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex flex-column align-center mr-6"
                            >
                              <v-icon
                                v-if="coreSkill.league == 'Gold'"
                                height="23px"
                                width="25"
                                class="goldIcons--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                              <v-icon
                                v-if="coreSkill.league == 'Silver'"
                                height="23px"
                                width="25"
                                class="silverIcon--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                              <v-icon
                                v-if="
                                  coreSkill.league == 'Bronze' ||
                                  coreSkill.league == 'Bonze'
                                "
                                height="23px"
                                width="25"
                                class="bronzeIcon--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                            </div>
                          </template>
                          <span style="font-size: 15px"
                            >{{ coreSkill.skillName }} -
                            {{ coreSkill.percentile.toFixed(2) }}%
                          </span>
                        </v-tooltip>
                        <div v-else class="d-flex justify-center mr-8 mt-1">
                          -
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        item.coreSkillsSubject.length > 0 &&
                        item.coreSkillsSubject.length >= 3
                      "
                      class="d-flex flex-row w-100 flex-wrap"
                    >
              
                    <div 
                    v-for="(coreSkill, index) in item.coreSkillsSubject"
                    :key="index"
                    >
                      <v-tooltip v-if="coreSkill.leagueValueIcon != 'NA'" top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex flex-column align-center ml-6"
                          >
                   
                            <v-icon
                              v-if="item.coreSkillsSubject[0].league == 'Gold'"
                              height="23px"
                              width="25"
                              class="goldIcons--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                coreSkill.item.coreSkillsSubject[0] == 'Silver'
                              "
                              height="23px"
                              width="25"
                              class="silverIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                item.coreSkillsSubject[0].league == 'Bronze' ||
                                item.coreSkillsSubject[0].league == 'Bonze'
                              "
                              height="23px"
                              width="25"
                              class="bronzeIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <div style="font-size: 12px">
                              #{{ item.coreSkillsSubject[0].rank }}
                            </div>
                          </div>
                        </template>
                        <span style="font-size: 15px"
                          >{{ item.coreSkillsSubject[0].skillName }} -
                          {{
                            item.coreSkillsSubject[0].percentile.toFixed(2)
                          }}%</span
                        >
                      </v-tooltip>
                    </div>
                    <div 
                    v-for="(coreSkill, index) in item.coreSkillsSubject"
                    :key="index"
                    >
                      <v-tooltip v-if="coreSkill.leagueValueIcon != 'NA'" top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex flex-column align-center ml-6"
                          >
                            <v-icon
                              v-if="item.coreSkillsSubject[1].league == 'Gold'"
                              height="23px"
                              width="25"
                              class="goldIcons--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                item.coreSkillsSubject[1].league == 'Silver'
                              "
                              height="23px"
                              width="25"
                              class="silverIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                item.coreSkillsSubject[1].league == 'Bronze' ||
                                item.coreSkillsSubject[1].league == 'Bonze'
                              "
                              height="23px"
                              width="25"
                              class="bronzeIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <div style="font-size: 12px">
                              #{{ item.coreSkillsSubject[1].rank }}
                            </div>
                          </div>
                        </template>
                        <span style="font-size: 15px"
                          >{{ item.coreSkillsSubject[1].skillName }} -
                          {{ item.coreSkillsSubject[1].percentile.toFixed(2) }}%
                        </span>
                      </v-tooltip>
                    </div>
                      
                    <div
                    v-for="(coreSkill, index) in item.coreSkillsSubject"
                    :key="index"
                    >
                      <v-tooltip v-if="coreSkill.leagueValueIcon != 'NA'" top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex flex-column align-center ml-6"
                          >
                            <v-icon
                              v-if="item.coreSkillsSubject[2].league == 'Gold'"
                              height="23px"
                              width="25"
                              class="goldIcons--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                item.coreSkillsSubject[2].league == 'Silver'
                              "
                              height="23px"
                              width="25"
                              class="silverIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                item.coreSkillsSubject[2].league == 'Bronze' ||
                                item.coreSkillsSubject[2].league == 'Bonze'
                              "
                              height="23px"
                              width="25"
                              class="bronzeIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <div style="font-size: 12px">
                              #{{ item.coreSkillsSubject[2].rank }}
                            </div>
                          </div>
                        </template>
                        <span style="font-size: 15px"
                          >{{ item.coreSkillsSubject[2].skillName }} -
                          {{
                            item.coreSkillsSubject[2].percentile.toFixed(2)
                          }}%</span
                        >
                      </v-tooltip>
                    </div>
                      

                      <v-btn
                        v-if="item.coreSkillsSubject.length >= 3"
                        :loading="btnLoading"
                        @click="
                          openLeagueDialog(
                            item.coreSkillsSubject,
                            item.personalInfo.firstName,
                            item.personalInfo.lastName
                          )
                        "
                        height="10px"
                        width="10px"
                        text
                        color="#1B72E8"
                        class="text-body-2 ml-9 mt-4"
                        >View More</v-btn
                      >
                    </div>
                  </div>
                  <div
                    class="d-flex justify-center mr-8 mt-1"
                    v-if="
                      item.coreSkillsSubject != undefined &&
                      item.coreSkillsSubject.length == 0
                    "
                  >
                    -
                  </div>
                </v-col>
                <v-col cols="1" class="d-flex justify-end" align-self="end">
                  <img
                    @click="getResult(item)"
                    class="cursor mr-3 mb-2"
                    width="24px"
                    src="../assets/Live.svg"
                  />
                  <img
                    @click="getFinalResult(item)"
                    class="cursor  mb-2"
                    width="24px"
                    src="../assets/Final.svg"
                  />
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card>
  
        </div>
  
      </v-col>
    </div>
  </template>
    
  <script >
  import AuthService from "@/services/AuthService";
  
  import {
    collection,
    getDocs,
    onSnapshot,
    query,
    where,
  } from "firebase/firestore";
  import { db } from "../firebase";
  import FunnelChartSchool from "./schoolPrincipalChart/FunnelChartSchool.vue";
  import FunnelChartSchool1 from "./schoolPrincipalChart/FunnelChartSchool1.vue";
  import FunnelChartSchool2 from "./schoolPrincipalChart/FunnelChartSchool2.vue";
  import FunnelChartSchool3 from "./schoolPrincipalChart/FunnelChartSchool3.vue";
  import FunnelChartSchool4 from "./schoolPrincipalChart/FunnelChartSchool4.vue";
  import CampaignController from "@/controllers/CampaignController";
 
  export default {
    name: "ViewSchoolData",
    components: {
      FunnelChartSchool,
      FunnelChartSchool1,
      FunnelChartSchool2,
      FunnelChartSchool3,
      FunnelChartSchool4,
    },
    data() {
      return {
        data: [
          {
            name: "Frozen Yogurt",
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
          },
          {
            name: "Eclair",
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
          },
          {
            name: "Cupcake",
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
          },
  
          {
            name: "Honeycomb",
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
          },
        ],
  
        clusters: [],
        schoolClusterTeacher: [],
        topTeacherInCluster: [],
        invited: 0,
        screeningAttempted: 0,
        screeningResult: 0,
        mainsAttempted: 0,
        result: 0,
        apraisals: 0,
        pip: 0,
        tbd: 0,
        chartData: {},
        schoolChartData: {},
        emptySchoolChartData: {
          totalInvited: 0,
          totalScreeningStarted: 0,
          totalScreeningPassed: 0,
          totalScreeningFailed: 0,
          totalMainsStarted: 0,
          totalMainsPassed: 0,
          totalMainsFailed: 0,
        },
        schooolTeacherAppraisal: 0,
        schooolTeacherPip: 0,
        schooolTeacherTBD: 0,
      };
    },
  
    computed: {
      displayedClusterTeachers() {
        return this.topTeacherInCluster;
      },
    },
    methods: {
      logout() {
        AuthService.logout();
        this.$router.push("/login");
      },
      getClusterOrSchool() {
        this.roleCluster = this.$store.state.role.clusterName;
        this.roleSchool = this.$route.params.schoolName;
  
        // console.log("my cluster>>", this.roleCluster);
        // console.log("my school>>", this.roleSchool);
      },
  
      async teacherSchoolSnapshotListener() {
        const campaignRef = collection(db, "campaigns");
        onSnapshot(campaignRef, (snapshot) => {
          snapshot.docs.forEach(async (doc) => {
            const campaignName = doc.data().name;
            if (doc.data().audienceType.toLowerCase() === "teacher") {
              try {
                const jobSeekerRef = collection(campaignRef, doc.id, "audience");
                const jobSeekDocs = await getDocs(jobSeekerRef);
  
                jobSeekDocs.forEach((d) => {
                  console.log("jobSeekersDocsTraverse...", d.data());
                  if (d.data().schoolName == this.roleSchool) {
                    console.log("entered...////");
                    const topTeachersClusterData = {
                      screeningTotal:
                        d.data().screeningTotal == null
                          ? "-"
                          : d.data().screeningTotal,
                      mainsTotal:
                        d.data().mainsTotal == null ? "-" : d.data().mainsTotal,
                      firstName:
                        d.data().personalInfo.firstName == null
                          ? ""
                          : d.data().personalInfo.firstName,
                      lastName:
                        d.data().personalInfo.lastName == null
                          ? ""
                          : d.data().personalInfo.lastName,
                      schoolName:
                        d.data().schoolName == null ? "" : d.data().schoolName,
                      lastStatus: (() => {
                        let stageValue = "";
                        let statusValue = "";
  
                        if (
                          d.data().campaignStatus &&
                          d.data().campaignStatus.length > 0
                        ) {
                          const lastStatus =
                            d.data().campaignStatus[
                              d.data().campaignStatus.length - 1
                            ].status;
                          if (lastStatus === "INVITED") {
                            stageValue = "Screening";
                            statusValue = "Yet to Attempt";
                          } else if (lastStatus === "SCREENING PASSED") {
                            stageValue = "Mains";
                            statusValue = "Yet to Attempt";
                          } else if (lastStatus === "SCREENING FAILED") {
                            stageValue = "Screening";
                            statusValue = "Fail";
                          } else if (lastStatus === "MAINS PASSED") {
                            stageValue = "Mains";
                            statusValue = "Pass";
                          } else if (lastStatus === "MAINS FAILED") {
                            stageValue = "Mains";
                            statusValue = "Fail";
                          }
                        }
  
                        return {
                          stageValue: stageValue,
                          statusValue: statusValue,
                        };
                      })(),
                      responsibleTeachingAt: d.data().responsibleTeachingAt,
                      
                      campaignName: campaignName,
                      campaignStatus : d.data().campaignStatus,
                      coreSkillsSubject : d.data().coreSkillsSubject,
                      campaignId :doc.id,
                      userId: d.data().userId
                    };
                    this.schoolClusterTeacher.push(topTeachersClusterData);
                    console.log(
                      "screening total result show >>",
                      d.data().screeningTotal
                    );
                  }
                });
                let sortedProducts = this.schoolClusterTeacher.sort((p1, p2) =>
                  p1.screeningTotal < p2.screeningTotal
                    ? 1
                    : p1.screeningTotal > p2.screeningTotal
                      ? -1
                      : 0
                );
                this.topTeacherInCluster = this.calculateUsersForLeague(this.schoolClusterTeacher);
              } catch (error) {
                console.error("Error fetching audience collection:", error);
              }
            }
          });
        });
      },
      calculateUsersForLeague(users) {
      users.forEach((user) => {
        var coreSkills = [];
        if (user.coreSkillsSubject !== undefined) {
          user.coreSkillsSubject.forEach((coreSkillSubject) => {
            if (coreSkillSubject.result == "PASSED") {
              coreSkills.push(coreSkillSubject);
            }
          });
        }
        user.coreSkillsSubject = coreSkills;
      });
      return users;
    },
    async getResult(data) {
      console.log("data" , data.campaignId);
      const response = await CampaignController.getResult(data.userId, data.campaignId);
      if (response.status == 200) {
        if (response.data.length == 0) {
          alert("User test not started");
        } else {
          this.$router.push(`/testreport/${data.campaignId}/${data.userId}`);
        }
      } else {
        alert(response.data.error);
      }
    },
    getFinalResult(data) {
      this.$router.push({
        path: "/userreport",
        name: "UserReport", //use name for router push
        query: {
          campaignId: data.campaignId,
          userId: data.userId,
        },
      });
    },

  
      async setupSnapshotListenerSchoolHrFunnel() {
        const id = collection(
          db,
          "dashboards",
          "management-dashboard-001",
          "schools"
        );
        console.log("inside snapshot", id);
        if (!id) {
          this.schoolChartData = this.emptySchoolChartData;
          console.log("isnide snap", this.schoolChartData);
        }
  
        const q = query(id, where("name", "==", this.roleSchool));
        if (!q.exists) {
          this.schoolChartData = this.emptySchoolChartData;
        }
  
        onSnapshot(q, (snapshot) => {
          snapshot.forEach((doc) => {
            if (
              doc.data().totalInvited === undefined ||
              doc.data().totalScreeningStarted === undefined ||
              doc.data().totalScreeningPassed === undefined ||
              doc.data().totalScreeningFailed === undefined ||
              doc.data().totalMainsFailed === undefined ||
              doc.data().totalMainsPassed === undefined ||
              doc.data().totalMainsStarted === undefined
            ) {
              this.schoolChartData = this.emptySchoolChartData;
            } else {  
              this.schoolChartData = doc.data();
            }
          });
        });
      },
      calculatePercentage(value, total) {
        if (total === 0) {
          return 0;
        }
        const percentage = (value / total) * 100;
        if (isNaN(percentage)) {
          return 0;
        }
        const roundedPercentage = Math.round(percentage);
        return roundedPercentage;
      },
    },
  
    async created() {
      this.getClusterOrSchool();
      this.setupSnapshotListenerSchoolHrFunnel();
      this.teacherSchoolSnapshotListener();
    },
  };
  </script>
    
  <style scoped>
  .tbl>.t-head>.t-row>.head {
    background-color: primary;
  }
  
  .tbl {
    height: 196px;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  
  .tbl th {
    padding: 8px;
  }
  
  .tbl td {
    padding: 5px;
  }
  
  .tbl>.t-body>.t-row {
    background-color: white;
  }
  
  .head {
    text-align: center;
  }
  
  .tbl>.t-body>.t-row>.t-data {
    text-align: center;
  }
  
  .tbl>tr:nth-child(even) {
    background-color: white !important;
  }
  
  
  .fail {
    color: #FF0505;
  
  }
  
  .pass {
    color: #06C270;
  }
  
  .yetToAttempt {
    color: #000000DE;
  }
  
  .dotyetToAttempt {
    width: 8px;
    height: 8px;
    background-color: #000000de;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .dotfail {
    width: 8px;
    height: 8px;
    background-color: #ff0505;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .dotpass {
    width: 8px;
    height: 8px;
    background-color: #06c270;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  </style>