<template>
	<div>
		<v-card height="56px" width="100%"
			class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
			style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      ">
			<v-card-title class="pl-0 ml-0 mt-1">
				<v-menu offset-y class="menuZindex">
					<template v-slot:activator="{ on }">
						<span v-on="on" class="breadcrumb-text" style="margin-top: 2px">
							Support
							<v-icon class="breadcrumb-arrow cursor"
								style="margin-top: 5px !important">mdi-chevron-down</v-icon>
						</span>
					</template>
					<MastersDropdownComponent />
				</v-menu>
			</v-card-title>

			<div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center"
				style="position: fixed; right: 0">
				<v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
					<v-hover>
						<img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg">
					</v-hover>
				</v-badge>

				<div>
					<img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32">
				</div>
				<div class="d-flex flex-column d-justify-evenly user-details">
					<v-card-title class="ma-0 pa-0 elipsis">
						{{ $store.state.userInfo.name }}
					</v-card-title>
					<v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
						{{ $store.state.userInfo.email }}
					</v-card-subtitle>
				</div>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
							<v-icon class="">mdi-chevron-down</v-icon>
						</v-btn>
					</template>
					<v-btn @click="logout">logout</v-btn>
				</v-menu>
			</div>
		</v-card>

		<div fluid class="pad px-8 d-flex flex-wrap">

			<v-card width="100%" outlined class="rounded-lg">
				<v-list class="py-0">
					<v-list-group :value="false">
						<template v-slot:activator>
							<v-list-item-title><b>User Manual for Job Seekers</b></v-list-item-title>
						</template>
						<v-list-item>
							<v-list-item-content>
								<div class="d-flex justify-end">
									<img width="30px" class="cursor" src="../assets/downloadManual.svg"
										@click="downloadManual('/files/user_manual.pdf')" />
								</div>
								<PDFPreviewer pdfPath="/files/user_manual.pdf" />
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</v-card>
			<v-card width="100%" outlined class="rounded-lg mt-4">
				<v-list class="py-0">
					<v-list-group :value="false">
						<template v-slot:activator>
							<v-list-item-title><b>FAQs for Job Seekers</b></v-list-item-title>
						</template>
						<v-list-item>
							<v-list-item-content>
								<v-card class="elevation-0 transparent">
									<v-text-field solo label="Search here..." hide-details
										prepend-inner-icon="mdi-magnify" v-model="search"
										class="search-bar search-bar2 border-button rounded-pill mx-2" dense clearable
										@click:clear="clearSearch"></v-text-field>
								</v-card>

								<v-card class="rounded-lg mt-2" outlined v-for="(item, index) in filteredFAQs"
									:key="index">
									<div style="background-color: #B2AC8880;" class="py-2 pl-4">
										<span>{{ item.name }}</span>
									</div>
									<v-list>
										<v-list-item v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
											<v-list-item-content>
												<v-list-item-title>{{ subItem.question }}</v-list-item-title>
												<v-list-item-subtitle class="answer"
													v-html="subItem.answer"></v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</v-card>
			<v-card width="100%" outlined class="rounded-lg mt-4">
				<v-list class="py-0">
					<v-list-group :value="false">
						<template v-slot:activator>
							<v-list-item-title><b>User Manual for VIBGYOR Group of Schools</b></v-list-item-title>
						</template>
						<v-list-item>
							<v-list-item-content>
								<div class="d-flex justify-end">
									<img width="30px" class="cursor" src="../assets/downloadManual.svg"
										@click="downloadManual('/files/vgos_manual.pdf')" />
								</div>
								<PDFPreviewer pdfPath="/files/vgos_manual.pdf" />
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</v-card>
			<v-card width="100%" outlined class="rounded-lg mt-4">
				<v-list class="py-0">
					<v-list-group :value="false">
						<template v-slot:activator>
							<v-list-item-title><b>FAQs for VIBGYOR Group of Schools</b></v-list-item-title>
						</template>
						<v-list-item>
							<v-list-item-content>
								<v-card class="elevation-0 transparent">
									<v-text-field solo label="Search here..." hide-details
										prepend-inner-icon="mdi-magnify" v-model="vgosSearch"
										class="search-bar search-bar2 border-button rounded-pill mx-2" dense clearable
										@click:clear="clearVgosSearch"></v-text-field>
								</v-card>

								<v-card class="rounded-lg mt-2" outlined v-for="(item, index) in filterVgosFaqs"
									:key="index">
									<div style="background-color: #B2AC8880;" class="py-2 pl-4">
										<span>{{ item.name }}</span>
									</div>
									<v-list>
										<v-list-item v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
											<v-list-item-content>
												<v-list-item-title>{{ subItem.question }}</v-list-item-title>
												<v-list-item-subtitle class="answer"
													v-html="subItem.answer"></v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card>
							</v-list-item-content>
						</v-list-item>
					</v-list-group>
				</v-list>
			</v-card>
		</div>
		<v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
			<Notification @close-dialog="notificationDialog = false" />
		</v-dialog>
	</div>
</template>
<script>
import PDFPreviewer from '../components/PDFPreviewer.vue';
import {
	getDownloadURL,
	ref as storageRef,
} from "firebase/storage";
import { storage } from "../firebase.js";


export default {
	components: {
		PDFPreviewer
	},
	data() {
		return {
			notificationDialog: false,
			search: "",
      vgosSearch: "",
			items: [ // Static list item data with sub-items
				{
					name: "Hubble STAR (Smart Testing And Recruitment)  ",
					subItems: [
						{ question: '1. What is the purpose of Hubble STAR?', answer: 'a. The Smart Staff Selection application/ platform is designed to optimize the process of staff recruitment for the VIBGYOR Group of Schools, guiding job seekers through various assessment stages.' },
						{ question: '2. How can Hubble STAR help teachers?', answer: 'a. The Hubble STAR assessment offers job seekers a fair opportunity to demonstrate their skills and adaptability, facilitating entry into esteemed educational institutions for a fulfilling teaching career. It streamlines the selection process, saving time on job searches and applications. Through modern assessment tools, teachers gain exposure and self-awareness, guiding their professional growth.' },
						{ question: '3. How can the job seekers start the SSS test?', answer: 'a. Job seekers receive a personalized email containing an assessment link tailored to their expertise. Job seekers can click on the assessment link provided in the email to access the application.' },
						{ question: '4. What can job seekers do if they do not get an email for the test?', answer: 'a. In this case, they should contact Hubble STAR support at <a href="mailto:hubblestar.support@hubblehox.com">hubblestar.support@hubblehox.com</a>.' },
						{ question: '5. What are the different rounds in the Hubble STAR assessment?', answer: 'a. The assessment consists of four rounds: 1.Screening 2.Mains 3.Demo 4.Interview' },
						{ question: '6. How can the job seekers authenticate their identity on the platform?', answer: 'a. Job seekers authenticate their identity by entering an OTP sent to their phone number or email address.' },
						{ question: '7. What if the candidate does not receive the OTP?', answer: 'a. The candidate should first ensure they have entered the correct email or phone number. Then, they should check their spam or junk folder for the email. If they still do not receive the OTP, they can click the “RESEND OTP” button to try again. If the issue persists, contact support at <a href="mailto:hubblestar.support@hubblehox.com">hubblestar.support@hubblehox.com</a>.' },
						{ question: '8. What is the total duration of the test?', answer: 'a. The total duration of the test varies depending on the rounds, as each stage has a different set of timings.' },
						{ question: '9. Is there a screen with instructions explaining the test details before it begins?', answer: 'a. Job seekers are provided with instructions on the test instructions page before starting the screening or mains tests.' },
						{ question: '10. How can the job seeker select their job preferences?', answer: 'a. Job seekers choose their preferred level, school, board, and subjects from displayed lists, allowing them to select up to three subjects.' },
						{ question: '11. Can the job seeker change their job preferences?', answer: 'a. Once job seekers click on the assessment link, they must carefully choose their preference, as it cannot be changed later.' },
						{ question: '12. Can job seekers proceed to the next round if they fail in one?', answer: 'a. Candidates cannot proceed to the next round unless they clear the preceding one. For instance, if candidates fail the screening, they cannot advance to the main round. Similarly, if they do not pass the main round, they are ineligible for the demo round.' },
						{ question: '13. If the job seeker fails, can they retake the test?', answer: 'a. Job seekers are not allowed to retake the test if they fail any of the rounds.' },
						{ question: '14. Is it a proctored test?', answer: 'a. Yes, the screening and mains tests are AI-proctored to ensure a fair and transparent assessment process.' },
						{ question: '15. What are the recommended steps to set up your environment before starting an online proctored test?', answer: 'a. Ensure a quiet, well-lit room, clear your desk, and check computer and internet functionality.' },
					]
				},
				{
					name: "Screening",
					subItems: [
						{ question: '1. What is the screening test?', answer: " a. The 30-45 minute screening round assesses a candidate's core skills, pedagogical skills, digital literacy, and communication skills, which are crucial for the role and effective teamwork. " },
						{ question: '2. Is photo identity capture required for the screening test?', answer: 'a. Yes, job seekers need to capture their photo identity for authentication.' },
						{ question: '3. What happens after submitting the screening test?', answer: 'a. Upon completing and submitting the test, job seekers receive a confirmation email, acknowledging their submission and assuring them of the ongoing review of their application.' },
						{ question: '4. How are job seekers notified of their screening test results?', answer: 'a. Job seekers are notified via email regarding their test outcome the next day. ' },
						{ question: '5. What happens if a job seeker clears the screening test?', answer: 'a. After successfully clearing the screening test, the job seeker will receive an email with a link to the main test.' },
						{ question: '6. What happens if a job seeker does not clear the screening test?', answer: 'a. They receive an email informing them that they did not clear the screening test.' }
					]
				},
				{
					name: "Mains",
					subItems: [
						{ question: '1. What is the mains test?y', answer: 'a. The mains test is an online assessment at the school, lasting 90-120 minutes, covering core skills, pedagogy, digital literacy, communication skills, and psychometry' },
						{ question: '2. What happens after submitting the mains test?y', answer: 'a. Upon completing and submitting the test, job seekers receive a confirmation email, acknowledging their submission and assuring them of the ongoing review of their application.' },
						{ question: '3. How are job seekers notified of their mains test results?', answer: 'a. Job seekers are notified via email regarding their test outcome the next day.' },
						{ question: '4. What happens if a job seeker clears the mains test?', answer: 'a. After successfully clearing the mains test, the job seeker will receive an email with instructions for the demo submission, along with the assigned topic.' },
						{ question: '5. What happens if a job seeker does not clear the mains test?', answer: 'a. They receive an email informing them that they did not clear the mains test.' }
					]
				},
				{
					name: "Demo",
					subItems: [
						{ question: '1. What are the requirements for the demo submission?', answer: 'a. Job seekers need to submit a 15-minute demo on a specified subject topic adhering to the naming format "FirstName_LastName_Level_Subject".' },
						{ question: '2. Is it necessary for candidates to submit two demo videos if they choose two subjects?', answer: 'a. Yes, the candidates are required to submit two demo videos if they opt for two subjects.' }
					]
				},
				{
					name: "Interview",
					subItems: [
						{ question: '1. How will the interview be scheduled?', answer: 'a. HR will coordinate with the job seeker and schedule the interview based on the available slots.' },
						{ question: '2. When will the interviews be conducted?', answer: 'a. Interviews will be held between 3:00 PM and 5:00 PM on Wednesdays and Fridays.' },
						{ question: '3. Where will the interview be conducted?', answer: 'a. The interview process is offline, requiring the job seeker to visit the school in person for the interview.' },
						{ question: '4. What happens after the interview round?', answer: "a. The HR will review the interview feedback, decide the candidate's suitability for the role, and proceed with the appropriate next steps. " },
						{ question: '5. What if the job seeker cannot attend the scheduled interview?', answer: 'a.If a job seeker is unable to attend the scheduled interview, they should email <a href="mailto:hubblestar.support@hubblehox.com">hubblestar.support@hubblehox.com</a> to inquire about the possibility of rescheduling.' }
					]
				},
				{
					name: "Feedback",
					subItems: [
						{ question: '1. How can the job seekers provide question feedback?', answer: 'a. Job Seekers can provide feedback on individual questions by clicking the “Report the Question” icon next to each question ' },
						{ question: '2. How can job seekers provide feedback for the Smart Staff Selection application?', answer: 'a. Job seekers can access a feedback form within the application after attempting each round, such as after the Screening, Mains, and Demo rounds. If they do not pass the screening or mains test, a feedback form will be included in the email.' }
					]
				},
				{
					name: "General",
					subItems: [
						{ question: '1. What are the recommended steps to set up your environment before starting an online proctored test?', answer: 'a. Ensure a quiet, well-lit room, clear your desk, and check computer and internet functionality.' },
						{ question: '2. Can I have a break during the exam?', answer: 'a. Breaks are not permitted during the exam. However, in case of an emergency, the job seeker may leave the exam area. They should be aware that they are under AI proctoring and may receive a "No Person" popup repeatedly.' },
						{ question: '3. What technical infrastructure does the job seeker need to take the test?  ', answer: 'a. They will need a laptop or a PC with a functional webcam and a strong internet connection.' },
						{ question: '4. Do I need a specific browser?  ', answer: "a. There's no specific browser requirement. You can take the test using the latest versions of Google Chrome, Firefox, or Microsoft Edge." }
					]
				}

			],
			vogsQnA: [
				{
					"name": "Hubble STAR (Smart Testing And Recruitment)",
					"subItems": [
						{
							"question": "1. What is the purpose of Hubble STAR?",
							"answer": "The Hubble STAR application is designed to streamline the process of shortlisting potential teachers for the VIBGYOR Group of Schools."
						},
						{
							"question": "2. How can Hubble STAR help teachers?",
							"answer": "The Hubble STAR assessment offers job seekers a fair opportunity to demonstrate their skills and adaptability, facilitating entry into esteemed educational institutions for a fulfilling teaching career. It streamlines the selection process, saving time on job searches and applications. Teachers gain exposure and self-awareness through modern assessment tools, guiding their professional growth."
						},
						{
							"question": "3. What educational levels are evaluated by the Hubble STAR application?",
							"answer": "The Hubble STAR application conducts assessments across 5 NEP (National Education Policy) also known as teaching levels: Foundational Level - Grades 1 and 2, Preparatory Level - Grades 3, 4, and 5, Middle Level - Grades 6, 7, and 8, Secondary Level - Grades 9 and 10, and Senior Secondary Level - Grades 11 and 12."
						},
						{
							"question": "4. Which subjects are included in the assessment?",
							"answer": "The assessment will be conducted based on the following grade-specific subjects: Grade 1, Grade 2- English, Hindi, Mathematics; Grade 3, Grade 4, Grade 5- English, Hindi, Mathematics, EVS; Grade 6, Grade 7, Grade 8- English, Hindi, Mathematics, Social Science, Science; Grade 9, Grade 10- English, Hindi, Mathematics, Social Science, Science; Grade 11, Grade 12- Biology, Physics, Chemistry, Mathematics, English	."
						},
						{
							"question": "5. What should teachers do if the above subjects do not align with those they teach? How will the questions be selected for the assessment in such cases?",
							"answer": "The subjects that teachers are currently teaching will be matched internally with the listed subjects. Questions pertinent to these subjects will be selected for the assessment."
						},
						{
							"question": "6. Do teachers teaching subjects other than those mentioned above also need to take the assessment?",
							"answer": "In this phase, only teachers who teach the subjects mentioned will be eligible for the assessment."
						},
						{
							"question": "7. How can the teachers access the Hubble STAR application?",
							"answer": "Teachers receive a personalized email with an assessment link tailored to their profile, preferences, and expertise as specified by VGOS. By clicking the assessment link in the email, teachers can access the application."
						},
						{
							"question": "8. What can teachers do if they do not get an email for the test?",
							"answer": "In this case, the teachers can raise a ticket at ISR (Internal Service Request) or write to Hubble STAR support at hubblestar.support@hubblehox.com."
						},
						{
							"question": "9. How can teachers log in to the application?",
							"answer": "Teachers have two login options:They can use Single Sign-On, which redirects them to the assessment profile information page, In the second option, their email address will be pre-filled. They can log in by entering a 6-digit OTP sent to their email for authentication"
						},
						{
							"question": "10. How can the teachers authenticate their identity on the platform?",
							"answer": "Job seekers authenticate their identity by entering an OTP sent to their email address."
						},
						{
							"question": "11. What are the different rounds for the VGOS Hubble STAR assessment?",
							"answer": "The assessment consists of three rounds: Screening (online test), Mains (online test), and Demo (online submission)."
						},
						{
							"question": "12. What if the candidate does not receive the OTP?",
							"answer": "The candidate should first check their spam or junk folder for the email. If they still do not receive the OTP, they can click the 'RESEND OTP' button to try again. If the issue persists, contact support at hubblestar.support@hubblehox.com."
						},
						{
							"question": "13. What is the total duration of the test?",
							"answer": "The total duration of the test varies depending on the rounds, as each stage has a different set of timings."
						},
						{
							"question": "14. Is there a screen with instructions explaining the test details before it begins?",
							"answer": "Teachers are provided with instructions on the test instructions page before starting the screening or mains tests."
						},
						{
							"question": "15. Can the teacher select their level, school, board, grade, and subject preferences?",
							"answer": "Teachers cannot choose their preferred level, school, board, or subjects, as their data is already uploaded into the application. This comprehensive database allows administrators to efficiently match teachers with suitable assessment campaigns."
						},
						{
							"question": "16. What happens if a teacher teaches multiple grades across different levels?",
							"answer": "If a teacher is teaching multiple grades or levels, the assessment will contain questions based on the subjects of the highest grade at the highest level."
						},
						{
							"question": "17. Can teachers proceed to the next round if they fail in one?",
							"answer": "Teachers cannot proceed to the next round unless they clear the preceding one. For instance, if the teacher fails the screening, they cannot advance to the main round. Similarly, if they do not pass the main round, they are ineligible for the demo round."
						},
						{
							"question": "18. If the teacher fails, can they retake the assessment?",
							"answer": "Teachers are not allowed to retake the assessment if they fail any of the rounds."
						},
						{
							"question": "19. Is it a proctored assessment?",
							"answer": "Yes, the screening and mains tests are AI-proctored to ensure a fair and transparent assessment process."
						}
					]
				},
				{
					"name": "Screening",
					"subItems": [
						{
							"question": "1. What is the screening test?",
							"answer": "The 30-45 minute screening round assesses a teacher’s core skills, pedagogical skills, digital literacy, and communication skills, which are crucial for the role and effective teamwork."
						},
						{
							"question": "2. Is photo identity capture required for the screening assessment?",
							"answer": "Yes, teachers need to capture their photo identity for authentication."
						},
						{
							"question": "3. What happens after submitting the screening assessment?",
							"answer": "Upon completing and submitting the test, teachers receive a confirmation email, acknowledging their submission and assuring them of the ongoing review of their application."
						},
						{
							"question": "4. How are teachers notified of their screening assessment results?",
							"answer": "Teachers are notified via email regarding their test outcome the next day."
						},
						{
							"question": "5. What happens if the teacher clears the screening assessment?",
							"answer": "After successfully clearing the screening assessment, the teacher will receive an email with a link to the main assessment."
						},
						{
							"question": "6. What happens if a teacher does not clear the screening assessment?",
							"answer": "They receive an email informing them that they did not clear the screening assessment."
						}
					]
				},
				{
					"name": "Mains",
					"subItems": [
						{
							"question": "1. What is the mains assessment?",
							"answer": "The mains assessment is an online assessment, lasting 90-120 minutes, covering core skills, pedagogy, digital literacy, communication skills, and psychometry."
						},
						{
							"question": "2. What happens after submitting the mains assessment?",
							"answer": "Upon completing and submitting the assessment, teachers receive a confirmation email, acknowledging their submission and assuring them of the ongoing review of their application."
						},
						{
							"question": "3. How are teachers notified of their mains assessment results?",
							"answer": "Teachers are notified via email regarding their assessment outcome the next day."
						},
						{
							"question": "4. What happens if a teacher clears the mains assessment?",
							"answer": "After successfully clearing the mains assessment, the teacher will receive an email with instructions for the demo submission, along with the assigned topic."
						},
						{
							"question": "5. What happens if a teacher does not clear the mains assessment?",
							"answer": "They receive an email informing them that they did not clear the mains assessment."
						}
					]
				},
				{
					"name": "Demo",
					"subItems": [
						{
							"question": "1. How will the teacher receive the demo topic?",
							"answer": "Teachers who pass the mains test will receive an email with the demo topics for each subject they passed."
						},
						{
							"question": "2. What are the requirements for the demo submission?",
							"answer": "Teachers need to submit a 15-minute demo on a specified subject topic adhering to the naming format 'FirstName_LastName_Level_Subject'."
						},
						{
							"question": "3. Is it necessary for teachers to submit two demo videos if they teach two subjects?",
							"answer": "Yes, the candidates are required to submit two demo videos if they opt for two subjects."
						}
					]
				},
				{
					"name": "Feedback",
					"subItems": [
						{
							"question": "1. How can the teachers provide question feedback?",
							"answer": "Job Seekers can provide feedback on individual questions by clicking the 'Report the Question' icon next to each question."
						},
						{
							"question": "2. How can teachers provide feedback for the Hubble STAR application?",
							"answer": "Teachers can access the Google feedback form within the application after completing each round, such as after the Screening, Mains, and Demo rounds. If the teachers do not pass the screening or mains assessment, a feedback option will be included in the email."
						}
					]
				},
				{
					"name": "General",
					"subItems": [
						{
							"question": "1. What are the recommended steps to set up your environment before starting an online proctored test?",
							"answer": "Ensure a quiet, well-lit room, clear your desk, and check computer and internet functionality."
						},
						{
							"question": "2. Can I have a break during the exam?",
							"answer": "Breaks are not permitted during the exam. However, in case of an emergency, the teacher may leave the exam area. They should be aware that they are under AI proctoring and may receive a 'No Person' popup repeatedly."
						},
						{
							"question": "3. What technical infrastructure does the teacher need to take the test?",
							"answer": "They will need a laptop or a PC with a functional webcam and a strong internet connection."
						},
						{
							"question": "4. What technical requirements do I need?",
							"answer": "Operating System: Windows 7 or higher; Screen Resolution: 1024 x 768 or higher; Internet Bandwidth: Greater than 2 MBps."
						},
						{
							"question": "5. Do I need a specific browser?",
							"answer": "There's no specific browser requirement. The teacher can take the test using the latest versions of Google Chrome, Firefox, or Microsoft Edge."
						}
					]
				}
			]



		}
	},
	methods: {
		logout() {
			AuthService.logout();
			this.$router.push("/login");
		},
		// async downloadManual() {
		//   try {
		//   	const pdfRef = storageRef(
		//         storage,
		//         "user_manual/user_manual.pdf"
		//       );
		//     const url = await getDownloadURL(pdfRef);

		//     // Create a temporary link element to trigger the download
		//     const link = document.createElement('a');
		//     link.href = url;
		//     link.target = '_blank';
		//     link.download = 'user_manual.pdf';
		//     document.body.appendChild(link);
		//     link.click();
		//     document.body.removeChild(link);
		//   } catch (error) {
		//     console.error('Error downloading manual:', error);
		//     // Handle error downloading manual
		//   }
		// },
		async downloadManual(url) {
			try {
				// Create a temporary link element to trigger the download
				const link = document.createElement('a');
				link.href = url;
				link.target = '_blank'; // Open in a new tab if necessary
				link.download = url.replace(/^.*[\\\/]/, '') // Specify the filename for download
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} catch (error) {
				console.error('Error downloading manual:', error);
				// Handle error downloading manual
			}
		},

		clearSearch() {
			this.search = "";
		},
    clearVgosSearch(){
      this.vgosSearch = ""
    }

	},
	computed: {
		filteredFAQs() {
			const searchText = this.search.toLowerCase();
			console.log('searchText', searchText)
			return this.items.map(item => {
				const filteredSubItems = item.subItems.filter(subItem => {
					const questionMatch = subItem.question.toLowerCase().includes(searchText);
					const answerMatch = subItem.answer.toLowerCase().includes(searchText);
					return questionMatch || answerMatch;
				});
				return { ...item, subItems: filteredSubItems };
			}).filter(item => item.subItems.length > 0);
		},
    filterVgosFaqs(){
      const searchText = this.vgosSearch.toLowerCase();
			console.log(' vgos searchText', searchText)
			return this.vogsQnA.map(item => {
				const filteredSubItems = item.subItems.filter(subItem => {
					const questionMatch = subItem.question.toLowerCase().includes(searchText);
					const answerMatch = subItem.answer.toLowerCase().includes(searchText);
					return questionMatch || answerMatch;
				});
				return { ...item, subItems: filteredSubItems };
			}).filter(item => item.subItems.length > 0);
    }
	}

}

</script>
<style>
.spaced-list {
	margin-top: 16px;
	/* Adjust the value as needed */
}

.cursor {
	cursor: pointer;
}

.pdf-preview {
	width: 100%;
	height: 500px;
	/* Adjust the height as needed */
	border: 1px solid #ccc;
}

.answer {
	white-space: normal;
	word-wrap: break-word;
}
</style>