<template>
  <!-- v-if="this.$store.state.role.roleName === 'Super Admin'" -->
  <div v-if="this.roleCluster === 'All Clusters' && this.roleSchool === 'All'">
    <v-carousel
      v-model="activeIndex"
      :cycle="false"
      interval="60000"
      height="100vh"
      hide-delimiters
      :show-arrows="false"
      class="custom-carousel"
    >
      <v-carousel-item v-if="userTypeViews.includes('teacher')">
        <!-- First Dashboard content here -->
        <!-- Wrap your existing dashboard code here -->
        <template>
          <div>
            <div v-if="!isFullScreen">
              <v-card
                height="56px"
                width="100%"
                class="mx-6 mt-2 pl-4 pr-8 transparent elevation-0 d-flex flex-row justify-space-between align-center"
                ><v-card-title class="pl-0 ml-0">
                  <p class="breadcrumb-text">Dashboard ({{ this.roleName }})</p>
                  <span
                    ><v-icon class="breadcrumb-arrow"
                      >mdi-chevron-down</v-icon
                    ></span
                  >
                </v-card-title>
                <div
                  class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center"
                >
                  <v-badge
                    color="red"
                    dot
                    right
                    transition="slide-x-transition"
                    class="mr-8"
                  >
                    <v-hover v-model="hover">
                      <img
                        @click="notificationDialog = true"
                        class="cursor"
                        src="../assets/bell 1.svg"
                      />
                    </v-hover>
                  </v-badge>
                  <div>
                    <img
                      src="../assets/avtar.png"
                      class="rounded-xl mr-2"
                      width="32"
                      height="32"
                    />
                  </div>
                  <div class="d-flex flex-column d-justify-evenly user-details">
                    <v-card-title class="ma-0 pa-0 elipsis">
                      {{ $store.state.userInfo.name }}
                    </v-card-title>
                    <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
                      {{ $store.state.userInfo.email }}
                    </v-card-subtitle>
                  </div>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="40px"
                        height="40px"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <v-icon class="">mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-btn @click="logout">logout</v-btn>
                  </v-menu>
                </div>
              </v-card>
            </div>

            <div class="home-view-container"
              :class="{ fullScreenSize: isFullScreen === true }"
              style="height: calc(100vh - 64px)"
            >
              <v-row class="heading ml-0 mb-1">
                <v-card-title class="text-h5 pb-0 mb-0 font-weight-light"
                  >Dashboard - <b>Teachers </b></v-card-title
                >
              </v-row>

              <div>
                <div>
                  <div class="w-100 h-240 d-flex flex-row mb-4">
                    <div class="w-35 margin-betwen-col">
                      <v-card class="rounded-xl cardBorder" elevation="0">
                        <v-card-text style="text-align: center">
                          <DonutChart
                            :clusterData="clusterData"
                            :donutArray="donutArray"
                          />
                        </v-card-text>
                      </v-card>
                    </div>

                    <div
                      class="w-47 cardBorder sec-col margin-betwen-col pa-2"
                      style="height: 38vh"
                    >
                      <v-card elevation="0">
                        <!-- <v-card-title class="text-subtitle-1 "> -->
                        <div
                          class="table-header w-100 d-flex flex-row justify-space-between"
                        >
                          <div class="i-patch">
                            Top Performing Schools (All)
                          </div>

                          <v-card
                            class="pl-1 d-flex align-center my-2 elevation-0 overflow-hidden"
                            width="20%"
                          >
                            <!-- <v-select
                              solo
                              outlined
                              class="circle-radius"
                              prefix="Top"
                              color="black"
                              :items="dropdownItems"
                              v-model="selectedItem"
                              hide-details
                              dense
                              append-icon="mdi-chevron-down"
                            >
                            </v-select> -->
                          </v-card>

                          <!-- <v-select
                            outlined
                            class="circle-radius"
                            prefix="Top"
                            color="black"
                            :items="dropdownItems"
                            v-model="selectedItem"
                            hide-details
                            dense
                            append-icon="mdi-chevron-down"
                          >
                          </v-select> -->
                        </div>
                        <div class="table-container">
                          <table class="tbl">
                            <thead class="t-head">
                              <tr class="t-row">
                                <th class="head i-patch">Rank</th>
                                <th class="head i-patch text-left pl-2">School Name</th>
                                <th class="head i-patch">Cluster</th>
                                <th class="head i-patch">Teacher Passed</th>
                                <th class="head i-patch">Action</th>
                              </tr>
                            </thead>
                            <tbody class="t-body">
                              <tr
                                class="t-row"
                                v-for="(school, index) in topSchools"
                                :key="school.id"
                              >
                                <td class="t-data">{{ index + 1 }}</td>
                                <td class="t-data text-left pl-2">
                                  {{ school.name }}
                                </td>
                                <td class="t-data">
                                  {{ extractClusterNumber(school.cluster) }}
                                </td>
                                <td class="t-data">
                                  {{
                                    `${school.totalMainsPassed}/${school.totalMainsStarted}`
                                  }}
                                </td>
                                <td
                                  class="t-data blue--text cursor"
                                  @click="
                                    $router.push({
                                      path: `/view/${school.name}`,
                                    })
                                  "
                                >
                                  VIEW
                                </td>

                                <!-- Display "View" for the action -->
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="total-count mr-2 pr-2 tableBottomCount">
                          <span text-subtitle-1
                            >Total Schools Participated :
                            {{ totalSchoolsCount }}</span
                          >

                          <!-- <router-link>VIEW ALL DATA</router-link> -->
                          <v-btn
                            text
                            @click="$router.push('/campaignteachersresult')"
                            class=" cursor rounded-xl elevation-0 white--text"
                            >VIEW ALL DATA</v-btn
                          >
                        </div>
                        <!-- </v-card-title> -->
                      </v-card>
                    </div>

                    <div class="w-25 col3 margin-betwen-col">
                      <v-card
                        @click="$router.push('/teachersappraisal')"
                        class="cursor rounded-xl col3-card cardBorder"
                        outlined
                        color="#4D695B"
                      >
                        <v-card-title class="text-subtitle-1">
                          <v-row
                            justify="space-between"
                            class="pl-2 pt-1 white--text"
                          >
                            <span>No. of teachers Passed</span>
                            <v-icon color="white">mdi-chevron-right</v-icon>
                          </v-row>
                        </v-card-title>

                        <v-card-subtitle class="pt-2 white--text text-h5">
                          <!-- {{ apraisals }} -->
                          {{ funnelTeachersData.mainsPassed }}
                        </v-card-subtitle>
                      </v-card>
                      <v-card
                        @click="$router.push('/teacherspip')"
                        class="cursor rounded-xl col3-card cardBorder"
                        outlined
                        color="#FB8C00"
                      >
                        <v-card-title class="text-subtitle-1">
                          <v-row
                            justify="space-between"
                            class="pl-2 pt-2 white--text"
                          >
                            <span>No. of Teachers on PIP</span>
                            <v-icon color="white">mdi-chevron-right</v-icon>
                          </v-row>
                        </v-card-title>

                        <v-card-subtitle class="pt-2 white--text text-h5">
                          <!-- {{ pip }} -->
                          {{ funnelTeachersData.mainsFailed }}
                        </v-card-subtitle>
                      </v-card>
                      <v-card
                        @click="$router.push('/teacherstbd')"
                        class="cursor rounded-xl col3-card cardBorder"
                        outlined
                        color="#00000061"
                      >
                        <v-card-title class="text-subtitle-1">
                          <v-row justify="space-between" class="white--text">
                            <div>
                              <img
                                src="../../public/svgs/Vector.svg"
                                alt=""
                                srcset=""
                                class="flag-icon"
                              />
                              <span class="pl-0 no-space"
                                >No. of teachers on TBD</span
                              >
                            </div>

                            <v-icon color="white">mdi-chevron-right</v-icon>
                          </v-row>
                        </v-card-title>

                        <v-card-subtitle class="pt-2 pl-7 white--text text-h5">
                          <!-- {{ tbd }} -->
                          {{ funnelTeachersData.screeningFailed }}
                        </v-card-subtitle>
                      </v-card>
                    </div>
                  </div>
                </div>

                <div class="heading decrease-top-spacing-teacher">
                  <v-card-title class="text-h6 py-2 mt-0 font-weight-medium">
                    <p>Aggregated Campaign Results</p></v-card-title
                  >
                </div>

                <div class="funnel-contianer mt-2">
                  <div class="w-100 d-flex flex-row">
                    <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                      <v-card
                        class="rounded-lg pr-0 pl-0 cardBorder"
                        width="100%"
                        height="30vh"
                      >
                        <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                          <p class="py-0 my-0 pl-2 funnelText">
                            TEACHERS
                          </p>
                        </v-card-title>
  
                        <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                          <span class="pl-3 black--text"
                            >Registered/Invited</span
                          >
                          <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="invited" propertyEnd="screeningStarted" funnelColor="#E0ED4B"/>
                          <div class="d-flex justify-end py-1 pr-2">
                            <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                            <div 
                              class="red--text pl-2"
                              style="display: inline-block; margin-bottom: 0">
                              {{
                                calculatePercentage(
                                  funnelTeachersData.invited -
                                    funnelTeachersData.screeningStarted,
                                  funnelTeachersData.invited
                                )
                              }}%
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                    <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                      <v-card
                        class="rounded-lg cardBorder"
                        width="100%"
                        height="30vh"
                      >
                        <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                          <p class="pl-2 funnelText">
                            SCREENING
                          </p>
                        </v-card-title>  
                        <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                          <span class="pl-3 black--text"
                            >Attempted</span
                          >
                          <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                          <div class="d-flex justify-end py-1 pr-2">
                            <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                            <div 
                              class="red--text pl-2"
                              style="display: inline-block; margin-bottom: 0">
                              {{
                                calculatePercentage(
                                  funnelTeachersData.screeningStarted -
                                    funnelTeachersData.screeningPassed,
                                  funnelTeachersData.screeningStarted
                                )
                              }}%
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                    <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                      <v-card
                        class="rounded-lg cardBorder"
                        width="100%"
                        height="30vh"
                      >
                        <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                          <p class="pl-2 funnelText">
                            SCREENING RESULT
                          </p>
                        </v-card-title>
                        <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                          <span class="pl-3 green--text"
                            >Passed </span
                          >
                          <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                          <div class="d-flex justify-end py-1 pr-2">
                            <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                            <div 
                              class="red--text pl-2"
                              style="display: inline-block; margin-bottom: 0">
                              {{
                                calculatePercentage(
                                  funnelTeachersData.screeningPassed -
                                    funnelTeachersData.mainsStarted,
                                  funnelTeachersData.screeningPassed
                                )
                              }}%
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                    <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                      <v-card
                        class="rounded-lg cardBorder"
                        width="100%"
                        height="30vh"
                      >
                        <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                          <p class="pl-2 funnelText">MAINS</p>
                        </v-card-title>
  
                        <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                          <span class="pl-3 black--text"
                            >Attempted</span
                          >
                          <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>
  
                          <div class="d-flex justify-end py-1 pr-2">
                            <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                            <div 
                              class="red--text pl-2"
                              style="display: inline-block; margin-bottom: 0">
                              {{
                                calculatePercentage(
                                  funnelTeachersData.mainsStarted -
                                    funnelTeachersData.mainsPassed,
                                  funnelTeachersData.mainsStarted
                                )
                              }}%
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
  
                    <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                      <v-card
                        class="rounded-lg cardBorder"
                        width="100%"
                        height="30vh"
                      >
                        <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                          <p class="pl-2 funnelText">
                            MAINS RESULT
                          </p>
                        </v-card-title>
  
                        <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                          <span class="pl-3 green--text"
                            >Passed</span
                          >
                          <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
                          <div class="d-flex justify-end py-1 pr-2">
                            <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                            <div 
                              class="red--text pl-2"
                              style="display: inline-block; margin-bottom: 0">
                              {{
                                calculatePercentage(
                                  funnelTeachersData.mainsPassed -
                                    funnelTeachersData.demoSubmitted,
                                  funnelTeachersData.mainsPassed
                                )
                              }}%
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
  
                    <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                      <v-card
                        class="rounded-lg cardBorder"
                        width="100%"
                        height="30vh"
                      >
                        <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                          <p class="pl-2 funnelText">DEMO</p>
                        </v-card-title>
  
                        
  
                        <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                          <span class="pl-3 black--text"
                            >Submitted </span
                          >
                          <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyEnd="interviewSubmitted" propertyBegin="demoSubmitted"/>
  
                          <div class="d-flex justify-end py-1 pr-2">
                            <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                            <div 
                              class="red--text pl-2"
                              style="display: inline-block; margin-bottom: 0">
                              {{
                                calculatePercentage(
                                  funnelTeachersData.demoSubmitted -
                                    funnelTeachersData.interviewSubmitted,
                                  funnelTeachersData.demoSubmitted
                                )
                              }}%
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <v-dialog
              v-model="notificationDialog"
              class="cdz"
              max-width="380px"
            >
              <Notification @close-dialog="notificationDialog = false" />
            </v-dialog>
          </div>
        </template>
      </v-carousel-item>
      <v-carousel-item>
        <!-- Second Dashboard content here -->
        <!-- Wrap your existing dashboard code here -->
        <template>
          <div>
            <div v-if="!isFullScreen">
              <v-card
                height="56px"
                width="100%"
                class="mx-6 mt-2 pl-4 pr-8 transparent elevation-0 d-flex flex-row justify-space-between align-center"
              >
                <v-card-title class="pl-0 ml-0">
                  <p class="breadcrumb-text">Dashboard ({{ this.roleName }})</p>
                  <span
                    ><v-icon class="breadcrumb-arrow"
                      >mdi-chevron-down</v-icon
                    ></span
                  >
                </v-card-title>

                <div
                  class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center"
                >
                  <v-badge
                    color="red"
                    dot
                    right
                    transition="slide-x-transition"
                    class="mr-8"
                  >
                    <v-hover v-model="hover">
                      <img
                        @click="notificationDialog = true"
                        class="cursor"
                        src="../assets/bell 1.svg"
                      />
                    </v-hover>
                  </v-badge>
                  <div>
                    <img
                      src="../assets/avtar.png"
                      class="rounded-xl mr-2"
                      width="32"
                      height="32"
                    />
                  </div>
                  <div class="d-flex flex-column d-justify-evenly user-details">
                    <v-card-title class="ma-0 pa-0 elipsis">
                      {{ $store.state.userInfo.name }}
                    </v-card-title>
                    <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
                      {{ $store.state.userInfo.email }}
                    </v-card-subtitle>
                  </div>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="40px"
                        height="40px"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon
                      >
                        <v-icon class="">mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-btn @click="logout">logout</v-btn>
                  </v-menu>
                </div>
              </v-card>
            </div>

            <div class="home-view-container"
             :class="{ fullScreenSize: isFullScreen === true }">
              <div>
                <v-row class="heading">
                  <v-card-title class="pb-0 mb-0 text-h5 font-weight-light"
                    >Dashboard - <b>Job Seekers</b></v-card-title
                  >                  
                </v-row>

                <div class="pt-2 mb-4 w-100 h-240 d-flex flex-row">
                  <div class="w-35 margin-betwen-col">
                    <v-card class="rounded-xl cardBorder" elevation="0">
                      <v-card-text style="text-align: center">
                        <DonutChartAdminJobSeeker
                          :jobSeekerData="jobSeekerData"
                          :donutArray="donutArrayAdminJobSeeker"
                        />
                      </v-card-text>
                    </v-card>
                  </div>
                  <div
                    class="w-47 sec-col margin-betwen-col pa-2 cardBorder"
                    style="height: 38vh"
                  >
                    <v-card elevation="0">
                      <!-- <v-card-title class="text-subtitle-1 "> -->
                      <div
                        class="table-header w-100 d-flex flex-row justify-space-between"
                      >
                        <div class="i-patch">
                          Top 5 Job Seekers
                        </div>

                        <v-card
                          class="pl-1 d-flex align-center my-2"
                          elevation="0"
                          width="20%"
                          height="25px"
                        >
                          <!-- <v-select
                            solo
                            outlined
                            prefix="Top"
                            color="black"
                            class="circle-radius"
                            :items="dropdownItems"
                            v-model="selectedItem"
                            hide-details
                            dense
                            append-icon="mdi-chevron-down"
                          >
                          </v-select> -->
                        </v-card>

                        <!-- <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded my-2"
                        border-color="black" border-radius="8px" width="15%" height="25px">
                        Top 5
                        <v-spacer></v-spacer>
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-card> -->

                        <!-- <div class="circle-outline">
                          <v-select
                            outlined
                            prefix="Top"
                            color="black"
                            class="circle-radius"
                            :items="dropdownItems"
                            v-model="selectedItem"
                            hide-details
                            dense
                            append-icon="mdi-chevron-down"
                          >
                          </v-select>
                        </div> -->
                      </div>
                      <table class="tbl">
                        <thead class="t-head">
                          <tr class="t-row">
                            <th class="head i-patch">Rank</th>
                            <th class="head i-patch">Name</th>
                            <th class="head i-patch">Mains Score</th>
                            <th class="head i-patch">Level</th>
                            <th class="head i-patch">Action</th>
                          </tr>
                        </thead>
                        <tbody class="t-body">
                          <tr
                            class="t-row"
                            v-for="(teacher, index) in displayedAdminJobSeeker"
                            :key="teacher.id"
                          >
                            <td class="t-data">{{ index + 1 }}</td>
                            <td class="t-data">
                              {{ teacher.name }}
                              <!-- {{ `${teacher.firstName} ${teacher.lastName}` }} -->
                            </td>
                            <td class="t-data">
                              {{ teacher.mainsTotal }}
                            </td>
                            <td class="t-data">
                              <v-tooltip top>
                                <template
                                  v-if="teacher.level.length > 0"
                                  v-slot:activator="{ on, attrs }"
                                >
                                  <span v-bind="attrs" v-on="on">
                                    {{ teacher.level[0] }}
                                  </span>
                                </template>
                                <span>{{ teacher.level.join(", ") }}</span>
                              </v-tooltip>
                            </td>
                            <td
                                  class="t-data blue--text cursor"
                                  @click="
                                    $router.push({
                                      path: `/userreport?campaignId=${teacher.campaignId}&userId=${teacher.userId}`,
                                    })
                                  "
                                >
                                  VIEW
                                </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="total-count mr-2 pr-2 tableBottomCount">
                        <span text-subtitle-1
                          >Total Users Participated :
                          {{ this.totalUsersParticipated }}</span
                        >

                        <!-- <router-link>VIEW ALL DATA</router-link> -->
                        <v-btn
                          text
                          @click="$router.push('/campaignjobseekersresult')"
                          class=" rounded-xl cursor white--text"
                          >VIEW ALL DATA</v-btn
                        >
                      </div>
                      <!-- </v-card-title> -->
                    </v-card>
                  </div>

                  <div v-if="!showComingSoon" class="w-25 col3 margin-betwen-col">
                    <v-card
                      @click="$router.push('/jobseekersappraisal')"
                      class="cursor rounded-xl col3-card cardBorder"
                      outlined
                      color="#4D695B"
                    >
                      <v-card-title class="text-subtitle-1">
                        <v-row justify="space-between" class="pl-2 pt-1 white--text">
                          <span>Recommended for Interview</span>
                          <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-row>
                      </v-card-title>

                      <v-card-subtitle class="pt-2 white--text text-h5">
                        <!-- {{ this.recommended }} -->
                        {{ funnelJobSeekersData.demoSubmitted }}
                      </v-card-subtitle>
                    </v-card>
                    <v-card
                      @click="$router.push('/jobseekerspip')"
                      class="cursor rounded-xl col3-card cardBorder"
                      outlined
                      color="#FB8C00"
                    >
                      <v-card-title class="text-subtitle-1">
                        <v-row justify="space-between" class="pl-2 pt-1 white--text">
                          <span>Job Seekers Interviewed</span>
                          <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-row>
                      </v-card-title>

                      <v-card-subtitle class="pt-2 white--text text-h5">
                        <!-- {{ this.jobSeekerRejected }} -->
                        {{ funnelJobSeekersData.interviewSubmitted }}
                      </v-card-subtitle>
                    </v-card>
                    <v-card
                      @click="$router.push('/jobseekerstbd')"
                      class="cursor rounded-xl col3-card cardBorder"
                      outlined
                      color="#00000061"
                    >
                      <v-card-title class="text-subtitle-1">
                        <v-row justify="space-between" class="pl-2 white--text">
                          <div>
                            <span class="no-space"
                              >Recommended for Hiring</span
                            >
                          </div>

                          <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-row>
                      </v-card-title>

                      <v-card-subtitle class="pt-2 white--text text-h5">
                        <!-- {{ this.jobSeekerTBD }} -->
                        {{ funnelJobSeekersData.screeningFailed }}
                      </v-card-subtitle>
                    </v-card>
                  </div>
                  <div v-if="showComingSoon" class="w-25 col3 margin-betwen-col coming-soon-container">
                    <p class="coming-soon-text">Coming Soon</p>
                  </div>
                </div>
              </div>

              <div class="heading decrease-top-spacing-jobSeeker">
                <v-card-title class="text-h6 py-0 font-weight-medium">
                  <p>Aggregated Campaign Results </p></v-card-title
                >               
              </div>

              <div class="funnel-contianer mt-2">
                <div class="w-100 d-flex flex-row">
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg pr-0 pl-0 cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                        <p class="py-0 my-0 pl-2 funnelText">
                          JOB SEEKERS
                        </p>
                      </v-card-title>

                      <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                        <span class="pl-3 black--text"
                          >Users Registered/Invited</span
                        >
                        <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="invited" propertyEnd="screeningStarted" funnelColor="#E0ED4B"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelJobSeekersData.invited -
                                  funnelJobSeekersData.screeningStarted,
                                funnelJobSeekersData.invited
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">
                          SCREENING
                        </p>
                      </v-card-title>

                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 black--text"
                          >Attempted</span
                        >
                        <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelJobSeekersData.screeningStarted -
                                  funnelJobSeekersData.screeningPassed,
                                funnelJobSeekersData.screeningStarted
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">
                          SCREENING RESULT
                        </p>
                      </v-card-title>
                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 green--text"
                          >Passed </span
                        >
                        <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelJobSeekersData.screeningPassed -
                                  funnelJobSeekersData.mainsStarted,
                                funnelJobSeekersData.screeningPassed
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">MAINS</p>
                      </v-card-title>


                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 black--text"
                          >Attempted</span
                        >
                        <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>

                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelJobSeekersData.mainsStarted -
                                  funnelJobSeekersData.mainsPassed,
                                funnelJobSeekersData.mainsStarted
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>

                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">
                          MAINS RESULT
                        </p>
                      </v-card-title>

                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 green--text"
                          >Passed</span
                        >
                        <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelJobSeekersData.mainsPassed -
                                  funnelJobSeekersData.demoSubmitted,
                                funnelJobSeekersData.mainsPassed
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>

                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">DEMO</p>
                      </v-card-title>

                      

                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 black--text"
                          >Submitted </span
                        >
                        <FunnelChartCommon :invites="funnelJobSeekersData" scaleMax="invited" propertyEnd="interviewSubmitted" propertyBegin="demoSubmitted"/>

                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelJobSeekersData.demoSubmitted -
                                  funnelJobSeekersData.interviewSubmitted,
                                funnelJobSeekersData.demoSubmitted
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-carousel-item>
    </v-carousel>
    <div class="carousel-buttons">
      <v-chip
        v-for="(item, i) in userTypeViews.length"
        :key="i"
        class="carousel-button"
        :style="
          i !== activeIndex ? 'width: 16px !important ; height : 16px;' : ''
        "
        :class="{ active: i === activeIndex }"
        @click="activeIndex = i"
        :color="activeIndex === i ? 'brown' : 'brown lighten-4'"
        fab
        small
        rounded
      >
      </v-chip>
    </div>
  </div>

  <!-- #################################################### -->
  <!-- school role  -->

  <div
    v-else-if="this.roleCluster !== 'All Clusters' && this.roleSchool !== 'All'"
  >
    <v-carousel
      v-model="activeIndex"
      :cycle="false"
      interval="60000"
      height="100vh"
      hide-delimiters
      :show-arrows="false"
      class="custom-carousel"
    >
      <v-carousel-item v-if="userTypeViews.includes('teacher')">
        <!-- First Dashboard content here -->
        <!-- Wrap your existing dashboard code here -->
        <template>
          <div>
            <v-card
              height="56px"
              width="100%"
              class="mx-4 mt-4 mb-1 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
              ><v-card-title class="pl-0 ml-0">
                <p class="breadcrumb-text">Dashboard ({{ this.roleName }})</p>
                <!-- <p class="breadcrumb-text">Dashboard {{ roles.role }}</p> -->
                <span
                  ><v-icon class="breadcrumb-arrow"
                    >mdi-chevron-down</v-icon
                  ></span
                >
              </v-card-title>
              <div
                class="size-menu d-flex flex-row justify-start w-fit align-center"
              >
                <v-badge
                  color="red"
                  dot
                  right
                  transition="slide-x-transition"
                  class="mr-8"
                >
                  <v-hover v-model="hover">
                    <img
                      @click="notificationDialog = true"
                      class="cursor"
                      src="../assets/bell 1.svg"
                    />
                  </v-hover>
                </v-badge>
                <div>
                  <img
                    src="../assets/avtar.png"
                    class="rounded-xl mr-2"
                    width="32"
                    height="32"
                  />
                </div>
                <div class="d-flex flex-column d-justify-evenly user-details">
                  <v-card-title class="ma-0 pa-0 elipsis">
                    {{ $store.state.userInfo.name }}
                  </v-card-title>
                  <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
                    {{ $store.state.userInfo.email }}
                  </v-card-subtitle>
                </div>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="40px"
                      height="40px"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon class="">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-btn @click="logout">logout</v-btn>
                </v-menu>
              </div>
            </v-card>

            <v-row class="heading home-view-container my-0 ml-2">
              <v-card-title class="text-h5 pa-0 font-weight-light"
                >Dashboard - <b>Teachers</b></v-card-title
              >
              
            </v-row>

            <div class="home-view-container">
              <div class="w-100 h-240 ma-1 d-flex flex-row mb-3">
                <div class="w-35 pa-1 pb-0 h-240 bookingBox">
                  <v-card class="rounded-xl w-100 h-240 pa-0" elevation="0">
                    <v-card-text style="text-align: center">
                      <DonutChartSchool
                        :schoolData="schoolData"
                        :donutArray="donutArray"
                      />
                    </v-card-text>
                  </v-card>
                </div>
                <div class="w-80 sec-col-school pa-1 ma-1">
                  <v-card elevation="0">
                    <!-- <v-card-title class="text-subtitle-1 "> -->
                    <div
                      class="table-header w-100 d-flex flex-row justify-space-between"
                    >
                      <div class="i-patch">
                        Top Performing Teachers (All)
                      </div>

                      <v-card
                        class="pl-1 d-flex align-center my-2"
                        elevation="0"
                        width="23.5%"
                      >
                        <!-- <v-select
                          solo
                          outlined
                          prefix="Top"
                          color="black"
                          class="circle-radius"
                          :items="dropdownItems"
                          v-model="selectedItem"
                          hide-details
                          dense
                          append-icon="mdi-chevron-down"
                        >
                        </v-select> -->
                      </v-card>
                      <!-- <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded my-2"
                          border-color="black" border-radius="8px" width="24%" height="25px">
                          Top 5
                          <v-spacer></v-spacer>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-card> -->
                      <!-- <div class="circle-outline">
                          <v-select
                            outlined
                            prefix="Top"
                            color="black"
                            class="circle-radius"
                            :items="dropdownItems"
                            v-model="selectedItem"
                            hide-details
                            dense
                            append-icon="mdi-chevron-down"
                          >
                          </v-select>
                        </div> -->
                    </div>
                    <div class="table-container">
                      <table class="tbl">
                        <thead class="t-head">
                          <tr class="t-row">
                            <th class="head i-patch">Rank</th>
                            <th class="head i-patch">Teacher's Name</th>

                            <th class="head i-patch">Teacher's Score</th>
                            <th class="head i-patch">Action</th>
                          </tr>
                        </thead>

                        <!-- <div class=""> -->
                        <tbody class="t-body">
                          <tr
                            class="t-row"
                            v-for="(teacher, index) in displayedTeachers"
                            :key="teacher.id"
                          >
                            <td class="t-data">{{ index + 1 }}</td>
                            <td class="t-data">
                              {{ teacher.name }}
                              <!-- {{ `${teacher.firstName} ${teacher.lastName}` }} -->
                            </td>
                            <td class="t-data">
                              {{ teacher.screeningTotal }}
                            </td>
                            <!-- <td class="t-data">
                              {{
                                `${school.totalMainsPassed}/${school.totalScreeningStarted}`
                              }}
                            </td> -->
                            <td class="t-data">View</td>
                          </tr>
                        </tbody>
                        <!-- </div> -->
                      </table>
                    </div>
                    <div class="total-count mr-2 pr-2">
                      <span text-subtitle-1
                        >Total Teachers Participated :
                        {{ this.countingDocumentSchool }}</span
                      >

                      <!-- <router-link>VIEW ALL DATA</router-link> -->
                      <span
                        @click="$router.push('/schoolcampaignteachersresult')"
                        class=" cursor"
                        >VIEW ALL DATA</span
                      >
                    </div>
                    <!-- </v-card-title> -->
                  </v-card>
                </div>

                <div class="w-20 col3 ma-1">
                  <v-card
                    @click="$router.push('/schoolteachersappraisal')"
                    class="cursor rounded-xl col3-card cardBorder"
                    outlined
                    color="#4D695B"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row
                        justify="space-between"
                        class="body2 pt-1 pl-1 white--text"
                      >
                        <span>No. of teachers Passed</span>
                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-2 white--text text-h5">
                      {{ schoolChartData.totalMainsPassed }}
                    </v-card-subtitle>
                  </v-card>
                  <v-card
                    @click="$router.push('/schoolteacherspip')"
                    class="cursor rounded-xl col3-card cardBorder"
                    outlined
                    color="#FB8C00"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row
                        justify="space-between"
                        class="body2 pt-2 pl-1 white--text"
                      >
                        <span>No .of Teachers on PIP</span>
                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-4 white--text text-h5">
                      {{ schoolChartData.totalMainsFailed }}
                    </v-card-subtitle>
                  </v-card>
                  <v-card
                    @click="$router.push('/schoolteacherstbd')"
                    class="cursor rounded-xl col3-card cardBorder"
                    outlined
                    color="#00000061"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row
                        justify="space-between"
                        class="body2 pt-0 white--text"
                      >
                        <div>
                          <img
                            src="../../public/svgs/Vector.svg"
                            alt=""
                            srcset=""
                            class="flag-icon"
                          />
                          <span class="pl-0 no-space"
                            >No. of teachers on TBD</span
                          >
                        </div>

                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-2 pl-8 white--text text-h5">
                      {{ schoolChartData.totalScreeningFailed }}
                    </v-card-subtitle>
                  </v-card>
                </div>
              </div>

              <v-row class="heading decrease-top-spacing-school ml-5 mt-2 mb-1">
                <v-card-title class="text-h6 mt-0 font-weight-medium">
                  <p>Aggregated Campaign Results</p></v-card-title
                >
               
              </v-row>
              <div class="funnel-contianer mt-2">
                <div class="w-100 d-flex flex-row">
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg pr-0 pl-0 cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                        <p class="py-0 my-0 pl-2 funnelText">
                          TEACHERS
                        </p>
                      </v-card-title>
                      <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                        <span class="pl-3 black--text"
                          >Registered/Invited</span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="invited" propertyEnd="screeningStarted" funnelColor="#E0ED4B"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.invited -
                                  funnelTeachersData.screeningStarted,
                                funnelTeachersData.invited
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">
                          SCREENING
                        </p>
                      </v-card-title>

                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 black--text"
                          >Attempted</span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.screeningStarted -
                                  funnelTeachersData.screeningPassed,
                                funnelTeachersData.screeningStarted
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">
                          SCREENING RESULT
                        </p>
                      </v-card-title>
                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 green--text"
                          >Passed </span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.screeningPassed -
                                  funnelTeachersData.mainsStarted,
                                funnelTeachersData.screeningPassed
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                        <p class="pl-2 funnelText">MAINS</p>
                      </v-card-title>


                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 black--text"
                          >Attempted</span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>

                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.mainsStarted -
                                  funnelTeachersData.mainsPassed,
                                funnelTeachersData.mainsStarted
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>

                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">
                          MAINS RESULT
                        </p>
                      </v-card-title>

                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 green--text"
                          >Passed</span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.mainsPassed -
                                  funnelTeachersData.demoSubmitted,
                                funnelTeachersData.mainsPassed
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>

                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">DEMO</p>
                      </v-card-title>

                      

                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 black--text"
                          >Submitted </span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyEnd="interviewSubmitted" propertyBegin="demoSubmitted"/>

                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.demoSubmitted -
                                  funnelTeachersData.interviewSubmitted,
                                funnelTeachersData.demoSubmitted
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-carousel-item>
      <v-carousel-item>
        <!-- Second Dashboard content here -->
        <!-- Wrap your existing dashboard code here -->
        <template>
          <div>
            <v-card
              height="56px"
              width="100%"
              class="mx-4 mt-4 mb-1 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
            >
              <v-card-title class="pl-0 ml-0">
                <p class="breadcrumb-text">Dashboard ({{ this.roleName }})</p>
                <span
                  ><v-icon class="breadcrumb-arrow"
                    >mdi-chevron-down</v-icon
                  ></span
                >
              </v-card-title>

              <div
                class="size-menu d-flex flex-row justify-start w-fit align-center"
              >
                <v-badge
                  color="red"
                  dot
                  right
                  transition="slide-x-transition"
                  class="mr-8"
                >
                  <v-hover v-model="hover">
                    <img
                      @click="notificationDialog = true"
                      class="cursor"
                      src="../assets/bell 1.svg"
                    />
                  </v-hover>
                </v-badge>
                <div>
                  <img
                    src="../assets/avtar.png"
                    class="rounded-xl mr-2"
                    width="32"
                    height="32"
                  />
                </div>
                <div class="d-flex flex-column d-justify-evenly user-details">
                  <v-card-title class="ma-0 pa-0 elipsis">
                    {{ $store.state.userInfo.name }}
                  </v-card-title>
                  <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
                    {{ $store.state.userInfo.email }}
                  </v-card-subtitle>
                </div>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="40px"
                      height="40px"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon class="">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-btn @click="logout">logout</v-btn>
                </v-menu>
              </div>
            </v-card>
            <div>
              <v-row class="heading">
                <v-card-title
                  class="ml-8 pl-2 pb-0 mb-0 text-h5 font-weight-light"
                  >Dashboard - <b>Job Seekers</b></v-card-title
                >
                
              </v-row>

              <div class="pt-1 home-view-container w-100 h-240 d-flex flex-row mb-6">                            

                <div class="w-100 sec-col margin-betwen-col pa-2">
                  <v-card elevation="0">
                    <!-- <v-card-title class="text-subtitle-1 "> -->
                    <div
                      class="table-header w-100 d-flex flex-row justify-space-between"
                    >
                      <div class="i-patch">
                        Top 5 Job Seekers
                      </div>

                      <v-card
                        class="pl-1 d-flex align-center my-2"
                        elevation="0"
                        width="20%"
                        height="25px"
                      >
                        <!-- <v-select
                          solo
                          outlined
                          prefix="Top"
                          color="black"
                          class="circle-radius"
                          :items="dropdownItems"
                          v-model="selectedItem"
                          hide-details
                          dense
                          append-icon="mdi-chevron-down"
                        >
                        </v-select> -->
                      </v-card>

                      <!-- <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded my-2"
                        border-color="black" border-radius="8px" width="15%" height="25px">
                        Top 5
                        <v-spacer></v-spacer>
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-card> -->
                      <!-- <div class="circle-outline">
                          <v-select
                            outlined
                            prefix="Top"
                            color="black"
                            class="circle-radius"
                            :items="dropdownItems"
                            v-model="selectedItem"
                            hide-details
                            dense
                            append-icon="mdi-chevron-down"
                          >
                          </v-select>
                        </div> -->
                    </div>
                    <table class="tbl">
                      <thead class="t-head">
                        <tr class="t-row">
                          <th class="head i-patch">Rank</th>
                          <th class="head i-patch">Name</th>
                          <th class="head i-patch">Mains Score</th>
                          <th class="head i-patch">Level</th>
                          <!-- <th class="head">Action</th> -->
                        </tr>
                      </thead>
                      <tbody class="t-body">
                        <tr
                          class="t-row"
                          v-for="(teacher, index) in displayedJobSeekers"
                          :key="teacher.id"
                        >
                          <td class="t-data">{{ index + 1 }}</td>
                          <td class="t-data">
                            {{ `${teacher.name}` }}
                          </td>
                          <td class="t-data">
                            {{ teacher.mainsTotal }}
                          </td>
                          <td class="t-data">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                  {{ teacher.level[0] }}
                                </span>
                              </template>
                              <span>{{ teacher.level.join(", ") }}</span>
                            </v-tooltip>
                          </td>
                          <!-- <td class="t-data">View</td> -->
                        </tr>
                      </tbody>
                    </table>
                    <div class="total-count mr-2 pr-2">
                      <span text-subtitle-1
                        >Total Users Participated :
                        {{ this.countingJobSeekerSchool }}</span
                      >

                      <!-- <router-link>VIEW ALL DATA</router-link> -->
                      <span
                        @click="$router.push('/schoolcampaignjobseekersresult')"
                        class="cursor "
                        >VIEW ALL DATA</span
                      >
                    </div>
                    <!-- </v-card-title> -->
                  </v-card>
                </div>

                <div v-if="!showComingSoon" class="w-25 col3 margin-betwen-col">
                  <v-card
                    @click="$router.push('/schooljobseekersappraisal')"
                    class="cardBorder cursor rounded-xl col3-card"
                    outlined
                    color="#4D695B"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row justify="space-between" class="pl-2 pt-1 white--text">
                        <span>Recommended for Interview</span>
                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-2 white--text text-h5">
                      {{ schoolJobSeekerChartData.demoSubmitted }}
                    </v-card-subtitle>
                  </v-card>
                  <v-card
                    @click="$router.push('/schooljobseekerspip')"
                    class="cursor cardBorder rounded-xl col3-card"
                    outlined
                    color="#FB8C00"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row justify="space-between" class="pl-2 pt-1 white--text">
                        <span>Job Seekers Interviewed</span>
                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-2 white--text text-h5">
                      {{ schoolJobSeekerChartData.interviewSubmitted }}
                    </v-card-subtitle>
                  </v-card>
                  <v-card
                    @click="$router.push('/schooljobseekerstbd')"
                    class="cursor cardBorder rounded-xl col3-card"
                    outlined
                    color="#00000061"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row justify="space-between" class="pl-2 white--text">
                        <div>
                          <span class="no-space">Recommended for Hiring</span>
                        </div>

                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-2 white--text text-h5">
                      {{
                        schoolJobSeekerChartData.screeningFailed
                      }}
                    </v-card-subtitle>
                  </v-card>
                </div>
                <div v-if="showComingSoon" class="w-25 col3 margin-betwen-col coming-soon-container">
                  <p class="coming-soon-text">Coming Soon</p>
                </div>
              </div>
            </div>
            <v-row class="heading ml-2 mt-5 mb-2 home-view-container">
              <v-card-title class="text-h6 mt-0 font-weight-medium">
                <p>Aggregated Campaign Results</p></v-card-title
              >
            </v-row>
            <div class="funnel-contianer ml-2 pr-4 home-view-container" fluid>
              <div class="w-100 d-flex flex-row">
                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg pr-0 pl-0 cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                      <p class="py-0 my-0 pl-2 funnelText">
                        JOB SEEKERS
                      </p>
                    </v-card-title>

                    <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                      <span class="pl-3 black--text"
                        >Users Registered/Invited </span
                      >
                      <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="invited" propertyBegin="invited" propertyEnd="screeningStarted" funnelColor="#E0ED4B"/>
                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              schoolJobSeekerChartData.invited -
                              schoolJobSeekerChartData.screeningStarted,
                              schoolJobSeekerChartData.invited
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                      <p class="pl-2 funnelText">
                        SCREENING
                      </p>
                    </v-card-title>

                    <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                      <span class="pl-3 black--text"
                        >Attempted</span
                      >
                      <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              schoolJobSeekerChartData.screeningStarted -
                              schoolJobSeekerChartData.screeningPassed,
                              schoolJobSeekerChartData.screeningStarted
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                      <p class="pl-2 funnelText">
                        SCREENING RESULT
                      </p>
                    </v-card-title>
                    <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                      <span class="pl-3 green--text"
                        >Passed</span
                      >
                      <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              schoolJobSeekerChartData.screeningPassed -
                              schoolJobSeekerChartData.mainsStarted,
                              schoolJobSeekerChartData.screeningPassed
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                      <p class="pl-2 funnelText">MAINS</p>
                    </v-card-title>

                    <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                      <span class="pl-3 black--text"
                        >Attempted </span
                      >
                      <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>

                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              schoolJobSeekerChartData.screeningPassed -
                              schoolJobSeekerChartData.mainsStarted,
                              schoolJobSeekerChartData.screeningPassed
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                      <p class="pl-2 funnelText">
                        MAINS RESULT
                      </p>
                    </v-card-title>

                    <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                      <span class="pl-3 green--text"
                        >Passed </span
                      >
                      <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              schoolJobSeekerChartData.screeningPassed -
                              schoolJobSeekerChartData.mainsStarted,
                              schoolJobSeekerChartData.screeningPassed
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                      <p class="pl-2 funnelText">DEMO</p>
                    </v-card-title>

                    

                    <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                      <span class="pl-3 black--text"
                        >Submitted</span
                      >
                      <FunnelChartCommon :invites="schoolJobSeekerChartData" scaleMax="invited" propertyBegin="demoSubmitted" propertyEnd="interviewSubmitted"/>

                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              schoolJobSeekerChartData.screeningPassed -
                              schoolJobSeekerChartData.mainsStarted,
                              schoolJobSeekerChartData.screeningPassed
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-carousel-item>
    </v-carousel>
    <div class="carousel-buttons">
      <v-chip
        v-for="(item, i) in userTypeViews.length"
        :key="i"
        class="carousel-button"
        :style="
          i !== activeIndex
            ? ' min-width: 16px; width: 16px !important ; height : 15px;'
            : ''
        "
        :class="{ active: i == activeIndex }"
        @click="activeIndex = i"
        :color="activeIndex == i ? 'brown' : 'brown lighten-4'"
        fab
        small
        rounded
      >
      </v-chip>
    </div>
  </div>

  <!-- ################################# -->
  <!-- cluster role -->

  <div
    div
    v-else-if="this.roleCluster !== 'All Clusters' && this.roleSchool === 'All'"
  >
    <v-carousel
      v-model="activeIndex"
      :cycle="false"
      interval="60000"
      height="100vh"
      hide-delimiters
      :show-arrows="false"
      class="custom-carousel"
    >
      <v-carousel-item v-if="userTypeViews.includes('teacher')">
        <!-- First Dashboard content here -->
        <!-- Wrap your existing dashboard code here -->
        <template>
          <div>
            <v-card
              height="56px"
              width="100%"
              class="ml-4 mt-4 pl-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
              ><v-card-title class="pl-0 ml-0">
                <p class="breadcrumb-text">Dashboard ({{ this.roleName }})</p>
                <span
                  ><v-icon class="breadcrumb-arrow"
                    >mdi-chevron-down</v-icon
                  ></span
                >
              </v-card-title>
              <div
                class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center"
              >
                <v-badge
                  color="red"
                  dot
                  right
                  transition="slide-x-transition"
                  class="mr-8"
                >
                  <v-hover v-model="hover">
                    <img
                      @click="notificationDialog = true"
                      class="cursor"
                      src="../assets/bell 1.svg"
                    />
                  </v-hover>
                </v-badge>
                <div>
                  <img
                    src="../assets/avtar.png"
                    class="rounded-xl mr-2"
                    width="32"
                    height="32"
                  />
                </div>
                <div class="d-flex flex-column d-justify-evenly user-details">
                  <v-card-title class="ma-0 pa-0 elipsis">
                    {{ $store.state.userInfo.name }}
                  </v-card-title>
                  <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
                    {{ $store.state.userInfo.email }}
                  </v-card-subtitle>
                </div>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="40px"
                      height="40px"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon class="">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-btn @click="logout">logout</v-btn>
                </v-menu>
              </div>
            </v-card>

            <v-row class="heading home-view-container">
              <v-card-title class="text-h5 pl-2 font-weight-light"
                >Dashboard - <b> Teachers</b></v-card-title
              >
            </v-row>

            <div class="home-view-container">
              <div class="w-100 h-240 d-flex flex-row mb-6">
                <div class="w-23 mx-1 h-240">
                  <v-card elevation="0" class="border-box w-100">
                    <!-- <v-card-title class="text-subtitle-1 "> -->
                    <div>
                      <div class="text-center text-h6 pa-1 font-weight-medium">
                        {{ this.$store.state.role.clusterName }}
                      </div>
                    </div>
                    <table class="tbl2 pa-1">
                      <thead class="t-head">
                        <tr class="t-row">
                          <th class="head text-left">Schools</th>

                          <th class="head text-right">Total Teachers</th>
                        </tr>
                      </thead>
                      <tbody class="t-body">
                        <tr
                          class="t-row"
                          v-for="i in teacherCountClusterData"
                          :key="i.id"
                        >
                          <td class="t-data text-left">{{ i.name }}</td>
                          <td class="t-data text-right">{{ i.teachers }}</td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- </v-card-title> -->
                  </v-card>
                </div>
                <div class="w-23 mx-1">
                  <v-card class="rounded-xl w-100" elevation="0">
                    <v-card-text style="text-align: center">
                      <DonutChartCluster :clustersData="clustersData" />
                    </v-card-text>
                  </v-card>
                </div>
                <div class="w-40 sec-col mx-1">
                  <v-card elevation="0">
                    <!-- <v-card-title class="text-subtitle-1 "> -->
                    <div
                      class="table-header pa-1 w-100 d-flex flex-row justify-space-between"
                    >
                      <div class="i-patch">
                        Top Performing Teachers (All)
                      </div>
                      <v-card
                        class="pl-1 d-flex align-center my-2"
                        elevation="0"
                        width="23.5%"
                        height="25px"
                      >
                        <!-- <v-select
                          solo
                          outlined
                          prefix="Top"
                          color="black"
                          class="circle-radius"
                          :items="dropdownItems"
                          v-model="selectedItem"
                          hide-details
                          dense
                          append-icon="mdi-chevron-down"
                        >
                        </v-select> -->
                      </v-card>

                      <!-- <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded my-2"
                          border-color="black" border-radius="8px" width="24%" height="25px">
                          Top 5
                          <v-spacer></v-spacer>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-card> -->
                      <!-- <div class="circle-outline">
                          <v-select
                            outlined
                            class="circle-radius"
                            :items="dropdownItems"
                            v-model="selectedItem"
                            label="Top 5"
                            hide-details
                            dense
                            append-icon="mdi-chevron-down"
                          >
                          </v-select>
                        </div> -->
                    </div>
                    <table class="tbl">
                      <thead class="t-head">
                        <tr class="t-row">
                          <th class="head i-patch">Rank</th>
                          <th class="head i-patch">Teacher's Name</th>

                          <th class="head i-patch">Teacher's Score</th>
                          <th class="head i-patch">Action</th>
                        </tr>
                      </thead>
                      <tbody class="t-body">
                        <tr
                          class="t-row"
                          v-for="(teacher, index) in displayedClusterTeachers"
                          :key="teacher.id"
                        >
                          <td class="t-data">{{ index + 1 }}</td>
                          <td class="t-data">
                            {{ teacher.name }}
                            <!-- {{ `${teacher.firstName} ${teacher.lastName}` }} -->
                          </td>
                          <td class="t-data">
                            {{ teacher.screeningTotal }}
                          </td>
                          <!-- <td class="t-data">
                              {{
                                `${school.totalMainsPassed}/${school.totalScreeningStarted}`
                              }}
                            </td> -->
                          <td class="t-data">View</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="total-count mr-2 pr-2">
                      <span text-subtitle-1
                        >Total Teachers Participated :
                        {{ this.countTeacherCluster }}</span
                      >

                      <!-- <router-link>VIEW ALL DATA</router-link> -->
                      <span
                        @click="$router.push('/clustercampaignteachersresult')"
                        class=" cursor"
                        >VIEW ALL DATA</span
                      >
                    </div>
                    <!-- </v-card-title> -->
                  </v-card>
                </div>

                <div class="w-20 col3 ma-1">
                  <v-card
                    @click="$router.push('/clusterteachersappraisal')"
                    class="cursor cardBorder rounded-xl col3-card"
                    outlined
                    color="#4D695B"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row
                        justify="space-between"
                        class="body2 pt-1 pl-1 white--text"
                      >
                        <span>No. of teachers Passed</span>
                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-2 white--text text-h5">
                      {{ this.clusterTeacherChartData.totalMainsPassed }}
                    </v-card-subtitle>
                  </v-card>
                  <v-card
                    @click="$router.push('/clusterteacherspip')"
                    class="cursor cardBorder rounded-xl col3-card"
                    outlined
                    color="#FB8C00"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row
                        justify="space-between"
                        class="body2 pt-2 pl-1 white--text"
                      >
                        <span>No .of Teachers on PIP</span>
                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-4 white--text text-h5">
                      {{ this.clusterTeacherChartData.totalMainsFailed }}
                    </v-card-subtitle>
                  </v-card>
                  <v-card
                    @click="$router.push('/clusterteacherstbd')"
                    class="cursor cardBorder rounded-xl col3-card"
                    outlined
                    color="#00000061"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row
                        justify="space-between"
                        class="body2 pt-0 white--text"
                      >
                        <div>
                          <img
                            src="../../public/svgs/Vector.svg"
                            alt=""
                            srcset=""
                            class="flag-icon"
                          />
                          <span class="pl-0 no-space"
                            >No. of teachers on TBD</span
                          >
                        </div>

                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-2 pl-8 white--text text-h5">
                      {{ this.clusterTeacherChartData.totalScreeningFailed }}
                    </v-card-subtitle>
                  </v-card>
                </div>
              </div>

              <v-row class="heading pl-2 mt-2 mb-1">
                <v-card-title class="text-h6 mt-0 font-weight-medium">
                  <p>Aggregated Campaign Results</p></v-card-title
                >
              </v-row>
              <div class="funnel-contianer mt-2">
                <div class="w-100 d-flex flex-row">
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg pr-0 pl-0 cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                        <p class="py-0 my-0 pl-2 funnelText">
                          TEACHERS
                        </p>
                      </v-card-title>

                      <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                        <span class="pl-3 black--text"
                          >Registered/Invited</span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="invited" propertyEnd="screeningStarted" funnelColor="#E0ED4B"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.invited -
                                  funnelTeachersData.screeningStarted,
                                funnelTeachersData.invited
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">
                          SCREENING
                        </p>
                      </v-card-title>

                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 black--text"
                          >Attempted</span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.screeningStarted -
                                  funnelTeachersData.screeningPassed,
                                funnelTeachersData.screeningStarted
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">
                          SCREENING RESULT
                        </p>
                      </v-card-title>
                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 green--text"
                          >Passed </span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.screeningPassed -
                                  funnelTeachersData.mainsStarted,
                                funnelTeachersData.screeningPassed
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">MAINS</p>
                      </v-card-title>


                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 black--text"
                          >Attempted</span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>

                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.mainsStarted -
                                  funnelTeachersData.mainsPassed,
                                funnelTeachersData.mainsStarted
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>

                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">
                          MAINS RESULT
                        </p>
                      </v-card-title>

                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 green--text"
                          >Passed</span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.mainsPassed -
                                  funnelTeachersData.demoSubmitted,
                                funnelTeachersData.mainsPassed
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>

                  <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                    <v-card
                      class="rounded-lg cardBorder"
                      width="100%"
                      height="30vh"
                    >
                      <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                        <p class="pl-2 funnelText">DEMO</p>
                      </v-card-title>

                      

                      <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                        <span class="pl-3 black--text"
                          >Submitted </span
                        >
                        <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyEnd="interviewSubmitted" propertyBegin="demoSubmitted"/>

                        <div class="d-flex justify-end py-1 pr-2">
                          <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                          <div 
                            class="red--text pl-2"
                            style="display: inline-block; margin-bottom: 0">
                            {{
                              calculatePercentage(
                                funnelTeachersData.demoSubmitted -
                                  funnelTeachersData.interviewSubmitted,
                                funnelTeachersData.demoSubmitted
                              )
                            }}%
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-carousel-item>
      <v-carousel-item>
        <!-- Second Dashboard content here -->
        <!-- Wrap your existing dashboard code here -->
        <template>
          <div>
            <v-card
              height="56px"
              width="100%"
              class="mx-4 mt-4 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
            >
              <v-card-title class="pl-0 ml-0">
                <p class="breadcrumb-text">Dashboard ({{ this.roleName }})</p>
                <span
                  ><v-icon class="breadcrumb-arrow"
                    >mdi-chevron-down</v-icon
                  ></span
                >
              </v-card-title>

              <div
                class="size-menu d-flex flex-row justify-start w-fit align-center mr-2"
              >
                <v-badge
                  color="red"
                  dot
                  right
                  transition="slide-x-transition"
                  class="mr-8"
                >
                  <v-hover v-model="hover">
                    <img
                      @click="notificationDialog = true"
                      class="cursor"
                      src="../assets/bell 1.svg"
                    />
                  </v-hover>
                </v-badge>
                <div>
                  <img
                    src="../assets/avtar.png"
                    class="rounded-xl mr-2"
                    width="32"
                    height="32"
                  />
                </div>
                <div class="d-flex flex-column d-justify-evenly user-details">
                  <v-card-title class="ma-0 pa-0 elipsis">
                    {{ $store.state.userInfo.name }}
                  </v-card-title>
                  <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
                    {{ $store.state.userInfo.email }}
                  </v-card-subtitle>
                </div>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="40px"
                      height="40px"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon class="">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-btn @click="logout">logout</v-btn>
                </v-menu>
              </div>
            </v-card>
            <div class="home-view-container">
              <v-row class="heading">
                <v-card-title class="pb-0 mb-0 pl-1 text-h5 font-weight-light"
                  >Dashboard - <b>Job Seekers </b></v-card-title
                >
              </v-row>

              <div class="pt-4 w-100 h-240 d-flex flex-row mb-6">
                <div class="w-35 margin-betwen-col">
                  <v-card elevation="0" class="border-box w-100">
                    <!-- <v-card-title class="text-subtitle-1 "> -->
                    <div>
                      <div class="text-center text-h6 pa-2 font-weight-medium">
                        {{ this.$store.state.role.clusterName }}
                      </div>
                    </div>
                    <table class="tblClusterJob pa-1">
                      <thead class="t-head">
                        <tr class="t-row">
                          <th class="head text-left">Schools</th>

                          <th class="head text-right">Total Users</th>
                        </tr>
                      </thead>
                      <tbody class="t-body">
                        <tr
                          class="t-row"
                          v-for="i in jobSeekersClusterData"
                          :key="i.id"
                        >
                          <td class="t-data text-left">{{ i.name }}</td>
                          <td class="t-data text-right">{{ i.jobSeekers }}</td>
                        </tr>
                      </tbody>
                      <!-- <tbody class="t-body">
                        <tr class="t-row">
                         
                          <td class="t-data text-left">VGOS - Malad</td>
                          <td class="t-data text-right">11</td>
                        </tr>
                        <tr class="t-row">
                          <td class="t-data text-left">VGOS - Mira road</td>
                          <td class="t-data text-right">22</td>
                        </tr>
                        <tr class="t-row">
                          <td class="t-data text-left">VGOS - Rajasthan</td>
                          <td class="t-data text-right">34</td>
                        </tr>
                        <tr class="t-row">
                          <td class="t-data text-left">VGOS - Bengaluru</td>
                          <td class="t-data text-right">40</td>
                        </tr>
                        <tr class="t-row">
                          <td class="t-data text-left">VGOS - Malad</td>
                          <td class="t-data text-right">25</td>
                        </tr>
                      </tbody> -->
                    </table>

                    <!-- </v-card-title> -->
                  </v-card>
                </div>
                <div class="w-47 sec-col margin-betwen-col pa-2">
                  <v-card elevation="0">
                    <!-- <v-card-title class="text-subtitle-1 "> -->
                    <div
                      class="table-header w-100 d-flex flex-row justify-space-between"
                    >
                      <div class="i-patch">
                        Top 5 Job Seekers
                      </div>

                      <v-card
                        class="pl-1 d-flex align-center my-2"
                        elevation="0"
                        width="20%"
                        height="25px"
                      >
                        <!-- <v-select
                          solo
                          outlined
                          prefix="Top"
                          color="black"
                          class="circle-radius"
                          :items="dropdownItems"
                          v-model="selectedItem"
                          hide-details
                          dense
                          append-icon="mdi-chevron-down"
                        >
                        </v-select> -->
                      </v-card>

                      <!-- <v-card outlined class="font-weight-medium pl-2 d-flex align-center rounded my-2"
                        border-color="black" border-radius="8px" width="15%" height="25px">
                        Top 5
                        <v-spacer></v-spacer>
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-card> -->
                      <!-- <div class="circle-outline">
                          <v-select
                            outlined
                            class="circle-radius"
                            :items="dropdownItems"
                            v-model="selectedItem"
                            label="Top 5"
                            hide-details
                            dense
                            append-icon="mdi-chevron-down"
                          >
                          </v-select>
                        </div> -->
                    </div>
                    <table class="tbl">
                      <thead class="t-head">
                        <tr class="t-row">
                          <th class="head i-patch">Rank</th>
                          <th class="head i-patch">Name</th>
                          <th class="head i-patch">Mains Score</th>
                          <th class="head i-patch">Level</th>
                          <th class="head i-patch">Action</th>
                        </tr>
                      </thead>
                      <tbody class="t-body">
                        <tr
                          class="t-row"
                          v-for="(teacher, index) in displayedClusterJobSeekers"
                          :key="teacher.id"
                        >
                          <td class="t-data">{{ index + 1 }}</td>
                          <td class="t-data">
                            {{ teacher.name }}
                            <!-- {{ `${teacher.firstName} ${teacher.lastName}` }} -->
                          </td>
                          <td class="t-data">
                            {{ teacher.mainsTotal }}
                          </td>
                          <td class="t-data">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                  {{ teacher.level[0] }}
                                </span>
                              </template>
                              <span>{{ teacher.level.join(", ") }}</span>
                            </v-tooltip>
                          </td>
                          <td class="t-data">View</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="total-count mr-2 pr-2">
                      <span text-subtitle-1
                        >Total Users Participated :
                        {{ this.countingJobSeekerCluster }}</span
                      >

                      <!-- <router-link>VIEW ALL DATA</router-link> -->
                      <span
                        @click="
                          $router.push('/clustercampaignjobseekersresult')
                        "
                        class=" cursor"
                        >VIEW ALL DATA</span
                      >
                    </div>
                    <!-- </v-card-title> -->
                  </v-card>
                </div>

                <div class="w-25 col3 margin-betwen-col">
                  <v-card
                    @click="$router.push('/clusterjobseekersappraisal')"
                    class="cursor cardBorder rounded-xl col3-card"
                    outlined
                    color="#4D695B"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row justify="space-between" class="pl-2  pt-1 white--text">
                        <span>Recommended for Interview</span>
                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-2 white--text text-h5">
                      {{
                        this.clusterJobSeekersChartData
                          .totalJobSeekersDemoSubmitted
                      }}
                    </v-card-subtitle>
                  </v-card>
                  <v-card
                    @click="$router.push('/clusterjobseekerspip')"
                    class="cursor cardBorder rounded-xl col3-card"
                    outlined
                    color="#FB8C00"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row justify="space-between" class="pl-2 pt-1 white--text">
                        <span>Job Seekers Interviewed</span>
                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-2 white--text text-h5">
                      {{
                        this.clusterJobSeekersChartData
                          .totalJobSeekersInterviewSubmitted
                      }}
                    </v-card-subtitle>
                  </v-card>
                  <v-card
                    @click="$router.push('/clusterjobseekerstbd')"
                    class="cursor cardBorder rounded-xl col3-card"
                    outlined
                    color="#00000061"
                  >
                    <v-card-title class="text-subtitle-1">
                      <v-row justify="space-between" class="pl-2 white--text">
                        <div>
                          <span class="no-space">Recommended for Hiring</span>
                        </div>

                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-row>
                    </v-card-title>

                    <v-card-subtitle class="pt-2 white--text text-h5">
                      {{
                        this.clusterJobSeekersChartData
                          .totalJobSeekersScreeningFailed
                      }}
                    </v-card-subtitle>
                  </v-card>
                </div>
              </div>
            
            <v-row class="heading pl-4 mt-5 mb-1">
              <v-card-title class="text-h6 mt-0 font-weight-medium">
                <p>Aggregated Campaign Results</p></v-card-title
              >
            </v-row>
            <div class="funnel-contianer pl-1 pr-4" fluid>
              <div class="w-100 d-flex flex-row">
                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg pr-0 pl-0 cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                      <p class="py-0 my-0 pl-2 funnelText">
                        JOB SEEKERS
                      </p>
                    </v-card-title>

                    <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                      <span class="pl-3 black--text"
                        >Users Registered/Invited</span
                      >
                      <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersInvited" propertyEnd="totalJobSeekersScreeningStarted" funnelColor="#E0ED4B"/>
                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              clusterJobSeekersChartData.totalJobSeekersInvited -
                              clusterJobSeekersChartData.totalJobSeekersScreeningStarted,
                              clusterJobSeekersChartData.totalJobSeekersInvited
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                      <p class="pl-2 funnelText">
                        SCREENING
                      </p>
                    </v-card-title>

                    <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                      <span class="pl-3 black--text"
                        >Attempted</span
                      >
                      <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersScreeningStarted" propertyEnd="totalJobSeekersScreeningPassed" funnelColor="#C3ED4B"/>
                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              clusterJobSeekersChartData.totalJobSeekersScreeningStarted -
                              clusterJobSeekersChartData.totalJobSeekersScreeningPassed,
                              clusterJobSeekersChartData.totalJobSeekersScreeningStarted
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                      <p class="pl-2 funnelText">
                        SCREENING RESULT
                      </p>
                    </v-card-title>

                    <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                      <span class="pl-3 green--text"
                        >Passed </span
                      >
                      <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersScreeningPassed" propertyEnd="totalJobSeekersMainsStarted" funnelColor="#B0D644"/>
                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              clusterJobSeekersChartData.totalJobSeekersScreeningPassed -
                              clusterJobSeekersChartData.totalJobSeekersMainsStarted,
                              clusterJobSeekersChartData.totalJobSeekersScreeningPassed
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                      <p class="pl-2 funnelText">MAINS</p>
                    </v-card-title>

                    <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                      <span class="pl-3 black--text"
                        >Attempted</span
                      >
                      <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersMainsStarted" propertyEnd="totalJobSeekersMainsPassed" funnelColor="#6AD644"/>

                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              clusterJobSeekersChartData.totalJobSeekersMainsStarted -
                              clusterJobSeekersChartData.totalJobSeekersMainsPassed,
                              clusterJobSeekersChartData.totalJobSeekersMainsStarted
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                      <p class="pl-2 funnelText">
                        MAINS RESULT
                      </p>
                    </v-card-title>

                    <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                      <span class="pl-3 green--text"
                        >Passed :
                        {{ clusterJobSeekersChartData.totalJobSeekersMainsPassed }}</span
                      >
                      <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyBegin="totalJobSeekersMainsPassed" propertyEnd="totalJobSeekersDemoSubmitted"/>
                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              clusterJobSeekersChartData.totalJobSeekersMainsPassed -
                              clusterJobSeekersChartData.totalJobSeekersDemoSubmitted,
                              clusterJobSeekersChartData.totalJobSeekersMainsPassed
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>

                <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                  <v-card
                    class="rounded-lg cardBorder"
                    width="100%"
                    height="30vh"
                  >
                    <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                      <p class="pl-2 funnelText">DEMO</p>
                    </v-card-title>

                    

                    <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                      <span class="pl-3 black--text"
                        >Submitted :
                        {{ clusterJobSeekersChartData.totalJobSeekersDemoSubmitted }}</span
                      >
                      <FunnelChartCommon :invites="clusterJobSeekersChartData" scaleMax="totalJobSeekersInvited" propertyEnd="totalJobSeekersInterviewSubmitted" propertyBegin="totalJobSeekersDemoSubmitted"/>

                      <div class="d-flex justify-end py-1 pr-2">
                        <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                        <div 
                          class="red--text pl-2"
                          style="display: inline-block; margin-bottom: 0">
                          {{
                            calculatePercentage(
                              clusterJobSeekersChartData.totalJobSeekersInvited -
                              clusterJobSeekersChartData.totalJobSeekersScreeningStarted,
                              clusterJobSeekersChartData.totalJobSeekersInvited
                            )
                          }}%
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </div>
          </div>
          </div>
        </template>
      </v-carousel-item>
    </v-carousel>
    <div class="carousel-buttons">
      <v-chip
        v-for="(item, i) in userTypeViews.length"
        :key="i"
        class="carousel-button"
        :style="
          i !== activeIndex
            ? ' min-width: 16px ;width: 16px !important ; height : 15px;'
            : ''
        "
        :class="{ active: i === activeIndex }"
        @click="activeIndex = i"
        :color="activeIndex === i ? 'brown' : 'brown lighten-4'"
        fab
        small
        rounded
      >
      </v-chip>
    </div>
  </div>
</template>

<script>
import Notification from "./Notification";
import DonutChart from "./DonutChart.vue";
import DonutChartCluster from "./DonutChartCluster.vue";
import DonutChartSchool from "./DonutChartSchool.vue";
import DonutChartAdminJobSeeker from "./DonutChartAdminJobSeeker.vue";
// Common Funnel Chart Component
import FunnelChartCommon from "./FunnelChartCommon.vue";
import AuthService from "@/services/AuthService";
import { VCarousel, VCarouselItem, VTemplate } from "vuetify/lib";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  collectionGroup,
  query,
  where,
  QuerySnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import { onUnmounted } from "vue";
import { mapState } from "vuex";
export default {
  name: "HomeView",
  components: {
    Notification,
    DonutChart,
    DonutChartCluster,
    DonutChartSchool,
    DonutChartAdminJobSeeker,
    FunnelChartCommon,
  },
  data() {
    return {
      notificationDialog: false,
      fullscreenBackgroundColor: "green",
      topSchools: [],
      topTeachers: [],
      teachers: [],
      roleName: "",

      schools: [],
      donutArray: [],
      schoolTeacher: [],
      topTeacherInSchool: [],
      topJobSeekerInSchool: [],
      schoolJobSeeker: [],
      topJobSeekerInCluster: [],
      schoolClusterJobSeeker: [],
      schoolClusterTeacher: [],
      schoolClusterIntoTeacher: [],
      topTeacherInCluster: [],
      topTeacherIntoCluster: [],
      jobseekerArray: [],
      adminJobSeeker: [],
      topJobSeekerInAdmin: [],
      roles: "Admin",
      dashboardId: "management-dashbaord-001",
      teachersInTotalCampaign: 0,
      jobSeekerInTotalCampaign: 0,
      activeIndex: 0,
      activeIndex2: 0,
      totalSchools: 0,
      totalSchoolsCount: 0,
      countJobSeekerAtCluster: 0,
      countTeacherCluster: 0,
      totalTeachersCount: 0,
      countingTeacher: 0,
      countingDocumentCluster: 0,
      countingDocumentSchool: 0,
      countingJobSeeker: 0,
      countingJobSeekerCluster: 0,
      countingJobSeekerSchool: 0,
      liveCampaignlength: 0,
      totalPrePrimaryTeacher: 0,
      totalPrimaryTeacher: 0,
      totalSecondaryTeacher: 0,
      totalSeniorSecondaryTeacher: 0,
      totalMiddleTeacher: 0,
      totalTeachersParticipated: 0,
      scheduledCampaignlength: 0,
      expiredCampaignlength: 0,
      totalLiveTeacherCampaigns: 0,
      expiredCampaignCount: 0,
      liveCampaignCount: 0,
      upcomingCampaignCount: 0,
      expiredCampaignCountJB: 0,
      upcomingCampaignCountJB: 0,
      liveCampaignCountJB: 0,
      totalUsersParticipated: 0,
      totalTeachersParticipatedCount: 0,
      hover: "",
      clusterName: "",
      clusterData: [["", ""]],
      schoolData: [["", ""]],
      clustersData: [["", ""]],
      jobSeekerData: [["", ""]],
      schoolsData: [],
      mainsBookingTeacherSchool: new Map(),
      mainsBookingJobSeekerSchool: new Map(),
      jobSeekersClusterData: [],
      teacherCountClusterData: [],
      dropdownItems: [5],
      selectedItem: 5,
      tableRows: [],
      liveCampaign: [],
      scheduledCampaign: [],
      expiredCampaign: [],
      data: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },

        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
      ],
      clusters: [],
      invited: 0,
      screeningStarted: 0,
      screeningPassed: 0,
      screeningFailed: 0,
      mainsStarted: 0,
      mainsPassed: 0,
      mainsFailed: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      chartData: {},
      emptyChartData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
      },
      jobSeekerChartData: {},
      emptyJobSeekerChartData: {
        jobSeekerInvited: 0,
        jobSeekerScreeningStarted: 0,
        jobSeekerScreeningPassed: 0,
        jobSeekerScreeningFailed: 0,
        jobSeekerMainsStarted: 0,
        jobSeekerMainsPassed: 0,
        jobSeekerMainsFailed: 0,
      },
      recommended: 0,
      jobSeekerRejected: 0,
      jobSeekerTBD: 0,
      schoolChartData: {},
      emptySchoolChartData: {
        totalInvited: 0,
        totalScreeningStarted: 0,
        totalScreeningPassed: 0,
        totalScreeningFailed: 0,
        totalMainsStarted: 0,
        totalMainsPassed: 0,
        totalMainsFailed: 0,
      },
      schooolTeacherAppraisal: 0,
      schooolTeacherPip: 0,
      schooolTeacherTBD: 0,
      schoolJobSeekerChartData: {},
      emptySchoolJobSeekerChartData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
        demoSubmitted: 0,
        interviewSubmitted: 0
      },
      clusterTeacherChartData: {},
      emptyClusterTeacherChartData: {
        totalInvited: 0,
        totalScreeningStarted: 0,
        totalScreeningPassed: 0,
        totalScreeningFailed: 0,
        totalMainsStarted: 0,
        totalMainsPassed: 0,
        totalMainsFailed: 0,
      },
      clusterJobSeekersChartData: {},
      emptyClusterJobSeekersChartData: {
        totalJobSeekersInvited: 0,
        totalJobSeekersScreeningStarted: 0,
        totalJobSeekersScreeningPassed: 0,
        totalJobSeekersScreeningFailed: 0,
        totalJobSeekersMainsStarted: 0,
        totalJobSeekersMainsPassed: 0,
        totalJobSeekersMainsFailed: 0,
        totalJobSeekersDemoSubmitted: 0,
        totalJobSeekersInterviewSubmitted: 0,
      },
      roleCluster: "",
      roleSchool: "",
      isFullScreen: false,

      funnelTeachersData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
        demoSubmitted: 0,
        interviewSubmitted: 0,
      },

      funnelJobSeekersData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
        demoSubmitted: 0,
        interviewSubmitted: 0
      },
      userTypeViews: ['jobSeeker', 'teacher'],
      donutArrayAdminJobSeeker: [0, 0, 0],
      showComingSoon: false
    };
  },
  computed: {
    displayedTeachers() {
      return this.topTeacherInSchool.slice(0, this.selectedItem);
    },
    displayedJobSeekers() {
      return this.topJobSeekerInSchool.slice(0, this.selectedItem);
    },
    displayedClusterJobSeekers() {
      return this.topJobSeekerInCluster.slice(0, this.selectedItem);
    },
    displayedClusterTeachers() {
      return this.topTeacherInCluster.slice(0, this.selectedItem);
    },
    displayedAdminJobSeeker() {
      return this.topJobSeekerInAdmin.slice(0, this.selectedItem);
    },
    bookingMainsTeacher() {
      return this.mainsBookingTeacherSchool;
    },
    bookingMainsJobSeeker() {
      return this.mainsBookingJobSeekerSchool;
    },

    // fullscreenStyles() {
    //   return this.isFullScreen
    //     ? {
    //         position: "fixed",
    //         top: 0,
    //         left: 0,
    //         width: "100%",
    //         height: "100%",
    //         zIndex: 9999,
    //         backgroundColor: this.fullscreenBackgroundColor,
    //       }
    //     : {
    //         /* Define your normal styles here */
    //       };
    // },
  },

  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    toggleScreen() {
      // const elem = this.$refs.fullScreenElement;
      if (this.isFullScreen) {
        // If the document is already in full-screen mode, exit it.
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
      } else {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
      }
      this.$store.state.isNavBar = this.isFullScreen;
    },

    extractClusterNumber(cluster) {
      const clusterNumber = cluster.split(" ")[1];
      return clusterNumber;
    },

    async newTeacherFunnelChartDataListener() {
      const dashboardTeachersRef = doc(
        db,
        "dashboards",
        this.$store.state.role.id + "-vgos-teachers-dashboard",
        "results",
        "campaignresults"
      );

      onSnapshot(dashboardTeachersRef, (doc) => {
        const resultData = doc.data();
        if (resultData) {
          this.funnelTeachersData = {
            invited: resultData.invited ? resultData.invited : 0,
            screeningStarted: resultData.screeningStarted
              ? resultData.screeningStarted
              : 0,
            screeningPassed: resultData.screeningPassed
              ? resultData.screeningPassed
              : 0,
            screeningFailed: resultData.screeningFailed
              ? resultData.screeningFailed
              : 0,
            mainsStarted: resultData.mainsStarted ? resultData.mainsStarted : 0,
            mainsPassed: resultData.mainsPassed ? resultData.mainsPassed : 0,
            mainsFailed: resultData.mainsFailed ? resultData.mainsFailed : 0,
            demoSubmitted: resultData.demoSubmitted ? resultData.demoSubmitted : 0,
            interviewSubmitted: resultData.interviewSubmitted ? resultData.interviewSubmitted : 0,
          };
        }
      });
    },

    async newJobSeekerFunnelChartDataListener() {
      console.log('this.$store.state.role.id + "-jobseekers-dashboard"', this.$store.state.role.id + "-jobseekers-dashboard")
      const dashboardJobSeekersRef = doc(
        db,
        "dashboards",
        this.$store.state.role.id + "-jobseekers-dashboard",
        "results",
        "campaignresults"
      );

      // const dashboardJobSeekersRef=doc(db, "dashboards", "12345-jobseekers-dashboard", "results", "campaignresults")

      onSnapshot(dashboardJobSeekersRef, (doc) => {
        const resultData = doc.data();
        if (resultData) {
          this.funnelJobSeekersData = {
            invited: resultData.invited ? resultData.invited : 0,
            screeningStarted: resultData.screeningStarted
              ? resultData.screeningStarted
              : 0,
            screeningPassed: resultData.screeningPassed
              ? resultData.screeningPassed
              : 0,
            screeningFailed: resultData.screeningFailed
              ? resultData.screeningFailed
              : 0,
            mainsStarted: resultData.mainsStarted ? resultData.mainsStarted : 0,
            mainsPassed: resultData.mainsPassed ? resultData.mainsPassed : 0,
            mainsFailed: resultData.mainsFailed ? resultData.mainsFailed : 0,
            demoSubmitted: resultData.demoSubmitted ? resultData.demoSubmitted : 0,
            interviewSubmitted: resultData.interviewSubmitted ? resultData.interviewSubmitted : 0            
          };
        }
      });
    },

    async setupSnapshotListener() {
      const dashboardRef = doc(db, "dashboards", "management-dashboard-001");

      onSnapshot(dashboardRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          const totalClusters = data.totalClusters;
        } else {
          // console.log("Document does not exist");
        }
      });
    },
    async extractClusterData() {
      try {
        // console.log("inside extractClusterData fun >>>>........");
        const clustersRef = collection(
          db,
          "dashboards",
          "management-dashboard-001",
          "clusters"
        );
        // console.log("cluster ref", clustersRef);
        // const clustersSnapshot = await getDocs(clustersRef);

        onSnapshot(clustersRef, (clustersSnapshot) => {
          this.clusterData = [["", ""]];
          clustersSnapshot.forEach((clusterDoc) => {
            const { name, totalSchools } = clusterDoc.data();
            let noOfSchool = parseInt(totalSchools);
            this.clusterval = [name, noOfSchool];
            this.clusterData.push(this.clusterval);
          });
        });
      } catch (error) {
        // console.error("Error extracting cluster data:", error);
      }
    },

    // donut chart Admin for TEACHERS .....
    // async extractClusterData() {
    //   try {
    //     //console.log("inside extractClusterData fun >>>>........");
    //     const clustersRef = collection(
    //       db,
    //       "dashboards",
    //       this.$store.state.role.id + "-vgos-teachers-dashboard",
    //       //  "123456-vgos-teachers-dashboard",
    //       "clusters"
    //     );
    //     // console.log("cluster ref", clustersRef);
    //     // const clustersSnapshot = await getDocs(clustersRef);

    //     onSnapshot(clustersRef, (clustersSnapshot) => {
    //       this.clusterData = [["", ""]];

    //       clustersSnapshot.forEach((clusterDoc) => {
    //         //console.log("clustersName >>>>,,,,,,,", clusterDoc.data());
    //         const clusterData = clusterDoc.data();
    //         const clusterCountValue = clusterData.clusterCount;
    //         //const { name, totalSchools } = clusterDoc.data();
    //         this.clusterval = [clusterDoc.id, clusterCountValue];
    //         this.clusterData.push(this.clusterval);
    //       });
    //     });
    //   } catch (error) {
    //     // console.error("Error extracting cluster data:", error);
    //   }
    // },

    async setupschoolSnapshotListener() {
      const schoolName = this.$store.state.role.schools;
      // console.log("schoolName >>", schoolName);
      const q = query(
        collection(db, "dashboards", "management-dashboard-001", "schools"),
        where("name", "==", schoolName)
      );

      onSnapshot(q, (querySnapshot) => {
        this.schoolData = [["", ""]];
        const posts = [
          "Foundational",
          "Preparatory",
          "Middle",
          "Secondary",
          "Senior Secondary",
        ];
        querySnapshot.forEach((d) => {
          console.log("querySnapshotData", d.data());
          for (const post of posts) {
            this.schoolData.push([post, d.data()[post]]);
            console.log("queryabcdefghi", d.data()[post]);
          }
        });
        // console.log("schoolData >>", this.schoolData);
      });
    },

    async setupAdminJobSeekerSnapshot() {
      const q = doc(db, "dashboards", "management-dashboard-001");
      onSnapshot(q, (doc) => {
        const data = doc.data();
        this.jobSeekerData.splice(1);
        if (data) {
          const clustersCount = data.clustersCount;
          const totalUsersParticipated = data.totalUsersParticipated;
          this.totalUsersParticipated = totalUsersParticipated;
          //console.log("Printing totalUsersParticipated",totalUsersParticipated);
          for (const k in clustersCount) {
            this.jobSeekerData.push([k, clustersCount[k].jobSeeker]);
          }
        }
      });
    },

    async setupclusterTeacherCountSnapshot() {
      const clusterName = this.$store.state.role.clusterName;
      this.clusterName = clusterName;
      // console.log("ClusterrNameJobSeeker >>", clusterName);
      // const q = collection(db, "dashboards", "ums-dashboard", "schools");
      const roleId = this.$store.state.role.id;
      const dashboardTeacherRef = collection(
        db,
        "dashboards",
        roleId + "-vgos-teachers-dashboard",
        "schools"
      );

      onSnapshot(dashboardTeacherRef, (schoolSnapshot) => {
        this.teacherCountClusterData.splice(0);
        schoolSnapshot.forEach(async (school) => {
          const schoolData = school.data();
          this.teacherCountClusterData.push({
            name: school.id,
            teachers: schoolData.totalTeachers,
          });
        });
      });
    },

    async setupclusterJobSeekerSnapshot() {
      const clusterName = this.$store.state.role.clusterName;
      this.clusterName = clusterName;

      const roleId = this.$store.state.role.id;
      const dashboardJobSeekersRef = collection(
        db,
        "dashboards",
        roleId + "-jobseekers-dashboard",
        "schools"
      );

      onSnapshot(dashboardJobSeekersRef, (schoolSnapshot) => {
        this.jobSeekersClusterData.splice(0);
        schoolSnapshot.forEach((school) => {
          const schoolData = school.data();
          this.jobSeekersClusterData.push({
            name: school.id,
            jobSeekers: schoolData.totalJobSeekers,
          });
        });
      });
    },

    getLevels(levelsRef){
      try{
        const levelsData = [];
        return new Promise((res)=>{
          onSnapshot(levelsRef, (snapshot)=>{
          snapshot.docs.forEach((doc) => {
          levelsData.push(doc.data());
          })
          res(levelsData)
        })
      })
    }
      catch(error){
        console.log(error)
      }      
    },

    getLvlCountData(dashboardRef){
      try{
        return new Promise((res)=>{
          onSnapshot(dashboardRef, (lvlCount) => {
          this.clustersData.splice(1);
          res(lvlCount.data());
          })
        })
      }
      catch(error){
        console.log(error)
      }
    },

    checkLevelDataValue(levelData,level){
      return typeof levelData[level]=='undefined'? 0 : levelData[level]
    },

    async setupclusterSnapshotListener() {
      const clusterName = this.$store.state.role.clusterName;
      this.clusterName = clusterName;
      const roleId = this.$store.state.role.id;
      console.log("user_details", this.$store.state.role);
      // const q = doc(db, "dashboards", "ums-dashboard", "clusters", clusterName);
      const dashboardRef = doc(
        db,
        "dashboards",
        roleId + "-vgos-teachers-dashboard",
        "clusterstrengths",
        "levelcount"
      );
      const levelsRef = collection(db,"levels");
      const levelsData = await this.getLevels(levelsRef);

      let allLevels = levelsData.map(level=>level.name)
      const levelData = await this.getLvlCountData(dashboardRef) 
      allLevels.forEach(level=>{
          this.clusterData.push([level, this.checkLevelDataValue(levelData,level)])
      })
    },

    async schoolCountListener() {
      const dashboardRef = collection(db, "users");
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        let countTeacher = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
          if (
            userData.userType?.toLowerCase() === "teacher" &&
            userData.campaignStatus !== undefined &&
            userData.campaignStatus.length === 1
          ) {
            // for Admin
            countTeacher++;
          }
        });
        this.countingTeacher = countTeacher;
      });
    },

    async schoolCountClusterListener() {
      const dashboardRef = collection(db, "users");
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        let countDocumentCluster = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
          if (
            userData.userType?.toLowerCase() === "teacher" &&
            userData.campaignStatus !== undefined &&
            userData.campaignStatus.length === 1 &&
            userData.cluster === this.$store.state.role.clusterName
          ) {
            countDocumentCluster++;
          }
        });
        this.countingDocumentCluster = countDocumentCluster;
      });
    },

    async schoolCountSchoolListener() {
      const dashboardRef = collection(db, "users");
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        let countDocumentSchool = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
          if (
            userData.userType?.toLowerCase() === "teacher" &&
            userData.campaignStatus !== undefined &&
            userData.campaignStatus.length === 1 &&
            userData.schoolName == this.$store.state.role.schools
          ) {
            countDocumentSchool++;
          }
        });
        this.countingDocumentSchool = countDocumentSchool;
      });
    },

    async jobSeekerCountListener() {
      const dashboardRef = collection(db, "users");
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        let countJobSeeker = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
          // console.log("userData--->>>>", userData, userData.size);
          if (
            userData.userType?.toLowerCase() === "job seeker" &&
            userData.campaignStatus !== undefined &&
            userData.campaignStatus.length === 1
          ) {
            // For Admin Job Seeker
            countJobSeeker++;
          }
        });
        this.countingJobSeeker = countJobSeeker;
      });
    },

    async jobSeekerCountClusterListener() {
      const dashboardRef = collection(db, "users");
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        let countJobSeekerCluster = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
          if (
            userData.userType?.toLowerCase() === "job seeker" &&
            userData.campaignStatus !== undefined &&
            userData.campaignStatus.length === 1 &&
            userData.cluster === this.$store.state.role.clusterName
          ) {
            // For Admin Job Seeker
            countJobSeekerCluster++;
          }
        });
        //this.countingJobSeekerCluster = countJobSeekerCluster;
      });
    },

    async jobSeekerCountSchoolListener() {
      const dashboardRef = collection(db, "users");
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        let countJobSeekerSchool = 0;
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        snapshot.docs.forEach((doc) => {
          const userData = doc.data();
          if (
            userData.userType?.toLowerCase() === "job seeker" &&
            userData.campaignStatus !== undefined &&
            userData.campaignStatus.length === 1 &&
            userData.schoolName == this.$store.state.role.schools
          ) {
            // For Admin Job Seeker
            countJobSeekerSchool++;
          }
        });
        this.countingJobSeekerSchool = countJobSeekerSchool;
      });
    },

    async schoolSnapshotListener() {
      const dashboardRef = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "schools"
      );
      // console.log("called");

      onSnapshot(dashboardRef, (snapshot) => {
        //console.log("datamdnwbdfwbfj", snapshot);
        // const schools = [];
        this.schools.splice(0);
        snapshot.docs.forEach((doc) => {
          const schoolData = {
            cluster: doc.data().cluster == null ? "" : doc.data().cluster,
            rank: doc.data().rank == null ? 0 : doc.data().rank,
            name: doc.data().name == null ? "" : doc.data().name,
            totalMainsPassed: doc.data()?.totalMainsPassed
              ? doc.data().totalMainsPassed
              : 0,
            totalScreeningStarted: doc.data()?.totalScreeningStarted
              ? doc.data().totalScreeningStarted
              : 0,
            
            totalMainsStarted: doc.data()?.totalMainsStarted ? doc.data().totalMainsStarted : 0,
            
          };
          if (schoolData.totalScreeningStarted > 0) {
            this.schools.push(schoolData);
          }
        });
        let sortedProducts = this.schools.sort((p1, p2) =>
          p1.rank < p2.rank ? 1 : p1.rank > p2.rank ? -1 : 0
        );
        console.log("this_schools :", this.schools)
        // schools.sort((a, b) => a.rank - b.rank);
        this.topSchools = this.schools.slice(0, this.selectedItem);
        console.log("arrayOfSchool", this.topSchools);
      });
    },

    async jobSeekerAdminSnapshot() {
      const jobSeekersRef = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "jobseekers"
      );
      onSnapshot(jobSeekersRef, (snapshot) => {
        this.adminJobSeeker.splice(0);
        snapshot.docs.forEach(async (doc) => {
          const data = doc.data();
          const topJobSeekersClusterData = {
            userId : data.userId == undefined ? "" : data.userId ,
            campaignId : data.campaignId == undefined ? "" : data.campaignId,
            mainsTotal:
              data.mainsTotal == null ? "" : data.mainsTotal,
            name: data.name === null ? "" : data.name,
            // firstName:
            //   data.personalInfo.firstName == null
            //     ? ""
            //     : d.data().personalInfo.firstName,
            // lastName:
            //   d.data().personalInfo.lastName == null
            //     ? ""
            //     : d.data().personalInfo.lastName,
            // level: level,
            level: data.levels === null ? [] : data.levels,
          };
          this.adminJobSeeker.push(topJobSeekersClusterData);
        });
        let sortedProducts = this.adminJobSeeker.sort((p1, p2) =>
          p1.mainsTotal < p2.mainsTotal
            ? 1
            : p1.mainsTotal > p2.mainsTotal
            ? -1
            : 0
        );
        this.topJobSeekerInAdmin = this.adminJobSeeker;
      });

      // const campaignRef = collection(db, "campaigns"); // const schoolMarks = {}

      // onSnapshot(campaignRef, (snapshot) => {
      //   snapshot.docs.forEach(async (doc) => {
      //     // if teacher, run this

      //     if (doc.data().audienceType.toLowerCase() === "job_seeker") {
      //       try {
      //         const jobSeekerRef = collection(campaignRef, doc.id, "audience");
      //         const jobSeekDocs = await getDocs(jobSeekerRef);

      //         jobSeekDocs.forEach((d) => {
      //           const level = [];
      //           if (d.data().responsibleTeachingAt) {
      //             for (const l of d.data().responsibleTeachingAt) {
      //               level.push(l.level);
      //             }
      //           }
      //           //console.log("screening total >>", d.data().screeningTotal);
      //           const topJobSeekersClusterData = {
      //             screeningTotal:
      //               d.data().screeningTotal == null
      //                 ? ""
      //                 : d.data().screeningTotal,
      //             firstName:
      //               d.data().personalInfo.firstName == null
      //                 ? ""
      //                 : d.data().personalInfo.firstName,
      //             lastName:
      //               d.data().personalInfo.lastName == null
      //                 ? ""
      //                 : d.data().personalInfo.lastName,
      //             cluster: d.data().cluster == null ? "" : d.data().cluster,
      //             level: level,
      //           };

      //           // console.log("hotttt chocolate>>>", topJobSeekersClusterData);
      //           this.adminJobSeeker.push(topJobSeekersClusterData);
      //           console.log(
      //             "screening total result show >>",
      //             d.data().screeningTotal
      //           );
      //           // data.schoolMarks[d.data().schoolName] += d.data().screeningTotal
      //         });

      //         let sortedProducts = this.adminJobSeeker.sort((p1, p2) =>
      //           p1.screeningTotal < p2.screeningTotal
      //             ? 1
      //             : p1.screeningTotal > p2.screeningTotal
      //               ? -1
      //               : 0
      //         );
      //         this.topJobSeekerInAdmin = this.adminJobSeeker;
      //         console.log("Final data >>>>", this.topJobSeekerInAdmin);
      //       } catch (error) {
      //         console.error("Error fetching audience collection:", error);
      //       }
      //     }
      //   });
      // });
    },

    // COMMENTED CODE FOR SCHOOL LEVEL EARLIER
    // async teacherSnapshotListener() {
    //   const campaignRef = collection(db, "campaigns"); // const schoolMarks = {}

    //   onSnapshot(campaignRef, (snapshot) => {
    //     snapshot.docs.forEach(async (doc) => {
    //       // if teacher, run this
    //       // console.log("doc id >>", doc.id)
    //       if (doc.data().audienceType.toLowerCase() === "job_seeker") {
    //         console.log("teacher >>", doc.id);
    //         try {
    //           const jobSeekerRef = collection(campaignRef, doc.id, "audience");
    //           const jobSeekDocs = await getDocs(jobSeekerRef);

    //           jobSeekDocs.forEach((d) => {
    //             //console.log("screening total >>", d.data().screeningTotal);
    //             if (d.data().schoolName == this.$store.state.role.schools) {
    //               const level = [];
    //               if (d.data().responsibleTeachingAt) {
    //                 for (const l of d.data().responsibleTeachingAt) {
    //                   level.push(l.level);
    //                 }
    //               }
    //               const topJobSeekersData = {
    //                 screeningTotal:
    //                   d.data().screeningTotal == null
    //                     ? ""
    //                     : d.data().screeningTotal,
    //                 firstName:
    //                   d.data().personalInfo.firstName == null
    //                     ? ""
    //                     : d.data().personalInfo.firstName,
    //                 lastName:
    //                   d.data().personalInfo.lastName == null
    //                     ? ""
    //                     : d.data().personalInfo.lastName,
    //                 cluster: d.data().cluster == null ? "" : d.data().cluster,
    //                 // level: d.data().levels == null ? [] : d.data().levels,
    //                 level: level,
    //                 //user.data().personalInfo.firstName
    //               };
    //               this.schoolJobSeeker.push(topJobSeekersData);
    //               // console.log(
    //               //   "screening total result show >>",
    //               //   d.data().screeningTotal
    //               // );
    //               // console.log("naresh sir", this.schoolJobSeeker);
    //             } // data.schoolMarks[d.data().schoolName] += d.data().screeningTotal
    //           });
    //           let sortedProducts = this.schoolJobSeeker.sort((p1, p2) =>
    //             p1.screeningTotal < p2.screeningTotal
    //               ? 1
    //               : p1.screeningTotal > p2.screeningTotal
    //               ? -1
    //               : 0
    //           );
    //           this.topJobSeekerInSchool = this.schoolJobSeeker;
    //         } catch (error) {
    //           console.error("Error fetching audience collection:", error);
    //         }
    //       } // if jobseeker, run this
    //       else {
    //         // for (const sName of doc.data().schools) {
    //         //   if (!(sName in data.schoolMarks)) {
    //         //     console.log("entered");
    //         //     data.schoolMarks[sName] = 0;
    //         //   }
    //         // }
    //         try {
    //           const jobSeekerRef = collection(campaignRef, doc.id, "audience");
    //           const jobSeekDocs = await getDocs(jobSeekerRef);

    //           jobSeekDocs.forEach((d) => {
    //             //console.log("screening total >>", d.data().screeningTotal);
    //             if (d.data().schoolName == this.$store.state.role.schools) {
    //               const topTeachersData = {
    //                 screeningTotal:
    //                   d.data().screeningTotal == null
    //                     ? ""
    //                     : d.data().screeningTotal,
    //                 firstName:
    //                   d.data().personalInfo.firstName == null
    //                     ? ""
    //                     : d.data().personalInfo.firstName,
    //                 lastName:
    //                   d.data().personalInfo.lastName == null
    //                     ? ""
    //                     : d.data().personalInfo.lastName,
    //                 cluster: d.data().cluster == null ? "" : d.data().cluster,
    //                 //user.data().personalInfo.firstName
    //               };
    //               this.schoolTeacher.push(topTeachersData);
    //               // console.log(
    //               //   "screening total result show >>",
    //               //   d.data().screeningTotal
    //               // );
    //               //console.log("naresh sir", this.schoolTeacher);
    //             } // data.schoolMarks[d.data().schoolName] += d.data().screeningTotal
    //           });
    //           let sortedProducts = this.schoolTeacher.sort((p1, p2) =>
    //             p1.screeningTotal < p2.screeningTotal
    //               ? 1
    //               : p1.screeningTotal > p2.screeningTotal
    //               ? -1
    //               : 0
    //           );
    //           this.topTeacherInSchool = this.schoolTeacher;
    //         } catch (error) {
    //           console.error("Error fetching audience collection:", error);
    //         }
    //         // console.log("job seeker id >>", doc.id) // go to audience collection. // const docRef=doc(campaignRef, doc.id, ) // fetch screeningTotal and mainsTotal for each audience. // get rank
    //       }
    //     });
    //   });
    // },

    // TOP PERFORMING FOR SCHOOL LEVEL
    async teacherSnapshotListener() {
      try {
        //console.log("inside extractClusterData fun >>>>........");
        const clustersRef = collection(
          db,
          "dashboards",
          this.$store.state.role.id + "-vgos-teachers-dashboard",
          //  "123456-vgos-teachers-dashboard",
          "users"
        );

        console.log("cluster_refg", clustersRef);
        // const clustersSnapshot = await getDocs(clustersRef);

        onSnapshot(clustersRef, (snapshot) => {
          this.schoolTeacher.splice(0);
          snapshot.forEach((clusterDoc) => {
            console.log("abcdefg..>", clusterDoc.data());
            const topTeachersClusterData = {
              screeningTotal:
                clusterDoc.data().screeningTotal == null
                  ? ""
                  : clusterDoc.data().screeningTotal,
              name:
                clusterDoc.data().name == null ? "" : clusterDoc.data().name,
              //level: d.data().levels == null ? [] : d.data().levels,
              //user.data().personalInfo.firstName
            };
            this.schoolTeacher.push(topTeachersClusterData);
            //console.log("DATAAA....",this.schoolClusterTeacher);
          });

          let sortedProducts = this.schoolTeacher.sort((p1, p2) =>
            p1.screeningTotal < p2.screeningTotal
              ? 1
              : p1.screeningTotal > p2.screeningTotal
              ? -1
              : 0
          );
          this.topTeacherInSchool = this.schoolTeacher;
          //console.log("QAER..",this.topTeacherInCluster);
        });
      } catch (error) {
        // console.error("Error extracting cluster data:", error);
      }
    },

    // TOP PERFORMING FOR JOB SEEKERS AT SCHOOL LEVEL
    async jobSeekersSchoolSnapshotListener() {
      try {
        //console.log("inside extractClusterData fun >>>>........");
        const clustersRef = collection(
          db,
          "dashboards",
          this.$store.state.role.id + "-jobseekers-dashboard",
          //  "123456-jobseekers-dashboard",
          "users"
        );
        //console.log("cluster refg", clustersRef);
        // const clustersSnapshot = await getDocs(clustersRef);

        onSnapshot(clustersRef, (snapshot) => {
          this.schoolClusterJobSeeker.splice(0);
          snapshot.forEach((clusterDoc) => {
            //console.log("abcdefg..>",clusterDoc.data())
            const topTeachersClusterData = {
              mainsTotal:
                clusterDoc.data().mainsTotal == null
                  ? ""
                  : clusterDoc.data().mainsTotal,
              name:
                clusterDoc.data().name == null ? "" : clusterDoc.data().name,
              level:
                clusterDoc.data().levels == null
                  ? []
                  : clusterDoc.data().levels,
              //user.data().personalInfo.firstName
            };
            this.schoolJobSeeker.push(topTeachersClusterData);
            //console.log("DATAAA....",this.schoolClusterJobSeeker);
          });

          let sortedProducts = this.schoolJobSeeker.sort((p1, p2) =>
            p1.mainsTotal < p2.mainsTotal
              ? 1
              : p1.mainsTotal > p2.mainsTotal
              ? -1
              : 0
          );
          this.topJobSeekerInSchool = this.schoolJobSeeker;
          // console.log("QAER..",this.topJobSeekerInCluster);
        });
      } catch (error) {
        // console.error("Error extracting cluster data:", error);
      }
    },

    // new code for top performing at cluster level(teachers)
    async teacherClusterSnapshotListener() {
      try {
        //console.log("inside extractClusterData fun >>>>........");
        // console.log("roleId", this.$store.state.role)
        const clustersRef = collection(
          db,
          "dashboards",
          // "SNMA86swHZpuQRYjaDK4-vgos-teachers-dashboard",
          this.$store.state.role.id + "-vgos-teachers-dashboard",
          //  "123456-vgos-teachers-dashboard",
          "users"
        );
        // console.log("clustersRef", clustersRef)
        //console.log("cluster refg", clustersRef);
        // const clustersSnapshot = await getDocs(clustersRef);

        onSnapshot(clustersRef, (snapshot) => {
          this.schoolClusterTeacher.splice(0);

          snapshot.forEach((clusterDoc) => {
            console.log("abcdefg..>", clusterDoc.data());
            const topTeachersClusterData = {
              screeningTotal:
                clusterDoc.data().screeningTotal == null
                  ? ""
                  : clusterDoc.data().screeningTotal,
              name:
                clusterDoc.data().name == null ? "" : clusterDoc.data().name,
              //level: d.data().levels == null ? [] : d.data().levels,
              //user.data().personalInfo.firstName
            };
            this.schoolClusterTeacher.push(topTeachersClusterData);
            console.log("DATAAA....", this.schoolClusterTeacher);
          });

          let sortedProducts = this.schoolClusterTeacher.sort((p1, p2) =>
            p1.screeningTotal < p2.screeningTotal
              ? 1
              : p1.screeningTotal > p2.screeningTotal
              ? -1
              : 0
          );
          this.topTeacherInCluster = this.schoolClusterTeacher;
          console.log("QAER..", this.topTeacherInCluster);
        });
      } catch (error) {
        // console.error("Error extracting cluster data:", error);
      }
    },

    // new code for top performing at cluster level(JB)
    async jobSeekersClusterSnapshotListener() {
      try {
        //console.log("inside extractClusterData fun >>>>........");
        const clustersRef = collection(
          db,
          "dashboards",
          this.$store.state.role.id + "-jobseekers-dashboard",
          //  "123456-jobseekers-dashboard",
          "users"
        );
        //console.log("cluster refg", clustersRef);
        // const clustersSnapshot = await getDocs(clustersRef);

        onSnapshot(clustersRef, (snapshot) => {
          this.schoolClusterJobSeeker.splice(0);
          snapshot.forEach((clusterDoc) => {
            //console.log("abcdefg..>",clusterDoc.data())
            const topTeachersClusterData = {
              mainsTotal:
                clusterDoc.data().mainsTotal == null
                  ? ""
                  : clusterDoc.data().mainsTotal,
              name:
                clusterDoc.data().name == null ? "" : clusterDoc.data().name,
              level:
                clusterDoc.data().levels == null
                  ? []
                  : clusterDoc.data().levels,
              //user.data().personalInfo.firstName
            };
            this.schoolClusterJobSeeker.push(topTeachersClusterData);
            //console.log("DATAAA....",this.schoolClusterJobSeeker);
          });

          let sortedProducts = this.schoolClusterJobSeeker.sort((p1, p2) =>
            p1.mainsTotal < p2.mainsTotal
              ? 1
              : p1.mainsTotal > p2.mainsTotal
              ? -1
              : 0
          );
          this.topJobSeekerInCluster = this.schoolClusterJobSeeker;
          // console.log("QAER..",this.topJobSeekerInCluster);
        });
      } catch (error) {
        // console.error("Error extracting cluster data:", error);
      }
    },

    // TOTAL TEACHERS PARTICIPATED AT CLUSTER LEVEL :
    async setUpTotalTeacherParticipatedAtCluster() {
      const clusterName = this.$store.state.role.clusterName;
      //console.log("clusternameeeeee... >>", clusterName);

      const q = query(
        collection(db, "dashboards", "management-dashboard-001", "clusters"),
        where("name", "==", clusterName)
      );

      //console.log("query..", q);

      // Set up a listener for changes in the query
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data(); // Get data from the document snapshot
          console.log("data printing", data);
          this.countTeacherCluster = data.totalTeachersInCluster;

          //console.log("TOTALTEACHERPARTICIAPTED.. >>", this.countTeacherCluster);
        });
      });
    },

    // TOTAL USERS PARTICIPATED AT CLUSTER LEVEL :
    async setUpTotalUsersParticipatedAtCluster() {
      const clusterName = this.$store.state.role.clusterName;
      //console.log("clusternameeeeee... >>", clusterName);

      const q = query(
        collection(db, "dashboards", "management-dashboard-001", "clusters"),
        where("name", "==", clusterName)
      );

      //console.log("query..", q);

      // Set up a listener for changes in the query
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data(); // Get data from the document snapshot
          console.log("data printing", data);
          this.countingJobSeekerCluster = data.totalUsersInCluster;

          console.log(
            "TOTALUSERSPARTICIAPTED.. >>",
            this.countingJobSeekerCluster
          );
        });
      });
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    },

    compareDates(dateString1, dateString2, dateString3) {
      var validation = "";
      const currentDate = new Date(dateString1);
      const startDate = new Date(dateString2);
      const endDate = new Date(dateString3);

      if (currentDate >= startDate && currentDate <= endDate) {
        validation = "Live";
      } else if (currentDate < startDate) {
        validation = "Scheduled";
      } else {
        validation = "Expired";
      }
      return validation;
    },
    async campaignSnapshotListener() {
      var currentDate = new Date(Date.now()).toISOString();
      var todayDatevalue = this.formatDate(currentDate);
      const liveCollectionRef = collection(db, "campaigns");
      // console.log("live called");

      onSnapshot(liveCollectionRef, (snapshot) => {
        snapshot.docs.forEach((doc) => {
          const campaignData = doc.data();
          if (
            campaignData.campaignConfigration != null &&
            campaignData.isAudienceUpdated == true
          ) {
            if (campaignData.campaignConfigration != null) {
              var valid = this.compareDates(
                todayDatevalue,
                campaignData.startDate,
                campaignData.mainsEndDate
              );
              if (valid == "Live") {
                this.liveCampaign.push(campaignData);
                this.liveCampaignlength = this.liveCampaign.length;
              } else if (valid == "Scheduled") {
                this.scheduledCampaign.push(campaignData);
                this.scheduledCampaignlength = this.scheduledCampaign.length;
              } else if (valid == "Expired") {
                this.expiredCampaign.push(campaignData);
                this.expiredCampaignlength = this.expiredCampaign.length;

              }
            }
          }
        });
      });
      // console.log("Live", this.liveCampaign);
      // console.log("Schedule", this.scheduledCampaign);
      // console.log("expiredCampaign", this.expiredCampaign);
    },

    async totalSchoolsParticipated() {
      const dashboardRef = doc(db, "dashboards", "management-dashboard-001");
      // console.log("calledddddd");

      onSnapshot(dashboardRef, (snapshot) => {
        //console.log("palviiiiiii", snapshot);
        const data = snapshot.data();
        const totalSchools = data.totalchools;
        //console.log("palvicountAjaaa",data);
        const totalTeacherCampaign = data.totalTeacherCampaign;
        const totalSchoolsCount = data.totalSchoolsCount;
        const totalTeachersParticipated = data.totalTeachersParticipated;
        const totalTeachersParticipatedCount =
          data.totalTeachersParticipatedCount;
        // this.totalTeachersParticipatedCount=totalTeachersParticipatedCount;
        // console.log("ghj", totalTeachersParticipated);

        this.donutArray.push(
          this.liveCampaignlength + this.scheduledCampaignlength + this.expiredCampaignlength,
          this.liveCampaignlength,
          this.expiredCampaignlength,
          totalSchoolsCount,
          totalTeachersParticipated,
          totalTeachersParticipatedCount
       );

        this.totalSchools = totalSchools;
        this.totalSchoolsCount = totalSchoolsCount;
        // console.log("palvicount", totalSchools);
        this.totalLiveTeacherCampaigns = totalTeacherCampaign;
      });
    },

    async setupSnapshotListenerFunnel() {
      const id = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "campaigns"
      );
      console.log("dashbaord id>>>", id);
      if (id === undefined) {
        console.log("isnide_iiif");
        this.chartData = this.emptyChartData;
        return;
        // console.log("isnide snap", this.chartData);
      }
      const data = await getDocs(id);
      if (data === undefined) {
        // console.log("isnide if");
        this.chartData = this.emptyChartData;
        return;
        // console.log("isnide snap", this.emptyChartData);
      }
      const document = data.docs[0];
      if (document === undefined) {
        // console.log("isnide if");
        this.chartData = this.emptyChartData;
        // console.log("isnide snap", this.emptyChartData);
      } else {
        const docRef = doc(id, data.docs[0].id);

        onSnapshot(docRef, (snapshot) => {
          let funnelData = snapshot.data();
          console.log("campaignStatus data received:", funnelData);
          if (
            funnelData.invited === undefined ||
            funnelData.screeningStarted === undefined ||
            funnelData.screeningPassed === undefined ||
            funnelData.screeningFailed === undefined ||
            funnelData.mainsStarted === undefined ||
            funnelData.mainsPassed === undefined ||
            funnelData.mainsFailed === undefined
          ) {
            // console.log("isnide if");
            this.chartData = this.emptyChartData;
            // console.log("inside if data", this.chartData);
          } else {
            this.chartData = funnelData;
            this.invited = funnelData.invited;
            this.screeningStarted = funnelData.screeningStarted;
            this.screeningPassed = funnelData.screeningPassed;
            this.mainsStarted = funnelData.mainsStarted;
            this.mainsPassed = funnelData.mainsPassed;
            this.screeningFailed = funnelData.screeningFailed;
            this.mainsFailed = funnelData.mainsFailed;

            this.apraisals = funnelData.mainsPassed;
            this.pip = funnelData.mainsFailed;
            this.tbd = funnelData.screeningFailed;

            // console.log(this.chartData);
          }
        });
      }
    },
    async setupSnapshotListenerJobSeekerFunnel() {
      const id = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "jobSeekerCampaign"
      );
      // console.log("inside_snapshot", id);

      if (id === undefined) {
        // console.log("inside_ifffff");
        this.jobSeekerChartData = this.emptyJobSeekerChartData;
        // console.log("this_jobSeekerChartData", this.jobSeekerChartData);
        return;
        // console.log("isnide snap", this.jobSeekerChartData);
      }
      // console.log("outside_iffff")

      const data = await getDocs(id);
      // console.log("data>>", data);
      if (data === undefined) {
        // console.log("inside___iiiiiif");
        this.jobSeekerChartData = this.emptyJobSeekerChartData;
        return;
        // console.log("isnide snap", this.jobSeekerChartData);
      }
      // console.log("outside_second_if")
      // console.log("get Docs", data.docs[0]);
      const document = data.docs[0];
      if (document === undefined) {
        // console.log("inside_third_if");
        this.jobSeekerChartData = this.emptyJobSeekerChartData;
        // console.log("isnide snap", this.jobSeekerChartData);
      } else {
        // console.log("outside_third_if");

        const docRef = doc(id, data.docs[0].id);

        onSnapshot(docRef, (snapshot) => {
          let funnelData = snapshot.data();

          if (
            funnelData.jobSeekerInvited === undefined ||
            funnelData.jobSeekerScreeningStarted === undefined ||
            funnelData.jobSeekerScreeningPassed === undefined ||
            funnelData.jobSeekerScreeningFailed === undefined ||
            funnelData.jobSeekerMainsStarted === undefined ||
            funnelData.jobSeekerMainsPassed === undefined ||
            funnelData.jobSeekerMainsFailed === undefined
          ) {
            // console.log("isnide if");
            this.jobSeekerChartData = this.emptyJobSeekerChartData;
            // console.log("isnide snap", this.jobSeekerChartData);
          } else {
            // console.log("isnide else:", funnelData);
            this.jobSeekerChartData = funnelData;

            // console.log("inisde snap job", this.jobSeekerChartData);
            this.recommended = funnelData.jobSeekerMainsPassed;
            this.jobSeekerRejected = funnelData.jobSeekerMainsFailed;
            this.jobSeekerTBD = funnelData.jobSeekerScreeningFailed;
          }
        });
      }
    },

    calculatePercentage(value, total) {
      if (total === 0) {
        // console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // console.log(roundedPercentage);
      return roundedPercentage;
    },

    async setupSnapshotListenerSchoolHrFunnel() {
      const id = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "schools"
      );
      // console.log("inside snapshot", id);
      if (!id) {
        // console.log("isnide if dashboard");
        this.schoolChartData = this.emptySchoolChartData;
        return;
        // console.log("isnide snap", this.schoolChartData);
      }

      const q = query(id, where("name", "==", this.roleSchool));
      // const data = await getDocs(id);
      // console.log("data",data)
      // var data;
      // console.log("query??", q);
      if (!q.exists) {
        // console.log("isnide if query");
        this.schoolChartData = this.emptySchoolChartData;
        return;
        // console.log("isnide snap", this.schoolChartData);
      }
      // console.log("get Docs schools?>>", q);

      onSnapshot(q, (snapshot) => {
        snapshot.forEach((doc) => {
          // console.log("data teachers###", doc.data());
          if (
            doc.data().totalInvited === undefined ||
            doc.data().totalScreeningStarted === undefined ||
            doc.data().totalScreeningPassed === undefined ||
            doc.data().totalScreeningFailed === undefined ||
            doc.data().totalMainsFailed === undefined ||
            doc.data().totalMainsPassed === undefined ||
            doc.data().totalMainsStarted === undefined
          ) {
            // console.log("inside snapshot teacher>>>");
            this.schoolChartData = this.emptySchoolChartData;
            // console.log("isnide snap", this.schoolChartData);
          } else {
            this.schoolChartData = doc.data();
          }
        });
      });
    },
    async setupSnapshotListenerJobSeekersSchoolHrFunnel() {
      const id = doc(
        db,
        "dashboards",
        this.$store.state.role.id + "-jobseekers-dashboard",
        "results",
        "campaignresults"
      );

      onSnapshot(id, (snapshot) => {
        const data = snapshot.data();

        if (!data) {
          this.schoolJobSeekerChartData = this.emptySchoolJobSeekerChartData;
          return;
        }

        if (
          data.invited === undefined ||
          data.screeningStarted === undefined ||
          data.screeningPassed === undefined ||
          data.screeningFailed === undefined ||
          data.mainsFailed === undefined ||
          data.mainsPassed === undefined ||
          data.mainsStarted === undefined ||
          data.demoSubmitted === undefined ||
          data.interviewSubmitted === undefined
        ) {
          this.schoolJobSeekerChartData = this.emptySchoolJobSeekerChartData;
        } else {
          this.schoolJobSeekerChartData = data;
        }
      }, (error) => {
        console.error("Error fetching snapshot:", error);
        this.schoolJobSeekerChartData = this.emptySchoolJobSeekerChartData;
      });
    },

    async setupSnapshotListenerTeacherClusterFunnel() {
      const id = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "clusters"
      );
      console.log("chk1");
      // console.log("kdjkbcsjbjc", id);
      if (!id) {
        console.log("chk2");
        // console.log("isnide if dashboard");
        this.clusterTeacherChartData = this.emptyClusterTeacherChartData;
        return;
        // console.log("isnide snap", this.schoolChartData);
      }

      // const idData=await getDocs(id);
      // idData.forEach((doc)=>{
      //   console.log("idData...", doc.id, doc.data())
      // })

      // console.log("idData", idData);

      const q = query(id, where("name", "==", this.roleCluster));
      // console.log("this_cluster", q.exists);

      // console.log("this.roleCluster", this.roleCluster)
      if (!q.exists) {
        console.log("chk3");
        //console.log("isnide if query");
        this.clusterTeacherChartData = this.emptyClusterTeacherChartData;
        return;
        // console.log("isnide snap", this.schoolJobSeekerChartData);
      }

      const data = await getDocs(q);
      console.log("abcdef", data);

      // console.log("data current >>", data.docs[0].id);
      const schoolRef = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "clusters",
        data.docs[0].id,
        "schools"
      );

      // console.log("schoolRef", schoolRef)
      if (!(await getDocs(schoolRef))) {
        // console.log("isnide if Adil");
        this.clusterTeacherChartData = this.emptyClusterTeacherChartData;
        return;
      }
      console.log("outSidegetdocs");
      onSnapshot(schoolRef, (snapshot) => {
        this.clusterTeacherChartData = {
          totalInvited: 0,
          totalScreeningStarted: 0,
          totalScreeningPassed: 0,
          totalScreeningFailed: 0,
          totalMainsStarted: 0,
          totalMainsPassed: 0,
          totalMainsFailed: 0,
        };
        snapshot.forEach((doc) => {
          if (
            doc.data().totalInvited === undefined ||
            doc.data().totalScreeningStarted === undefined ||
            doc.data().totalScreeningPassed === undefined ||
            doc.data().totalScreeningFailed === undefined ||
            doc.data().totalMainsFailed === undefined ||
            doc.data().totalMainsPassed === undefined ||
            doc.data().totalMainsStarted === undefined
          ) {
            this.clusterTeacherChartData = this.emptyClusterTeacherChartData;
            // console.log("bskdbcj", this.clusterTeacherChartData);
          } else {
            console.log("ak###", doc.data());
            this.clusterTeacherChartData.totalInvited +=
              doc.data().totalInvited;
            this.clusterTeacherChartData.totalScreeningStarted +=
              doc.data().totalScreeningStarted;
            this.clusterTeacherChartData.totalScreeningPassed +=
              doc.data().totalScreeningPassed;
            this.clusterTeacherChartData.totalScreeningFailed +=
              doc.data().totalScreeningFailed;
            this.clusterTeacherChartData.totalMainsStarted +=
              doc.data().totalMainsStarted;
            this.clusterTeacherChartData.totalMainsPassed +=
              doc.data().totalMainsPassed;
            this.clusterTeacherChartData.totalMainsFailed +=
              doc.data().totalMainsFailed;
            console.log("bhcjsbdc", this.clusterTeacherChartData);
          }
        });
      });
    },
    async setupSnapshotListenerJobSeekersClusterFunnel() {
      const roleId = this.$store.state.role.id;
      const dashboardRef = doc(db, "dashboards", `${roleId}-jobseekers-dashboard`);

      if (!dashboardRef) {
          this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
          return;
      }

      const resultsRef = collection(dashboardRef, "results");

      if (!resultsRef) {
          this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
          return;
      }

      const campaignDocRef = doc(resultsRef, "campaignresults");

      if (!campaignDocRef) {
          this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
          return;
      }

      onSnapshot(campaignDocRef, (docSnapshot) => {
          if (!docSnapshot.exists()) {
              this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
              return;
          }

          const data = docSnapshot.data();

          if (
              data.invited === undefined ||
              data.screeningStarted === undefined ||
              data.screeningPassed === undefined ||
              data.screeningFailed === undefined ||
              data.mainsStarted === undefined ||
              data.mainsPassed === undefined ||
              data.mainsFailed === undefined ||
              data.demoSubmitted === undefined ||
              data.interviewSubmitted === undefined
          ) {
              this.clusterJobSeekersChartData = this.emptyClusterJobSeekersChartData;
          } else {
              this.clusterJobSeekersChartData = {
                  totalJobSeekersInvited: data.invited,
                  totalJobSeekersScreeningStarted: data.screeningStarted,
                  totalJobSeekersScreeningPassed: data.screeningPassed,
                  totalJobSeekersScreeningFailed: data.screeningFailed,
                  totalJobSeekersMainsStarted: data.mainsStarted,
                  totalJobSeekersMainsPassed: data.mainsPassed,
                  totalJobSeekersMainsFailed: data.mainsFailed,
                  totalJobSeekersDemoSubmitted: data.demoSubmitted,
                  totalJobSeekersInterviewSubmitted: data.interviewSubmitted,
              };
          }
      });
  },

    formatInputDate(inputDate) {
      const date = new Date(inputDate);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      return `${day} ${month} ${year}`;
    },

    async teacherMainsBookingAdminSnapshot() {
      const campaignRef = collection(
        db,
        "dashboards",
        this.$store.state.role.id + "-vgos-teachers-dashboard",
        // "123456-vgos-teachers-dashboard",
        "mainsbookings"
      );
      onSnapshot(campaignRef, (snapshot) => {
        this.mainsBookingTeacherSchool.clear();
        snapshot.docs.forEach(async (doc) => {
          const clusterData = doc.data();
          const clusterCountValue = clusterData.userCount;
          // const mainsBookingTeacher = [doc.id, clusterCountValue];
          // console.log("pooh...>>",mainsBookingTeacher);
          this.mainsBookingTeacherSchool.set(doc.id, clusterCountValue);
          // this.mainsBookingTeacherSchool.push(mainsBookingTeacher);
        });
      });
    },

    async jobSeekerMainsBookingAdminSnapshot() {
      const campaignRef = collection(
        db,
        "dashboards",
        this.$store.state.role.id + "-jobseekers-dashboard",
        // "123456-jobseekers-dashboard",
        "mainsbookings"
      );
      onSnapshot(campaignRef, (snapshot) => {
        this.mainsBookingJobSeekerSchool.clear();
        snapshot.docs.forEach(async (doc) => {
          const clusterData = doc.data();
          const clusterCountValue = clusterData.userCount;
          // const mainsBookingJobSeeker = [doc.id, clusterCountValue];
          // console.log("pooh...>>",mainsBookingJobSeeker);
          this.mainsBookingJobSeekerSchool.set(doc.id, clusterCountValue);
          // this.mainsBookingJobSeekerSchool.push(mainsBookingJobSeeker);
        });
      });
    },

    // async jobSeekerMainsBookingAdminSnapshot() {
    //   const campaignRef = collection(db, "users"); // const schoolMarks = {}
    //   //console.log("abc...>>>");
    //   onSnapshot(campaignRef, (snapshot) => {
    //     this.mainsBookingJobSeekerSchool.clear();
    //     snapshot.docs.forEach(async (doc) => {
    //       // if teacher, run this
    //       const userData = doc.data();
    //       //console.log("userData....>>>>>",userData);
    //       let user = 0;
    //       if (
    //         userData.userType.toLowerCase() === "job seeker" &&
    //         userData.mainsSlotDetails !== undefined &&
    //         userData.schoolName == this.$store.state.role.schools
    //       ) {
    //         try {
    //           const date = userData.mainsSlotDetails.date;

    //           const formattedDate = this.formatInputDate(date);
    //           //console.log("RashidPerfect..>>>",formattedDate);
    //           if (!(formattedDate in this.mainsBookingJobSeekerSchool)) {
    //             this.mainsBookingJobSeekerSchool.set(formattedDate, 1);
    //           } else {
    //             const temp =
    //               this.mainsBookingJobSeekerSchool.get(formattedDate);
    //             this.mainsBookingJobSeekerSchool.set(formattedDate, temp + 1);
    //           }
    //         } catch (error) {
    //           console.error("Error fetching audience collection:", error);
    //         }
    //       }
    //     });
    //   });
    // },

    async schoolLevelLiveCampaignSnapshot() {
      const currentDate = new Date();
      //console.log("hii",currentDate);
      const campaignRef = collection(db, "campaigns");

      onSnapshot(campaignRef, (snapshot) => {
        snapshot.docs.forEach(async (doc) => {
          const newDateForm = new Date(
            doc.data().mainsEndDate + "T" + doc.data().mainsEndTime + "Z"
          );
          const newDate = new Date(
            doc.data().startDate + "T" + doc.data().campaignStartTime + "Z"
          );
          // console.log(newDateForm);
          // console.log(newDate);
          if (
            doc.data().audienceType?.toLowerCase() === "teacher" &&
            doc.data().schools.includes(this.$store.state.role.schools)
          ) {
            if (newDateForm < currentDate) {
              this.expiredCampaignCount += 1;
              // console.log("this is expired", this.expiredCampaignCount);
            } else if (doc.data().startDate && currentDate < newDate) {
              this.upcomingCampaignCount += 1;
              // console.log("this is upcoming", this.upcomingCampaignCount);
            } else {
              this.liveCampaignCount += 1;
            }
          }
        });
      });
    },

    async schoolLevelLiveCampaignJobSeekersSnapshot() {
      const currentDate = new Date();
      //console.log("hii",currentDate);
      const campaignRef = collection(db, "campaigns");

      onSnapshot(campaignRef, (snapshot) => {
        snapshot.docs.forEach(async (doc) => {
          const newDateForm = new Date(
            doc.data().mainsEndDate + "T" + doc.data().mainsEndTime + "Z"
          );
          const newDate = new Date(
            doc.data().startDate + "T" + doc.data().campaignStartTime + "Z"
          );
          // console.log(newDateForm);
          // console.log(newDate);
          if (
            doc.data().audienceType?.toLowerCase() === "job_seeker" &&
            doc.data().schools.includes(this.$store.state.role.schools)
          ) {
            if (newDateForm < currentDate) {
              this.expiredCampaignCountJB += 1;
              // console.log("this is expired", this.expiredCampaignCount);
            } else if (doc.data().startDate && currentDate < newDate) {
              this.upcomingCampaignCountJB += 1;
              // console.log("this is upcoming", this.upcomingCampaignCount);
            } else {
              this.liveCampaignCountJB += 1;
            }
          }
        });
      });
    },

    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;

      // if (this.roleSchool == undefined) {
      //   AuthService.logout();
      //   this.$router.push("/login");
      // }
      // if (this.roleCluster != "All Clusters") {
      //   this.campaignData.clusters = [this.roleCluster];
      //   this.getClusterSchool();
      // }
      // if (this.roleSchool != "All") {
      //   this.campaignData.schools = [this.roleSchool];
      // }
    },
    // For Super Admin - Job seeker campaign snapshot
    async adminLevelJobSeekerCampaignSnapshot() {
      try {
        const todayDate = new Date().toISOString();
        const todayDatevalue = this.formatDate(todayDate);

        const campaignQuery = collection(db, 'campaigns');

        onSnapshot(campaignQuery, async (snapshot) => {
          if (snapshot.empty) {
            return;
          }

          let liveCampaign = [];
          let scheduledCampaign = [];
          let expiredCampaign = [];
          let totalCampaigns = 0;

          const audiencePromises = snapshot.docs.map(async (campaignDoc) => {
            const campaignData = campaignDoc.data();
            if (campaignData.campaignConfigration != null && campaignData.isAudienceUpdated && campaignData.audienceType?.toLowerCase() === "job_seeker") {
              const audienceRef = collection(db, 'campaigns', campaignDoc.id, 'audience');
              const audienceSnapshot = await getDocs(audienceRef);

              let audienceData = [];
              if (!audienceSnapshot.empty) {
                audienceData = audienceSnapshot.docs.map((audienceDoc) => audienceDoc.data());
              }
              return {
                ...campaignData,
                audience: audienceData,
              };
            }
            return null;
          });

          const campaigns = await Promise.all(audiencePromises);

          campaigns.forEach(campaignWithAudience => {
            if (campaignWithAudience) {
              totalCampaigns++;
              const valid = this.compareDates(todayDatevalue, campaignWithAudience.startDate, campaignWithAudience.mainsEndDate);
              if (valid === 'Live') {
                liveCampaign.push(campaignWithAudience);
              } else if (valid === 'Scheduled') {
                scheduledCampaign.push(campaignWithAudience);
              } else if (valid === 'Expired') {
                expiredCampaign.push(campaignWithAudience);
              }
            }
          });

          // Update donut array counts
          this.donutArrayAdminJobSeeker = [totalCampaigns, liveCampaign.length, expiredCampaign.length ]
        }, (error) => {
          console.error("Error in onSnapshot:", error);
        });
      } catch (error) {
        console.error("Error in adminLevelJobSeekerCampaignSnapshot:", error);
      }
    }

  },
  // computed: {
  //   ...mapState({
  //     role: state => state.role,

  //   }),

  // },
  created() {
    //console.log("ROLESSSSS....>>",this.$store.state.role.id);
    // this.setupSnapshotListener();
    this.setUpTotalUsersParticipatedAtCluster();
    this.setUpTotalTeacherParticipatedAtCluster();
    this.jobSeekersSchoolSnapshotListener();
    this.setupclusterTeacherCountSnapshot();
    this.schoolLevelLiveCampaignJobSeekersSnapshot();
    this.schoolLevelLiveCampaignSnapshot();
    this.jobSeekerMainsBookingAdminSnapshot();
    this.teacherMainsBookingAdminSnapshot();
    this.extractClusterData();
    this.schoolCountSchoolListener();
    this.schoolCountClusterListener();
    this.teacherClusterSnapshotListener();
    this.jobSeekerCountListener();
    this.schoolCountListener();
    this.getClusterOrSchool();
    this.setupclusterJobSeekerSnapshot();
    this.setupAdminJobSeekerSnapshot();
    this.jobSeekerCountClusterListener();
    this.jobSeekerCountSchoolListener();
    this.jobSeekerAdminSnapshot();
    this.setupschoolSnapshotListener();
    this.campaignSnapshotListener();
    this.setupclusterSnapshotListener();
    this.setupSnapshotListenerFunnel();
    this.setupSnapshotListenerJobSeekerFunnel();
    this.schoolSnapshotListener();
    this.totalSchoolsParticipated();
    this.teacherSnapshotListener();
    this.setupSnapshotListenerSchoolHrFunnel();
    this.setupSnapshotListenerJobSeekersSchoolHrFunnel();
    this.jobSeekersClusterSnapshotListener();
    this.setupSnapshotListenerTeacherClusterFunnel();
    // this.setupSnapshotListenerTeacherClusterFunnel();
    this.setupSnapshotListenerJobSeekersClusterFunnel();
    this.newTeacherFunnelChartDataListener();
    this.newJobSeekerFunnelChartDataListener();
    this.adminLevelJobSeekerCampaignSnapshot()
  },
  watch: {
    selectedItem(newValue, oldValue) {
      console.log("changing ");
      console.log("teachers", this.teachers);
      this.topSchools = this.schools.slice(0, this.selectedItem);
      console.log("teachers", this.topSchools);
    },
    isFullScreen(newValue) {
      // Update the body background color when full-screen mode is active
      if (newValue) {
        document.body.style.backgroundColor = this.fullscreenBackgroundColor;
      } else {
        // Revert to the default background color when exiting full-screen mode
        document.body.style.backgroundColor = "";
      }
    },
  },

  mounted() {
    console.log("imp",this.roleSchool,this.roleCluster)
    if (this.$store.state.role.createdOn == undefined) {
      this.logout();
    }
    this.roleName = this.$store.state.role.roleName || "";
    console.log(
      "test !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",
      this.$store.state.role.roleName
    );
  },
};
</script>

<style scoped>
.heading {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-left:0px;
}

/* .custom-table th.v-data-table__thead > tr > th {
  background-color: #f0f0f0;
  
} */

.dimension {
  margin-top: 16px !important;
}

.chart-row {
  padding-left: 48px !important;
  height: 43.5vh !important;
  width: 100%;
}

.sec-col {
  background-color: white;
  border-radius: 8px;
  /* margin-top: 15px; */
  height: 100%;
}

.sec-col-school {
  background-color: white;
  border-radius: 8px;
  /* margin-top: 15px; */
  height: 38vh;
}

.school-box {
  height: 31px;
  /* padding: 8px; */
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  background-color: white;
  margin-left: 35px;
  width: calc(100% - 40px);
  /* justify: space-between; */
}

.custom-data-table[data-v-9ea40744] {
  background-color: white;
}

thead .v-data-table-header {
  background: white !important;
}

.margin-betwen-col {
  margin: 8px 8px 8px 8px;
}

/* .margin-betwen-col-2 {
  margin: 2px 2px 2px 2px;
} */

.col3 {
  height: 100%;
  /* padding-right: 0; */
}

.col3-card {
  width: 100%;
  height: 11vh;
  margin-bottom: 2.263vh;
}

.dashboard > table > thead > tr {
  background-color: white !important;
}

.funnel-contianer {
  display: flex;
  height: 33.3vh;
}

.tbl > .t-head > .t-row > .head {
  background-color: white;
}

.t-row {
  background-color: white !important;
}

.tbl2 > .t-head > .t-row > .head {
  background-color: white;
}

.tbl {
  height: 23vh;
  width: 100%;
  text-align: left;
  font-size: 14px;
}

.tbl3 {
  height: 25.7vh;
  width: 100%;
  text-align: left;
}

.scroll-table {
  height: 170px;
  overflow-y: scroll;
}

.tbl2 {
  height: 33vh;
  width: 100%;
  text-align: left;
}

.tblClusterJob {
  height: 30vh;
  width: 100%;
  text-align: left;
}

.t-body {
  height: auto;
}

.tbl2 > .t-body > .t-row {
  background-color: white;
}

.tbl > .t-body > .t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl > .t-body > .t-row > .t-data {
  text-align: center;
}

.tbl > tr:nth-child(even) {
  background-color: white !important;
}

.tbl2 > tr:nth-child(even) {
  background-color: white !important;
}

.total-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  height: 31px;
  background-color: #4d695b;
  border-radius: 4px;
  padding: 0 8px 0 8px;
}

.chart-icon {
  position: absolute;
  bottom: 40px;
  z-index: 1000;
  align-self: center;
}

.red-text {
  color: red;
}

.green-text {
  color: green;
}

.carousel-buttons {
  display: flex;
  justify-content: center;
  margin-top: -30px;
  /* Adjust this value as desired */
}

.carousel-button {
  margin: 0 2px;
  height: auto;
  /* min-width: 15px; */
  width: 28px;
  height: 16px;
  max-width: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  font-size: 12px;
  /* Adjust font size as needed */
}

.carousel-button.active {
  background-color: brown;
  color: white;
}

.chip-button-label {
  line-height: 22px;
  padding: 0 8px;
}

.custom-carousel .v-carousel__controls {
  display: none !important;
}


.decrease-top-spacing-teacher, .decrease-top-spacing-jobSeeker {
  padding: 0 10px;
  /* Adjust the value as desired */
}


.decrease-top-spacing-school {
  margin-top: -20px;
  margin-bottom: -8px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circle-outline {
  display: flex;
  align-items: center;
  width: 100px;
  height: 10px;
  border-radius: 15px;
  padding: 5px;
  text-align: center;
  color: #000;
}

.circle-radius {
  border-radius: 10%;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.circle-outline {
  display: flex;
  align-items: center;
  width: 100px;
  height: 8px;
  border-radius: 15px;
  padding: 5px;
  text-align: center;
  color: #000;
}

.circle-radius {
  border-radius: 10%;
}

.flag-spacing {
  margin-left: 10px;
}

.flag-icon {
  width: 25px;
  height: 25px;
  margin-right: 0;
  padding-top: 5px;
  padding-right: 5px;
}

.no-space {
  margin-left: 0;
}

.fullScreenSize {
  position: fixed;
  left: 30px;
  right: 30px;
  top: 100px;
  /* left: -100px; */
}

.bookingBox {
  height: 100%;
  overflow: hidden;
}

.bookMargin {
  margin-bottom: -8px !important;
}

.home-view-container {
  padding: 0 16px 0 32px;
  th {
    font-size: 14px;
    letter-spacing: 0;
  }
}

.v-application .blue--text {
  font-weight: 600;
  padding: 0;
}

.theme--light.v-btn:focus::before, .theme--light.v-btn:hover::before {
  opacity: 0;
}

.v-application .text-h6 {
  padding: 0;
}

.left-header, 

.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa; /* Adjust as needed */
  border: 1px solid #ddd; /* Adjust as needed */
  border-radius: 8px; /* Adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  transition: transform 0.2s, box-shadow 0.2s; /* Adds transition for hover effect */
}

.coming-soon-text {
  background-color: #000;
  font-weight: bold; /* Bold text */
  text-align: center; /* Center the text */
  padding: 0 10px; /* Add some padding */
  -webkit-background-clip: text; /* Clip background to text */
  -webkit-text-fill-color: transparent; /* Make text transparent to show gradient */
}

</style>