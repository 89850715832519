<template>
  <div>
        <!-- Filter Dialog -->
        <v-dialog v-model="filterDialog" class="cdz" max-width="570px" style="z-index:1000 !important">
          <v-card width="570px" height="100vh" class="filterDialogClass">
            <v-row class="mt-4 mx-1">
              <v-col>
                <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                  Filter
                </div>
              </v-col>
              <v-col class="d-flex justify-end">
                <div
                  @click="clearFilter"
                  class="text-body1 font-weight-bold black--text cursor"
                >
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>
            <v-tabs vertical>   
              <div class="ml-3 mt-4 mr-4 filterLine">
                <v-tab key="stage" class="black--text justify-start" active-class="activeTab">
                  Stage
                  <span v-if="selectedStageFilter.length > 0" class="ml-1">
                  ({{selectedStageFilter.length}})</span>
                </v-tab>
                <!-- <v-tab key="CoreSkillsSubject" class="black--text justify-start" active-class="activeTab">
                  League
                  <span v-if="selectedLeagueFilter.length > 0" class="ml-1">
                    ({{ selectedLeagueFilter.length }})</span>
                </v-tab> -->
              </div>
    
              <v-tab-item key="stage">
                <v-card>
                  <v-card-text>
                    <v-chip-group v-model="selectedStageFilter" active-class="secondary" column :multiple="true">
                      <v-chip v-for="(stage,index) in stageOptions" 
                      :key="index"
                      :value="stage"
                      elevated>
                        {{stage}}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item>
    
              <!-- <v-tab-item key="CoreSkillsSubject">
                <v-card>
                  <v-card-text>
                    <v-chip-group v-model="selectedLeagueFilter" active-class="secondary" column :multiple="true">
                      <v-chip v-for="(league,index) in leagueOptions" 
                      :key="index"
                      :value="league"
                      elevated>
                        {{league}}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-tab-item> -->
            </v-tabs>
            <div class="filterDialogButton">
              <v-card-actions class="px-6 pb-0">
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  outlined
                  class="accent--text pa-4"
                  @click="closeFilter"
                  >Cancel</v-btn
                >
                <v-btn
                  class="accent pa-4"
                  :disabled="selectedStageFilter.length==0 "
                  @click="filterTeachers(selectedStageFilter),getFilterCount()">Apply</v-btn
                >
              </v-card-actions>
            </div>
          </v-card>                
        </v-dialog>
    <v-col>
      <v-card height="64px" width="100%"
        class="mx-4 pl-3 pt-5 pr-4 background elevation-0 d-flex flex-row justify-space-between align-center fixBar">
        <v-card-title class="pl-0 ml-0">
          <span @click="$router.push('/')"
            class="breadcrumb-text-unselected underline-on-hover cursor">Dashboard</span>

          <span><v-icon class="breadcrumb-arrow-unselected">mdi-chevron-right</v-icon></span>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="breadcrumb-text">
                Campaign Result
                <v-icon class="breadcrumb-arrow cursor">mdi-chevron-down</v-icon>
              </span>
            </template>

            <v-list>
              <v-list-item @click="$router.push('/jobseekersappraisal')">No. of users recommended</v-list-item>
              <v-list-item @click="$router.push('/jobseekerspip')">No. of users rejected</v-list-item>
              <v-list-item @click="$router.push('/jobseekerstbd')">No. of teachers on TBD</v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <div class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center fixLog">
          <div>
            <img src="../assets/avtar.png" class="rounded-xl mr-2" width="24px" />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on, attrs }">
              <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>

      <div class="pt-12">

        <div class="background fixTop30 ml-2 pt-4">

          <v-container class="funnel-contianer pl-0 pr-7 ml-3" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      JOB SEEKERS
                    </p>
                  </v-card-title>

                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Users Registered/Invited</span>
                    <FunnelChartCommon :invites="jobSeekerChartData" scaleMax="jobSeekerInvited" propertyBegin="jobSeekerInvited" propertyEnd="jobSeekerScreeningStarted" funnelColor="#E0ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                       <div 
                         class="red--text pl-2"
                         style="display: inline-block; margin-bottom: 0">
                         {{
                           calculatePercentage(
                             jobSeekerChartData.jobSeekersDemoSubmitted -
                             jobSeekerChartData.jobSeekersInterviewSubmitted,
                             jobSeekerChartData.jobSeekersDemoSubmitted
                           )
                         }}%
                       </div>
                     </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="jobSeekerChartData" scaleMax="jobSeekerInvited" propertyBegin="jobSeekerScreeningStarted" propertyEnd="jobSeekerScreeningPassed" funnelColor="#C3ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                       <div 
                         class="red--text pl-2"
                         style="display: inline-block; margin-bottom: 0">
                         {{
                           calculatePercentage(
                             jobSeekerChartData.jobSeekersDemoSubmitted -
                             jobSeekerChartData.jobSeekersInterviewSubmitted,
                             jobSeekerChartData.jobSeekersDemoSubmitted
                           )
                         }}%
                       </div>
                     </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING RESULT
                    </p>
                  </v-card-title>
                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="jobSeekerChartData" scaleMax="jobSeekerInvited" propertyBegin="jobSeekerScreeningPassed" propertyEnd="jobSeekerMainsStarted" funnelColor="#B0D644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                       <div 
                         class="red--text pl-2"
                         style="display: inline-block; margin-bottom: 0">
                         {{
                           calculatePercentage(
                             jobSeekerChartData.jobSeekersDemoSubmitted -
                             jobSeekerChartData.jobSeekersInterviewSubmitted,
                             jobSeekerChartData.jobSeekersDemoSubmitted
                           )
                         }}%
                       </div>
                     </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">MAINS</p>
                  </v-card-title>


                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="jobSeekerChartData" scaleMax="jobSeekerInvited" propertyBegin="jobSeekerMainsStarted" propertyEnd="jobSeekerMainsPassed" funnelColor="#6AD644"/>

                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                       <div 
                         class="red--text pl-2"
                         style="display: inline-block; margin-bottom: 0">
                         {{
                           calculatePercentage(
                             jobSeekerChartData.jobSeekersDemoSubmitted -
                             jobSeekerChartData.jobSeekersInterviewSubmitted,
                             jobSeekerChartData.jobSeekersDemoSubmitted
                           )
                         }}%
                       </div>
                     </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="jobSeekerChartData" scaleMax="jobSeekerInvited" propertyBegin="jobSeekerMainsPassed" propertyEnd="jobSeekersDemoSubmitted"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                       <div 
                         class="red--text pl-2"
                         style="display: inline-block; margin-bottom: 0">
                         {{
                           calculatePercentage(
                             jobSeekerChartData.jobSeekersDemoSubmitted -
                             jobSeekerChartData.jobSeekersInterviewSubmitted,
                             jobSeekerChartData.jobSeekersDemoSubmitted
                           )
                         }}%
                       </div>
                     </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">DEMO</p>
                  </v-card-title>

                  

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Submitted</span
                    >
                    <FunnelChartCommon :invites="jobSeekerChartData" scaleMax="jobSeekerInvited" propertyEnd="jobSeekersInterviewSubmitted" propertyBegin="jobSeekersDemoSubmitted"/>

                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                       <div 
                         class="red--text pl-2"
                         style="display: inline-block; margin-bottom: 0">
                         {{
                           calculatePercentage(
                             jobSeekerChartData.jobSeekersDemoSubmitted -
                             jobSeekerChartData.jobSeekersInterviewSubmitted,
                             jobSeekerChartData.jobSeekersDemoSubmitted
                           )
                         }}%
                       </div>
                     </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <!-- <v-col cols="2.5">
                  <FunnelChart/>
                 </v-col>
                 <v-col cols="2.5">
                  <FunnelChart/>
                 </v-col>
                 <v-col cols="2.5">
                  <FunnelChart/>
                 </v-col> -->
          </v-container>

          <v-row class="d-flex justify-center pt-0 my-0 ml-3 " fluid>
            <v-col cols="4" md="4" sm="4" class="pl-1">
              <div class="text-h6 font-weight-bold highEmphasis">
                No. of Jobseekers on TBD ({{ rejectedJobSeekersCount }})
              </div>
            </v-col>

            <v-col cols="8" md="8" sm="8" class="d-flex flex-row justify-end">
              <v-card class="elevation-0 ma-0 pa-0 transparent">
                <v-text-field solo hide-details label="Search here..." prepend-inner-icon="mdi-magnify" v-model="search"
                  class="border-button rounded-pill mx-3" dense depressed clearable></v-text-field>
              </v-card>

              <v-btn depressed rounded outlined @click="showfilterdialogue" :disabled="topTeacherInCluster.length==0" class="border-button mx-3"><v-icon>mdi-tune</v-icon>
                <span v-if="filterCount>0">FILTER({{filterCount}})</span>
                <span v-else>FILTER</span>
              </v-btn>
              <!-- <v-btn depressed rounded outlined class="border-button mx-3"
            ><img src="../../public/svgs/SORT.svg" alt="" />SORT</v-btn
          > -->

              <!-- <v-btn
                class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
              <v-btn depressed outlined rounded @click="downloadJobSeekersTbd"  :disabled="topTeacherInCluster.length==0" class="search-bar border-button mx-3"><v-icon>mdi-import
                  mdi-rotate-90</v-icon></v-btn>
            </v-col>
          </v-row>

        </div>

        <v-card elevation="0" class="ml-6 mr-4 mt-0">
          <v-card-title class="text-subtitle-1 pa-0">
            <table class="tbl">
              <thead class="t-head">
                <tr class="t-row">
                  <th class="head">S.No</th>
                  <th class="head">Full Name</th>
                  <th class="head">Campaign</th>
                  <th class="head">School Name</th>
                  <th class="head">Stage</th>
                  <th class="head">Status</th>
                  <th class="head">Screening</th>
                  <th class="head">Mains</th>
                </tr>
              </thead>
              <tbody class="t-body">
                <tr class="t-row" v-for="(teacher, index) in displayedClusterTeachers" :key="teacher.id">
                  <td class="t-data">{{ index + 1 }}</td>
                  <td class="t-data">
                    {{ `${teacher.firstName} ${teacher.lastName}` }}
                  </td>
                  <td class="t-data">{{ teacher.campaignName }}</td>
                  <td class="t-data">{{ teacher.schoolName }}</td>
                  <td class="t-data">
                    {{
                      teacher?.stage === "Screening" ||
                      teacher?.stage === "Mains"
                      ? teacher.stage
                      : "Invited"
                    }}
                  </td>
                  <td class="t-data">
                    <v-chip class="pass-color" text-color="#06C270" outlined v-if="teacher?.status === 'SCREENING PASSED' ||
                        teacher?.status === 'MAINS PASSED'
                        ">
                      <div class="dot-pass"></div>PASSED
                    </v-chip>
                    <template v-else-if="teacher?.status==='INVITED'">INVITED</template>
                    <template v-else>FAILED</template>
                  </td>
                  <td class="t-data">
                    {{ teacher.screeningTotal }}
                  </td>
                  <td class="t-data">
                    {{ teacher.mainsTotal }}
                  </td>
                </tr>
              </tbody>
              <!-- <tbody class="t-body">
              <tr class="t-row">
                <td class="t-data">1</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img
                    src="../../public/svgs/u_map-marker.svg"
                    alt=""
                    srcset=""
                  />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Screening</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                  <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">68%</td>
                <td class="t-data">-</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">2</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img
                    src="../../public/svgs/u_map-marker.svg"
                    alt=""
                    srcset=""
                  />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                     <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">3</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img
                    src="../../public/svgs/u_map-marker.svg"
                    alt=""
                    srcset=""
                  />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                     <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">4</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img
                    src="../../public/svgs/u_map-marker.svg"
                    alt=""
                    srcset=""
                  />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                     <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">5</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img
                    src="../../public/svgs/u_map-marker.svg"
                    alt=""
                    srcset=""
                  />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                     <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
              <tr class="t-row">
                <td class="t-data">6</td>
                <td class="t-data">Rashid Ali</td>
                <td class="t-data">Campaign 1</td>
                <td class="t-data d-flex align-center justify-center">
                  <img
                    src="../../public/svgs/u_map-marker.svg"
                    alt=""
                    srcset=""
                  />View Map
                </td>
                <td class="t-data">VGOS- Malad</td>
                <td class="t-data">Mains</td>
                <td class="t-data d-flex h-100 align-center justify-center">
                     <v-chip class="ma-1 yetToAttempt-color" text-color="#000000DE" size="x-small">
                    <div class="dot-yetToAttempt"></div>
                    Yet To Attempt
                  </v-chip>
                </td>
                <td class="t-data">74%</td>
                <td class="t-data">74%</td>
              </tr>
            </tbody> -->
            </table>
          </v-card-title>
        </v-card>

      </div>

    </v-col>
  </div>
</template>
    
<script >
import FunnelChart from "./FunnelChart.vue";
import AuthService from "@/services/AuthService";
import { collection, getDocs, onSnapshot, doc } from "firebase/firestore";
import { db } from "../firebase";
import FunnelChartCommon from "./FunnelChartCommon.vue";
import LeagueController from "@/controllers/LeagueController";
import Papa from "papaparse";

export default {
  name: "CampaignResult",
  components: {
    FunnelChartCommon
  },
  data() {
    return {
      data: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
        },

        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
        },
      ],
      clusters: [],
      schools: [],
      schoolClusterTeacher: [],
      topTeacherInCluster: [],
      invited: 0,
      screeningAttempted: 0,
      screeningResult: 0,
      mainsAttempted: 0,
      result: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      chartData: {},
      jobSeekerChartData: {
        jobSeekerInvited: 100,
        jobSeekerScreeningStarted: 90,
        jobSeekerScreeningPassed: 50,
        jobSeekerScreeningFailed: 40,
        jobSeekerMainsStarted: 30,
        jobSeekerMainsPassed: 20,
        jobSeekerMainsFailed: 10,
        jobSeekersDemoSubmitted: 5,
        jobSeekersInterviewSubmitted: 1,
        },
      emptyJobSeekerChartData: {
        jobSeekerInvited: 0,
        jobSeekerScreeningStarted: 0,
        jobSeekerScreeningPassed: 0,
        jobSeekerScreeningFailed: 0,
        jobSeekerMainsStarted: 0,
        jobSeekerMainsPassed: 0,
        jobSeekerMainsFailed: 0,
        jobSeekersDemoSubmitted: 0,
        jobSeekersInterviewSubmitted: 0,
      },
      recommended: 0,
      jobSeekerRejected: 0,
      jobSeekerTBD: 0,
      filterDialog: false,
      filterCount: 0,
      appliedFilter: false,
      selectedStageFilter : [],
      filteredTeachers: [],
      stageOptions:["Screening","Mains"],
    

      search: "",
      searchedResults:[],
      appliedSearch:false,

      passedJobSeekersCount: 0,
      rejectedJobSeekersCount: 0,
      tdbJobSeekersCount: 0,
      otherJobSeekersCount: 0,
      jobSeekerList: [],

    };
  },
  computed: {
    displayedClusterTeachers() {
      if(this.appliedFilter==false){
        if(this.appliedSearch==true){
          return this.searchedResults
        }
        else{
          return this.topTeacherInCluster;
        }
        }
      else if(this.appliedFilter==true){
        if(this.appliedSearch==true){
          return this.searchedResults
        }
        else{
          return this.filteredTeachers;
        }
      }
    },
  },
  watch:{
    search(newValue){
      if (newValue=='' || newValue == null) {
        this.appliedSearch=false;
      }
      else{
        this.searchData(newValue);
      }
    },
  }, 
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
   

    searchData(search){
      if (this.appliedFilter==true){
        this.searchedResults=this.filteredTeachers.filter((teacher)=>
          new RegExp(search,"i").test(teacher.firstName) || 
          new RegExp(search,"i").test(teacher.lastName) || 
          new RegExp(search,"i").test(`${teacher.firstName} ${teacher.lastName}`)
       );
      }
      else{
        this.searchedResults=this.topTeacherInCluster.filter((teacher)=>
          new RegExp(search,"i").test(teacher.firstName) || 
          new RegExp(search,"i").test(teacher.lastName) || 
          new RegExp(search,"i").test(`${teacher.firstName} ${teacher.lastName}`))
      }
        this.appliedSearch=true;
      },

    showfilterdialogue() {
      this.filterDialog = true;
      // console.log(this.displayedClusterTeacher);
    },

    closeFilter(){
    this.filterDialog = false;
  },

  filterTeachers(selectedStage) {
    this.filteredTeachers=[];
    for (const teacher of this.topTeacherInCluster){
      for (const stage of selectedStage){
        if (teacher.stage==stage){
        this.filteredTeachers.push(teacher);
        }
      }
    }
    this.appliedFilter=true;
    this.filterDialog=false;
  },

    clearFilter() {
      this.appliedFilter = false;
      this.selectedStageFilter = "",
      this.filterCount = 0,
      this.filteredTeachers=[];
      // this.filterDialog = false;
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedStageFilter.length > 0) {
        this.filterCount++;
      }
      // if (this.selectedLeagueFilter.length > 0) {
      //   this.filterCount++;
      // }
    },

    downloadJobSeekersTbd() {
        const fileName = "JobSeekersTBD.csv";
        const dataForDownload = [];
        for (const teacher of this.topTeacherInCluster) {
          const fullName = `${teacher.firstName} ${teacher.lastName}`;
          const campaign = teacher.campaignName;
          const schoolName = teacher.schoolName?teacher.schoolName:"-";
          const campstat = teacher.stage?teacher.stage:"-"

          dataForDownload.push({
              FullName : fullName,
              Campaign : campaign,
              SchoolName : schoolName,
              CurrentStageStatus : campstat
            });
          }
          this.downloadCSV(dataForDownload,fileName);
    },

    async downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },


    // async setupSnapshotListenerJobSeekerFunnel() {
    //   const id = collection(
    //     db,
    //     "dashboards",
    //     "management-dashboard-001",
    //     "jobSeekerCampaign"
    //   );
    //   console.log("inside snapshot", id);
    //   if (id === undefined) {
    //     console.log("isnide if");
    //     this.jobSeekerChartData = this.emptyJobSeekerChartData
    //     console.log("isnide snap", this.jobSeekerChartData);
    //   }

    //   const data = await getDocs(id);
    //   console.log("data>>", data);
    //   if (data === undefined) {
    //     console.log("isnide if");
    //     this.jobSeekerChartData = this.emptyJobSeekerChartData
    //     console.log("isnide snap", this.jobSeekerChartData);
    //   }
    //   console.log("get Docs", data.docs[0]);
    //   const document = data.docs[0]
    //   if (document === undefined) {
    //     console.log("isnide if");
    //     this.jobSeekerChartData = this.emptyJobSeekerChartData
    //     console.log("isnide snap", this.jobSeekerChartData);
    //   } else {
    //     const docRef = doc(id, data.docs[0].id);

    //     onSnapshot(docRef, (snapshot) => {
    //       let funnelData = snapshot.data();

    //       if (
    //         funnelData.jobSeekerInvited === undefined ||
    //         funnelData.jobSeekerScreeningStarted === undefined ||
    //         funnelData.jobSeekerScreeningPassed === undefined ||
    //         funnelData.jobSeekerScreeningFailed === undefined ||
    //         funnelData.jobSeekerMainsStarted === undefined ||
    //         funnelData.jobSeekerMainsPassed === undefined ||
    //         funnelData.jobSeekerMainsFailed === undefined ||
    //         funnelData.jobSeekersDemoSubmitted === undefined ||
    //         funnelData.jobSeekersInterviewSubmitted === undefined 
    //       ) {
    //         console.log("isnide if");
    //         this.jobSeekerChartData = this.emptyJobSeekerChartData
    //         console.log("isnide snap", this.jobSeekerChartData);

    //       } else {
    //         console.log("isnide else:", funnelData);
    //         this.jobSeekerChartData = funnelData;

    //         console.log("inisde snap job", this.jobSeekerChartData);
    //         this.recommended = funnelData.jobSeekerMainsPassed;
    //         this.jobSeekerRejected = funnelData.jobSeekerMainsFailed;
    //         this.jobSeekerTBD = funnelData.jobSeekerScreeningFailed;
    //       }

    //     });
    //   }
    // },

    calculatePercentage(value, total) {
      if (total === 0) {
        // console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // console.log(roundedPercentage);
      return roundedPercentage;
    },

    async jobSeekerSchoolSnapshotListener() {
      const campaignRef = collection(db, "campaigns"); // const schoolMarks = {}
      onSnapshot(campaignRef, (snapshot) => {
        this.passedJobSeekersCount=0
        this.rejectedJobSeekersCount=0
        this.tdbJobSeekersCount=0
        this.otherJobSeekersCount=0
        this.jobSeekerList.splice(0)

        snapshot.docs.forEach(async (doc) => {
          ////console.log("asldfjlasdkfj...>>>", doc.id, ".....", doc.data())
          const campaignName = doc.data().name;
          // if teacher, run this
          // //console.log("doc id >>", doc.id)
          if (doc.data().audienceType.toLowerCase() === "job_seeker") {
            //console.log("job seeker >>", doc.id);
            try {
              const jobSeekerRef = collection(campaignRef, doc.id, "audience");
              const jobSeekDocs = await getDocs(jobSeekerRef);

              jobSeekDocs.forEach((d) => {
                const jobSeekerData=d.data()
                if (jobSeekerData?.campaignStatus?.length === 5) {
                  if (jobSeekerData.campaignStatus[2].status === "SCREENING PASSED" && jobSeekerData.campaignStatus[4].status === "MAINS FAILED") {
                    this.rejectedJobSeekersCount++;
                  }

                  if (jobSeekerData.campaignStatus[2].status === "SCREENING FAILED" && jobSeekerData.campaignStatus[4].status === "MAINS FAILED") {
                    this.tdbJobSeekersCount++;
                  }

                  if (jobSeekerData.campaignStatus[2].status === "SCREENING FAILED" && jobSeekerData.campaignStatus[4].status === "MAINS PASSED") {
                    this.otherJobSeekersCount++;
                  }

                  if (jobSeekerData.campaignStatus[2].status === "SCREENING PASSED" && jobSeekerData.campaignStatus[4].status === "MAINS PASSED") {
                    let flag=true;
                    if (jobSeekerData?.coreSkillsSubject?.length > 0) {
                      for (const dt of jobSeekerData.coreSkillsSubject) {
                        if (dt.league !== "Not Eligible") {
                          flag=false;
                          this.passedJobSeekersCount++;
                          break;
                        }
                      }
                    }
                    if (flag){
                      this.otherJobSeekersCount++;
                    }
                  }
                }

                if (jobSeekerData?.campaignStatus?.length === 5 && jobSeekerData.campaignStatus[2].status === "SCREENING FAILED" && jobSeekerData.campaignStatus[4].status === "MAINS FAILED") {
                  let screeningTotal = 0;
                  let mainsTotal = 0;
                  let screeningNetScore = 0;
                  let mainsNetScore = 0;

                  // Calculate the total scores for screening and mains
                  jobSeekerData.coreSkillsSubjectScreening.forEach((jobSeekerScreeningData) => {
                    // const jobSeekerScreeningData = screeningDoc.data();
                    screeningTotal += parseFloat(jobSeekerScreeningData?.finalNetScore);
                  });
                  screeningTotal = screeningTotal/jobSeekerData.coreSkillsSubjectScreening.length;
                  screeningNetScore = (screeningTotal) ? screeningTotal.toFixed(2) : 0.00;

                  jobSeekerData.coreSkillsSubject.forEach((jobSeekerMainsData) => {
                    // // const jobSeekerMainsData = mainsDoc.data();
                    mainsTotal += parseFloat(jobSeekerMainsData?.finalNetScore);
                  });

                  mainsTotal = mainsTotal/jobSeekerData.coreSkillsSubject.length;
                  mainsNetScore = (mainsTotal) ? mainsTotal.toFixed(2) : 0.00;

                  const temp = {
                    firstName: jobSeekerData?.personalInfo?.firstName ? jobSeekerData.personalInfo.firstName : "",
                    lastName: jobSeekerData?.personalInfo?.lastName ? jobSeekerData.personalInfo.lastName : "",
                    campaignName: campaignName,
                    schoolName: jobSeekerData?.teachingInterests?.schools?.length > 0 ? jobSeekerData.teachingInterests.schools[0] : "",
                    // status: jobSeekerData?.status ? jobSeekerData.status : "",
                    screeningTotal: screeningNetScore,
                    mainsTotal: mainsNetScore,
                    status: jobSeekerData.campaignStatus ? jobSeekerData.campaignStatus[jobSeekerData.campaignStatus.length - 1].status : "INVITED",
                    stage: "Invited",
                    campaignId: doc.id,
                    userId: jobSeekerData.userId
                  }

                  // let stage="Invited"
                  if (temp.status === "SCREENING PASSED" || temp.status === "SCREENING FAILED")
                    temp.stage = "Screening"
                  if (temp.status === "MAINS PASSED" || temp.status === "MAINS FAILED")
                    temp.stage = "Mains"

                  this.jobSeekerList.push(temp);
                }

              })
              // this.topTeacherInCluster = this.schoolClusterTeacher;
              this.topTeacherInCluster=this.jobSeekerList
            } catch (error) {
              console.error("Error fetching audience collection:", error);
            }
          }
        });
      });
    },



    // async jobSeekerSchoolSnapshotListener() {
    //   const campaignRef = collection(db, "campaigns"); // const schoolMarks = {}
    //   onSnapshot(campaignRef, (snapshot) => {
    //     snapshot.docs.forEach(async (doc) => {
    //       //console.log("asldfjlasdkfj...>>>", doc.id, ".....", doc.data())
    //       const campaignName = doc.data().name;
    //       // if teacher, run this
    //       // console.log("doc id >>", doc.id)
    //       if (doc.data().audienceType.toLowerCase() === "job_seeker") {
    //         console.log("job seeker >>", doc.id);
    //         try {
    //           const jobSeekerRef = collection(campaignRef, doc.id, "audience");
    //           const jobSeekDocs = await getDocs(jobSeekerRef);

    //           jobSeekDocs.forEach((d) => {
    //             console.log("jobSeekersDocsTraverse...", d.data());
    //             //countTeacherCluster++;
    //             console.log("screening total >>", d.data().screeningTotal);
    //             const topTeachersClusterData = {
    //               screeningTotal:
    //                 d.data().screeningTotal == null
    //                   ? "-"
    //                   : d.data().screeningTotal,
    //               mainsTotal:
    //                 d.data().mainsTotal == null ? "-" : d.data().mainsTotal,
    //               firstName:
    //                 d.data().personalInfo.firstName == null
    //                   ? ""
    //                   : d.data().personalInfo.firstName,
    //               lastName:
    //                 d.data().personalInfo.lastName == null
    //                   ? ""
    //                   : d.data().personalInfo.lastName,
    //               schoolName:
    //                 d.data().schoolName == null ? "" : d.data().schoolName,
    //               lastStatus: (() => {
    //                 let stageValue = "";
    //                 let statusValue = "";

    //                 if (
    //                   d.data().campaignStatus &&
    //                   d.data().campaignStatus.length > 0
    //                 ) {
    //                   const lastStatus =
    //                     d.data().campaignStatus[
    //                       d.data().campaignStatus.length - 1
    //                     ].status;
    //                   if (lastStatus === "INVITED") {
    //                     stageValue = "Screening";
    //                     statusValue = "Yet to Attempt";
    //                   } else if (lastStatus === "SCREENING PASSED") {
    //                     stageValue = "Mains";
    //                     statusValue = "Yet to Attempt";
    //                   }
    //                 }

    //                 return {
    //                   stageValue: stageValue,
    //                   statusValue: statusValue,
    //                 };
    //               })(),

    //               campaignName: campaignName,
    //             };
    //             this.schoolClusterTeacher.push(topTeachersClusterData);
    //             console.log(
    //               "screening total result show >>",
    //               d.data().screeningTotal
    //             );

    //             // console.log("naresh sirrrrr", this.schoolClusterTeacher);
    //             // data.schoolMarks[d.data().schoolName] += d.data().screeningTotal
    //           });
    //           //this.countTeacherCluster = countTeacherCluster;
    //           let sortedProducts = this.schoolClusterTeacher.sort((p1, p2) =>
    //             p1.screeningTotal < p2.screeningTotal
    //               ? 1
    //               : p1.screeningTotal > p2.screeningTotal
    //                 ? -1
    //                 : 0
    //           );
    //           this.topTeacherInCluster = this.schoolClusterTeacher;
    //         } catch (error) {
    //           console.error("Error fetching audience collection:", error);
    //         }
    //       }
    //     });
    //   });
    // },
  },
  created() {
    this.jobSeekerSchoolSnapshotListener();
    this.setupSnapshotListenerJobSeekerFunnel();
  },
};
</script>
    
<style scoped>
.tbl>.t-head>.t-row>.head {
  background-color: primary;
}

.tbl {
  height: 196px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tbl th {
  padding: 8px;
}

.tbl td {
  padding: 5px;
}

.tbl>.t-body>.t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl>.t-body>.t-row>.t-data {
  text-align: center;
}

.tbl>tr:nth-child(even) {
  background-color: white !important;
}
</style>