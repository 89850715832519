// import { TRUE } from 'sass'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bulkQuestionRes: [],
    levelChart: null,
    assesedChart:[],
    clusterChart: [],
    chartSearch: "",
    role: {},
    updateRole: null,
    userInfo: {
      name: "user",
      email: "dummyemail@gmail.com"
    },
    school:"Vibgyor Roots - PANATHUR",
    cluster:"Cluster 4",
    isNavBar:false,
    user: null,
//     role: {

//       roleName: "newRole",
//       clusterName: "",
//       schools: [],
//       userManagement: {
//           admin: false,
//           read: true,
//       },
//       teacherOnboarding: {
//           admin: false,
//           read: true,
//       },
//       otherAdminOnboarding: {
//           admin: false,
//           read: true,
//       },
//       rolesAndPrivilage: {
//           admin: true,
//           read: true,
//       },
//       masters: {
//           admin: false,
//           read: true,
//       },
//       cluster: {
//           admin: false,
//           read: false,
//       },
//       Brand: {
//           admin: false,
//           read: true,
//       },
//       board: {
//           admin: false,
//           read: true,
//       },
//       school: {
//           admin: false,
//           read: true,
//       },
//       Level: {
//           admin: false,
//           read: true,
//       },
//       Grade: {
//           admin: false,
//           read: true,
//       },
//       subjectCategory: {
//           admin: false,
//           read: true,
//       },
//       subjects: {
//           admin: false,
//           read: true,
//       },
//       skills: {
//           admin: false,
//           read: true,
//       },
//       learningObjective: {
//           admin: false,
//           read: true,
//       },
//       campaign: {
//           admin: false,
//           read: true,
//       },
//       assessment: {
//           admin: false,
//           read: true,
//       },
//       questionBank: {
//           admin: false,
//           read: false,
//       },

//   }
    breadcrumb:"",
    // userDetails: {},
    selectedVideo: {}
  },

  getters: {
  },
  mutations: {
    setChartSearch(state, value) {
      state.chartSearch = value;
    },
    setVideoDetails(state, payload){
      state.selectedVideo = payload
    }
    // other mutations...
  },
  actions: {
  },
  modules: {
  }
})
