<template>
  <div>
      <!-- Filter Dialog -->
      <v-dialog v-model="filterDialog" class="cdz" max-width="570px" style="z-index:1000 !important">
        <v-card width="570px" height="100vh" class="filterDialogClass">
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                Filter
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div
                @click="clearFilter"
                class="text-body1 font-weight-bold black--text cursor"
              >
                CLEAR FILTER
              </div>
            </v-col>
          </v-row>
          <v-tabs vertical>   
            <div class="ml-3 mt-4 mr-4 filterLine">
              <v-tab key="stage" class="black--text justify-start" active-class="activeTab">
                Stage
                <span v-if="selectedStageFilter.length > 0" class="ml-1">
                ({{selectedStageFilter.length}})</span>
              </v-tab>
              <v-tab key="CoreSkillsSubject" class="black--text justify-start" active-class="activeTab">
                League
                <span v-if="selectedLeagueFilter.length > 0" class="ml-1">
                  ({{ selectedLeagueFilter.length }})</span>
              </v-tab>
            </div>
  
            <v-tab-item key="stage">
              <v-card>
                <v-card-text>
                  <v-chip-group v-model="selectedStageFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(stage,index) in stageOptions" 
                    :key="index"
                    :value="stage"
                    elevated>
                      {{stage}}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
  
            <v-tab-item key="CoreSkillsSubject">
              <v-card>
                <v-card-text>
                  <v-chip-group v-model="selectedLeagueFilter" active-class="secondary" column :multiple="true">
                    <v-chip v-for="(league,index) in leagueOptions" 
                    :key="index"
                    :value="league"
                    elevated>
                      {{league}}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <div class="filterDialogButton">
            <v-card-actions class="px-6 pb-0">
              <v-spacer></v-spacer>
              <v-btn
                rounded
                outlined
                class="accent--text pa-4"
                @click="closeFilter"
                >Cancel</v-btn
              >
              <v-btn
                class="accent pa-4"
                :disabled="selectedStageFilter.length==0 && selectedLeagueFilter.length==0"
                @click="filterTeachers(selectedStageFilter,selectedLeagueFilter),getFilterCount()">Apply</v-btn
              >
            </v-card-actions>
          </div>
        </v-card>                
      </v-dialog>
    <v-col>
      <v-card
        height="64px"
        width="100%"
        class="mx-4 pl-2 pt-5 pr-4 background elevation-0 d-flex flex-row justify-space-between align-center fixBar"
      >
        <v-card-title class="pl-0 ml-0">
          <span
            @click="$router.push('/')"
            class="breadcrumb-text-unselected underline-on-hover cursor"
            >Dashboard</span
          >

          <span
            ><v-icon class="breadcrumb-arrow-unselected"
              >mdi-chevron-right</v-icon
            ></span
          >
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="breadcrumb-text">
                Campaign Result
                <v-icon class="breadcrumb-arrow cursor"
                  >mdi-chevron-down</v-icon
                >
              </span>
            </template>

            <v-list>
              <v-list-item @click="$router.push('/teachersappraisal')"
                >No. of teachers to be Appraised</v-list-item
              >
              <v-list-item @click="$router.push('/teacherspip')"
                >No. of teachers on PIP</v-list-item
              >
              <v-list-item @click="$router.push('/teacherstbd')"
                >No. of teachers on TBD</v-list-item
              >
            </v-list>
          </v-menu>
        </v-card-title>
        <div
          class="size-menu d-flex flex-row justify-start pr-4 w-fit align-center fixLog"
        >
          <v-badge
            color="red"
            dot
            right
            transition="slide-x-transition"
            class="mr-8"
          >
            <img src="../assets/bell 1.svg" />

            <!-- <v-hover v-model="hover">
              <img width="24px" src="../assets/bell 1.svg" />
            </v-hover> -->
          </v-badge>
          <div>
            <img
              src="../assets/avtar.png"
              class="rounded-xl mr-2"
              width="32"
              height="32"
            />
          </div>
          <div class="d-flex flex-column d-justify-evenly user-details">
            <v-card-title class="ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.name }}
            </v-card-title>
            <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
              {{ $store.state.userInfo.email }}
            </v-card-subtitle>
          </div>
          <v-menu offset-y class="menuZindex">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                width="40px"
                height="40px"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon class="">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-btn @click="logout">logout</v-btn>
          </v-menu>
        </div>
      </v-card>

      <div class="pt-12">
        <div class="background ml-2 pt-4 fixTop30">
          <v-container class="funnel-contianer pb-0 pl-0 pr-8 ml-5" fluid>
            <div class="w-100 d-flex flex-row">
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg pr-0 pl-0 cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1">
                    <p class="py-0 my-0 pl-2 funnelText">
                      TEACHERS
                    </p>
                  </v-card-title>

                  <v-card-text class="pt-0  mt-n2 pr-0 pl-0">
                    <span class="pl-3 black--text"
                      >Registered/Invited</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="invited" propertyEnd="screeningStarted" funnelColor="#E0ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.invited -
                              funnelTeachersData.screeningStarted,
                            funnelTeachersData.invited
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="screeningStarted" propertyEnd="screeningPassed" funnelColor="#C3ED4B"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.screeningStarted -
                              funnelTeachersData.screeningPassed,
                            funnelTeachersData.screeningStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      SCREENING RESULT
                    </p>
                  </v-card-title>
                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed </span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="screeningPassed" propertyEnd="mainsStarted" funnelColor="#B0D644"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.screeningPassed -
                              funnelTeachersData.mainsStarted,
                            funnelTeachersData.screeningPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">MAINS</p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Attempted</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsStarted" propertyEnd="mainsPassed" funnelColor="#6AD644"/>

                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.mainsStarted -
                              funnelTeachersData.mainsPassed,
                            funnelTeachersData.mainsStarted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>

              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">
                      MAINS RESULT
                    </p>
                  </v-card-title>

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 green--text"
                      >Passed</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyBegin="mainsPassed" propertyEnd="demoSubmitted"/>
                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.mainsPassed -
                              funnelTeachersData.demoSubmitted,
                            funnelTeachersData.mainsPassed
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>

              <div class="rounded-lg px-0 py-0 pl-1 flex-item-1">
                <v-card
                  class="rounded-lg cardBorder"
                  width="100%"
                  height="30vh"
                >
                  <v-card-title class="pb-2 pl-1 pr-0 pt-1 ">
                    <p class="pl-2 funnelText">DEMO</p>
                  </v-card-title>

                  

                  <v-card-text class="pr-0  mt-n2 pl-0 pt-0">
                    <span class="pl-3 black--text"
                      >Submitted</span
                    >
                    <FunnelChartCommon :invites="funnelTeachersData" scaleMax="invited" propertyEnd="interviewSubmitted" propertyBegin="demoSubmitted"/>

                    <div class="d-flex justify-end py-1 pr-2">
                      <img src="../assets/streamline_graph-arrow-decrease.svg" alt="Icon"/>
                      <div 
                        class="red--text pl-2"
                        style="display: inline-block; margin-bottom: 0">
                        {{
                          calculatePercentage(
                            funnelTeachersData.demoSubmitted -
                              funnelTeachersData.interviewSubmitted,
                            funnelTeachersData.demoSubmitted
                          )
                        }}%
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <!-- <v-col cols="2.5">
              <FunnelChart/>
             </v-col>
             <v-col cols="2.5">
              <FunnelChart/>
             </v-col>
             <v-col cols="2.5">
              <FunnelChart/>
             </v-col> -->
          </v-container>

          <v-row class="d-flex justify-center pt-0 my-0 ml-3 pr-1" fluid>
            <v-col cols="4" md="4" sm="4" class="pt-0 pl-1">
              <div class="text-h6 highEmphasis i-patch">
                Campaign Result
              </div>
            </v-col>

            <v-col
              cols="8"
              md="8"
              sm="8"
              class="d-flex flex-row justify-end pt-0"
            >
              <v-card class="elevation-0 ma-0 pa-0 transparent" width="326px">
                <v-text-field
                  solo
                  hide-details
                  label="Search here..."
                  prepend-inner-icon="mdi-magnify"
                  class="search-bar border-button rounded-pill mx-3"
                  v-model="search"
                  dense
                  clearable
                ></v-text-field>
              </v-card>

              <v-btn depressed rounded outlined @click="showfilterdialogue" :disabled="topTeacherInSchool.length==0"
              class="border-button mx-3"
                ><v-icon>mdi-tune</v-icon>
                <span v-if="filterCount>0">FILTER({{filterCount}})</span>
                <span v-else>FILTER</span>
                </v-btn
              >
              <!-- <v-btn depressed rounded outlined class="border-button mx-3"
            ><img src="../../public/svgs/SORT.svg" alt="" />SORT</v-btn
          > -->

              <!-- <v-btn
            class="primary mx-2" rounded><v-icon>mdi-export</v-icon>Export</v-btn> -->
              <v-btn depressed outlined rounded @click="downloadCampaignTeachersResults"  :disabled="topTeacherInSchool.length==0" class="border-button mx-3"
                ><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </div>

        <v-card
          elevation="0"
          height="55px"
          class="d-flex align-center rounded-table mx-5"
          color="#B2AC88"
        >
          <v-row class="pl-4">
            <v-col cols="1" class="reportTableText"> S.No </v-col>
            <v-col cols="1" class="reportTableText mr-8"> Full Name </v-col>
            <v-col cols="1" class="reportTableText i-patch"> Campaign </v-col>
            <v-col cols="2" class="reportTableText"> SchoolName </v-col>
            <v-col cols="1" class="reportTableText i-patch"> Invited </v-col>
            <v-col cols="1" class="reportTableText i-patch"> Screening </v-col>
            <v-col cols="1" class="reportTableText i-patch"> Mains </v-col>
            <v-col cols="1" class="reportTableText"> Demo </v-col>
            <v-col cols="1" class="text-center reportTableText"> League </v-col>
            <v-col cols="1" class="text-end reportTableText i-patch"> Action </v-col>

          </v-row>
        </v-card>
        <v-card
          class="rounded-table mx-5 mt-2"
          id="myScroll"
          height="350px"
          elevation="0"
        >
          <v-list elevation="0" class="px-0 py-0">
            <v-list-item
              class="px-0 pl-4 py-0"
              v-for="(item, index) in displayedTeachers"
              :key="item.id"
            >
              <v-row class="px-0 py-0 mt-2">
                <v-col cols="1" class="d-flex flex-row align-center ml-2 py-0">
                  {{ index + 1 }}
                </v-col>
                <v-col
                  class="d-flex flex-row align-center pl-0 mr-8 py-0"
                  cols="1"
                >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <td class="t-data" v-bind="attrs" v-on="on">
                        {{ `${item.firstName} ${item.lastName}` }}
                      </td>
                    </template>
                    <span style="font-size: 15px">
                      {{
                        "Grade : " +
                        item.responsibleTeachingAt[0].grade +
                        ", " +
                        "Level : " +
                        item.responsibleTeachingAt[0].level +
                        ", " +
                        "Subject : " +
                        item.responsibleTeachingAt[0].subject
                      }}
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="1" class="d-flex flex-row align-center px-0 py-0">
                  <div>{{ item.campaignName }}</div>
                </v-col>
                <v-col cols="2" class="d-flex flex-row align-center px-0 py-0">
                  <div>{{ item.schoolName }}</div>
                </v-col>
                <v-col class="d-flex flex-row align-center py-0 pr-15" cols="4">
                  <v-row class="d-flex flex-row align-center pl-2">
                    <v-icon
                      medium
                      :color="item.stepper.invitation.iconColor"
                      >{{ item.stepper.invitation.name}}
                    </v-icon>
                    <v-divider
                      :thickness="10"
                      class="border-opacity-25 px-0 py-0"
                       :color="item.stepper.screening.iconColor"
                    ></v-divider>
                    <v-icon
                      medium
                       :color="item.stepper.screening.iconColor"
                    >
                    {{ item.stepper.screening.name}}
                    </v-icon>
                    <v-divider
                      :thickness="2"
                      class="border-opacity-87 px-0 py-0"
                      :color="item.stepper.mains.iconColor"
                    ></v-divider>
                    <v-icon
                      medium
                      :color="item.stepper.mains.iconColor"
                    >
                    {{ item.stepper.mains.name}}</v-icon
                    >

                    <v-divider
                      :thickness="10"
                      class="border-opacity-25 px-0 py-0"
                      :color="item.stepper.demo.iconColor"
                    ></v-divider>
                    <v-icon
                      medium
                      class="pr-8"
                      :color="item.stepper.demo.iconColor"
                      >{{ item.stepper.demo.name}}</v-icon
                    >
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <div
                    v-if="
                      item.coreSkillsSubject != undefined &&
                      item.coreSkillsSubject.length > 0
                    "
                    class="text-subtitle-1 d-flex justify-center"
                  >
                    <div
                      v-if="
                        item.coreSkillsSubject.length > 0 &&
                        item.coreSkillsSubject.length <= 3
                      "
                      class="d-flex flex-row w-100 flex-wrap d-flex justify-center"
                    >
                      <div
                        v-for="(coreSkill, index) in item.coreSkillsSubject"
                        :key="index"
                        class="w-fit"
                      >
                        <v-tooltip v-if="coreSkill.leagueValueIcon != 'NA'" top>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex flex-column align-center mr-6"
                            >
                              <v-icon
                                v-if="coreSkill.league == 'Gold'"
                                height="23px"
                                width="25"
                                class="goldIcons--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                              <v-icon
                                v-if="coreSkill.league == 'Silver'"
                                height="23px"
                                width="25"
                                class="silverIcon--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                              <v-icon
                                v-if="
                                  coreSkill.league == 'Bronze' ||
                                  coreSkill.league == 'Bonze'
                                "
                                height="23px"
                                width="25"
                                class="bronzeIcon--text"
                              >
                                mdi-shield-crown-outline
                              </v-icon>
                            </div>
                          </template>
                          <span style="font-size: 15px"
                            >{{ coreSkill.skillName }} -
                            {{ coreSkill.percentile.toFixed(2) }}%
                          </span>
                        </v-tooltip>
                        <div v-else class="d-flex justify-center mr-8 mt-1">
                          -
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        item.coreSkillsSubject.length > 0 &&
                        item.coreSkillsSubject.length >= 3
                      "
                      class="d-flex flex-row w-100 flex-wrap"
                    >
              
                    <div 
                    v-for="(coreSkill, index) in item.coreSkillsSubject"
                    :key="index"
                    >
                      <v-tooltip v-if="coreSkill.leagueValueIcon != 'NA'" top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex flex-column align-center ml-6"
                          >
                   
                            <v-icon
                              v-if="item.coreSkillsSubject[0].league == 'Gold'"
                              height="23px"
                              width="25"
                              class="goldIcons--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                coreSkill.item.coreSkillsSubject[0] == 'Silver'
                              "
                              height="23px"
                              width="25"
                              class="silverIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                item.coreSkillsSubject[0].league == 'Bronze' ||
                                item.coreSkillsSubject[0].league == 'Bonze'
                              "
                              height="23px"
                              width="25"
                              class="bronzeIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <div style="font-size: 12px">
                              #{{ item.coreSkillsSubject[0].rank }}
                            </div>
                          </div>
                        </template>
                        <span style="font-size: 15px"
                          >{{ item.coreSkillsSubject[0].skillName }} -
                          {{
                            item.coreSkillsSubject[0].percentile.toFixed(2)
                          }}%</span
                        >
                      </v-tooltip>
                    </div>
                    <div 
                    v-for="(coreSkill, index) in item.coreSkillsSubject"
                    :key="index"
                    >
                      <v-tooltip v-if="coreSkill.leagueValueIcon != 'NA'" top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex flex-column align-center ml-6"
                          >
                            <v-icon
                              v-if="item.coreSkillsSubject[1].league == 'Gold'"
                              height="23px"
                              width="25"
                              class="goldIcons--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                item.coreSkillsSubject[1].league == 'Silver'
                              "
                              height="23px"
                              width="25"
                              class="silverIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                item.coreSkillsSubject[1].league == 'Bronze' ||
                                item.coreSkillsSubject[1].league == 'Bonze'
                              "
                              height="23px"
                              width="25"
                              class="bronzeIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <div style="font-size: 12px">
                              #{{ item.coreSkillsSubject[1].rank }}
                            </div>
                          </div>
                        </template>
                        <span style="font-size: 15px"
                          >{{ item.coreSkillsSubject[1].skillName }} -
                          {{ item.coreSkillsSubject[1].percentile.toFixed(2) }}%
                        </span>
                      </v-tooltip>
                    </div>
                      
                    <div
                    v-for="(coreSkill, index) in item.coreSkillsSubject"
                    :key="index"
                    >
                      <v-tooltip v-if="coreSkill.leagueValueIcon != 'NA'" top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex flex-column align-center ml-6"
                          >
                            <v-icon
                              v-if="item.coreSkillsSubject[2].league == 'Gold'"
                              height="23px"
                              width="25"
                              class="goldIcons--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                item.coreSkillsSubject[2].league == 'Silver'
                              "
                              height="23px"
                              width="25"
                              class="silverIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <v-icon
                              v-if="
                                item.coreSkillsSubject[2].league == 'Bronze' ||
                                item.coreSkillsSubject[2].league == 'Bonze'
                              "
                              height="23px"
                              width="25"
                              class="bronzeIcon--text"
                            >
                              mdi-shield-crown-outline
                            </v-icon>
                            <div style="font-size: 12px">
                              #{{ item.coreSkillsSubject[2].rank }}
                            </div>
                          </div>
                        </template>
                        <span style="font-size: 15px"
                          >{{ item.coreSkillsSubject[2].skillName }} -
                          {{
                            item.coreSkillsSubject[2].percentile.toFixed(2)
                          }}%</span
                        >
                      </v-tooltip>
                    </div>
                      

                      <v-btn
                        v-if="item.coreSkillsSubject.length >= 3"
                        :loading="btnLoading"
                        @click="
                          openLeagueDialog(
                            item.coreSkillsSubject,
                            item.personalInfo.firstName,
                            item.personalInfo.lastName
                          )
                        "
                        height="10px"
                        width="10px"
                        text
                        color="#1B72E8"
                        class="text-body-2 ml-9 mt-4"
                        >View More</v-btn
                      >
                    </div>
                  </div>
                  <div
                    class="d-flex justify-center mr-8 mt-1"
                    v-if="
                      item.coreSkillsSubject != undefined &&
                      item.coreSkillsSubject.length == 0
                    "
                  >
                    -
                  </div>
                </v-col>
                <v-col cols="1" class="d-flex justify-end" align-self="end">
                  <img
                    @click="getResult(item)"
                    class="cursor mr-3 mb-2"
                    width="24px"
                    src="../assets/Live.svg"
                  />
                  <img
                    @click="getFinalResult(item)"
                    class="cursor  mb-2"
                    width="24px"
                    src="../assets/Final.svg"
                  />
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </v-col>
  </div>
</template>

<script >
import AuthService from "@/services/AuthService";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,

} from "firebase/firestore";
import { db } from "../firebase";
import CampaignController from "@/controllers/CampaignController";
import Papa from "papaparse";
import LeagueController from "@/controllers/LeagueController";
import FunnelChartCommon from "./FunnelChartCommon.vue";

export default {
  name: "CampaignResult",
  components: {
    FunnelChartCommon,
  },
  data() {
    return {
      clusters: [],
      schoolTeacher: [],
      topTeacherInSchool: [],
      campaignNameAdmin: [],
      invited: 0,
      screeningStarted: 0,
      screeningPassed: 0,
      screeningFailed: 0,
      mainsStarted: 0,
      mainsPassed: 0,
      mainsFailed: 0,
      result: 0,
      apraisals: 0,
      pip: 0,
      tbd: 0,
      chartData: {},
      emptyChartData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
      },

      funnelTeachersData: {
        invited: 0,
        screeningStarted: 0,
        screeningPassed: 0,
        screeningFailed: 0,
        mainsStarted: 0,
        mainsPassed: 0,
        mainsFailed: 0,
        demoSubmitted: 0,
        interviewSubmitted: 0,
      },
      pipTeachersCount: 0,
      appraisedTeachersCount: 0,
      otherTeachersCount: 0,
      tbdTeachersCount: 0,
      filterDialog: false,
      filterCount: 0,
      appliedFilter: false,
      selectedStageFilter : [],
      selectedLeagueFilter : [],
      filteredTeachers: [],
      stageOptions:["Invited","Screening","Mains","Demo"],
      leagueOptions:[],
      search: "",
      searchedResults:[],
      appliedSearch:false,
    };
  },

  computed: {
    displayedTeachers() {
      if(this.appliedFilter==false){
        if(this.appliedSearch==true){
          return this.searchedResults
        }
        else{
          return this.topTeacherInSchool;
        }
        }
      else if(this.appliedFilter==true){
        if(this.appliedSearch==true){
          return this.searchedResults
        }
        else{
          return this.filteredTeachers;
        }
      }
    },
  },

  watch:{
    search(newValue){
      if (newValue=='' || newValue == null) {
        this.appliedSearch=false;
      }
      else{
        this.searchData(newValue);
      }
    },
  },

  methods: {
    async newTeacherFunnelChartDataListener() {
      const dashboardTeachersRef = doc(
        db,
        "dashboards",
        this.$store.state.role.id + "-vgos-teachers-dashboard",
        "results",
        "campaignresults"
      );

      onSnapshot(dashboardTeachersRef, (doc) => {
        const resultData = doc.data();
        if (resultData) {
          this.funnelTeachersData = {
            invited: resultData.invited ? resultData.invited : 0,
            screeningStarted: resultData.screeningStarted? resultData.screeningStarted: 0,
            screeningPassed: resultData.screeningPassed? resultData.screeningPassed: 0,
            screeningFailed: resultData.screeningFailed? resultData.screeningFailed: 0,
            mainsStarted: resultData.mainsStarted ? resultData.mainsStarted : 0,
            mainsPassed: resultData.mainsPassed ? resultData.mainsPassed : 0,
            mainsFailed: resultData.mainsFailed ? resultData.mainsFailed : 0,
            demoSubmitted: resultData.demoSubmitted ? resultData.demoSubmitted : 0,
            interviewSubmitted: resultData.interviewSubmitted ? resultData.interviewSubmitted : 0,
          };
        }
      });
    },

    async getLeague()
    { 
      const response = await LeagueController.getLeagues();
      if (response.status==200){
        for (const league of response.data.leagues){
          if (league.name=="Bonze" || league.name=="Bronze"){
          this.leagueOptions.push("Bronze");
          }
          else{
            this.leagueOptions.push(league.name);
          }
        }
      }
    },

    searchData(search){
      if (this.appliedFilter==true){
        this.searchedResults=this.filteredTeachers.filter((teacher)=>
          new RegExp(search,"i").test(teacher.firstName) || 
          new RegExp(search,"i").test(teacher.lastName) || 
          new RegExp(search,"i").test(`${teacher.firstName} ${teacher.lastName}`)
      );
      }
      else{
        this.searchedResults=this.topTeacherInSchool.filter((teacher)=>
          new RegExp(search,"i").test(teacher.firstName) || 
          new RegExp(search,"i").test(teacher.lastName) || 
          new RegExp(search,"i").test(`${teacher.firstName} ${teacher.lastName}`))
      }
        this.appliedSearch=true;
    },

    showfilterdialogue() {
      this.filterDialog = true;
    },

    closeFilter(){
    this.filterDialog = false;
  },

  filterBasedOnStage(selectedStage){
     let lastStatus = '';
     lastStatus = selectedStage.slice(-1)[0]?.toLowerCase();
       this.filteredTeachers =   this.filteredTeachers.filter((item)=> item.campaignStatus.map(({status})=>status?.toLowerCase()).join("").includes(lastStatus))

  },
  filterBasedOnLeague(selectedLeague){
   this.filteredTeachers =  this.filteredTeachers.filter((teacher)=>{
      let teachersLeagueArr = teacher.coreSkillsSubject.map(({league})=> league.toLowerCase())
      console.log(teachersLeagueArr)
      return selectedLeague.some((league)=> teachersLeagueArr?.includes(league.toLowerCase()) )
        })
  },
  filterTeachers(
      selectedStage =[],
      selectedLeague =[]
    ) {
      let stageApplied = selectedStage?.length > 0
      let leagueApplied =  selectedLeague?.length > 0
      this.filteredTeachers = this.topTeacherInSchool;
     
      if(stageApplied){  
       this.filterBasedOnStage(selectedStage);
      } 

      if(leagueApplied){
        this.filterBasedOnLeague(selectedLeague,);
      }
    
      this.appliedFilter=true;
      this.filterDialog=false;
    },

    clearFilter() {
      this.appliedFilter = false;
      this.selectedStageFilter = "",
      this.selectedLeagueFilter= "",
      this.filterCount = 0,
      this.filteredTeachers=[];
    },

    getFilterCount() {
      this.filterCount = 0;
      if (this.selectedStageFilter.length > 0) {
        this.filterCount++;
      }
      if (this.selectedLeagueFilter.length > 0) {
        this.filterCount++;
      }
    },

    downloadCampaignTeachersResults() {
        const fileName = "CampaignTeachersResults.csv";
        const dataForDownload = [];
        for (const teacher of this.topTeacherInSchool) {
          const fullName = `${teacher.firstName} ${teacher.lastName}`;
          const campaign = teacher.campaignName;
          const schoolName = teacher.schoolName;
          const campstat = teacher.demoStatus?teacher.demoStatus:teacher.campaignStatus[teacher.campaignStatus.length-1].status

          dataForDownload.push({
              FullName : fullName,
              Campaign : campaign,
              SchoolName : schoolName,
              CurrentCampaignStatus : campstat
            });
          }
          this.downloadCSV(dataForDownload,fileName);
    },

    async downloadCSV(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async getResult(data) {
      const response = await CampaignController.getResult(data.userId, data.campaignId);
      if (response.status == 200) {
        if (response.data.length == 0) {
          alert("User test not started");
        } else {
          this.$router.push(`/testreport/${data.campaignId}/${data.userId}`);
        }
      } else {
        alert(response.data.error);
      }
    },
    getFinalResult(data) {
      this.$router.push({
        path: "/userreport",
        name: "UserReport", 
        query: {
          campaignId: data.campaignId,
          userId: data.userId,
        },
      });
    },

    async setupSnapshotListenerFunnel() {
      const id = collection(
        db,
        "dashboards",
        "management-dashboard-001",
        "campaigns"
      );
      if (id === undefined) {
        this.chartData = this.emptyChartData;
      }
      const data = await getDocs(id);
      if (data === undefined) {
        this.chartData = this.emptyChartData;
      }
      const document = data.docs[0];
      if (document === undefined) {
        this.chartData = this.emptyChartData;
      } else {
        const docRef = doc(id, data.docs[0].id);

        onSnapshot(docRef, (snapshot) => {
          let funnelData = snapshot.data();
          //console.log("campaignStatus data received:", funnelData);
          if (
            funnelData.invited === undefined ||
            funnelData.screeningStarted === undefined ||
            funnelData.screeningPassed === undefined ||
            funnelData.screeningFailed === undefined ||
            funnelData.mainsStarted === undefined ||
            funnelData.mainsPassed === undefined ||
            funnelData.mainsFailed === undefined
          ) {
            //console.log("isnide if");
            this.chartData = this.emptyChartData;
            //console.log("inside if data", this.chartData);
          } else {
            this.chartData = funnelData;
            this.invited = funnelData.invited;
            this.screeningStarted = funnelData.screeningStarted;
            this.screeningPassed = funnelData.screeningPassed;
            this.mainsStarted = funnelData.mainsStarted;
            this.mainsPassed = funnelData.mainsPassed;
            this.screeningFailed = funnelData.screeningFailed;
            this.mainsFailed = funnelData.mainsFailed;

            this.apraisals = funnelData.mainsPassed;
            this.pip = funnelData.mainsFailed;
            this.tbd = funnelData.screeningFailed;

            //console.log(this.chartData);
          }
        });
      }
    },
    calculatePercentage(value, total) {
      if (total === 0) {
        // //console.log("Error: Total cannot be zero.");
        return 0;
      }
      const percentage = (value / total) * 100;
      if (isNaN(percentage)) {
        // //console.log("Error: Invalid input. Please provide valid numbers.");
        return 0;
      }
      const roundedPercentage = Math.round(percentage);
      // //console.log(roundedPercentage);
      return roundedPercentage;
    },
    async teacherSnapshotListener() {
      const campaignRef = collection(db, "campaigns"); // const schoolMarks = {}

      this.appraisedTeachersCount = 0;
      this.pipTeachersCount = 0;
      this.tbdTeachersCount = 0;
      this.otherTeachersCount = 0;

      onSnapshot(campaignRef, (snapshot) => {
        this.schoolTeacher.splice(0);

        snapshot.docs.forEach(async (doc) => {
          // if teacher, run this
          // //console.log("doc id >>", doc.id)
          const campaignName = doc.data().name ? doc.data().name : "";
          // this.topTeacherInSchool.push(campaignName);
          ////console.log("CampaignName>>>", this.topTeacherInSchool);
          if (doc.data().audienceType.toLowerCase() === "teacher") {
            try {
              // //console.log("id.......<<<<", doc.id)
              const jobSeekerRef = collection(campaignRef, doc.id, "audience");
              const jobSeekDocs = await getDocs(jobSeekerRef);
              jobSeekDocs.forEach((d) => {
                // //console.log("teachersData :", d.data());
                const teachersData = d.data();
                //console.log("teachersData :", d.data());

                if (teachersData?.campaignStatus?.length === 5) {
                  if (
                    teachersData.campaignStatus[2].status ===
                      "SCREENING PASSED" &&
                    teachersData.campaignStatus[4].status === "MAINS FAILED"
                  ) {
                    this.pipTeachersCount++;
                  }

                  if (
                    teachersData.campaignStatus[2].status ===
                      "SCREENING FAILED" &&
                    teachersData.campaignStatus[4].status === "MAINS FAILED"
                  ) {
                    this.tbdTeachersCount++;
                  }

                  if (
                    teachersData.campaignStatus[2].status ===
                      "SCREENING FAILED" &&
                    teachersData.campaignStatus[4].status === "MAINS PASSED"
                  ) {
                    this.otherTeachersCount++;
                  }

                  if (
                    teachersData.campaignStatus[2].status ===
                      "SCREENING PASSED" &&
                    teachersData.campaignStatus[4].status === "MAINS PASSED"
                  ) {
                    let flag = true;
                    if (teachersData?.coreSkillsSubject?.length > 0) {
                      for (const dt of teachersData.coreSkillsSubject) {
                        if (dt.league !== "Not Eligible") {
                          flag = false;
                          this.appraisedTeachersCount++;
                          break;
                        }
                      }
                    }
                    if (flag) {
                      this.otherTeachersCount++;
                    }
                  }
                }
                           
                    // //console.log("inside-for_loop")
                     const temp={
                        firstName: teachersData?.personalInfo?.firstName ? teachersData.personalInfo.firstName : "",
                        lastName: teachersData?.personalInfo?.lastName ? teachersData.personalInfo.lastName : "",
                        campaignName: campaignName,
                        schoolName: teachersData?.schoolName ? teachersData.schoolName : "",
                        // status: teachersData?.status ? teachersData.status : "",
                        screeningTotal: teachersData.screeningTotal ? teachersData.screeningTotal : 0,
                        mainsTotal: teachersData.mainsTotal ? teachersData.mainsTotal : 0,
                        status: teachersData.campaignStatus ? teachersData.campaignStatus[teachersData.campaignStatus.length-1].status : "INVITED",
                        responsibleTeachingAt: teachersData.responsibleTeachingAt ? teachersData.responsibleTeachingAt : [[]],
                        stage: "INVITED",
                        campaignStatus : teachersData.campaignStatus,
                        coreSkillsSubject : teachersData.coreSkillsSubject,
                        campaignId :doc.id,
                        userId: teachersData.userId
                      }

                    // let stage="Invited"
                    if (
                      temp.status === "SCREENING PASSED" ||
                      temp.status === "SCREENING FAILED"
                    )
                      temp.stage = "Screening";
                    if (
                      temp.status === "MAINS PASSED" ||
                      temp.status === "MAINS FAILED"
                    )
                      temp.stage = "Mains";
                    this.schoolTeacher.push(temp);
              });

              this.topTeacherInSchool = this.calculateUsersForLeague(
                this.schoolTeacher
              );
              this.topTeacherInSchool = this.addDemoInterviewScore(this.topTeacherInSchool)
              this.topTeacherInSchool = this.createStepperData(this.topTeacherInSchool)
            } catch (error) {
              console.error("Error fetching audience collection:", error);
            }
          }
        });
      });
    },
    getPassFailGenericVal(status){
      if(status !== null && status !== undefined && status !== ''){
        return status.split(' ')[1]?.toLowerCase().trim()
      } else{   return ''}
  
    },
  getStepperIconAndColor(prevStatus = '', curStatus = ''){
      let key = prevStatus+curStatus.trim()
      let map = new Map([
        ['started', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['startedfailed', {name:'mdi-close-circle', iconColor: 'red'} ],
        ['startedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
        ['failed',{name:'mdi-circle-double', iconColor: 'grey'}],
        ['failedpassed', {name: 'mdi-check-circle', iconColor: 'green'}],
        ['failedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['passed', {name:'mdi-circle-double', iconColor: 'grey'}],
        ['passedfailed', {name:'mdi-close-circle', iconColor: 'red'} ],
        ['passedstarted', {name:'mdi-dots-horizontal-circle-outline', iconColor:'blue' }],
        ['', {name:'mdi-circle-double', iconColor: 'grey'}],

      ])
      return map.get(key)
    },
    createStepperData(data){
     return data?.map((item)=>{
        let obj = {invitation: { name:'mdi-check-circle', iconColor: 'green'}, 
                 screening:{  },
                 mains:{ },
                 demo:{ },
                 interview:{}}
        let status = item?.campaignStatus;
        obj['screening'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[1]?.status), this.getPassFailGenericVal(status[2]?.status))
        obj['mains'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[3]?.status), this.getPassFailGenericVal(status[4]?.status))
        obj['demo'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[5]?.status), this.getPassFailGenericVal(status[6]?.status))
        obj['interview'] = this.getStepperIconAndColor(this.getPassFailGenericVal(status[7]?.status), this.getPassFailGenericVal(status[8]?.status))
        item['stepper'] = obj;
        obj = {};
        return item;
      })
    },
    addDemoInterviewScore(data){
  return data.map((user)=>{
        if(user?.demoStatus){
          if(user.userType !== 'Teacher' && user.campaignStatus[user.campaignStatus.length-1].status == 'MAINS PASSED') {
            user.enableScheduleInterview = true
          }
          if(user.campaignStatus.filter((item)=> item.status?.includes('DEMO')).length == 0){
            user.campaignStatus.push({status: 'DEMO STARTED'})
           user.campaignStatus.push({status: 'DEMO PASSED'}) 
          }
                  
        }
        if(user?.interViewFeedback?.length > 0){
          if(user.campaignStatus.filter((item)=> item.status?.includes('INTERVIEW')).length == 0){
            user.campaignStatus.push({status: 'INTERVIEW STARTED'})
           user.campaignStatus.push({status: 'INTERVIEW PASSED'})
          }       
        }
        return user
      })
    
    },
    calculateUsersForLeague(users) {
      users.forEach((user) => {
        var coreSkills = [];
        if (user.coreSkillsSubject !== undefined) {
          user.coreSkillsSubject.forEach((coreSkillSubject) => {
            if (coreSkillSubject.result == "PASSED") {
              coreSkills.push(coreSkillSubject);
            }
          });
        }
        user.coreSkillsSubject = coreSkills;
      });
      return users;
    },
  },
  async created() {
    this.setupSnapshotListenerFunnel();
    this.teacherSnapshotListener();
    this.newTeacherFunnelChartDataListener();
    this.getLeague();
  },
};
</script>

<style scoped>
.tbl > .t-head > .t-row > .head {
  background-color: primary;
}

.tbl {
  height: 196px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.tbl th {
  padding: 8px;
}

.tbl td {
  padding: 5px;
  /* Adjust the padding value as per your preference */
}

.tbl > .t-body > .t-row {
  background-color: white;
}

.head {
  text-align: center;
}

.tbl > .t-body > .t-row > .t-data {
  text-align: center;
}

.tbl > tr:nth-child(even) {
  background-color: white !important;
}

.fail {
  color: #ff0505;
  /* background-color: #ff0505; */
}

.pass {
  color: #06c270;
  /* background-color: #06c270; */
}

.yetToAttempt {
  color: #000000de;
  /* background-color: #000000de; */
}

.dotyetToAttempt {
  width: 8px;
  height: 8px;
  background-color: #000000de;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotfail {
  width: 8px;
  height: 8px;
  background-color: #ff0505;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dotpass {
  width: 8px;
  height: 8px;
  background-color: #06c270;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
</style>