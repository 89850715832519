<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>

  <div v-else>
    <v-card
      height="64px"
      width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      "
    >
      <v-card-title class="pl-0 ml-0">
        <p
          class="breadcrumb-text-unselected underline-on-hover cursor"
          @click="$router.push('/campaign')"
        >
          Campaigns
        </p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
          >mdi-chevron-right</v-icon
        >
        <p
          class="breadcrumb-text-unselected underline-on-hover cursor"
          @click="$router.back()"
        >
          {{ campName }}
        </p>
        <v-icon
          class="breadcrumb-arrow-unselected"
          style="margin-top: 6px !important"
          >mdi-chevron-right</v-icon
        >
        <p class="breadcrumb-text" style="margin-top: 2px">Test Report</p>
        <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important"
          >mdi-chevron-down</v-icon
        >
      </v-card-title>
      <div
        class="size-menu d-flex flex-row justify-start pr-0 w-fit align-center"
        style="position: fixed; right: 0"
      >
        <v-badge
          color="red"
          dot
          right
          transition="slide-x-transition"
          class="mr-8"
        >
          <v-hover>
            <img
              @click="notificationDialog = true"
              class="cursor"
              width="24px"
              src="../assets/bell 1.svg"
            />
          </v-hover>
        </v-badge>

        <div>
          <img
            src="../assets/avtar.png"
            class="rounded-xl mr-2"
            width="32"
            height="32"
          />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="40px"
              height="40px"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>

    <v-card class="repCard">
      <div style="position: sticky; top: 0; z-index: 2; background: white">
        <div
          elevation="0"
          class="d-flex flex-row align-center pt-3 pb-1 justify-space-between px-3"
        >
          <p class="font-weight-bold">{{ selectedUserName }}</p>
          <div>
            <!-- <v-btn @click="goBack" outlined elevation="0" class="accent--text mr-4 mt-3" rounded>Close</v-btn> -->
            <!-- <v-btn @click="generateReport" :loading="downloadLoader" elevation="0" class="accent mt-3"
                            rounded>Download</v-btn> -->
            <!-- <v-icon>mdi-arrow-left</v-icon>
                            <v-icon>mdi-arrow-right</v-icon> -->
            <v-btn-toggle
              @change="switchResult(isSelect)"
              v-model="isSelect"
              mandatory
              dense
              rounded
            >
              <v-btn
                width="100px"
                :class="
                  isSelect == 0
                    ? 'btn-color white--text text-subtitle-2 '
                    : '#000000 btn-color--text text-subtitle-2'
                "
              >
                Screening
              </v-btn>
              <v-btn
                width="100px"
                :class="
                  isSelect == 1
                    ? 'btn-color white--text ttext-subtitle-2'
                    : '#000000 btn-color--text text-subtitle-2'
                "
              >
                Mains
              </v-btn>
              <v-btn
                :disabled="!hasDemo"
                width="100px"
                :class="
                  isSelect == 2
                    ? 'btn-color white--text ttext-subtitle-2'
                    : '#000000 btn-color--text text-subtitle-2'
                "
              >
                Demo
              </v-btn>
              <v-btn
                :disabled="!hasInterview"
                width="100px"
                :class="
                  isSelect == 3
                    ? 'btn-color white--text ttext-subtitle-2'
                    : '#000000 btn-color--text text-subtitle-2'
                "
              >
                Interview
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>

        <div
          class="py-0 mx-4 d-flex flex-column justify-space-between viewTestProctoringBox"
        >
          <div class="py-0 mx-4 d-flex flex-row justify-space-between">
            <div class="d-flex flex-row align-center py-0 pr-0">
              <v-card-title class="pl-0"><b>Net Score-</b></v-card-title>
              <div class="d-flex flex-row ml-1">
                <v-card-title class="px-0"
                  ><b>({{ totalScore }} / {{ maxMarks }})</b></v-card-title
                >
              </div>
            </div>

            <v-btn
              @click="generateReport"
              :loading="downloadLoader"
              elevation="0"
              class="mt-3"
              rounded
              ><v-icon>mdi-download</v-icon></v-btn
            >
          </div>
          <div class="py-0 pb-3 mx-4 d-flex flex-row justify-space-between">
            <div v-if="isSelect === 1" class="d-flex flex-row">
              <p class="px-0"><b>Rank- </b></p>
              <p class="px-0">
                <b>{{
                  selectedSkill.rank == 0 ? " NA" : "#" + selectedSkill.rank
                }}</b>
              </p>
            </div>
            <p>Screenshot Attempted : {{ proctoringData.Screenshot }}</p>
            <p>Exit Full Screen - {{ proctoringData["Exit Fullscreen"] }}</p>
            <p>Screen Change - {{ proctoringData["Switch Tab/Browser"] }}</p>
            <!-- <p>Key Stroke Attempted : {{ proctoringData["Copy/Paste"] }}</p> -->
            <div style="display: flex">
              <p><b>Proctoring Data </b></p>
              <v-icon @click="showProc" color="#864f20">mdi-information</v-icon>
            </div>
          </div>
        </div>

        <div
          class="w-100 d-flex flex-row align-center justify-space-between py-0 my-0"
        >
          <div>
            <div
              v-if="selectedSkill.skillName == 'Psychometry'"
              class="d-flex flex-row align-center py-0 mb-3 mt-0"
            >
              <p class="mx-4 px-0">
                {{ selectedSkill.skillName }} -
                <b
                  >({{ selectedSkill.score }}/{{ selectedSkill.maximumMarks }})
                  -
                  <span :style="{ background: getPsychometricEvaluation(selectedSkill.score,
                        selectedSkill.maximumMarks).color }">

                    {{
                      getPsychometricEvaluation(
                        selectedSkill.score,
                        selectedSkill.maximumMarks
                        ).message
                      }}
                      </span>
                  
                  </b
                >
              </p>
            </div>

            <div v-else class="d-flex flex-row align-center py-0 mb-3 mt-0">
              <p class="mx-4 px-0">
                {{ selectedSkill.skillName }} -
                <b
                  >({{ selectedSkill.score }}/{{
                    selectedSkill.maximumMarks
                  }})</b
                >
              </p>
            </div>

            <div class="d-flex flex-row ml-4">
              <div class="text-body-2">Time Taken -</div>
              <div class="text-subtitle-2 pl-2">
                <b>({{ userTime }}/{{ maxTime }}) mins</b>
              </div>
            </div>
          </div>

          <div class="d-flex flex-row mr-5">
            <v-card width="270px" class="elevation-0 transparent mt-1">
              <!-- <img src="../../public/svgs/CurrentUserIcon.svg" alt="current icon"
                                :style="{ position: 'absolute', left: `${progressLevel}px`, bottom: '16px' }"> -->

              <v-progress-linear
                v-model="progressLevel"
                rounded
                :color="selectedSkill.result !=='PASSED' ? 'red' : 'green'"
                height="10"
                style="position: relative; pointer-events: none"
              ></v-progress-linear>

              <!-- <img src="../../public/svgs/AverageIcon.svg" alt="avg icon"
                                style="position: absolute; left: 72px"> -->              
                               
                                <v-tooltip bottom style="z-index: 999">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                      <img
                                        src="../../public/svgs/PassingIcon.svg"
                                        alt="pass icon"
                                        style="position: absolute"
                                        :style="`left:${((selectedSkill.threhold * 270) / maxMarks)-10}px`"
                                      />
                                    </span>
                                  </template>
                                  <span>{{ selectedSkill.threhold }} / {{maxMarks}}</span>
                                </v-tooltip>
            </v-card>

            <div class="d-flex flex-row align-center mx-0 px-0">
              <!-- <img class="mx-2" src="../../public/svgs/AverageIcon.svg" alt="avg reference icon">
                            <div class="text-body-2">Average User</div> -->
              <img
                class="ml-6 mr-2"
                src="../../public/svgs/PassingIcon.svg"
                alt="pass reference icon"
              />
              <div class="text-body-2">Passing Score</div>
              <!-- <img class="mx-2" src="../../public/svgs/CurrentUserUpIcon.svg" alt="current reference icon">
                            <div class="text-body-2">User's score</div> -->
            </div>
          </div>
        </div>

        <v-row class="py-0 px-4 my-1 mx-0 flex-row align-center">
          <v-spacer></v-spacer>
        </v-row>
      </div>

      <div class="w-100 d-flex flex-row question-result-view pa-4">
        <v-card
          class="scroll-view core-skills-container w-18"
          height="calc(100vh - 312px)"
          id="myScroll"
          outlined
          elevation="0"
          style="position: sticky; top: 300px"
        >
        <v-btn-toggle v-model="selectedSkillName" class="d-flex flex-column pa-4 ga-8 v-btn-toggle--group">           
          <v-card-subtitle class="pa-0 font-weight-bold">CORE SKILLS</v-card-subtitle>
          <template v-for="(skill, index) in selectedResult.skills">
            <template v-if="skill.isCoreSkill">
              <v-btn  
                depressed
                :color="
                  currenrSkillIndex == index
                    ? skill.result == 'PASSED'
                      ? 'green'
                      : skill.result == 'FAILED'
                      ? 'red'
                      : ''
                    : skill.result == 'PASSED'
                    ? 'green'
                    : skill.result == 'FAILED'
                    ? 'red'
                    : 'grey'
                "
                @click="getSelectedSkillData(skill, index)"
                outlined
                class="rounded-xl tabBtn mx-0" 
                :key="index" :value="skill.skillName" 
                >
                  <div class="d-flex flex-column w-100 align-start text-capitalize">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="text-subtitle-2 text-uppercase text--secondary">
                          {{ skill.skillName }}
                        </div>
                      </template>
                      <span>{{ skill.skillName }}</span>
                    </v-tooltip>
                    <div class="d-flex flex-row w-100 justify-space-between">
                      <div>
                        <div class="text-body-2 text--secondary">
                          Weightage
                        </div>
                        <div class="text-left text-body-2 font-weight-bold text--secondary">
                          {{ skill.weightage }}%
                        </div>
                      </div>
                      <div>
                        <div class="text-body-2 text--secondary">
                          Score
                        </div>
                        <div class="text-left text-body-2 font-weight-bold text--secondary">
                          {{((skill.score / skill.maximumMarks)*10).toFixed(2) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-btn
              >           
            </template>
          </template>
          <v-card-subtitle class="px-0 pb-0 font-weight-bold">OTHER SKILLS</v-card-subtitle>
          <template v-for="(skill, index) in selectedResult.skills">
              <v-btn                
                depressed
                :color="
                  currenrSkillIndex == index
                    ? skill.result == 'PASSED'
                      ? 'green'
                      : skill.result == 'FAILED'
                      ? 'red'
                      : ''
                    : skill.result == 'PASSED'
                    ? 'green'
                    : skill.result == 'FAILED'
                    ? 'red'
                    : 'grey'
                "
                @click="getSelectedSkillData(skill, index)"
                outlined
                class="rounded-xl tabBtn mx-0"  
                :key="index" :value="skill.skillName" 
                v-if="!skill.isCoreSkill"             
                >
                  <div class="d-flex flex-column w-100 align-start text-capitalize">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="text-subtitle-2 text-uppercase text--secondary">
                          {{ skill.skillName }}
                        </div>
                      </template>
                      <span>{{ skill.skillName }}</span>
                    </v-tooltip>
                    <div class="d-flex flex-row w-100 justify-space-between">
                      <div>
                        <div class="text-body-2 text--secondary">
                          Weightage
                        </div>
                        <div class="text-left text-body-2 font-weight-bold text--secondary">
                          {{ skill.weightage }}%
                        </div>
                      </div>
                      <div>
                        <div class="text-body-2 text--secondary">
                          Score
                        </div>
                        <div class="text-left text-body-2 font-weight-bold text--secondary">
                          {{((skill.score / skill.maximumMarks)*10).toFixed(2) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-btn
              >           
          </template>
        </v-btn-toggle>
        </v-card>

        <v-card
          class="scroll-view w-82"
          height="calc(100vh - 312px)"
          id="myScroll"
          elevation="0"
        >
          <v-list>
            <v-list-item
              v-for="(item, index) in selectedSkill.questions"
              :key="index"
            >
              <div class="w-100 d-flex flex-column">
                <div class="d-flex flex-row w-100">
                  <div class="d-flex flex-column w-50">
                    <div class="d-flex flex-row">
                      <div>Q.{{ index + 1 }}</div>
                      <div class="pl-4" v-html="item.questionStatement"></div>
                      
                    </div>

                    <v-row class="pl-8 pt-2 my-1 ml-2">
                      <v-chip
                        v-if="
                          item.difficultyLevel != 'NA' &&
                          item.skill != 'Psychometry'
                        "
                        small
                        class="mr-2"
                        >{{ item.difficultyLevel }}</v-chip
                      >
                      <v-chip
                        v-if="
                          item.complexityLevel != 'NA' &&
                          item.skill != 'Psychometry'
                        "
                        small
                        class="mr-2"
                        >{{ item.complexityLevel }}</v-chip
                      >
                      <v-chip
                        v-if="
                          item.knowledgeLevel &&
                          item.knowledgeLevel != 'NA' &&
                          item.skill != 'Psychometry'
                        "
                        small
                        class="mr-2"
                        >{{ item.knowledgeLevel }}</v-chip
                      >
                      <v-chip v-if="item.level != 'NA'" small class="mr-2">{{
                        item.level
                      }}</v-chip>
                    </v-row>

                    <v-layout wrap row class="pl-9 pt-2 ml-1">
                      <div class="pr-3">Options -</div>
                      <div
                        v-if="item.skill !== 'Psychometry'"
                        v-for="(option, i) in item.questionOptions"
                        :key="i"
                      >
                        <div
                          :class=" 
                            item.correctAnswer?.includes(option.optionKey)
                              ? 'pr-3 mt-1 text-body-2 green--text d-flex flex-row'
                              : 'pr-3 mt-1 text-body-2 d-flex flex-row'
                          "
                        >
                          <div class="mr-1"> {{option.optionKey}}.</div>
                          <div v-html="option.optionValue"></div>
                        </div>
                      </div>
                      <div
                        v-if="item.skill == 'Psychometry'"
                        v-for="(option, i) in item.questionOptions"
                        :key="i"
                      >
                        <div
                          :class="
                            option.optionScore > 0
                              ? 'pr-3 mt-1 text-body-2 green--text'
                              : 'pr-3 mt-1 text-body-2'
                          "
                        >
                          {{ option.optionKey }}. {{ option.optionValue }}
                        </div>
                      </div>
                    </v-layout>

                    <v-row class="pl-8 pt-2 ml-1">
                      <div class="pl-1 text-body-2">Time allocated -</div>
                      <div class="pl-1 text-subtitle-2">
                        {{ item.estimatedTime }} Sec
                      </div>
                      <v-spacer></v-spacer>
                      <div class="pl-1 text-body-2">
                        Avg time taken by other users -
                      </div>
                      <div
                        v-if="!item.averageTimeTaken"
                        class="pl-1 text-subtitle-2"
                      >
                        NA
                      </div>
                      <div v-else class="pl-1 text-subtitle-2">
                        {{ item.averageTimeTaken.toFixed(2) }}
                      </div>
                      <v-spacer></v-spacer>
                    </v-row>
                  </div>

                  <div class="w-25">
                    <PieChart
                      :pieData="[
                        ['Type', 'Percentage'],
                        [
                          'Correct',
                          (item.noOfCorrectAttempt / item.noOfAttempt) * 100,
                        ],
                        [
                          'Incorrect',
                          item.noOfCorrectAttempt
                            ? 100 -
                              (item.noOfCorrectAttempt / item.noOfAttempt) * 100
                            : 100,
                        ],
                      ]"
                    />
                  </div>

                  <div class="w-25">
                    <TimeChart
                      :timeData="[
                        ['Type', 'Time', { role: 'style' }],
                        ['Given', parseFloat(item.estimatedTime), '#9F84AE'],
                        [
                          'Average',
                          item.averageTimeTaken?.toFixed(2),
                          '#AA69CE',
                        ],
                        ['Current', item.timeTaken?.toFixed(2), '#9F84AE'],
                      ]"
                    />
                  </div>
                </div>

                <div class="d-flex flex-column w-100 ml-1">
                  <div class="px-0 pl-9 w-100 mt-4">
                    <v-divider></v-divider>
                  </div>

                  <div
                    v-if="item.skill != 'Psychometry'"
                    class="pl-9 pt-2 text-subtitle-2 w-100 d-flex flex-row"
                  >
                    <div>
                      Response Analytics -

                      <span v-if="!item.myAnswer" class="mr-6"
                        >Not Attempted</span
                      >

                      <span v-else class="mr-6">Attempted</span>
                    </div>

                    <div>Answered -</div>

                    <div v-if="!item.myAnswer" class="mr-6">Skipped</div>
                    <div
                      class="mr-6"
                      v-else
                      :class="
                        item.currectAns ? 'pl-1 green--text' : 'pl-1 red--text'
                      "
                    >
                      {{
                        item.currectAns
                          ? item.myAnswer + " (Correct)"
                          : item.myAnswer + " (Incorrect)"
                      }}
                    </div>
                    <!-- <v-row v-if="item.skill == 'Psychometry'" class="pl-9 text-body-2 rowSpacing">
                  <div>Answered -</div>
                  <div v-if="!item.myAnswer">Skipped</div>
                  <div v-else :class="item.questionOptions
                      .filter(option => item.myAnswer.includes(option.optionKey) && parseInt(option.optionScore) > 0)
                      .map(option => option.optionKey).length > 0
                        ? 'pl-1 correctAnswer'
                        : 'pl-1 incorrectAnswer'
                                      ">
                    {{
                      item.questionOptions.filter(option => item.myAnswer.includes(option.optionKey) && parseInt(option.optionScore) > 0)
                    .map(option => option.optionKey).length > 0
                    ? item.myAnswer + " (Correct)"
                    : item.myAnswer + " (Incorrect)"
                    }}
                  </div>
                </v-row> -->

                    <div>Time taken -</div>
                    <div v-if="item.timeTaken != null" class="pl-1">
                      {{ item.timeTaken.toFixed(2) }} Sec
                    </div>
                  </div>
                  <div
                    v-if="item.skill == 'Psychometry'"
                    class="pl-9 pt-2 text-subtitle-2 w-100 d-flex flex-row"
                  >
                    <div>
                      Response Analytics -

                      <span v-if="!item.myAnswer" class="mr-6"
                        >Not Attempted</span
                      >

                      <span v-else class="mr-6">Attempted</span>
                    </div>

                    <div>Answered -</div>

                    <div v-if="!item.myAnswer" class="mr-6">Skipped</div>
                    <div
                      class="mr-6"
                      v-else
                      :class="
                        item.questionOptions
                          .filter(
                            (option) =>
                              item.myAnswer.includes(option.optionKey) &&
                              parseInt(option.optionScore) > 0
                          )
                          .map((option) => option.optionKey).length > 0
                          ? 'pl-1 green--text'
                          : 'pl-1 red--text'
                      "
                    >
                      {{
                        item.questionOptions
                          .filter(
                            (option) =>
                              item.myAnswer.includes(option.optionKey) &&
                              parseInt(option.optionScore) > 0
                          )
                          .map((option) => option.optionKey).length > 0
                          ? item.myAnswer + " (Correct)"
                          : item.myAnswer + " (Incorrect)"
                      }}
                    </div>
                    <!-- <v-row v-if="item.skill == 'Psychometry'" class="pl-9 text-body-2 rowSpacing">
                  <div>Answered -</div>
                  <div v-if="!item.myAnswer">Skipped</div>
                  <div v-else :class="item.questionOptions
                      .filter(option => item.myAnswer.includes(option.optionKey) && parseInt(option.optionScore) > 0)
                      .map(option => option.optionKey).length > 0
                        ? 'pl-1 correctAnswer'
                        : 'pl-1 incorrectAnswer'
                                      ">
                    {{
                      item.questionOptions.filter(option => item.myAnswer.includes(option.optionKey) && parseInt(option.optionScore) > 0)
                    .map(option => option.optionKey).length > 0
                    ? item.myAnswer + " (Correct)"
                    : item.myAnswer + " (Incorrect)"
                    }}
                  </div>
                </v-row> -->

                    <div>Time taken -</div>
                    <div v-if="item.timeTaken != null" class="pl-1">
                      {{ item.timeTaken.toFixed(2) }} Sec
                    </div>
                  </div>
                  <div class="my-3 customDivider"></div>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </v-card>

    <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
      <Notification @close-dialog="notificationDialog = false" />
    </v-dialog>

    <v-dialog class="cdz" v-model="proctorDialog" fullscreen persistent>
      <v-card class="proctorCard" v-if="proctorDialog">
        <v-tabs color="green accent-4" left>
          <v-tab>Cumulative</v-tab>
          <v-tab>Timeline</v-tab>

          <v-tab-item :key="1">
            <v-container fluid>
              <div class="procBubChart">
                <GChart
                  v-if="proctorDialog"
                  type="BarChart"
                  style="height: 100%; width: 100%"
                  :data="cumulativeProctoringData"
                  :options="barChartOptions"
                />
              </div>
            </v-container>
          </v-tab-item>

          <v-tab-item :key="2">
            <v-container fluid>
              <div class="procBubChart">
                <GChart
                  v-if="proctorDialog"
                  type="BubbleChart"
                  style="height: 100%; width: 100%"
                  :data="bubbleChartData"
                  :options="bubbleChartOptions"
                />
              </div>
            </v-container>
          </v-tab-item>
        </v-tabs>
        <div class="w-100 d-flex justify-end px-4">
          <v-btn
            class="accent rounded-pill elevation-0 mx-4"
            @click="proctorDialog = false"
          >
            Close
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="841.89"
      :manual-pagination="true"
      :html-to-pdf-options="{
        margin: 1,
        filename: 'user-report',
        image: {
          type: 'jpeg', 
          quality: 1,
        },
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
        },
        jsPDF: {
          format: 'a4',
          orientation: 'portrait',
          unit: 'pt',
        }
      }"
      pdf-content-width="595.28"
      ref="html2Pdf"
      color="true"
      @progress="onProgress($event)"
    >
      <section slot="pdf-content">
        <v-card class="w-85 pdfCard" height="100%" elevation="0">
          <div class="pdfHead">
            <h2 v-if="isSelect === 0">Screening Result</h2>
            <h2 v-if="isSelect === 1">Mains Result</h2>
          </div>
          <div class="pdfTitle">
            <b>{{ selectedUserName }}</b>
            <b>Total Score: ({{ totalScore }} / {{ maxMarks }})</b>
          </div>

          <v-list
            v-for="(skill, ind) in selectedResult.skills"
            :key="ind"
          >
            <div class="pdfSubTitle">
              <b
                >Skill: {{ skill.skillName }} ({{ skill.score }}/{{
                  skill.maximumMarks
                }})</b
              >
            </div>

            <v-list-item v-for="(item, index) in skill.questions" :key="index" class="pdf-item">
              <v-col>
                <v-row class="rowSpacing">
                  <div>Q. {{ index + 1 }}</div>
                  <div class="pl-4" v-html="item.questionStatement"></div>
                </v-row>
                <v-row class="pl-8 pt-2 rowSpacing">
                  <v-chip
                    v-if="item.difficultyLevel !== 'NA' &&  item.skill !== 'Psychometry'"
                    small
                    class="chipSpacing"
                    >{{ item.difficultyLevel }}</v-chip
                  >
                  <v-chip
                    v-if="item.complexityLevel !== 'NA' &&  item.skill !== 'Psychometry'"
                    small
                    class="chipSpacing"
                    >{{ item.complexityLevel }}</v-chip
                  >
                  <v-chip v-if="item.level !== 'NA'" small class="chipSpacing">{{
                    item.level
                  }}</v-chip>
                </v-row>
                <v-layout wrap row class="pl-9 pt-2 rowSpacing">
                  <div class="pr-3">Options -</div>
                  <div v-for="(option, i) in item.questionOptions" :key="i">
                    <div
                      :class="item.skill!='Psychometry'?
                      item.correctAnswer.includes(option.optionKey)?
                         'pr-3 mt-1 text-body-2 green--text correctOption': 'pr-3 mt-1 text-body-2 otherOption'
                         :'pr-3 mt-1 text-body-2 otherOption'"
                         style="display: flex;">
                       <div class="mr-1">{{ option.optionKey }}.</div>
                       <div v-html="option.optionValue" style="display: inline;"></div>
                    </div>
                  </div>
                </v-layout>
                <v-row class="pl-8 pt-4 rowSpacing">
                  <div class="pl-1 text-body-2">Time allocated -</div>
                  <div class="pl-1 text-subtitle-2">
                    {{ item.estimatedTime }} Sec
                  </div>
                  <v-spacer></v-spacer>
                  <div class="pl-1 text-body-2">
                    Avg time taken by other users -
                  </div>
                  <div
                    v-if="!item.averageTimeTaken"
                    class="pl-1 text-subtitle-2"
                  >
                    NA
                  </div>
                  <div v-else class="pl-1 text-subtitle-2">
                    {{ item.averageTimeTaken.toFixed(2) }}
                  </div>
                  <v-spacer></v-spacer>
                  <div class="pl-1 text-body-2">
                    People answered correctly -
                  </div>
                  <div
                    v-if="!item.noOfAttempt && !item.noOfCorrectAttempt"
                    class="pl-1 text-subtitle-2"
                  >
                    NA
                  </div>
                  <div
                    v-else-if="!item.noOfCorrectAttempt && item.noOfAttempt"
                    class="pl-1 text-subtitle-2"
                  >
                    0%
                  </div>
                  <div v-else class="pl-1 text-subtitle-2">
                    {{ ((item.noOfCorrectAttempt / item.noOfAttempt) * 100).toFixed(2) }}%
                  </div>
                </v-row>
                <v-row class="px-0 pl-9 rowSpacing">
                  <v-divider></v-divider>
                </v-row>
                <v-row class="pl-9 pt-4 text-subtitle-2 rowSpacing">
                  <div>
                    Response Analytics -

                    <span v-if="!item.myAnswer">Not Attempted</span>
                    <!-- <span v-else>{{ item.status }}</span> -->
                    <span v-else>Attempted</span>
                  </div>
                </v-row>
                <v-row class="pl-9 text-body-2 rowSpacing">
                  <div>Answered -</div>
                  <div v-if="!item.myAnswer">Skipped</div>
                  <div
                    v-else
                    :class="
                      item.skill !== 'Psychometry'?
                      item.correctAnswer.replaceAll(' ','') == item.myAnswer.join(',')?
                       'pl-1 correctAnswer': 'pl-1 incorrectAnswer'
                       : 'pl-1'
                    "
                  >
                    {{
                      item.skill !== 'Psychometry'?
                      item.correctAnswer.replaceAll(' ','') == item.myAnswer.join(',')?
                       item.myAnswer + " (Correct)": item.myAnswer + " (Incorrect)"
                      : item.myAnswer+""
                    }}
                  </div>
                </v-row>

                <v-row class="pl-9 text-body-2 rowSpacing">
                  <div>Time taken -</div>
                  <div v-if="item.timeTaken != null" class="pl-1">
                    {{ item.timeTaken.toFixed(2) }} Sec
                  </div>
                </v-row>
                <v-row class="px-0 pl-9">
                  <v-divider></v-divider>
                </v-row>
              </v-col>
            </v-list-item>
            <div class="html2pdf__page-break"></div>
          </v-list>
        </v-card>
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import AuthService from "../services/AuthService";
import Notification from "./Notification";
import CampaignController from "@/controllers/CampaignController";
import { GChart } from "vue-google-charts/legacy";
import VueHtml2pdf from "vue-html2pdf";
import PieChart from "../components/ReportGraphs/PieChart.vue";
import TimeChart from "../components/ReportGraphs/TimeChart.vue";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase.js";

export default {
  components: {
    GChart,
    PieChart,
    TimeChart,
    Notification,
    VueHtml2pdf,
  },
  data() {
    return {
      preloader: true,
      userData: {},
      currenrSkillIndex: 0,
      selectedUserName: "",
      totalScore: 0,
      downloadLoader: false,
      progressLevel: 0,
      userTime: 0,
      maxTime: 0,
      maxMarks: 10,
      campName: "",
      audienceId: null,
      userId: null,
      campaignId: null,
      notificationDialog: false,
      isSelect: 0,
      selectedSkill: {},
      userName: "",
      selectedResult: {},
      proctoringData: {
        "Copy/Paste": 0,
        "Exit Fullscreen": 0,
        Screenshot: 0,
        "Switch Tab/Browser": 0,
      },
      selectedUser: "",
      proctorDialog: false,
      userProctoringActivities: [],
      cumulativeProctoringData: [["Activity", "Count", { role: "style" }]],
      selectedUserName: "",
      dataDemoVideos: {},
      bubbleChartOptions: {
        title: "",

        hAxis: {
          title: "Time (min)",
          format: 0,
          viewWindow: {
            min: 0,
            max: 10,
          },
        },
        vAxis: {
          title: "Ques",
          format: 0,
          viewWindow: {
            min: 0,
            max: 10,
          },
        },

        sizeAxis: {
          minSize: 5,
          maxSize: 5,
        },

        // legend: 'none',
      },

      bubbleChartData: [
        ["Id", "Time (min)", "Ques", "Activity", "Time Stamp"],
        // ["Screenshot", 15, 1, "03:15:47"],
        // ["Screenshot", 20, 2, "03:16:47"],
        // ["Screenshot", 25, 3, "03:17:47"],
        // ["Screenshot", 30, 4, "03:18:47"],
      ],

      // barChartData: this.cumulativeProctoringData,

      barChartOptions: {
        title: "",
        titleTextStyle: {
          fontSize: 12,
        },
        hAxis: {
          title: "Count",
          format: 0,
          gridlines: {
            color: "transparent",
          },
          viewWindow: {
            min: 0,
          },
        },
        vAxis: {
          title: "Activity",
        },
        legend: "none",
      },
      selectedSkillName: null,
      hasDemo: false,
      hasInterview: false
    };
  },
  watch: {
    search(newValue) {
      console.log(newValue);
      if (newValue == "" || newValue == null) {
        this.users = this.calculateUsersForLeague(this.preSearchUser);
      } else {
        this.searchData(newValue);
      }
    },

    async isSelect(newValue) {
      console.log("chk0");

      let testType = "SCREENING";

      if (newValue == 1) {
        testType = "MAINS";
      } else {
        testType = "SCREENING";
      }

      console.log("chk-1");

      const testRef = doc(
        db,
        "proctoredtests",
        this.campaignId,
        this.selectedUser,
        testType
      );

      console.log("chk1");
      const test = await getDoc(testRef);
      console.log("chk2");

      if (test.exists()) {
        // console.log(test.data())
        this.proctoringData = {
          "Copy/Paste": 0,
          "Exit Fullscreen": 0,
          Screenshot: 0,
          "Switch Tab/Browser": 0,
        };
        console.log("chk3");

        // this.userProctoringActivities=[]
        if (this.userProctoringActivities?.length > 0) {
          this.userProctoringActivities.splice(0);
        }

        if (this.cumulativeProctoringData?.length > 1) {
          this.cumulativeProctoringData.splice(1);
        }

        console.log("chk4");

        for (const temp of test.data().activities) {
          if (temp.activity in this.proctoringData) {
            this.proctoringData[temp.activity] += 1;
          }
          this.userProctoringActivities.push(temp);

          if (temp.activity !== "Started" && temp.activity !== "Completed") {
            let flag = true;
            for (let i = 0; i < this.cumulativeProctoringData.length; i++) {
              // if activitiy already exists
              if (this.cumulativeProctoringData[i][0] === temp.activity) {
                this.cumulativeProctoringData[i][1] += 1;
                flag = false;
                break;
              }
            }
            // if a new activity is found
            if (flag) {
              this.cumulativeProctoringData.push([
                temp.activity,
                1,
                this.generateRandomColor(),
              ]);
            }
          }
        }
        console.log("chk5");

        console.log("cumulative Data :", this.cumulativeProctoringData);

        this.addDataToChart();
      }
      console.log("chk6");
    },
  },

  mounted() {
    const id = this.$route.params.campaignId;
    this.fetchCampaignAudience(id);
  },
  async created() {
    this.getParams();
    await this.getResult();
    console.log("this_route_query_isSelect :", this.$route.query.isSelect)
    // if (this.$route.query.isSelect)
    //   this.isSelect=this.$route.query.isSelect

  },
  methods: {
    getPsychometricEvaluation(score, maxScore) {
      const percentage = (score / maxScore) * 100;
      let interpretation = {};
      if (percentage <= 25) {
        interpretation = {
          message:
            "Below Average - The participant has below average abilities in psychometric and socio-emotional dimension. Thourough validation is recommended during subsequent stage of the recruitment process before decision of hiring is taken.",
          color: "#F4CCCC",
        };
      } else if (percentage > 25 && percentage <= 50) {
        interpretation = {
          message:
            "Average - The participant has average abilities in psychometric and socio-emotional dimension. Further validation is recommended during subsequent stages of the recruitment process.",
          color: "#FFF2CC",
        };
      } else if (percentage > 50 && percentage <= 75) {
        interpretation = {
          message:
            "Appropriate - The participant has appropriate abilities in psychometric and socio-emotional dimension. He/she is recommended for the position and may required some scaffolding as per the need of the school",
          color: "#D0E0E3",
        };
      } else {
        interpretation = {
          message:
            "Excellent - The participant has excellent abilities in psychometric and socio-emotional dimension. He/she is best suited for the position and will be the asset to the school",
          color: "#D8EAD3",
        };
      }
      return interpretation;
    },
    isAnswerCorrect(myAnswer, correctAns) {
      if (myAnswer && correctAns) {
        console.log("correctAns" , correctAns)
        correctAns = correctAns.split(',').filter(answer => /^[A-Ea-e]$/.test(answer)).join(',');
        var sortedStr = correctAns.split(",").sort().join("");
        myAnswer.sort();
        return myAnswer.join("") === sortedStr ? true : false;
      } else {
        return false;
      }
    },
    getParams() {
      this.campaignId = this.$route.params.campaignId;
      this.userId = this.$route.params.userId;
      console.log("campaignId..?", this.campaignId);
      console.log("userId..?", this.userId);
    },

    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    async getResult() {
      try{
        console.log(
          "Seeing here all the data...????",
          this.campaignId,
          this.userId
        );
        const docRef = doc(db, "users", this.userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          this.userData = docSnap.data();
        } else {
          console.log("No such document!");
        }

        const response = await CampaignController.getResult(
          this.userId,
          this.campaignId
        );
        console.log("report response..?", response);
        if (response.status == 200) {
          if (response.data.data.length == 0) {
            alert("User test not Started");
            // this.btnLoading = false;
          } else {
            this.resultData = response.data.data;
            this.hasInterview = this.resultData.length > 3;
            this.hasDemo = this.resultData.length > 2;
            this.questionDialog = true;
            this.switchResult(0);
            this.selectedUser = this.userId;
            this.selectedUserName =
              this.userData.personalInfo.firstName +
              " " +
              this.userData.personalInfo.middleName +
              " " +
              this.userData.personalInfo.lastName;
            this.bubbleChartOptions.title = this.selectedUserName;
            console.log("selectedUser...>>>", this.selectedUser);

            console.log("id...>>>", this.campaignId);

            const testRef = doc(
              db,
              "proctoredtests",
              this.campaignId,
              this.selectedUser,
              "SCREENING"
            );

            const test = await getDoc(testRef);
            this.proctoringData = {
              "Copy/Paste": 0,
              "Exit Fullscreen": 0,
              Screenshot: 0,
              "Switch Tab/Browser": 0,
            };

            if (test.exists()) {
              // console.log(test.data())
              this.userProctoringActivities.splice(0);
              this.cumulativeProctoringData.splice(1);
              for (const temp of test.data().activities) {
                if (temp.activity in this.proctoringData) {
                  // console.log(temp)
                  this.proctoringData[temp.activity] += 1;
                }
                this.userProctoringActivities.push(temp);

                if (
                  temp.activity !== "Started" &&
                  temp.activity !== "Completed"
                ) {
                  let flag = true;
                  for (let i = 0; i < this.cumulativeProctoringData.length; i++) {
                    // if activitiy already exists
                    if (this.cumulativeProctoringData[i][0] === temp.activity) {
                      this.cumulativeProctoringData[i][1] += 1;
                      flag = false;
                    }
                  }
                  // if a new activity is found
                  if (flag) {
                    this.cumulativeProctoringData.push([
                      temp.activity,
                      1,
                      this.generateRandomColor(),
                    ]);
                  }
                }
              }
              this.addDataToChart();

              console.log(
                "cumulativeProcChartData :",
                this.cumulativeProctoringData
              );
            }
          }
        } else {
          alert(response.data.error);
        }
      } catch(err){
        console.log(err);
      } finally{
        this.preloader=false;
      }
    },

    goBack() {
      this.$router.back();
    },

    onProgress(event) {
      console.log(`progress data ${event}`);
      if (event === 100) {
        this.downloadLoader = false;
      }
    },

    generateReport() {
      this.downloadLoader = true;
      this.$refs.html2Pdf.generatePdf();

      // setTimeout(()=>{
      //   this.downloadLoader = false;
      // },5000)
    },

    generateRandomColor() {
      const minRed = 255;
      const maxRed = 255;
      const minGreen = 255;
      const maxGreen = 0;
      const minBlue = 0;
      const maxBlue = 0;

      const randomRed = Math.floor(
        Math.random() * (maxRed - minRed + 1) + minRed
      );
      const randomGreen = Math.floor(
        Math.random() * (maxGreen - minGreen + 1) + minGreen
      );
      const randomBlue = Math.floor(
        Math.random() * (maxBlue - minBlue + 1) + minBlue
      );

      const randomColor = `rgb(${randomRed},${randomGreen},${randomBlue})`;

      return randomColor;
    },

    demoReport() {
      // console.log("data demo videos...???", this.dataDemoVideos);
      // this.dataDemoVideos.sort((x, y) => x.date.localeCompare(y.date));

      // this.dataDemoVideos = this.dataDemoVideos[this.dataDemoVideos.length - 1]
      // localStorage.setItem("videoInfo", JSON.stringify(this.dataDemoVideos))
      // this.$store.commit("setVideoDetails", this.dataDemoVideos)

      this.$router.push(`/demoreport/${this.campaignId}/${this.userId}`);
      // this.demoDialog = true
    },

    // closeDemo() {
    //     this.demoDialog = false;
    //     // this.$router.back();
    //     this.switchResult(0);
    //     this.isSelect = 0;
    // },

    showProc() {
      if (this.bubbleChartData.length > 1) {
        this.proctorDialog = true;
      } else {
        alert("Proctoring data not found");
      }
    },

    switchResult(index) {
      if ([0,1].includes(index)) {
        // this.totalScore = 0;
        // this.maxMarks = 0;
        this.selectedResult = this.resultData[index];
        this.totalScore=this.selectedResult?.netScore

        this.maxTime = this.selectedResult.maximumTime / 60;
        this.userTime = (this.selectedResult.timeTakenByUser / 60).toFixed(2);
        if (this.selectedResult == null) {
          alert("Test not started yet");
          this.switchResult(0);
          this.isSelect = 0;
        } else {
          this.selectedSkill = this.resultData[index]?.skills[0];
          this.selectedSkillName = this.selectedSkill.skillName
          this.selectedSkill?.questions.forEach((obj) => {
            if (obj.myAnswer) {
              obj.currectAns = this.isAnswerCorrect(
                obj.myAnswer,
                obj.correctAnswer
              );
            }
          });
          
          console.log("selectedSkills...???", this.selectedSkill);
          this.progressLevel =
            (this.selectedSkill.score / this.selectedSkill.maximumMarks) * 100;
          this.selectedResult.skills.forEach((skill) => {
            // this.totalScore = this.totalScore + skill.score;
            // this.maxMarks += skill.maximumMarks;
          });
        }
      } else if (index === 2) {
        this.demoReport();
        // if (this.dataDemoVideos?.demovideos.length > 0) {
        //   this.demoReport();
        // } else {
        //   alert("Demo video not uploaded");
        //   this.switchResult(0);
        //   this.isSelect = 0;
        // }
      }else if (index === 3) {
        this.$router.push(`/interviewReport/${this.campaignId}/${this.userId}`);
      }
    },

    addDataToChart() {
      this.bubbleChartData.splice(1);
      this.bubbleChartOptions.hAxis.viewWindow.max =
        parseInt(
          this.timeToMinutes(
            this.userProctoringActivities[
              this.userProctoringActivities.length - 1
            ].time
          ) - this.timeToMinutes(this.userProctoringActivities[0].time)
        ) + 1;

      this.bubbleChartOptions.vAxis.viewWindow.max = 1;

      for (const act of this.userProctoringActivities) {
        // this.bubbleChartData.push([act.activity, this.timeToMinutes(act.time) - this.timeToMinutes(this.userProctoringActivities[0].time), act.time, act.activity, act.ques])
        this.bubbleChartData.push([
          act.activity,
          this.timeToMinutes(act.time) -
            this.timeToMinutes(this.userProctoringActivities[0].time),
          act.ques,
          act.activity,
          this.timeToMinutes(act.time) -
            this.timeToMinutes(this.userProctoringActivities[0].time),
        ]);

        this.bubbleChartOptions.vAxis.viewWindow.max = Math.max(
          this.bubbleChartOptions.vAxis.viewWindow.max,
          act.ques + 1
        );
      }
    },

    timeToMinutes(timeString) {
      var timeParts = timeString.split(":");
      var hours = parseInt(timeParts[0]);
      var minutes = parseInt(timeParts[1]);
      var seconds = parseInt(timeParts[2]);
      return (hours * 3600 + minutes * 60 + seconds) / 60;
    },

    getSelectedSkillData(skill, index) {
      console.log("skill :", skill);
      console.log("selectedResult :", this.selectedResult);
      this.currenrSkillIndex = index;
      this.selectedSkill = skill;
      // this.selectedSkillName = skill.skillName
      console.log("showing skills", this.selectedSkill);
      this.selectedSkill.questions.forEach((obj) => {
        if (obj.myAnswer) {
          obj.currectAns = this.isAnswerCorrect(
            obj.myAnswer,
            obj.correctAnswer
          );
        }
      });
      this.progressLevel =
        (this.selectedSkill.score / this.selectedSkill.maximumMarks) * 100;
    },

    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },

    async fetchCampaignAudience(id) {
      const campaignRef = collection(db, "campaigns", id, "audience");
      const campDetailsRef = doc(db, "campaigns", id);

      const docSnap = await getDoc(campDetailsRef);

      if (docSnap.exists()) {
        // console.log("camp details here..?", docSnap.data())
        const campDetails = docSnap.data();
        this.campName = campDetails.name;
        // console.log("name of the campaign..?", this.campName)
      }

      // console.log("heyyylooooo");
      onSnapshot(campaignRef, (snapshot) => {
        this.dataTableLoading = false;
        this.users = [];
        this.preSearchUser = [];
        var preUsers = [];
        if (snapshot.empty) {
        } else {
          this.count = snapshot.docs.length;
          snapshot.docs.forEach(async (doc) => {
            // this.users.push(doc.data());
            this.preSearchUser.push(doc.data());
            preUsers.push(doc.data());
          });
          this.users = this.calculateUsersForLeague(preUsers);

          // this.preSearchUser = response.data.audience;
          // var preUsers = response.data.audience == null ? [] : response.data.audience;
          this.chartData.invited = this.filterByStatus(
            preUsers,
            "INVITED"
          ).length;
          this.invited = this.filterByStatus(preUsers, "INVITED").length;
          this.screeningStarted = this.filterByStatus(
            preUsers,
            "SCREENING STARTED"
          ).length;
          this.screeningPassed = this.filterByStatus(
            preUsers,
            "SCREENING PASSED"
          ).length;
          this.mainsStarted = this.filterByStatus(
            preUsers,
            "MAINS STARTED"
          ).length;
          this.mainsPassed = this.filterByStatus(
            preUsers,
            "MAINS PASSED"
          ).length;
          this.screeningFailed = this.filterByStatus(
            preUsers,
            "SCREENING FAILED"
          ).length;
          this.mainsFailed = this.filterByStatus(
            preUsers,
            "MAINS FAILED"
          ).length;

          this.chartData.screeningStarted = this.filterByStatus(
            preUsers,
            "SCREENING STARTED"
          ).length;
          this.chartData.screeningPassed = this.filterByStatus(
            preUsers,
            "SCREENING PASSED"
          ).length;
          this.chartData.mainsStarted = this.filterByStatus(
            preUsers,
            "MAINS STARTED"
          ).length;
          this.chartData.mainsPassed = this.filterByStatus(
            preUsers,
            "MAINS PASSED"
          ).length;
          this.chartData.screeningFailed = this.filterByStatus(
            preUsers,
            "SCREENING FAILED"
          ).length;
          this.chartData.mainsFailed = this.filterByStatus(
            preUsers,
            "MAINS FAILED"
          ).length;
          this.apraisals = this.filterByStatus(preUsers, "MAINS PASSED").length;
          this.pip = this.filterByStatus(preUsers, "MAINS FAILED").length;
          this.tbd = this.filterByStatus(preUsers, "SCREENING FAILED").length;
        }
        // console.log("this.chartData", this.chartData)
      });
    },
  },
};
</script>

<style scoped>
.repCard {
  margin: 80px 12px 0 32px;
}

.tabBtn {
  font-size: 14px;
  border-color: inherit !important;
  width: 100% !important;
  height : auto !important;
  padding: 10px !important;
  border-left-width: thin !important;
}

.viewTestProctoringBox {
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
}

.pdfTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 12px;
}

.pdfSubTitle {
  margin: 8px 12px;
}

.page-break-item {
  page-break-after: always;
  /* Add a page break before each v-list-item */
}
.pdf-item{
  page-break-inside: avoid;
}

.rowSpacing {
  margin-bottom: 12px;
}

.chipSpacing {
  margin-right: 8px;
}

.pdfCard {
  padding: 4px;
}

.correctOption {
  color: green;
  padding-right: 12px;
  margin-top: 4px;
}

.otherOption {
  padding-right: 12px;
  margin-top: 4px;
}

.correctAnswer {
  color: green;
  padding-left: 4px;
}

.incorrectAnswer {
  color: red;
  padding-left: 4px;
}

.pdfHead {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 8px 12px;
}

.g-8 {
  gap: 8px
}

.core-skills-container {
  .text-subtitle-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 174px;
    font-family: "GraphikMedium", sans-serif !important;
  }
}
</style>