<template>
  <div v-if="preloader === true">
    <template>
      <v-dialog v-model="preloader" hide-overlay persistent width="300">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-dialog>
    </template>
  </div>

  <div class="users-view-container" v-else>
    <v-card
      height="64px"
      width="100%"
      class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
      style="
        position: fixed;
        z-index: 200;
        top: 0;
        width: 98%;
        padding-top: 16px;
      "
    >
      <v-card-title class="ml-0 pl-0">
        <p class="breadcrumb-text">Users</p>
        <span><v-icon class="breadcrumb-arrow">mdi-chevron-down</v-icon></span>
      </v-card-title>
      <div
        class="size-menu d-flex flex-row justify-end pr-2 w-fit align-center"
        style="position: fixed; right: 0"
      >
        <v-badge
          color="red"
          dot
          right
          transition="slide-x-transition"
          class="mr-8"
        >
          <img
            @click="notificationDialog = true"
            class="cursor"
            width="24px"
            src="../assets/bell 1.svg"
          />
        </v-badge>

        <div>
          <img
            src="../assets/avtar.png"
            class="rounded-xl mr-2"
            width="32"
            height="32"
          />
        </div>
        <div class="d-flex flex-column d-justify-evenly user-details">
          <v-card-title class="ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.name }}
          </v-card-title>
          <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
            {{ $store.state.userInfo.email }}
          </v-card-subtitle>
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="40px"
              height="40px"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon class="">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-btn @click="logout">logout</v-btn>
        </v-menu>
      </div>
    </v-card>

    <div fluid class="pad pl-8 pr-4">
      <div class="d-flex mb-4">
        <v-card
          class="background users-tab-container mb-0"
          height="132px"
          width="200px"
        >
          <!-- to="/users"  -->
          <v-btn
            @click="toAllTab"
            depressed
            width="195px"
            height="41px"
            class="rounded-lg mb-2 justify-start"
            :class="roleType.length > 1 ? 'blue-outline' : 'not-active-btn' "
          >
            <img class="mr-3" src="../assets/All Nav SVG.svg" />
            ALL
            ({{ this.teacherCount + this.jobSeekerCount }})
          </v-btn>
          <!-- to="/teachers" -->
          <!-- class="my-1 white rounded-xl text-subtitle-2 justify-start" -->

          <v-btn
            @click="toVgosTab"
            depressed
            width="195px"
            height="41px"
            class="rounded-lg mb-2 justify-start"
            :class="roleType[0] == 'Teacher' && roleType.length == 1 ? 'blue-outline' : 'not-active-btn' "
          >
            <img class="mr-3" src="../assets/Section Icons.svg" /> VGOS
            ({{
              this.teacherCount
            }})
          </v-btn>

          <!-- to="/jobseekers" -->

          <v-btn
            @click="toJobSeekerTab"
            active-class="highEmphasis"
            width="195px"
            height="41px"
            depressed
            class="rounded-lg justify-start"
            :class="roleType[0] == 'Job Seeker' ? 'blue-outline' : 'not-active-btn'"
            ><img class="mr-3" src="../assets/Job_Seeker.svg" /> Jobseekers
            ({{
              this.jobSeekerCount
            }})
          </v-btn>
        </v-card>
        <div class="chart-row d-flex flex-row justify-start">
          <v-card
            v-if="roleCluster == 'All Clusters' && roleType != 'Job Seeker'"
            width="33.3%"
            elevation="0"
            class="graph mb-0 cardBorder justify-center align-center overflow-hidden"
          >
            <HorizontalBarChartVue
              v-if="showHorizon"
              class="z-index-2"
              @data-updated="handleClusterData"
              :graphData="horizontalBarData"
            />
            <div v-else>
              <p class="graphHeading">Cluster</p>
              <p class="dataNotAvailable">Data Not Available</p>
            </div>
          </v-card>

          <v-card
            width="33.3%"
            elevation="0"
            class="graph mb-0 cardBorder pr-4 overflow-hidden"
          >
            <CampTotalPieChart
              v-if="showStatus"
              class="z-index-2"
              @data-updated="handleValue"
              :graphData="completionStatusGraph"
              message="completion Status"
            />
            <div v-else>
              <p class="graphHeading">Completion Status</p>
              <p class="dataNotAvailable">Data Not Available</p>
            </div>
          </v-card>

          <v-card
            width="33.3%"
            elevation="0"
            class="graph mb-0 cardBorder pr-4 align-center overflow-hidden"
          >
            <CampTotalPieChartLevel
              v-if="showlevel"
              class="z-index-2"
              @data-updated="handleData"
              :graphData="levelChartData"
              message="levels"
            />
            <div v-else>
              <p class="graphHeading">Levels</p>
              <p class="dataNotAvailable">Data Not Available</p>
            </div>
          </v-card>
          <v-card
            v-if="roleType == 'Job Seeker'"
            width="33.3%"
            min-height="30%"
            elevation="0"
            class="graph cardBorder pt-1"
          >
            <span class="graphHeading mx-2 mt-1 mb-2">Hiring Status</span>
            <v-card
              @click="getRecommended"
              height="32px"
              :class="
                hiringStatus[0] == 'hiringStatus : recommended'
                  ? 'grey lighten-3'
                  : ''
              "
              class="rounded custom-card py-1 px-3 mx-4 my-2 border-button"
              elevation="0"
              outline="0"
              >Recommended for Hiring -
              <span>{{
                recommended_count
              }}</span></v-card
            >
            <v-card
              @click="getRejected"
              height="32px"
              :class="
                hiringStatus[0] == 'hiringStatus : rejected'
                  ? 'grey lighten-3'
                  : ''
              "
              class="rounded custom-card py-1 px-3 mx-4 my-2 border-button"
              elevation="0"
              outline="0"
              >Rejected - <span>{{ rejected_count }}</span></v-card
            >
          </v-card>
        </div>
      </div>

      <!-- <v-row class="pl-3 pr-0 mb-4 w-100" justify="space-between">
        <div cols="1" class="pt-1 pb-0 pl-0 pr-0 mr-0">
          <div class="text-h6">{{ this.headding }}</div>
        </div> -->
      <div class="d-flex align-center mb-4">
        <div class="flex-grow-1">
          <div class="text-h6 pr-1 ml-auto">{{ this.headding }}</div>
        </div>
        <div
          class="px-0 mx-0 d-flex align-center justify-end"
        >
          <!-- <div cols="11" class="px-0 ml-0 d-flex flex-row align-center justify-end"> -->
          <!-- <v-row class="justify-end ml-0 pl-0"> -->
          <v-card width="326px" class="elevation-0 transparent">
            <v-text-field
              solo
              label="Search here..."
              hide-details
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              class="search-bar search-bar2 border-button rounded-pill mx-2"
              dense
              clearable
            ></v-text-field>
          </v-card>
          
          <v-btn
            @click="filterDialog = true"
            dense
            rounded
            class="border-button elevation-0 mx-2 pl-4 pr-6"
            ><v-icon class="pr-2">mdi-tune</v-icon>
            <span v-if="filterCount > 0">Filter ({{ filterCount }})</span>
            <span v-else>Filter</span>
          </v-btn>

          <!-- <v-btn depressed outlined rounded class="mx-3"><v-icon>mdi-archive</v-icon>VIEW ARCHIVE</v-btn> -->
          <v-btn
            class="border-button mx-2 pl-4 pr-6"
            outlined
            rounded
            @click="deleteDialog = true"
            v-if="$store.state.role.teacherOnboarding.admin"
            :disabled="selected.length == 0"
            ><v-icon class="pr-2">mdi-trash-can-outline</v-icon>Delete</v-btn
          >

          <v-btn
            class="border-button mx-2"
            outlined
            rounded
            @click="downlaodRoles"
            ><v-icon>mdi-import mdi-rotate-90</v-icon></v-btn
          >
          <!-- <v-btn
            :disabled="selected.length == 0"><v-icon>mdi-trash-can-outline</v-icon>Delete</v-btn>

             <v-btn
            class="border-button mx-2" outlined rounded
            :disabled="selected.length == 0"><v-icon>mdi-email-sync-outline</v-icon>Resend Invite</v-btn>
         -->
          <v-menu offset-y v-if="roleType != 'Job Seeker'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="accent ml-2 pl-4 pr-6"
                v-if="$store.state.role.teacherOnboarding.admin"
                rounded
                depressed
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="pr-2">mdi-plus</v-icon>
                <div class="">Create</div>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="goCreatePage">
                <v-list-item-title v-if="roleType != 'Job Seeker'">
                  <v-icon class="pr-1">mdi-email-plus-outline</v-icon>CREATE NEW
                  TEACHER
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="uploadBulkUsers = true"
                v-if="roleType != 'Job Seeker'"
              >
                <v-list-item-title>
                  <v-icon>mdi-account-multiple-plus </v-icon>

                  CREATE USERS IN BULK
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-dialog max-width="887px" class="cdz" v-model="uploadBulkUsers" center>
        <v-form ref="form" lazy-validation>
          <v-card>
            <v-card-title class="primary mb-8"
              >Upload Users in Bulk</v-card-title
            >

            <div class="d-flex justify-center mb-6 bg-surface-variant">
              <div class="box2" @dragover.prevent>
                <div
                  class="w-100 d-flex flex-row justify-center"
                  style="margin-top: 80px"
                >
                  <img src="../../public/svgs/xlsx_icon 1.svg" />
                </div>

                <div class="w-100 d-flex flex-row justify-center mt-4">
                  <div>
                    <div class="font-weight-light text-center">
                      Drag and drop a file or
                      <div class="uplaodbtn2">
                        browse file
                        <input
                          type="file"
                          @change="handleFileUpload"
                          name="csv"
                        />
                      </div>
                      <p class="grey--text">
                        (Supported only .XLSX, .csv file)
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div v-if="image">
                    <div class="imgback">
                      <img :src="image" alt="" width="100" height="auto" class="img" />
                      <div class="pencil">
                        <v-icon class="editpen">mdi-pencil-circle</v-icon>
                        <input class="editlogo" type="file" name="csv" />
                      </div>
                    </div>
                  </div> -->
            </div>

            <v-card-actions class="px-9 pb-6">
              <v-btn text @click="downloadCSV" class="mx-2 downloadColor">
                <img class="mr-2" src="../../public/svgs/fi_share.svg" />
                <span class="downloadColor"> Download Template Excel </span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                width="102px"
                height="48px"
                outlined
                class="accent--text pa-4"
                @click="
                  () => {
                    uploadBulkUsers = false; //
                    formbtnBool = false;
                  }
                "
                >Cancel</v-btn
              >
              <v-btn
                depressed
                @click="uploadUsers"
                rounded
                width="102px"
                height="48px"
                class="accent pa-4"
                >Create</v-btn
              >
            </v-card-actions>
            <!---------------------- subject upload section ends here --------------------------->
          </v-card>
        </v-form>
        <!---------------------- subject upload section starts here --------------------------->
      </v-dialog>

      <v-dialog
        max-width="600px"
        max-height="80vh"
        v-model="uploadingUsersDialog"
        center
      >
        <v-card class="pa-4">
          <v-row>
            <v-col cols="6" md="6">
              <v-card-title class="pa-0 ma-0">
                Uploading... {{ bulkUserUploadResponse.length }} /
                {{ bulkUsersJsonData.length }}
              </v-card-title>
            </v-col>
            <v-col cols="6" md="6">
              <v-btn
                class="float-right"
                icon
                @click="uploadingUsersDialog = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-data-table
            :headers="headersresponce"
            :items="bulkUserUploadResponse"
            :items-per-page="-1"
          >
            <template v-slot:[`item.status`]="{ item }">
              <div class="d-flex flex-row ml-2">
                {{ item.status }}

                <v-icon
                  v-if="item.status.includes('Uploaded Successfully!')"
                  color="green"
                  >mdi-check-all</v-icon
                >
                <v-icon v-else color="red">mdi-alert-circle-outline</v-icon>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex flex-row">
                <img
                  width="36px"
                  height="36"
                  @click="updateData(item)"
                  class="cursor"
                  src="../assets/edit.svg"
                />
                <img
                  width="36px"
                  height="36"
                  class="cursor"
                  src="../assets/userdelete.svg"
                />
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="filterDialog" class="cdz" max-width="570px">
        <v-card
          width="570px"
          height="100%"
          class="filterDialogClass scroll-view"
        >
          <v-row class="mt-4 mx-1">
            <v-col>
              <div class="pl-0 mb-2 text-body1 font-weight-bold grey--text">
                Filter
              </div>
            </v-col>
            <v-col class="d-flex justify-end">
              <div
                @click="clearFilter"
                class="text-body1 font-weight-bold black--text cursor"
              >
                CLEAR FILTER
              </div>
            </v-col>
          </v-row>
          <v-tabs vertical>
            <div class="ml-3 mt-4 mr-4 filterLine">
              <!-- <v-tab class="black--text justify-start" active-class="activeTab">Invited on</v-tab> -->

              <v-tab class="black--text justify-start" active-class="activeTab"
                >Status

                <span v-if="selectedStatusFilter.length > 0" class="ml-1"
                  >({{ selectedStatusFilter.length }})</span
                >
              </v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab"
                >Type
                <span v-if="roleTypeFilter.length > 0" class="ml-1"
                  >({{ roleTypeFilter.length }})</span
                >
              </v-tab>

              <v-tab class="black--text justify-start" active-class="activeTab"
                >Level
                <span v-if="levelTypeFilter.length > 0" class="ml-1"
                  >({{ levelTypeFilter.length }})</span
                ></v-tab
              >

              <v-tab class="black--text justify-start" active-class="activeTab"
                >Subject
                <span v-if="subjectTypeFilter.length > 0" class="ml-1"
                  >({{ subjectTypeFilter.length }})</span
                >
              </v-tab>

              <v-tab
                class="black--text justify-start"
                v-if="this.clusterSchool == ''"
                active-class="activeTab"
                >Cluster & School
                <span v-if="schoolTypeFilter.length > 0" class="ml-1"
                  >({{ schoolTypeFilter.length }})</span
                >
              </v-tab>
            </div>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="selectedStatusFilter"
                    active-class="secondary"
                    column
                    :multiple="true"
                    @change="getFilterCount"
                  >
                    <v-chip
                      v-for="(value, index) in status"
                      :key="index"
                      elevated
                      :value="value"
                    >
                      {{ value }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="roleTypeFilter"
                    active-class="secondary"
                    column
                    :multiple="true"
                    @change="getFilterCount"
                  >
                    <v-chip
                      v-for="(s, index) in role"
                      :value="s"
                      :key="index"
                      elevated
                    >
                      {{ s }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="levelTypeFilter"
                    active-class="secondary"
                    column
                    :multiple="true"
                    @change="getFilterCount"
                  >
                    <v-chip
                      v-for="(data, index) in tableLevels"
                      :key="index"
                      elevated
                      :value="data.name"
                    >
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="school-filter">
                <v-card-text>
                  <v-chip-group
                    v-model="subjectTypeFilter"
                    active-class="secondary"
                    column
                    :multiple="true"
                    @change="getFilterCount"
                  >
                    <v-chip
                      v-for="(data, index) in subjectsData"
                      :key="index"
                      elevated
                      :value="data.name"
                    >
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- cluster and school -->
            <v-tab-item v-if="this.clusterSchool == ''">
              <v-card flat class="school-filter">
                <v-card-text class="align-center d-flex flex-column">
                  <v-card-title class="ml-0 pl-0"> Clusters </v-card-title>
                  <v-chip-group
                    v-model="clusterTypeFilter"
                    active-class="secondary"
                    column
                    :multiple="true"
                    @change="getFilterCount"
                  >
                    <!-- @click="getClustersSchool" -->
                    <v-chip
                      v-for="(data, index) in clusters"
                      :key="index"
                      elevated
                      :value="data.name"
                    >
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>

                  <v-card-title class="pb-4 ml-0 pl-0"> Schools </v-card-title>

                  <v-card width="326px" class="elevation-0 transparent">
                    <v-text-field
                      solo
                      label="Search here..."
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      v-model="searchSchool"
                      class="search-bar2 border-button rounded-pill mx-2"
                      dense
                      clearable
                    ></v-text-field>
                  </v-card>

                  <v-progress-circular
                    class="mt-10"
                    indeterminate
                    v-if="
                      clustersSchool.length == 0 &&
                      clusterTypeFilter.length != 0
                    "
                    :size="76"
                  ></v-progress-circular>
                  <v-chip-group
                    v-model="schoolTypeFilter"
                    class="mt-4"
                    active-class="secondary"
                    column
                    @change="getFilterCount"
                    :multiple="true"
                  >
                    <v-chip
                      v-for="(data, index) in showClusterSchool"
                      height="40px"
                      class="w-100 rounded-lg"
                      :rounded="10"
                      :key="index"
                      elevated
                      :value="data.name"
                    >
                      {{ data.name }}
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <div>
            <v-card-actions class="px-6 my-2">
              <v-spacer></v-spacer>
              <v-btn
                rounded
                outlined
                class="accent--text pa-4"
                @click="filterDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                rounded
                class="accent mx-4 pa-4"
                :disabled="filterCount == 0"
                :loading="dLoading"
                @click="
                  (appliedFilter = true),
                    (filterDialog = false),
                    filterUser(
                      selectedStatusFilter,
                      roleTypeFilter,
                      levelTypeFilter,
                      subjectTypeFilter,
                      clusterTypeFilter,
                      schoolTypeFilter
                    ),
                    getFilterCount()
                "
                >Apply</v-btn
              >
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <!-- Notification Dialog -->

      <v-dialog v-model="notificationDialog" class="cdz" max-width="380px">
        <Notification @close-dialog="notificationDialog = false" />
      </v-dialog>

      <v-dialog v-model="deleteDialog" max-width="366px" class="cdz" persistent>
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-container></v-container>
              <v-avatar color="background" size="90"
                ><v-icon size="65">mdi-trash-can-outline</v-icon></v-avatar
              >
              <p class="text-h5 pt-6 pb-0">Delete User</p>
              <p
                class="text-disabled grey--text text-subtitle-1 pt-3"
                color="rgba(0, 0, 0, 0.6)"
                disabled
              >
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between pt-4 pb-2" fluid>
                <v-btn
                  depressed
                  class="background black--text"
                  large
                  width="157px"
                  rounded
                  @click="
                    () => {
                      deleteDialog = false;
                      formbtnBool = false;
                    }
                  "
                  >CANCEL</v-btn
                >
                <v-btn
                  class="accent white--text"
                  :loading="dLoading"
                  depressed
                  large
                  width="157px"
                  rounded
                  :disabled="selected.length == 0"
                  @click="deleteUser(selected)"
                  >DELETE</v-btn
                >
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="deleteSuccessDialog"
        max-width="366px"
        class="cdz"
        persistent
      >
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="#228B22" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 font-weight-bold py-4">User Deleted</p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="deleteSuccessDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <v-data-table
        @click:row="updateData"
        fixed-header
        height="calc(100vh - 412px)"
        :loading="isLoading"
        v-model="selected"
        :headers="headers"
        :items="users"
        show-select
        :single-select="singleSelect"
        item-key="userId"
        :options.sync="options"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100],
        }"
        :server-items-length="count"
        class="cursor usertable rounded-table"
      >
        <template v-slot:[`item.fullname`]="{ item }">
          {{ item.personalInfo?.firstName + " " + item.personalInfo?.lastName }}
        </template>

        <template v-slot:[`item.contactdetails`]="{ item }">
          <div
            class="overflow-text"
            v-if="
              item.personalInfo?.isEmailVerified === true ||
              item.personalInfo?.isEmailVerified === 'true' ||
              item.personalInfo?.isEmailVerified === 'TRUE'
            "
          >
            <v-icon color="#06C270">mdi-check</v-icon
            >{{ item.personalInfo?.contactInfo.email }}
          </div>
          <div
            v-if="
              item.personalInfo?.isPhoneVerified === true ||
              item.personalInfo?.isPhoneVerified === 'true' ||
              item.personalInfo?.isPhoneVerified === 'TRUE'
            "
          >
            <v-icon color="#06C270">mdi-check</v-icon
            >{{ item.personalInfo?.contactInfo.phoneNumber }}
          </div>
          <div
            v-if="
              item.personalInfo?.isEmailVerified === false ||
              item.personalInfo?.isEmailVerified === 'false' ||
              item.personalInfo?.isEmailVerified === 'FALSE'
            "
          >
            <v-icon color="#F08D32">mdi-alert-outline</v-icon
            >{{ item.personalInfo?.contactInfo.email }}
          </div>
          <div
            v-if="
              item.personalInfo?.isPhoneVerified === false ||
              item.personalInfo?.isPhoneVerified === 'false' ||
              item.personalInfo?.isPhoneVerified === 'FALSE'
            "
          >
            <v-icon color="#F08D32">mdi-alert-outline</v-icon
            >{{ item.personalInfo?.contactInfo.phoneNumber }}
          </div>
        </template>

        <template v-slot:[`item.level`]="{ item }">
          <div
            v-if="
              item.userType === 'teacher' ||
              item.userType === 'TEACHER' ||
              item.userType === 'Teacher'
            "
          >
            <span v-for="(lev, i) in item.responsibleTeachingAt" :key="i">
              <span v-if="i < 2">
                {{ lev.level
                }}<span v-if="i < 1 && item.responsibleTeachingAt.length != 1"
                  >,</span
                >
              </span>
            </span>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="item.responsibleTeachingAt.length - 2 > 0"
                  v-bind="attrs"
                  v-on="on"
                >
                  +{{ item.responsibleTeachingAt.length - 2 }}
                </span>
              </template>
              <div
                v-for="(item, j) in tooltipData(item.responsibleTeachingAt)"
                :key="j"
                v-html="item.level"
              ></div>
            </v-tooltip>
          </div>

          <div v-if="item.teachingInterests != undefined">
            <span v-for="(lev, i) in item.teachingInterests.levels" :key="i">
              <span v-if="i < 2">
                {{ lev }}
                <span v-if="i < 1 && item.teachingInterests.levels.length != 1"
                  >,</span
                >
              </span>
            </span>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="item.teachingInterests.levels.length - 2 > 0"
                  v-bind="attrs"
                  v-on="on"
                >
                  +{{ item.teachingInterests.levels.length - 2 }}
                </span>
              </template>
              <div
                v-for="(item, j) in tooltipData(item.teachingInterests.levels)"
                :key="j"
                v-html="item"
              ></div>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.schoolName`]="{ item }">
          <div
            v-if="
              item.userType === 'teacher' ||
              item.userType === 'TEACHER' ||
              item.userType === 'Teacher'
            "
          >
            <span>{{ item.schoolName }} </span>
          </div>

          <div v-if="item.teachingInterests !== undefined">
            <span v-for="(school, index) in item.teachingInterests.schools" :key="index">
              <span v-if="index < 2">
                {{ school
                }}<span
                  v-if="index < 1 && item.teachingInterests.schools.length != 1"
                  >,</span
                >
              </span>
            </span>
          </div>
        </template>

        <template v-slot:[`item.subject`]="{ item }">
          <div
            v-if="
              item.userType === 'teacher' ||
              item.userType === 'TEACHER' ||
              item.userType === 'Teacher'
            "
          >
            <span v-for="(sub, i) in item.responsibleTeachingAt" :key="i">
              <span v-if="i < 2">
                {{ sub.subject
                }}<span v-if="i < 1 && item.responsibleTeachingAt.length != 1"
                  >,</span
                >
              </span>
            </span>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="item.responsibleTeachingAt.length - 2 > 0"
                  v-bind="attrs"
                  v-on="on"
                >
                  +{{ item.responsibleTeachingAt.length - 2 }}
                </span>
              </template>
              <div
                v-for="(item, j) in tooltipData(item.responsibleTeachingAt)"
                :key="j"
                v-html="item.subject"
              ></div>
            </v-tooltip>
          </div>
          <div v-if="item.teachingInterests != undefined">
            <span v-for="(sub, i) in item.teachingInterests.subjects" :key="i">
              <span v-if="i < 2">
                {{ sub
                }}<span
                  v-if="i < 1 && item.teachingInterests.subjects.length != 1"
                  >,</span
                >
              </span>
            </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="item.teachingInterests.subjects.length - 2 > 0"
                  v-bind="attrs"
                  v-on="on"
                >
                  +{{ item.teachingInterests.subjects.length - 2 }}
                </span>
              </template>
              <div
                v-for="(item, j) in tooltipData(
                  item.teachingInterests.subjects
                )"
                :key="j"
                v-html="item"
              ></div>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.createdOn`]="{ item }">
          {{ getDate(item.createdOn) }}
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" class="cdz" fullscreen hide-overlay>
        <v-card class="background">
          <v-card-title class="fixBar background py-1">
            <v-toolbar elevation="0" class="background">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-toolbar-title class="text-h5">
                    {{ formbtn() }} User</v-toolbar-title
                  >
                </v-col>
              </v-row>
              <v-spacer></v-spacer>
              <v-btn class="float-right" icon @click="dialog = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-toolbar>
          </v-card-title>

          <v-card-text class="createUserPad">
            <v-container>
              <v-stepper elevation="0" class="background" v-model="e1">
                <v-card outlined elevation="0" class="pb-5 background">
                  <v-stepper-header class="text-subtitle-2 white">
                    <v-stepper-step color="#4D695B" :complete="e1 > 1" step="1">
                      PERSONAL INFORMATION
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step color="#4D695B" :complete="e1 > 2" step="2">
                      ACADEMICS
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step color="#4D695B" step="3">
                      PROFESSIONAL INFO
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step step="4"> VGOS Onboarding </v-stepper-step>
                  </v-stepper-header>
                </v-card>
                <div class="pb-5 text-h6">
                  {{ getStepName(this.e1) }}
                </div>

                <v-card outlined elevation="0">
                  <v-stepper-items>
                    <!------------------------------------------ STEP 1 ------------------------------------------>
                    <v-stepper-content step="1">
                      <v-form lazy-validation ref="step1">
                        <v-card elevation="0">
                          <v-row>
                            <v-col cols="12">
                              <v-card class="pa-4" elevation="0">
                                <v-row class="py-0">
                                  <v-col cols="2" class="py-0"
                                    ><v-select
                                      v-model="personalInfo.title"
                                      label="Title *"
                                      :items="['Ms', 'Mrs', 'Mr']"
                                      outlined
                                      class="rounded-xl"
                                      :rules="[
                                        (v) => !!v || 'Title is required',
                                      ]"
                                      required
                                      attach
                                      density="comfortable"
                                    >
                                    </v-select
                                  ></v-col>
                                  <v-col cols="4" class="py-0"
                                    ><v-text-field
                                      v-model="personalInfo.firstName"
                                      :rules="[
                                        (v) => !!v || 'First Name is required',
                                      ]"
                                      required
                                      outlined
                                      label="First Name *"
                                      rounded
                                      class="rounded-xl"
                                    ></v-text-field></v-col
                                  ><v-col cols="3" class="py-0"
                                    ><v-text-field
                                      v-model="personalInfo.middleName"
                                      outlined
                                      label="Middle Name"
                                      rounded
                                      class="rounded-xl"
                                    ></v-text-field></v-col
                                  ><v-col cols="3" class="py-0"
                                    ><v-text-field
                                      v-model="personalInfo.lastName"
                                      outlined
                                      label="Last Name*"
                                      rounded
                                      class="rounded-xl"
                                      :rules="[
                                        (v) => !!v || 'Last Name is required',
                                      ]"
                                    ></v-text-field
                                  ></v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col class="py-0"
                                    ><v-text-field
                                      v-model="personalInfo.contactInfo.email"
                                      outlined
                                      label="Email Address *"
                                      rounded
                                      class="rounded-xl"
                                      required
                                      :rules="[
                                        (v) =>
                                          !!v || 'Email Address is required',
                                      ]"
                                    ></v-text-field
                                  ></v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col class="py-0"
                                    ><v-text-field
                                      label="Your 10-digit mobile no. *"
                                      outlined
                                      rounded
                                      class="rounded-xl"
                                      counter="10"
                                      prefix="+91"
                                      :rules="[
                                        (v) =>
                                          !!v || 'Mobile number is required',
                                        (v) =>
                                          (v &&
                                            v.length >= 10 &&
                                            v.length <= 10) ||
                                          'Mobile number must be 10 digit',
                                      ]"
                                      @keypress="isNumber($event)"
                                      maxLength="10"
                                      v-model="
                                        personalInfo.contactInfo.phoneNumber
                                      "
                                    ></v-text-field
                                  ></v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col cols="4" class="py-0">
                                    <v-text-field
                                      outlined
                                      label="Date of Birth (DDMMYY) *"
                                      rounded
                                      class="rounded-xl"
                                      type="date"
                                      :rules="[
                                        (v) =>
                                          !!v || 'Date of Birth is required',
                                      ]"
                                      v-model="personalInfo.dob"
                                    ></v-text-field
                                  ></v-col>
                                  <v-col cols="2" class="py-0">
                                    <v-select
                                      label="Gender *"
                                      :items="['MALE', 'FEMALE', 'OTHERS']"
                                      outlined
                                      class="rounded-xl"
                                      v-model="personalInfo.gender"
                                      :rules="[
                                        (v) => !!v || 'Gender is required',
                                      ]"
                                      required
                                    >
                                    </v-select>
                                  </v-col>
                                </v-row>

                                <v-row class="py-0">
                                  <v-col cols="6" class="py-0">
                                    <v-select
                                      v-model="
                                        personalInfo.addressInfo.countryName
                                      "
                                      readonly
                                      label="Country"
                                      :items="countries"
                                      item-value="name"
                                      item-text="name"
                                      outlined
                                      class="rounded-xl"
                                      :rules="[
                                        (v) => !!v || 'Country is required',
                                      ]"
                                      required
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="6" class="py-0">
                                    <v-select
                                      v-model="
                                        personalInfo.addressInfo.stateName
                                      "
                                      label="State"
                                      :items="states"
                                      outlined
                                      class="rounded-xl"
                                      item-value="state"
                                      item-text="state"
                                      @change="fetchDistricts"
                                      :rules="[
                                        (v) => !!v || 'State is required',
                                      ]"
                                      required
                                    >
                                    </v-select>
                                  </v-col>
                                </v-row>
                                <v-row class="py-0">
                                  <v-col cols="6" class="py-0">
                                    <v-select
                                      v-model="
                                        personalInfo.addressInfo.districtName
                                      "
                                      label="District"
                                      :items="districts"
                                      outlined
                                      class="rounded-xl"
                                      item-value="district"
                                      item-text="district"
                                      @change="fetchCitiesAndTalukas"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="6" class="py-0">
                                    <v-text-field
                                      v-model="
                                        personalInfo.addressInfo.tulakName
                                      "
                                      label="Taluk / Tehsil"
                                      outlined
                                      class="rounded-xl"
                                    >
                                    </v-text-field>
                                  </v-col> </v-row
                                ><v-row class="py-0">
                                  <v-col cols="6" class="py-0">
                                    <v-select
                                      v-model="
                                        personalInfo.addressInfo.cityName
                                      "
                                      label="City / Village"
                                      :items="cities"
                                      outlined
                                      class="rounded-xl"
                                      item-value="city"
                                      item-text="city"
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="6" class="py-0">
                                    <v-text-field
                                      v-model="personalInfo.addressInfo.pincode"
                                      outlined
                                      label="Pin Code"
                                      rounded
                                      :rules="[
                                        (v) => !!v || 'Pincode is require',
                                        (v) =>
                                          (v &&
                                            v.length >= 6 &&
                                            v.length <= 6) ||
                                          'Pincode must be 6 digit',
                                      ]"
                                      @keypress="isNumber($event)"
                                      maxLength="6"
                                      counter="6"
                                      class="rounded-xl"
                                      required
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" class="py-0">
                                    <v-text-field
                                      v-model="personalInfo.addressInfo.address"
                                      outlined
                                      label="Address"
                                      rounded
                                      class="rounded-xl"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-form>

                      <v-btn
                        depressed
                        rounded
                        color="primary"
                        class="mx-4 my-4 btn-color white--text"
                        @click="goToStep2"
                      >
                        NEXT
                      </v-btn>
                    </v-stepper-content>
                    <!------------------------------------------ STEP 2 ------------------------------------------>
                    <v-stepper-content step="2">
                      <v-form lazy-validation ref="step2">
                        <v-card class="white px-0 py-0" elevation="0">
                          <v-card outlined class="px-0 py-0">
                            <v-expansion-panels v-model="expandedPanelIndex">
                              <v-expansion-panel
                                v-for="(qualification, index) in academicsInfo"
                                :key="index"
                                elevation="0"
                              >
                                <v-expansion-panel-header>
                                  <div
                                    class="d-flex flex-column"
                                    v-if="expandedPanelIndex != index"
                                  >
                                    <div class="font-weight-regular">
                                      {{
                                        index +
                                        1 +
                                        ". " +
                                        qualification.programme
                                      }}
                                    </div>
                                    <div
                                      class="text-body-2 grey--text pt-2 pb-2"
                                    >
                                      {{ qualification.institution }}
                                    </div>
                                    <div class="text-body-2 grey--text">
                                      {{
                                        new Date(
                                          qualification.startDate
                                        ).getFullYear() +
                                        " - " +
                                        new Date(
                                          qualification.endDate
                                        ).getFullYear()
                                      }}
                                    </div>
                                  </div>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                  <v-row class="py-0">
                                    <v-col class="py-0"
                                      ><v-text-field
                                        v-model="qualification.institution"
                                        outlined
                                        label="School/ College/ University *"
                                        rounded
                                        class="rounded-xl"
                                        :rules="[
                                          (v) =>
                                            !!v ||
                                            'School/ College/ University is required',
                                        ]"
                                        required
                                      ></v-text-field
                                    ></v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col class="py-0"
                                      ><v-text-field
                                        v-model="qualification.programme"
                                        outlined
                                        label="Degree/ Diploma/ Certification *"
                                        rounded
                                        class="rounded-xl"
                                        :rules="[
                                          (v) =>
                                            !!v ||
                                            'Degree/ Diploma/ Certification is required',
                                        ]"
                                        required
                                      ></v-text-field
                                    ></v-col> </v-row
                                  ><v-row class="py-0">
                                    <v-col class="py-0"
                                      ><v-text-field
                                        v-model="qualification.fieldOfStudy"
                                        outlined
                                        label="Field of Study"
                                        rounded
                                        class="rounded-xl"
                                      ></v-text-field
                                    ></v-col>
                                  </v-row>

                                  <v-row class="py-0">
                                    <v-col cols="6" class="py-0"
                                      ><v-text-field
                                        v-model="qualification.startDate"
                                        outlined
                                        label="Start Date*"
                                        rounded
                                        class="rounded-xl"
                                        type="date"
                                        :rules="[
                                          (v) =>
                                            !!v || 'Start Date is required',
                                        ]"
                                      ></v-text-field
                                    ></v-col>
                                    <v-col cols="6" class="py-0"
                                      ><v-text-field
                                        v-model="qualification.endDate"
                                        outlined
                                        label="End Date*"
                                        rounded
                                        class="rounded-xl"
                                        type="date"
                                        :rules="[
                                          (v) => !!v || 'End Date is required',
                                        ]"
                                      ></v-text-field
                                    ></v-col>
                                  </v-row>

                                  <v-row class="py-0">
                                    <v-col cols="12" class="py-0">
                                      <v-text-field
                                        v-model="extraCarricularActivities"
                                        outlined
                                        label="Extra Curricular Activities"
                                        rounded
                                        class="rounded-xl"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row class="py-0">
                                    <v-col cols="12" class="py-0">
                                      <v-text-field
                                        outlined
                                        label="Achievements"
                                        rounded
                                        class="rounded-xl"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="d-flex justify-space-between"
                                    >
                                      <v-btn
                                        v-if="academicsInfo.length != 1"
                                        @click="removeAcademic(index)"
                                        text
                                        class="d-flex justify-end red--text"
                                        >Remove</v-btn
                                      >
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>

                            <v-row>
                              <v-col class="mt-4">
                                <v-btn text @click="addAcademicQualification"
                                  >Add More Qualification</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-card>
                      </v-form>
                      <v-btn
                        rounded
                        text
                        class="mx-4 my-4"
                        color="brown-text"
                        @click="e1 = 1"
                      >
                        BACK
                      </v-btn>

                      <v-btn
                        depressed
                        rounded
                        color="primary"
                        class="mx-4 my-4 btn-color white--text"
                        @click="goToStep3"
                      >
                        NEXT
                      </v-btn>
                    </v-stepper-content>
                    <!------------------------------------------ STEP 3 ------------------------------------------>
                    <v-stepper-content step="3">
                      <v-form lazy-validation ref="step3">
                        <v-card elevation="0">
                          <v-card class="pa-4" elevation="0">
                            <v-expansion-panels v-model="expandedPanelIndex">
                              <v-expansion-panel
                                v-for="(
                                  professional, index
                                ) in professionalInfo"
                                :key="index"
                                elevation="0"
                              >
                                <v-expansion-panel-header>
                                  <div
                                    class="d-flex flex-column"
                                    v-if="expandedPanelIndex != index"
                                  >
                                    <div class="font-weight-regular">
                                      {{
                                        index + 1 + ". " + professional.position
                                      }}
                                    </div>
                                    <div class="text-body-2 grey--text">
                                      {{
                                        new Date(
                                          professional.startDate
                                        ).getFullYear() +
                                        " - " +
                                        new Date(
                                          professional.endDate
                                        ).getFullYear()
                                      }}
                                    </div>
                                  </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <v-row class="py-0">
                                    <v-col class="py-0">
                                      <v-card
                                        width="100%"
                                        elevation="0"
                                        class="mb-10 rounded-xl"
                                        outlined
                                        ><v-radio-group
                                          mandatory
                                          row
                                          v-model="experience"
                                          :rules="[
                                            (v) => !!v || 'Please select one',
                                          ]"
                                          required
                                        >
                                          <v-col class="py-0">
                                            <v-row class="py-0 px-0 ml-2">
                                              <v-radio
                                                class="mb-8 py-0"
                                                label="I have"
                                                value="Experienced"
                                              >
                                              </v-radio>

                                              <v-col
                                                cols="1 center"
                                                class="py-0 px-0"
                                              >
                                                <v-text-field
                                                  :disabled="
                                                    experience == 'Fresher'
                                                      ? true
                                                      : false
                                                  "
                                                  type="number"
                                                  outlined
                                                  @keypress="isNumber($event)"
                                                  class="rounded-xl"
                                                  v-model="
                                                    professional.experienceYear
                                                  "
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col
                                                cols="1 center"
                                                class="py-0 px-0"
                                              >
                                                <div class="pt-4 ml-4">
                                                  Years
                                                </div>
                                              </v-col>
                                              <v-col cols="1" class="py-0 px-0">
                                                <v-text-field
                                                  :disabled="
                                                    experience == 'Fresher'
                                                      ? true
                                                      : false
                                                  "
                                                  type="number"
                                                  @keypress="isNumber($event)"
                                                  outlined
                                                  class="rounded-xl"
                                                  v-model="
                                                    professional.experienceMonth
                                                  "
                                                >
                                                </v-text-field>
                                              </v-col>
                                              <v-col
                                                cols="4 center"
                                                class="py-0 px-0"
                                              >
                                                <div class="pt-4 ml-4">
                                                  Months of experiences
                                                </div>
                                              </v-col>
                                            </v-row>
                                            <v-row class="pb-4 ml-2">
                                              <v-radio
                                                label="Fresher"
                                                value="Fresher"
                                              >
                                              </v-radio
                                            ></v-row>
                                          </v-col> </v-radio-group
                                      ></v-card>
                                    </v-col>
                                  </v-row>
                                  <div v-if="experience == 'Experienced'">
                                    <v-row class="py-0">
                                      <v-col class="py-0"
                                        ><v-text-field
                                          outlined
                                          label="Role/ Position *"
                                          rounded
                                          class="rounded-xl"
                                          counter="100"
                                          maxLength="100"
                                          :rules="[
                                            (v) =>
                                              !!v ||
                                              'Role/ Position is required',
                                          ]"
                                          required
                                          v-model="professional.position"
                                        ></v-text-field
                                      ></v-col> </v-row
                                    ><v-row class="py-0">
                                      <v-col class="py-0"
                                        ><v-select
                                          label="Employment Type"
                                          :items="employeeType"
                                          outlined
                                          item-value="id"
                                          item-text="name"
                                          class="rounded-xl"
                                          v-model="professional.employeeTypeId"
                                        >
                                        </v-select
                                      ></v-col>
                                    </v-row>

                                    <v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-text-field
                                          v-model="professional.school"
                                          outlined
                                          label="School / Institute"
                                          rounded
                                          class="rounded-xl"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                    <v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-checkbox
                                          class="py-0"
                                          v-model="isCurrentlyWorking"
                                          label="I am currently working on this role / position."
                                        ></v-checkbox>
                                      </v-col>
                                    </v-row>
                                    <v-row class="py-0">
                                      <v-col cols="6" class="py-0"
                                        ><v-text-field
                                          outlined
                                          label="Start Date*"
                                          rounded
                                          class="rounded-xl"
                                          v-model="professional.startDate"
                                          type="date"
                                          :rules="[
                                            (v) =>
                                              !!v || 'Start Date is required',
                                          ]"
                                        ></v-text-field
                                      ></v-col>
                                      <v-col cols="6" class="py-0"
                                        ><v-text-field
                                          :disabled="isCurrentlyWorking"
                                          outlined
                                          label="End Date"
                                          rounded
                                          class="rounded-xl"
                                          v-model="professional.endDate"
                                          type="date"
                                          :rules="[
                                            (v) =>
                                              !!v || 'End Date is required',
                                          ]"
                                        ></v-text-field
                                      ></v-col>
                                    </v-row>
                                    <v-row class="py-0">
                                      <v-col class="py-0"
                                        ><v-select
                                          label="Board"
                                          :items="boardsData"
                                          item-text="name"
                                          item-value="name"
                                          outlined
                                          class="rounded-xl"
                                          v-model="professional.board"
                                        >
                                        </v-select
                                      ></v-col> </v-row
                                    ><v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-autocomplete
                                          clearable
                                          deletable-chips
                                          label="Levels"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="tableLevels"
                                          multiple
                                          item-text="name"
                                          v-model="professional.levels"
                                          item-value="name"
                                        >
                                        </v-autocomplete>
                                      </v-col> </v-row
                                    ><v-row class="py-0">
                                      <v-col class="py-0">
                                        <!-- v-model="professional.grades" -->
                                        <v-autocomplete
                                          v-model="professional.grades"
                                          clearable
                                          deletable-chips
                                          label="Grades"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="gradesData"
                                          multiple
                                          item-text="name"
                                          item-value="name"
                                        >
                                        </v-autocomplete
                                      ></v-col> </v-row
                                    ><v-row class="py-0">
                                      <v-col class="py-0">
                                        <!-- v-model="professional.subjects" -->

                                        <v-autocomplete
                                          v-model="professional.subjects"
                                          clearable
                                          deletable-chips
                                          label="Subjects"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="subjectsData"
                                          multiple
                                          item-text="name"
                                          item-value="name"
                                        >
                                        </v-autocomplete
                                      ></v-col>
                                    </v-row>
                                  </div>
                                  <div v-if="experience != 'Experienced'">
                                    <v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-autocomplete
                                          clearable
                                          deletable-chips
                                          label="board"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="boardsData"
                                          item-text="name"
                                          item-value="name"
                                          multiple
                                        >
                                        </v-autocomplete>
                                      </v-col> </v-row
                                    ><v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-autocomplete
                                          clearable
                                          deletable-chips
                                          label="Level"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="tableLevels"
                                          multiple
                                          item-text="name"
                                          item-value="name"
                                        >
                                        </v-autocomplete>
                                      </v-col>
                                    </v-row>

                                    <v-row class="py-0">
                                      <v-col class="py-0">
                                        <v-autocomplete
                                          clearable
                                          deletable-chips
                                          label="Subject"
                                          outlined
                                          class="rounded-xl"
                                          small-chips
                                          :items="subjectsData"
                                          multiple
                                          item-text="name"
                                          item-value="name"
                                        >
                                        </v-autocomplete>
                                      </v-col>
                                    </v-row>
                                  </div>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="d-flex justify-space-between"
                                    >
                                      <v-btn
                                        v-if="
                                          experience == 'Experienced' &&
                                          professionalInfo.length != 1
                                        "
                                        @click="removeProfessional(index)"
                                        text
                                        class="d-flex justify-end red--text"
                                        >Remove</v-btn
                                      >
                                    </v-col>
                                  </v-row>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                            <v-row>
                              <v-col
                                class="mt-4"
                                v-if="experience == 'Experienced'"
                              >
                                <v-btn text @click="addProfessionalInfo"
                                  >Add More Qualification</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-card>
                      </v-form>
                      <v-container>
                        <v-row>
                          <v-btn
                            rounded
                            outlined
                            class="ma-4"
                            color="brown-text"
                            @click="e1 = e1 - 1"
                          >
                            BACK
                          </v-btn>

                          <v-btn
                            depressed
                            rounded
                            @click="e1++"
                            color="primary"
                            class="mx-4 my-4 btn-color white--text"
                          >
                            Next
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                      <v-form lazy-validation ref="step4">
                        <v-card elevation="0">
                          <v-card class="pa-4" elevation="0">
                            <v-row class="py-0 px-0">
                              <!-- <v-col cols="6" class="py-0"> -->
                              <!-- level -->
                              <!-- <div class="text-body-1">Subjects</div> -->
                              <!-- <v-autocomplete
                              clearable
                              deletable-chips
                              :readonly="tag == false ? true : false"
                              label="Choose Subjects"
                              outlined
                              v-model="campaignData.subjects"
                              :rules="[(v) => !!v || 'Subject is required']"
                              class="rounded-xl"
                              small-chips
                              :items="subjects"
                              multiple
                              item-text="name"
                              item-value="name"
                              solo
                              flat
                            >
                            </v-autocomplete> -->
                              <!-- </v-col> -->
                              <v-col cols="6" class="py-0">
                                <div class="text-body-1">Cluster</div>
                                <v-autocomplete
                                  clearable
                                  deletable-chips
                                  label="Choose Clusters"
                                  outlined
                                  :disabled="roleCluster != 'All Clusters'"
                                  v-model="cluster"
                                  required
                                  :rules="[(v) => !!v || 'Cluster is required']"
                                  class="rounded-xl"
                                  :items="clusters"
                                  item-text="name"
                                  item-value="name"
                                  solo
                                  flat
                                  @change="getClusterSchool"
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col class="py-0" cols="6" md="6" sm="6">
                                <div class="text-body-1">School</div>
                                <!-- :disabled="roleSchool != 'All'" -->
                                <!-- v-model="schools" -->

                                <v-autocomplete
                                  clearable
                                  deletable-chips
                                  label="Choose School"
                                  outlined
                                  v-model="school"
                                  :disabled="roleSchool != 'All'"
                                  required:rules="[v => !!v || 'School is required']"
                                  class="rounded-xl"
                                  :items="schools"
                                  item-text="name"
                                  item-value="name"
                                  solo
                                  flat
                                >
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="py-0">
                                <v-autocomplete
                                  clearable
                                  deletable-chips
                                  label="Level"
                                  outlined
                                  class="rounded-xl"
                                  :items="tableLevels"
                                  v-model="levels"
                                  item-text="name"
                                  total-items
                                  @change="getGrades()"
                                >
                                </v-autocomplete>
                              </v-col>
                              <v-col class="py-0">
                                <v-autocomplete
                                  clearable
                                  deletable-chips
                                  label="Grade"
                                  outlined
                                  v-model="grades"
                                  class="rounded-xl"
                                  :items="gradesData"
                                  item-text="name"
                                  item-value="name"
                                >
                                </v-autocomplete
                              ></v-col>
                            </v-row>

                            <v-row>
                              <!-- v-model="professional.board" -->

                              <v-col class="py-0"
                                ><v-select
                                  label="Board"
                                  :items="boardsData"
                                  item-text="name"
                                  item-value="name"
                                  outlined
                                  class="rounded-xl"
                                  v-model="board"
                                >
                                </v-select>
                              </v-col>
                              <!-- v-model="professional.subjects" -->

                              <v-col class="py-0">
                                <v-autocomplete
                                  clearable
                                  deletable-chips
                                  label="Subject"
                                  outlined
                                  class="rounded-xl"
                                  v-model="subjects"
                                  :items="subjectsData"
                                  item-text="name"
                                  item-value="name"
                                >
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-card>
                      </v-form>
                      <v-container>
                        <v-row>
                          <v-btn
                            rounded
                            outlined
                            class="ma-4"
                            color="brown-text"
                            @click="e1 = e1 - 1"
                          >
                            BACK
                          </v-btn>

                          <v-btn
                            :loading="isCreatingUser"
                            rounded
                            depressed
                            color="primary"
                            class="mx-4 my-4 btn-color white--text"
                            @click="formbtnBool ? updateUser() : createUser()"
                          >
                            {{ formbtn() }} DETAILS
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-card>
              </v-stepper>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Success Dialog -->
      <v-dialog
        v-model="successDialog"
        max-width="366px"
        class="cdz"
        persistent
      >
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 py-4">
                New User {{ formbtnBool == false ? "Created" : "Updated" }}
              </p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="successDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- dialog for resend invite success-->
      <v-dialog
        v-model="resendInviteSuccessDialog"
        class="cdz"
        max-width="366px"
        persistent
      >
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="success" size="96"
                >mdi-check-circle-outline</v-icon
              >
              <p class="text-h5 py-4">Invite Sent Successfully</p>
              <v-btn
                class="accent"
                large
                width="157px"
                rounded
                @click="resendInviteSuccessDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!--diolog for delete academic and professional array-->
      <v-dialog
        v-model="deleteUserDetails"
        class="cdz"
        max-width="366px"
        persistent
      >
        <v-card fluid>
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
              <v-avatar color="#db44371f" size="90"
                ><v-icon size="65" color="#DB4437"
                  >mdi-trash-can-outline</v-icon
                ></v-avatar
              >

              <p class="text-h5 pt-4 pb-0">
                Are Sure you want to delete this Info ?
              </p>
              <p
                class="text-disabled grey--text text-subtitle-1"
                color="rgba(0, 0, 0, 0.6)"
                disabled
              >
                This action will permanently delete the item . This cannot be
                undone
              </p>

              <div class="d-flex justify-space-between" fluid>
                <v-btn
                  class="black--text"
                  color="#0000001a"
                  large
                  width="157px"
                  rounded
                  @click="deleteUserDetails = false"
                  >CANCEL</v-btn
                >
                <v-btn
                  class="primary"
                  large
                  width="157px"
                  rounded
                  @click="removeDataFromSteps()"
                  >DELETE</v-btn
                >
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>

      <!-- Error Dialog -->
      <v-dialog v-model="errorDialog" class="cdz" max-width="366px" persistent>
        <v-card>
          <v-container fluid class="pa-8">
            <v-card-text class="text-center">
              <v-icon color="error" size="96">mdi-close-circle-outline</v-icon>
              <p class="text-h5 pt-2 font-weight-medium">Error</p>
              <p class="text-h6 py-3 font-weight-regular">{{ errorMessage }}</p>
              <v-btn
                class="primary"
                large
                width="157px"
                rounded
                @click="errorDialog = false"
                >OK</v-btn
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- Filter Dialog -->
      <!-- <v-dialog v-model="filterDialog" max-width="400px">
        <v-card width="400px" height="100%">
          <v-card-text class="pa-6">
            <v-row>
              <v-col>
                <div class="pl-1 text-body1 font-weight-bold">FILTER</div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div class="text-body1 font-weight-bold black--text">
                  CLEAR FILTER
                </div>
              </v-col>
            </v-row>
            <div>
              <v-card
                height="450px"
                elevation="0"
                id="myScroll"
                class="pt-5 ,pb-5"
              >
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text">
                      Question Type
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selectedQuestionType"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(statusType, index) in questionTypeList"
                    :key="index"
                    :value="statusType"
                    :class="{
                      'selectedQuestionType-chip':
                        selectedQuestionType.includes(statusType),
                    }"
                    elevated
                    @click="updateQuestionType"
                  >
                    {{ statusType }}
                  </v-chip>
                </v-chip-group>
  
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text pt-3">
                      Level
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selectedLevel"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(level, index) in levels"
                    :key="index"
                    :value="level"
                    :class="{
                      'selectedLevel-chip': selectedLevel.includes(level),
                    }"
                    elevated
                    @click="updateLevel"
                  >
                    {{ level.name }}
                  </v-chip>
                </v-chip-group>
  
                <v-row class="pl-1">
                  <v-col>
                    <div class="text-body1 font-weight-normal black--text pt-3">
                      Difficulty Level
                    </div>
                  </v-col>
                </v-row>
                <v-chip-group
                  v-model="selecteddifficultyLevel"
                  active-class="primary"
                  column
                  :multiple="true"
                >
                  <v-chip
                    v-for="(difficultyLevel, index) in levels"
                    :key="index"
                    :value="difficultyLevel"
                    :class="{
                      'selecteddifficultyLevel-chip':
                        selecteddifficultyLevel.includes(difficultyLevel),
                    }"
                    elevated
                    @click="updatedifficultyLevel"
                  >
                    {{ difficultyLevel.name }}
                  </v-chip>
                </v-chip-group>
  
              </v-card>
              <div>
                <v-card-actions class="px-6 pb-6">
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    outlined
                    class="pa-4"
                    @click="filterDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn rounded class="primary pa-4">Apply</v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog> -->
    </div>
  </div>
</template>
<script>
import "../styles.css";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import AddressController from "@/controllers/AddressController";
import UsersController from "@/controllers/UsersController";
import LevelController from "@/controllers/LevelController";
import SubjectController from "@/controllers/SubjectController";
import ClusterController from "@/controllers/ClusterController";

import BoardController from "@/controllers/BoardController";
import SchoolController from "@/controllers/SchoolController";
import AuthService from "../services/AuthService";
import { onSnapshot, doc } from "firebase/firestore";

import algoliasearch from "algoliasearch";
import { collection, getDocs, getDoc, query, where } from "firebase/firestore";
import { db } from "../firebase";
import CampTotalPieChart from "./CampTotalPieChart.vue";
import CampTotalPieChartLevel from "./CampTotalPieChartLevel.vue";
import Papa from "papaparse";
CampTotalPieChartLevel;
import HorizontalBarChartVue from "./HorizontalBarChart";
import Notification from "./Notification";
import GradeController from "@/controllers/GradeController";
import { debounce } from "lodash";

export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },
  components: {
    CampTotalPieChart,
    HorizontalBarChartVue,
    CampTotalPieChartLevel,
    Notification,
  },
  name: "UsersView",
  data() {
    return {
      filterCount: 0,
      recommended_count: 0,
      userTypeFilter: [],
      rejected_count: 0,
      levelFilter: "",
      appliedFilter: false,
      uploadBulkUsers: false,
      teacherCount: 0,
      jobSeekerCount: 0,
      hiringStatus: [],
      jobseekrRec_Rej_str: "",
      clusters: [],
      cluster: [],
      csvFileUrl: "./files/sampleUsersUpload.csv",
      snapData: [],
      filterArray: [],
      roleFilter: [],
      questionTypeList: null,
      selectedStatusFilter: [],
      screeningTestFilter: [],
      mainsTestFilter: [],
      roleTypeFilter: [],
      searchSchool: "",
      levelTypeFilter: [],
      subjectTypeFilter: [],
      clusterTypeFilter: [],
      schoolTypeFilter: [],
      showClusterSchool: [],
      uploadLevels: [],
      uploadSchools: [],
      uploadSubjects: [],
      uploadBoard: [],
      headding: "All Users",
      schools: [],
      unsubscribe: [],
      bulkUsersJsonData: [],
      bulkUserUploadResponse: [],
      statusType: null,
      graphSchools: [],
      filterVal: "",
      horizontalBarData: [],
      completionStatusGraph: [],
      levelChartData: [["", ""]],
      dLoading: false,
      uploadingUsersDialog: false,
      roleType: ["Teacher", "Job Seeker"],
      showGraph: false,
      showHorizon: false,
      showStatus: false,
      showlevel: false,
      school: [],

      roleCluster: "",
      roleSchool: "",
      dobRules: [
        (v) => !!v || "Date of Birth is required",
        // (v) =>
        //   /^\d{4}-\d{2}-\d{2}$/.test(v) ||
        //   "Date of Birth must be in YYYY-MM-DD format",
      ],

      rules: {
        required: (value) => !!value || "Field is required",
      },
      algoliaIndex: null,
      options: {},
      isLoading: true,
      pageSize: 10,
      clustersSnap: [],
      page: 1,
      count: 0,
      e1: 1,
      editId: null,
      formbtnBool: false,
      experience: "Experienced",
      levels: [],
      status: ["active", "Inactive", "Invited"],
      ScreeningTestStatus: ["Passed", "Failed", "Yet to Start"],
      role: ["Teacher", "Job Seekers"],

      screeningId: -1,
      clusterSchool: "",
      hover: "",
      subjects: [],

      subjectId: [],
      isCurrentlyWorking: false,
      errorMessage: "Failed",

      dialog: false,
      deleteDialog: false,
      singleSelect: false,
      selected: [],
      experienceYear: -1,
      deleteUserDetails: false,
      experienceMonth: -1,
      filterDialog: false,
      notificationDialog: false,
      rolePosition: "",
      employeeTypeId: -1,
      board: "",
      gradesData: [],
      filterData: false,
      grades: [],
      clustersSchool: [],
      gradeId: -1,
      startDate: -1,
      endDate: -1,
      extraCarricularActivities: "",
      isCreatingUser: false,
      successDialog: false,
      resendInviteSuccessDialog: false,
      deleteSuccessDialog: false,
      errorDialog: false,
      boardsData: "",
      tableLevels: [],
      subjectsData: [],
      schoolData: "",
      store: "",
      search: "",
      vgosCount: 0,
      headersresponce: [
        { text: "User Index in CSV", value: "userIndex" },
        { text: "Status", value: "status" },
      ],

      headers: [
        { text: "Full Name", value: "fullname", cellClass: "w-15" },

        { text: "Role", value: "userType", cellClass: "w-15" },
        { text: "School", value: "schoolName", cellClass: "w-15" },
        {
          text: "Subject",
          value: "subject",
          cellClass: "w-15",
        },
        {
          text: "Level",
          value: "level",
          cellClass: "w-15",
        },
        {
          text: "Contact Details",
          value: "contactdetails",
          cellClass: "pr-2 w-15",
        },
      ],
      personalInfo: {
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        isEmailVerified: false,
        isPhoneVerified: false,
        contactInfo: {
          email: "",
          phoneNumber: "",
        },
        addressInfo: {
          countryName: "India",
          stateName: "",
          districtName: "",
          tulakName: "",
          cityName: "",
          pincode: null,
          address: "",
        },
      },
      academicsInfo: [
        {
          institution: "",
          programme: "",
          startDate: "",
          endDate: "",
          fieldOfStudy: "",
          gradeScore: 0,
          gradeType: "",
          certificateUrl: "",
        },
      ],
      professionalInfo: [
        {
          experienceYear: 0,
          experienceMonth: 0,
          position: "",
          employeeTypeId: 0,
          board: 0,
          startDate: Date.now(),
          endDate: Date.now(),
          levels: [],
          grades: [],
          subjects: [],
          school: "",
          otherName: "",
        },
      ],
      expandedPanelIndex: 0, // set the last panel as expanded by default
      countries: [
        {
          name: "India",
        },
      ],
      states: [],
      districts: [],
      cities: [],
      talukas: [],
      users: [],
      employeeType: [
        {
          id: 1,
          name: "Permanent",
        },
        {
          id: 2,
          name: "Contract",
        },
        {
          id: 3,
          name: "Prohibition",
        },
      ],
      algoliaData: [],
      preloader: true,
      assessmentstatus: "",
      // loader: false,
      allGrades:[],
      debouncedFetchUsers: null,
    };
  },
  watch: {
    filterCount(newValue) {
      console.log("filterCount", newValue);
    },
    options: {
      handler() {
        this.pageSize = this.options.itemsPerPage;
        this.page = this.options.page;
        this.users=[];
        this.isLoading=true
        this.fetchUsers();
      },
      deep: true,
    },
    searchSchool(newValue) {
      if (newValue === "") {
        this.showClusterSchool = this.clustersSchool;
      } else {
        const lowercaseNewValue = newValue?.toLowerCase();
        this.showClusterSchool = this.clustersSchool.filter((obj) =>
          obj.name?.toLowerCase().includes(lowercaseNewValue)
        );
      }
    },

    clusterTypeFilter(newValue) {
      this.getClustersSchool(newValue);
    },

    levelFilter(newValue) {
      // this.pageSize = this.options.itemsPerPage;
      // this.page = this.options.page;
      // this.options.page = 1;
      //  if (this.search == null) {
      //     this.search = ''
      //   }
      this.fetchUsers();
    },
    search(newValue) {
      this.pageSize = this.options.itemsPerPage;
      this.page = this.options.page;
      this.options.page = 1;
      if (this.search == null) {
        this.search = "";
      }
      this.debouncedFetchUsers();
    },
  },
  clustersSnap: {
    handler() {},
    deep: true,
  },

  methods: {
    getRecommended() {
      console.log("recommended");
      if (this.hiringStatus[0] == "hiringStatus : recommended") {
        this.hiringStatus = [];
        console.log("xxx", this.hiringStatus);
      } else {
        this.hiringStatus = ["hiringStatus : recommended"];
        console.log("xxx", this.hiringStatus);
      }
      this.fetchUsers();
    },
    getRejected() {
      console.log("rejected");
      // this.hiringStatus = ['hiringStatus : rejected']
      if (this.hiringStatus[0] == "hiringStatus : rejected") {
        this.hiringStatus = [];
        console.log("xxx", this.hiringStatus);
      } else {
        this.hiringStatus = ["hiringStatus : rejected"];
        console.log("xxx", this.hiringStatus);
      }
      this.fetchUsers();
    },
    downloadUsers(csvData, fileName) {
      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

  userFilters(){
    if (this.schoolTypeFilter.length === 0 && this.$store.state.role?.schools) {
        if (Array.isArray(this.$store.state.role.schools)) {
          // If schools is already an array, assign it directly
          this.schoolTypeFilter = this.$store.state.role.schools;
        } else {
          // If schools is not an array, check its value and handle accordingly
          this.schoolTypeFilter = this.$store.state.role.schools !== "All"
            ? [this.$store.state.role.schools]
            : [];
        }
      }

      if(this.clusterTypeFilter.length === 0 && this.$store.state.role?.clusterName){
        if (Array.isArray(this.$store.state.role.clusterName)) {
          // If clusterName is already an array, assign it directly
          this.clusterTypeFilter = this.$store.state.role.clusterName;
        } else {
          // If clusterName is not an array, check its value and handle accordingly
          this.clusterTypeFilter = this.$store.state.role.clusterName !== "All Clusters"
            ? [this.$store.state.role.clusterName]
            : [];
        }
      }
  },

   async downlaodRoles() {
      let users;
      this.userFilters();
      let data = {searchQuery: "",
      sortCol: "fullName",
      sortOrder: "asc",
      itemsPerPage: this.count,
      schools : this.schoolTypeFilter,
      clusters : this.clusterTypeFilter}
      try {
        const refs = await UsersController.filteredUsers(data);
        if (refs.data.flag) {
          users = refs.data.data.users;
        }
      } catch (error) {
        console.log('faced error while fetching the csv', error)
      }
      const fileName = "users.csv";
      const dataForDownload = [];

      for (const user of users) {
        const name =
          user.personalInfo?.firstName + " " + user.personalInfo?.lastName;
        const role = user.userType;
        const email = user.personalInfo?.contactInfo?.email;
        const phone = user.personalInfo?.contactInfo?.phoneNumber;
        let school = " ";
        let subject = [];
        let level = [];
        if (role?.toLowerCase() === "teacher") {
          school = user.schoolName;
          for (const res of user.responsibleTeachingAt) {
            subject.push(res.subject);
            level.push(res.level);
          }
        } else {
          school = user.teachingInterests?.schools;
          subject = user.teachingInterests?.subjects;
          level = user.teachingInterests?.levels;
        }
        dataForDownload.push({
          name: name,
          role: role,
          school: school,
          subject: subject,
          level: level,
          email: email,
          phoneNumber: phone,
        });
      }

      this.downloadUsers(dataForDownload, fileName);
    },

    // async graphFilter() {
    //   try {
    //     // facetFilters : ['cluster: Cluster 6','assessmentStatus: inprogress']

    //     const filters = ["board: CBSE", "assessmentStatus: inprogress"];

    //     this.algoliaIndex
    //       .search("", {
    //         facetFilters: this.filterArray,
    //       })
    //       .then(({ hits }) => {});
    //   } catch (error) {
    //     console.error("Error:", error);
    //   }
    // },
    tooltipData(data) {
      if (data.length > 1) {
        var data2 = data.slice(2, data.length);
        return data2;
      } else {
        return "";
      }
    },
    managementGraphSnap() {
      var query = "";
      //for management level
      if (this.roleCluster == "All Clusters" && this.roleSchool == "All") {
        query = "dashboards/ums-dashboard";
      } //for cluster level
      else if (
        this.roleCluster !== "All Clusters" &&
        this.roleSchool === "All"
      ) {
        query = "dashboards/ums-dashboard/clusters/" + this.roleCluster;
      } //for school level
      else if (
        this.roleCluster !== "All Clusters" &&
        this.roleSchool !== "All"
      ) {
        query = "dashboards/ums-dashboard/schools/" + this.roleSchool;
      }

      onSnapshot(doc(db, query), { includeMetadataChanges: true }, (doc) => {
        var data = doc.data();
        this.snapData = data;
        this.recommended_count = data.recommended_count;
        this.rejected_count = data.rejectedCount;
        //this.teacherCount = data.teacher_count
        //this.jobSeekerCount = data.jobseeker_count
        this.CompletionStatusData();
        this.levelChart();
      });
    },
    clusterLevelGraphSnapshot() {
      onSnapshot(
        doc(
          db,
          "dashboards",
          "ums-dashboard",
          "clusters",
          this.roleCluster
        ),
        { includeMetadataChanges: true },
        (doc) => {
          console.log("management count", doc.data());
          //this.teacherCount = doc.data().teacher_count
          //this.jobSeekerCount = doc.data().jobseeker_count
        }
      );
    },
    clustersSnapshot() {
      // const q = query(collection(db, "dashboards", "ums-dashboard", "clusters"));
      onSnapshot(
        collection(db, "dashboards", "ums-dashboard", "clusters"),
        (querySnapshot) => {
          const data = [];
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          this.clustersSnap = data;
          this.dataOnCluster();
        }
      );
    },
    clearSearch() {
      console.log("clear the search...>");
      this.search = " ";
    },

    uploadUsers() {
      this.uploadingUsersDialog = true;
      this.uploadBulkUsers = false;

      var index = 2;
      this.bulkUsersJsonData.forEach(async (user_) => {
        try {
          const response = await UsersController.createUser(user_);
          if (response.status == 200) {
            console.log(user_);
            this.bulkUserUploadResponse.push({
              userIndex: index,
              status: "Uploaded Successfully!",
            });
          } else {
            this.bulkUserUploadResponse.push({
              userIndex: index,
              status: "Uploaded Failed!",
            });
          }
          index++;
        } catch (error) {
          console.log(error);
        }
        console.log(this.bulkUserUploadResponse);
      });
    },
    downloadCSV() {
      const link = document.createElement("a");
      link.href = this.csvFileUrl;
      link.download = "sample_upload_CSV.csv";
      link.target = "_blank";
      link.click();
    },
    downloadTextFile(text, filename) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    downloadTextFile(text, filename) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    convertTolowerCase(arr){
      return arr.map(item => item.toLowerCase())
    },
    checkMaters(key, word) { 
      word = word.toLowerCase();
      switch (key) {
        case "level1":
          return this.convertTolowerCase(this.uploadLevels).includes(word);
        case "schoolName":
          return this.convertTolowerCase(this.uploadSchools).includes(word);
        case "subject1":
          if (word == "NA" || word == "na") {
            return true;
          }
          return this.convertTolowerCase(this.uploadSubjects).includes(word);
        case "board1":
          return this.convertTolowerCase(this.uploadBoard).includes(word);
          case "grade1":   
          return this.convertTolowerCase(this.allGrades).includes(word);
        default:
          return true;
      }
    },

    checkvalueHorizon(data, graph) {
      if (data.length > 1) {
        var sum = 0;
        data.forEach((obj) => {
          sum += obj[1];
        });
        sum > 0 ? (this.showGraph = true) : (this.showGraph = false);
      } else {
        this.showGraph = false;
      }

      if (graph == "horizon") {
        this.showHorizon = this.showGraph;
      } else if (graph == "status") {
        this.showStatus = this.showGraph;
      } else if (graph == "level") {
        this.showlevel = this.showGraph;
      }
    },

    goCreatePage() {
      if (this.roleType == "Job Seeker") {
        this.$router.push("create-jobseeker");
      } else {
        this.$router.push("create-teacher");
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.csvFileToJSON(file);
      }
    },
    removeDuplicateByEmail(data){
      let res = [];
      let err =  [];
       data.forEach((item, index ) => {
        if(index == data.findIndex(fin => fin.personalInfo.email === item.personalInfo.email)){
          res.push(item)
        }else {
          err.push(index+1)
        }
      })
      return {res, err}
    },
    csvFileToJSON(file) {
      var contents = [];
      this.unsubscribe.forEach((obj) => {
        obj();
      });

      try {
        Papa.parse(file, {
          header: false,
          skipEmptyLines: true,
          complete: function (results) {
            this.content = results;
            this.parsed = true;
            var removedQuestion = [];
            contents = this.content.data;
            var jsonData = [];

            var blankcell = false;
            for (var i = 0; i < contents.length; i++) {
              // var cells = rows[i].split(",");
              var responibleTeaching = [];
              var rowData = {};
              var cells = contents[i];
              for (var j = 0; j < cells.length; j++) {
                if (i == 0) {
                  var headers = contents[i];
                } else {
                  if (
                    headers[j] == "schoolName" ||
                    headers[j]== "level1" ||
                    headers[j] == "subject1" ||
                    headers[j] == "board" ||
                    headers[j] == "grade1"
                  ) {
                    if (!this.checkMaters(headers[j], cells[j])) {
                      removedQuestion.includes(i)
                        ? ""
                        : removedQuestion.push(i);
                      continue;
                    }
                  }

                  if (headers[j].includes("title")) {
                    var tempMap = {};
                    for (var z = 0; z < 11; z++) {
                      // parent1 loop for personal info
                      //  personalInfo/addressInfo
                      if (headers[j].includes("country")) {
                        var tempAddrMap = {};
                        // loop for add addrs info
                        for (var l = 0; l < 7; l++) {
                          tempAddrMap[headers[j]] = cells[j];
                          j++;
                        }
                        j--;
                        tempMap["addressInfo"] = tempAddrMap;
                      } else if (headers[j].includes("phoneNumber")) {
                        var tempContactMap = {};
                        // loop for add contact info
                        for (var m = 0; m < 2; m++) {
                          tempContactMap[headers[j]] = cells[j];
                          j++;
                        }
                        j--;
                        tempMap["contactInfo"] = tempContactMap;
                      } else {
                        if (
                          headers[j].includes("isEmailVerified") ||
                          headers[j].includes("isPhoneVerified")
                        ) {
                          tempMap[headers[j]] = cells[j].includes("TRUE")
                            ? true
                            : false;
                        } else {
                          tempMap[headers[j]] = cells[j];
                        }

                        j++;
                      }
                    }
                    j--;
                    rowData["personalInfo"] = tempMap;
                  } else if (headers[j].includes("grade")) {
                    tempMap = {};
                    for (var n = 0; n < 3; n++) {
                      if (
                        headers[j] == "level1" ||
                        headers[j] == "subject1"
                      ) {
                        var value = headers[j];
                        value = headers[j].includes("level1")
                          ? "level1"
                          : "subject1";
                        if (!this.checkMaters(value, cells[j])) {
                          removedQuestion.includes(i)
                            ? ""
                            : removedQuestion.push(i);
                          continue;
                        }
                      }
                      tempMap[headers[j].substring(0, headers[j].length - 1)] =
                        cells[j];
                      j++;
                    }
                    j--;
                    responibleTeaching.push(tempMap);
                  } else {
                    rowData[headers[j]] = cells[j].trim();
                  }
                }
                if (cells.length - 1 == j) {
                  rowData["responsibleTeachingAt"] = responibleTeaching;
                  rowData["userType"] = "Teacher";
                }
              }
              if (i != 0) {
                if (!removedQuestion.includes(i)) {
                  console.log("row data", rowData);
                  jsonData.push(rowData);
                }
              }
            }
            let msg = "";
            removedQuestion.length > 0 ? `Row No. ${removedQuestion.join()}  Will not upload due to black cell or Wrong Entry\n` : ''
            let dupeOperation = this.removeDuplicateByEmail(jsonData)
            dupeOperation.err.length > 0 ? msg+= `Row No. ${dupeOperation.err.join()} Will not upload due to duplicate records based on email id\n`: ''
            if (removedQuestion.length != 0 || dupeOperation.err.length != 0 ) {
              this.downloadTextFile(msg, "user uploadReport.txt");
            }
            this.bulkUsersJsonData = dupeOperation.res;
             this.uploadUsers();
          }.bind(this),
        });
      } catch (e) {
        console.error(e);
      }
    },
    toVgosTab() {
      this.roleType = ["Teacher"];
      this.levelFilter = "";
      this.search = "";
      this.headding = "All Users - VGOS";
      this.userTypeFilter = ["userType: Teacher"];
      this.filterArray = [];
      this.hiringStatus = [];
      //////
      this.clusterTypeFilter = [];
      this.levelTypeFilter = [];
      this.assessmentstatus = "";
      ////
      this.fetchUsers();
      this.dataOnCluster();
      this.CompletionStatusData();
      this.levelChart();
      this.hiringStatus = [];
    },
    toJobSeekerTab() {
      this.roleType = ["Job Seeker"];
      this.headding = "All Users - Job Seekers";
      this.levelFilter = "";
      this.filterArray = [];
      this.hiringStatus = [];
      this.search = "";
      this.userTypeFilter = ["userType: Job Seeker"];

      //////
      this.clusterTypeFilter = [];
      this.levelTypeFilter = [];
      this.assessmentstatus = "";
      ////
      this.fetchUsers();
      this.dataOnCluster();
      this.CompletionStatusData();
      this.levelChart();
    },
    toAllTab() {
      this.roleType = ["Teacher", "Job Seeker"];
      this.search = "";
      this.headding = "All Users";
      this.levelFilter = "";
      (this.userTypeFilter = []), (this.filterArray = []);
      this.hiringStatus = [];
      //////
      this.clusterTypeFilter = [];
      this.levelTypeFilter = [];
      this.assessmentstatus = "";
      ////
      this.fetchUsers();
      this.dataOnCluster();
      this.CompletionStatusData();
      this.levelChart();
    },
    async getClustersSchool(clusterTypeFilter) {
      if (this.clusterTypeFilter.length > 0) {
        const response = await SchoolController.getClusterSchool(
          this.clusterTypeFilter
        );
        // console.log("school", response);
        if (response.status == 200) {
          var schools = response.data.schools;
          this.clustersSchool = schools;
          this.showClusterSchool = this.clustersSchool;
          //   console.log("school", this.schools);
        } else {
          // alert(response.data.error);
        }
      } else {
        this.schools = [];
      }
    },
    clearFilter() {
      console.log("clearing the filter....>");
      this.appliedFilter = false;
      this.selectedStatusFilter = [];
      // this.screeningTestFilter = [];
      // this.mainsTestFilter = [];
      this.roleTypeFilter = [];
      this.levelTypeFilter = [];
      this.subjectTypeFilter = [];
      this.clusterTypeFilter = [];
      this.schoolTypeFilter = [];
      this.filterVal = "";
      this.filterCount = 0;
      // (this.filterDialog = false);
      this.filterData = false;
      ///ghanendra
      this.filterUser(
        this.selectedStatusFilter,
        // this.screeningTestFilter,
        // this.mainsTestFilter,
        this.roleTypeFilter,
        this.levelTypeFilter,
        this.subjectTypeFilter,
        this.clusterTypeFilter,
        this.schoolTypeFilter
      );
    },

    async filterUser(
      selectedStatusFilter,
      // screeningTestFilter,
      // mainsTestFilter,
      roleTypeFilter,
      levelTypeFilter,
      subjectTypeFilter,
      clusterTypeFilter,
      schoolTypeFilter
    ) {
      console.log("selectedStatusFilter", selectedStatusFilter);
      // console.log("screeningTestFilter", screeningTestFilter);
      console.log("roleTypeFilter", roleTypeFilter);
      // console.log("mainsTestFilter", mainsTestFilter);
      console.log("levelTypeFilter", levelTypeFilter);
      console.log("subjectTypeFilter", subjectTypeFilter);
      console.log("clusterTypeFilter", clusterTypeFilter);
      console.log("schoolTypeFilter", schoolTypeFilter);
      this.dLoading = true;
      this.roleType = [];
      await this.fetchUsers();
      this.dLoading = false;
      this.filterData = true;
      this.dLoading = false;
    },

    handleData(data) {
      this.levelTypeFilter = [];
      if (data == null) {
        this.levelFilter = "";
      } else {
        this.levelFilter = data;
        this.levelTypeFilter.push(data);
      }
    },
    handleClusterData(data) {
      console.log("horizantal here", data);
      this.clusterTypeFilter = [];
      this.clusterTypeFilter.push(data);
      if (data == null) {
        this.filterArray = this.filterArray.filter(
          (item) => !item.includes("cluster")
        );
      } else {
        this.filterArray = this.filterArray.filter(
          (item) => !item.includes("cluster")
        );
        this.filterArray.push("cluster: " + data);
      }
      this.fetchUsers();
    },
    handleValue(value) {
      if (value == null) {
        this.filterArray = this.filterArray.filter(
          (item) => !item.includes("assessmentStatus")
        );
      } else {
        this.filterArray = this.filterArray.filter(
          (item) => !item.includes("assessmentStatus")
        );
        var temp = value === "Un-assessed" ? "inprogress" : "Assessed";
        this.filterArray.push("assessmentStatus: " + temp);
      }
      var temp = value === "Un-assessed" ? "inprogress" : "Assessed";
      this.assessmentstatus = temp;
      this.fetchUsers();
      // Update the childValue data property with the emitted value
    },

    keyMaker(level, role){
      return `${level.toLowerCase().split(' ').join('_')}_${role}`
    },

    levelChart() {
      if (this.roleType.includes('Teacher') && this.roleType.includes('Job Seeker')) {
        this.levelChartData = this.uploadLevels.map(level=>
          [level, this.snapData[this.keyMaker(level,"teacher")]+this.snapData[this.keyMaker(level,"jobseeker")]]
        );
      }
      else if (this.roleType.includes('Teacher')) {
        this.levelChartData = this.uploadLevels.map(level=>[level, this.snapData[this.keyMaker(level,"teacher")]]);
      } 
      else if (this.roleType.includes('Job Seeker')) {
        console.log('this.snapData', this.snapData, 'this.uploadLevels', this.uploadLevels)
        this.levelChartData = this.uploadLevels.map(level=>[level, this.snapData[this.keyMaker(level,"jobseeker")]]);
        console.log('this.levelChartData', this.levelChartData)
      }
      this.checkvalueHorizon(this.levelChartData, "level");
    },
    CompletionStatusData() {
      if (this.roleType.includes('Teacher') && this.roleType.includes('Job Seeker') ) {
        this.completionStatusGraph = [
          [
            "Assessed",
            this.snapData.accessed_jobseeker + this.snapData.accessed_teacher,
          ],
          [
            "Un-assessed",
            this.snapData.unaccessed_teacher +
              this.snapData.unaccessed_jobseeker,
          ],
        ];
      } else if (this.roleType.includes('Teacher')) {
        this.completionStatusGraph = [
          ["Assessed", this.snapData.accessed_teacher],
          ["Un-assessed", this.snapData.unaccessed_teacher],
        ];
      } else if (this.roleType.includes('Job Seeker')) {
        this.completionStatusGraph = [
          ["Assessed", this.snapData.accessed_jobseeker],
          ["Un-assessed", this.snapData.unaccessed_jobseeker],
        ];
        console.log("jobseeker", this.completionStatusGraph);
      }
      this.checkvalueHorizon(this.completionStatusGraph, "status");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async dataOnCluster() {
      var horizontalBarData = [["not found", "", { role: "style" }]];
      if (
        this.roleType.includes("Teacher") &&
        this.roleType.includes("Job Seeker")
      ) {
        console.log("All", this.roleType);
        this.clustersSnap.forEach((obj) => {
          horizontalBarData.push([
            obj.cluster,
            obj.teacher_count + obj.jobseeker_count,
            "color: #6A4F79",
          ]);
        });
      } else if (this.roleType.includes("Teacher")) {
        console.log("Teacher");
        this.clustersSnap.forEach((obj) => {
          horizontalBarData.push([
            obj.cluster,
            obj.teacher_count,
            "color: #6A4F79",
          ]);
        });
      } else if (this.roleType.includes("Job Seeker")) {
        console.log("Job Seeker");
        this.clustersSnap.forEach((obj) => {
          horizontalBarData.push([
            obj.cluster,
            obj.jobseeker_count,
            "color: #6A4F79",
          ]);
        });
      } else {
      }
      this.horizontalBarData = horizontalBarData;
      this.checkvalueHorizon(this.horizontalBarData, "horizon");
    },

    testMethod() {},

    async getClusterSchool() {
      const response = await SchoolController.getClusterSchool([this.cluster]);
      if (response.data.flag) {
        var schools = response.data.schools;
        this.schools = schools;
        this.uploadSchools = this.schools;
      } else {
        // alert(response.data.error);
      }
    },
    async fetchAllClusters() {
      const response = await ClusterController.getAllClusters();

      if (response.data.flag) {
        this.clusters = response.data.clusters;
      } else {
        // alert(response.data.error);
      }
    },
    getClusterOrSchool() {
      this.roleCluster = this.$store.state.role.clusterName;
      this.roleSchool = this.$store.state.role.schools;
      console.log("cluster=", this.roleCluster, "school==", this.roleSchool);
      if (this.roleSchool == undefined) {
        AuthService.logout();
        this.$router.push("/login");
      }

      if (this.roleCluster != "All Clusters") {
        this.cluster = this.roleCluster;
        this.clusterSchool = this.roleCluster;
        this.getClusterSchool();
      }
      if (this.roleSchool != "All") {
        this.school = this.roleSchool;
        this.uploadSchools = [this.school];
        this.clusterSchool = this.roleSchool;
      }

      //for management level
      if (this.roleCluster == "All Clusters" && this.roleSchool == "All") {
      } //for cluster level
      else if (
        this.roleCluster !== "All Clusters" &&
        this.roleSchool === "All"
      ) {
        console.log("logged in as cluster head!!!!!!!!!!!!!!!!!");
        this.roleFilter.push("cluster: " + this.roleCluster);
      } //for school level
      else if (
        this.roleCluster !== "All Clusters" &&
        this.roleSchool !== "All"
      ) {
        this.roleFilter.push("schoolName: " + this.roleSchool);
      }
    },
    getStepName(step) {
      var name = "";
      if (step == 1) {
        name = "Personal Information";
      } else if (step == 2) {
        name = "Academics";
      } else if (step == 3) {
        name = "Professional Info";
      } else if (step == 4) {
        name = "VGOS Onboarding";
      }
      return name;
    },
    resetProgress() {
      this.progress = 0;
      this.buffer = 100;
      this.dialogVisible = false;
    },
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },

    // async getTotalCount() {
    //   console.log("filter array count", [
    //     ...this.roleFilter,
    //     ...this.filterArray,
    //     ...this.hiringStatus,
    //     ...this.userTypeFilter,
    //   ]);
    //   const allHits = await this.algoliaIndex.search(
    //     this.search + " " + this.levelFilter,
    //     {
    //       facetFilters: [
    //         ...this.roleFilter,
    //         ...this.filterArray,
    //         ...this.hiringStatus,
    //         ...this.userTypeFilter,
    //       ],
    //       queryType: "prefixNone",
    //     }
    //   );
    //   this.count = allHits.nbHits;
    // },

    getDate(timeStamp) {
      return new Date(timeStamp).toString().substring(0, 16);
    },
    async updateProfessionalInfo(data, userId) {
      const response = await UsersController.updateProfessionalInfo(
        data,
        userId,
        data.id
      );
    },
    goToStep2() {
      if (this.formbtnBool) {
        if (this.$refs.step1.validate()) {
          this.e1 = 2;
        }
      } else {
        if (this.$refs.step1.validate()) {
          this.e1 = 2;
          this.expandedPanelIndex = 0;
        }
      }
    },
    updateData(item) {
      if (this.$store.state.role?.teacherOnboarding?.admin) {
        this.$store.state.user = item;
        if (item.userType == "Teacher") {
          this.$router.push("/update-teacher");
        } else {
          this.$router.push("/update-jobseeker");
        }
      }
    },
    async getAllGrades() {
      const response = await GradeController.getAllGrades();
      if (response?.status == 200) {
        this.allGrades = response.data?.grades.map(item => item.name.toLowerCase())
      }
    },

    async getSubjects() {
      const response = await SubjectController.getSubject();

      if (response.status == 200) {
        this.subjectsData = response.data.subjects;
        this.subjectsData.forEach((obj) => {
          this.uploadSubjects.push(obj.name);
        });
      } else {
        // alert("subject Not Found!!");
      }
    },
    async getLevels() {
      const response = await LevelController.getLevel();
      if (response.status == 200) {
        this.tableLevels = response.data.levels;
        this.tableLevels.forEach((obj) => {
          this.uploadLevels.push(obj.name);
        });
      } else {
        // alert("Levels Not Found!!");
      }
    },
    async getGrades() {
      const array = this.tableLevels.filter((level) =>
        this.levels.includes(level.name)
      );
      this.gradesData = [];
      array.forEach((subArray) => {
        subArray.grades.forEach((grade) => {
          this.gradesData.push(grade);
        });
      });
    },
    goToStep3() {
      if (this.formbtnBool) {
        // this.updateAcademics();
        this.e1 = 3;
      } else {
        if (this.$refs.step2.validate()) {
          this.e1 = 3;
          this.expandedPanelIndex = 0;
        }
      }
    },
    async createUser() {
      if (this.formbtnBool) {
        this.dialog = false;
        this.formbtnBool = false;
      } else if (this.$refs.step3.validate()) {
        (this.personalInfo.contactInfo.phoneNumber =
          "+91" + this.personalInfo.contactInfo.phoneNumber),
          (this.isCreatingUser = true);
        const response = await UsersController.createUser({
          userType: "TEACHER",
          status: "INACTIVE",

          cluster: this.cluster,
          schoolName: this.school,

          board: this.board,
          responsibleTeachingAt: [
            {
              level: this.levels,
              grade: this.grades,
              subject: this.subjects,
            },
          ],
          subjects: this.subjects,
          personalInfo: this.personalInfo,
          academicsInfo: this.academicsInfo,
          professionalInfo: this.professionalInfo,
        });
        if (response.status == 200) {
          this.isCreatingUser = false;
          this.successDialog = true;
          this.dialog = false;
          this.fetchUsers();
        } else {
          this.isCreatingUser = false;
          this.errorMessage = response.data.error;
          this.errorDialog = true;
        }
      }
    },
    async updateUser() {
      const response = await UsersController.updateUserInfo(
        {
          userType: "Job Seeker",
          status: "INACTIVE",
          cluster: this.cluster,
          school: this.school,
          levels: this.levels,
          grades: this.grades,
          board: this.board,
          personalInfo: this.personalInfo,
          academicsInfo: this.academicsInfo,
          professionalInfo: this.professionalInfo,
        },
        this.editId
      );

      if (response.status) {
        this.isCreatingUser = false;
        this.successDialog = true;
        this.dialog = false;
        this.fetchUsers();
      } else {
        this.isCreatingUser = false;
        this.errorMessage = response.data.error;
        this.errorDialog = true;
      }
    },
    async updateAcademics(qualification, usrid) {
      const response = await UsersController.updateAcademics(
        qualification,
        usrid,
        qualification.id
      );
    },
    async getAcademics(id) {
      const response = await UsersController.getAcademics(id);
      this.academicsInfo = response.data.data;
    },

    async getProfessionalInfo(id) {
      const response = await UsersController.getprofessionalInfo(id);
      this.professionalInfo = response.data.data;
    },
    formbtn() {
      return this.formbtnBool === false ? "Create" : "Update";
    },

    async deleteUser(items) {
      var ids = [];
      this.dLoading = true;
      items.forEach((doc) => {
        ids.push(doc.userId);
      });
      const response = await UsersController.deleteUser({
        ids: ids,
      });
      if (response.status == 200) {
        console.log("ids :", ids);
        console.log("users :", this.users);

        let currIndex = 0;
        while (currIndex < this.users?.length) {
          if (ids.includes(this.users[currIndex].userId)) {
            this.users.splice(currIndex, 1);
          } else {
            currIndex += 1;
          }
        }

        this.selected = [];
        this.selected.length = 0;
        ids = [];
        this.dLoading = false;
        setTimeout(() => {
          // This code will execute after a 1 second delay
          // this.initAlgoliaClient();
          this.fetchUsers();
          // this.getTotalCount();
        }, 5000);
      } else {
        alert(response.data.error);
        this.dLoading = false;
      }
      this.deleteDialog = false;
      this.dLoading = false;
      this.deleteSuccessDialog = true;
    },

    addAcademicQualification() {
      this.academicsInfo.push({
        institution: "",
        programme: "",
        startDate: "",
        endDate: "",
        fieldOfStudy: "",
        gradeScore: 0,
        gradeType: "",
        certificateUrl: "",
      });
      this.expandedPanelIndex = this.academicsInfo.length - 1;
    },
    addProfessionalInfo() {
      this.professionalInfo.push({
        experienceYear: 0,
        experienceMonth: 0,
        position: "",
        employeeTypeId: 0,
        board: 0,
        startDate: "",
        endDate: "",
        school: "",
      });
      this.expandedPanelIndex = this.professionalInfo.length - 1;
    },

    async fetchUsers() {
      // await this.getTotalCount();
      await this.getUsersFromAlgolia();
      this.isLoading = false;
      this.preloader = false;

      // this.graphFilter();
    },
    async fetchCountries() {
      const response = await AddressController.getCountries();

      if (response.data.success) {
        this.countries = response.data.data.rows;
      } else {
        // alert(response.data.error);
      }
    },

    async fetchStates() {
      var states = [];
      const querySnapshot = await getDocs(collection(db, "states"));
      if (querySnapshot.empty) {
        // alert("States Not Found!!");
        return;
      } else {
        querySnapshot.forEach((doc) => {
          states.push(doc.data());
        });
      }
      this.states = states;
    },

    async fetchDistricts() {
      const q = query(
        collection(db, "districts"),
        where("state", "==", this.personalInfo.addressInfo.stateName)
      );
      const querySnapshots = await getDocs(q);
      this.districts = [];
      querySnapshots.forEach((doc) => {
        this.districts.push(doc.data());
      });
    },

    async fetchCitiesAndTalukas() {
      const q = query(
        collection(db, "cities"),
        where("state", "==", this.personalInfo.addressInfo.stateName),
        where("district", "==", this.personalInfo.addressInfo.districtName)
      );
      const querySnapshots = await getDocs(q);
      this.cities = [];
      querySnapshots.forEach((doc) => {
        this.cities.push(doc.data());
      });
    },

    async fetchTalukas() {
      const response = await AddressController.getTalukas(
        this.personalInfo.addressInfo.districtName
      );

      if (response.data.success) {
        this.talukas = response.data.data.rows;
      } else {
        // alert(response.data.error);
      }
    },
    async fetchCities() {
      const response = await AddressController.getCities(
        this.personalInfo.addressInfo.stateName,
        this.personalInfo.addressInfo.tulakName
      );
      if (response.data.success) {
        this.cities = response.data.data.rows;
      } else {
        // alert(response.data.error);
      }
    },
    removeDataFromSteps() {
      this.deleteUserDetails = true;
      if (this.e1 == 2) {
        this.academicsInfo.splice(this.indexValue, 1);
      } else if (this.e1 == 3) {
        this.professionalInfo.splice(this.indexValue, 1);
      }
      this.deleteUserDetails = false;
      this.indexValue = null;
    },
    openDeleteDiolog(index) {
      this.indexValue = index;
      this.deleteUserDetails = true;
    },
    async getBoards() {
      const response = await BoardController.getBoards();

      if (response.status == 200) {
        this.boardsData = response.data.boards;
        this.boardsData.forEach((obj) => {
          this.uploadBoard.push(obj.name);
        });
      } else {
        // alert("Boards Not Found!!");
      }
    },
    async getSchool() {
      const response = await SchoolController.getSchool();
      if (response.status == 200) {
        this.schoolData = response.data.schools;
        this.schoolData.forEach((obj) => {
          this.uploadSchools.push(obj.name);
        });
      } else {
        // alert("Schools Not Found!!");
      }
    },

    async getUsersFromAlgolia() {
      console.log("filter array in users:", [
        ...this.roleFilter,
        ...this.filterArray,
        ...this.hiringStatus,
        ...this.userTypeFilter,
      ]);
      this.userFilters();
      ///ghanendra
      var data = {};
      data.pgNum = this.page - 1;
      data.sortCol = "fullName";
      data.sortOrder = "asc";
      data.itemsPerPage = this.pageSize;
      data.searchQuery = this.search ?? "";
      data.status = this.selectedStatusFilter;
      // data.screeningTestResult = this.screeningTestFilter;
      // data.mainsTestResult = this.mainsTestFilter;
      data.levels = this.levelTypeFilter;
      data.subjects = this.subjectTypeFilter;
      data.schools = this.schoolTypeFilter;
      data.clusters = this.clusterTypeFilter;
      data.assessmentStatus = this.assessmentstatus;
      if(this.roleTypeFilter.length){
        data.roles = this.roleTypeFilter 
      } else{
        data.roles =  this.roleType 
      }
      try {
        const refs = await UsersController.filteredUsers(data);
        if (refs.data.flag) {
          this.users = refs.data.data.users;
          this.count = refs.data.data.totalUsers;
          this.preloader = false;
        }
      } catch (error) {
        alert(Error);
        this.preloader = false;
      }
    },

    getFilterCount() {
      this.filterCount = 0;
      let data = [this.selectedStatusFilter, this.screeningTestFilter, this.mainsTestFilter, this.roleTypeFilter, this.levelTypeFilter, this.subjectTypeFilter, this.schoolTypeFilter]
      this.filterCount = data.reduce((acc, cur)=> cur.length>0? acc+1 : acc, 0)
    },    
    async getUserCounts() {
      this.teacherCount = 0;
      this.jobSeekerCount = 0;
      const data = [];
      const querySnapshot = await getDocs(collection(db, "users"));
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      this.teacherCount = data.filter(user=>user.userType == "Teacher").length;
      this.jobSeekerCount = data.filter(user=>user.userType == "Job Seeker").length;
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    filteredLevel() {
      if (this.searchLevels) {
        const regex = new RegExp(this.searchLevels);
        return this.tableLevels.filter((tableLevels) =>
          regex.test(tableLevels.name)
        );
      } else {
        return this.tableLevels;
      }
    },

    filteredBoard() {
      if (this.searchBoards) {
        const regex = new RegExp(this.searchBoards);
        return this.boardsData.filter((boardData) =>
          regex.test(boardData.name)
        );
      } else {
        return this.tableLevels;
      }
    },
    filteredSubject() {
      if (this.searchSubject) {
        const regex = new RegExp(this.searchSubject);
        return this.subject.filter((subject) => regex.test(subject.name));
      } else {
        return this.subject;
      }
    },
  },
  created() {
    this.getUserCounts();
    this.fetchStates();
    this.dataOnCluster();
    this.levelChart();
    this.fetchUsers();
    this.getLevels();
    this.getSubjects();
    this.getGrades();
    this.getBoards();
    this.getSchool();
    this.fetchAllClusters();
    this.getAllGrades();
    // this.SnapshotOnCluster();
    this.debouncedFetchUsers = debounce(this.fetchUsers,500);
    this.$store.state.breadcrumb = "Users";
    // this.updateUsers()
  },
  mounted() {
    if (this.$store.state.role.createdOn == undefined) {
      this.logout();
    }
    if (!this.$store.state.role.teacherOnboarding.read) {
      this.$router.push("/notFound");
    }
    this.clustersSnapshot();

    this.getClusterOrSchool();
    this.managementGraphSnap();
  },
  unmounted(){
    this.debouncedFetchUsers.cancel()
  },
};
</script>
<style scoped>

@import url(../common.css);

.btnStyle.v-btn--outlined {
  border: thin solid #190cca;
}

.blue-border {
  border-color: blue !important;
}
.blue-outline{
  background-color: #fff !important;
  outline: 1px solid #1B72E8 !important;
  box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.12);
}

.not-active-btn{
  background-color: #FEFCFB !important;
}

.graph {
  border-radius: 8px !important;
  margin-left: 8px;
  padding: 0;
}

.users-tab-container .v-btn--has-bg {
  background: var(--white-01);
}

</style>